import React from 'react';
import PropTypes from 'prop-types';

import './selectTabs.scss';

export class SelectTabs extends React.Component {

  static propTypes = {
    tabs: PropTypes.array,
    initial: PropTypes.string,
    onChangeTab: PropTypes.func
  };

  static defaultProps = {
    tabs: [],
    initial: '',
    onChangeTab: () => {},
  };

  constructor(props, context) {
    super(props, context);
    this.state = {
      activeTab: props.initial
    };
  }

  render({tabs , onChangeTab} = this.props) {
    return (
      <div className='selectTabsWrapper'>
        {tabs && tabs.map((tab, idx) => (

          <div key={idx}
               onClick={() => this.setState({activeTab: tab}, () => onChangeTab(tab))}
               className={`clickable tab ${tab === this.state.activeTab ? 'active' : ''}`}>

            <span className={`checkmark ${tab === this.state.activeTab ? 'active' : ''}`}/>

            <p className='center' style={{textTransform: 'capitalize'}}>{tab}</p>
          </div>
        ))}
      </div>
    );
  }
}