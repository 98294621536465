import {api} from "../../lib/api";
import {store} from "../../index.js"
import { getTechnicalLink, getOrganisationId, getLogicalAccount, getSsoAccount, getCurrentTenantId } from "../apiConstants";

export const setFilter = ({filters}) => {
  return async (dispatch) => {
    dispatch({
      type: 'SET_FILTER',
      filters: filters
    });
  }
};

export const resetFilter = ({key}) => {
  return async (dispatch) => {
    dispatch({
      type: 'RESET_FILTER',
      key: key
    });
  }
};


export const getLiveEvents = ({uuid, filters}) => {
  var jsonToSend = {
    "domainId": uuid,
    "event": "all",
    "minutes": 1440,
    "blocked": "all",
    "ip": "all",
    "size": 50,
    "otherEvent": false,
    "otherIp": false
  }

  //IP filter
  if(filters.ip.selectedValues.length == 1) {
    //Case: ['all'] or ['my_ip']
    jsonToSend.ip = filters.ip.selectedValues[0];
  } else {
    //Case: ['other', '192.169.0.0']
    jsonToSend.ip = filters.ip.selectedValues[1];
    jsonToSend.otherIp = true;
  }

  //Forwarded traffic filter
  jsonToSend.blocked = filters.blocked.selectedValues[0];

  //Timestamp filter
  jsonToSend.minutes = filters.timestamp.selectedValues[0];

  //Event filter
  if(filters.event.selectedValues.length == 1) {
    //Case: ['all'], ['datalayer'], ['pageview'], etc.
    jsonToSend.event = filters.event.selectedValues[0];
  } else {
    //Case: ['other', 'specific-event']
    jsonToSend.event = filters.event.selectedValues[1];
    jsonToSend.otherEvent = true;
  }

  return async (dispatch) => {
    // let res = await api.post_ignore_errors('dashboard/live.json', {uuid: uuid, filters: filters});
    const BASE_ELASTIC_ENDPOINT = `v1/agencies/${getTechnicalLink()}/tenants/${getCurrentTenantId()}`;
    let res = await api.posttodotnet(`${BASE_ELASTIC_ENDPOINT}/elastic/live`, jsonToSend);

    if (res) {
      dispatch({
        type: 'SET_LIVE_EVENTS',
        events: res.events ? res.events : [],
        data: res.data,
      });
    }
  }
};


export function resetDashboardState() {
  return async (dispatch) => {
    dispatch({
      type: 'RESET_DASHBOARD_STATE'
    })
  }
}


