import React from "react";
import PropTypes from 'prop-types';
import ReactMarkdown from 'react-markdown';

import {InputSelect} from "../inputSelect/inputSelect";
import {Button} from "../button/button";
import {colors} from "../../theme/colors";
import {Input} from "../input/input";
import {emailValidator} from "../../lib/validators";
import {getWiki} from "../wiki/wiki";
import {CopyIcon} from "../icon/copy";

import './gdprModal.scss'

export class GDPRConfirmModal extends React.Component {

  static propTypes = {
    onConfirm: PropTypes.func,
    onSkip: PropTypes.func,
    role_type: PropTypes.oneOf(['admin', 'dpo']),
    creditor: PropTypes.any,
    confirmPending: PropTypes.bool,
  };

  constructor(props, context) {
    super(props, context);

    this.state = {
      selectedValues: [],
      link: `https://portal.tracedock.com/review_terms/${props.creditor.uuid}`,
      subject: '',
      email: '',
      gdpr_text: '',
    };
  }

  componentDidMount() {

    getWiki('gdpr_email.md', {link: this.state.link}, true).then(
      (content) => {
        // data is split between ***
        if (content !== '') {
          content = content.split('***');
          this.setState({subject: content[0], email: content[1]})
        }
      }
    );

    getWiki('gdpr.md', {}, true).then(
      (content) => {
        if (content !== '') this.setState({gdpr_text: content})
      }
    )
  }


  render({onConfirm, creditor, children} = this.props) {

    let invalidForm = this.state.selectedValues.length === 0 || (this.props.role_type === 'dpo' && !emailValidator(this.state.email));

    return (
      <div className='gdprModalWrapper'>

        <div className='gdprModal slideInFromTop'>

          <div className='modalOnboardContent'>

            <div className='bodyModal'>

              <div className='bodyContent'>
                <h4>TraceDock's Data Protection terms</h4>
                <h6 className='spacer'>Please review and sign below</h6>

                <div className='terms'>
                  <ReactMarkdown source={this.state.gdpr_text}/>
                </div>

                <div style={{marginTop: 42}}>

                  {this.props.role_type === 'dpo' && (
                    <Input
                      style={{marginBottom: 24}}
                      label="Email"
                      type="email"
                      focus={true}
                      onChange={(email) => this.setState({email})}
                    />
                  )}

                  <InputSelect
                    size='bold consent'
                    type='checkbox'
                    selectedValues={this.state.selectedValues}
                    choices={[
                      {key: 'confirm', value: `I am legally allowed to sign${creditor.company_name ? ' for ' + creditor.company_name : ''} and hereby confirm the data protection terms.`}
                    ]}
                    onChange={(values) => this.setState({selectedValues: values})}
                  />

                  <Button
                    style={{marginTop: 12}}
                    disabled={invalidForm}
                    onClick={() => onConfirm({email: this.state.email, uuid: this.props.creditor.uuid})}
                    isLoading={this.props.confirmPending}
                    title='Confirm'/>
                </div>

                {this.props.role_type === 'admin' && (
                  <div>
                    <h6 style={{marginTop: 42}}>Not allowed to sign?</h6>

                    <p>You can send an email to your data protection officer, and with this link the terms can be reviewed and confirmed remotely.</p>

                    <div className='row' style={{alignItems: 'center'}}>
                      <p className='code' style={{marginRight: 12}}>{this.state.link}</p>
                      <CopyIcon
                        text={this.state.link}
                      />
                    </div>

                    <div className='row' style={{justifyContent: 'space-between', marginTop: 24}}>

                      <a style={{width: '48%'}}
                         target="_blank" rel="noopener noreferrer" title='Generate email'
                         href={encodeURI(`mailto:?subject=${this.state.subject}&body=${this.state.email}`)}>
                        <Button
                          backgroundColor='white'
                          color={colors.brand.primary}
                          title='Generate email'
                          onClick={() => console.log('email')}
                        />
                      </a>

                      <Button
                        backgroundColor='white'
                        color={colors.brand.primary}
                        style={{width: '48%'}}
                        title='Skip for now'
                        onClick={() => this.props.onSkip()}/>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>

        </div>

      </div>
    );
  }
}