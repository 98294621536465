import React, {useCallback, useState} from 'react';
import {PropTypes} from 'prop-types';
import SectionTitle from '../SectionTitle';
import {Form, FormGroup, FormInline, InputField} from '../../../../components/form';
import {accountActions} from '../../../../redux/actions';
import {connect} from 'react-redux';

import styles from '../sectionContainer.module.scss';
import {isCountryValidator, isEmailValidator, isRequiredValidator, isVATvalidator} from "../../../../lib/validators";
import {STAGE} from "../../constants";
import {countries} from "../../../../lib/countries";

const AdditionalFinancialSection = ({goNext, updateCreditor, admin, isPendingUpdate}) => {
  const onConfirm = useCallback(
    async (data) => {
      setPending(true);
      let res = await updateCreditor({
        uuid: admin.uuid,
        creditor: data
      });

      // only navigate onwards if save went oke
      if (res) {
        goNext(STAGE.DOMAIN);
      } else {
        setPending(false);
      }
    },
    [goNext, updateCreditor]
  );

  const [pending, setPending] = useState(false);

  return (
    <div className={styles.section_content}>
      <SectionTitle
        title='Additional financial information'
        subtitle='To whom can we send the invoices?'
      />
      <Form
        isLoading={isPendingUpdate || pending}
        buttonText='Confirm financial information'
        onSave={onConfirm}
        initialValues={admin}
        validators={{
          company_name: isRequiredValidator,
          chamber_of_commerce: isRequiredValidator,
          tax_number: isVATvalidator,
          firstname: isRequiredValidator,
          lastname: isRequiredValidator,
          email: isEmailValidator,
          address1: isRequiredValidator,
          zipcode: isRequiredValidator,
          city: isRequiredValidator,
          country: isCountryValidator,
        }}
      >
        <FormGroup title='Company details'>
          <InputField name='company_name' label='Company name'/>
          <InputField name='chamber_of_commerce' label='Chamber of Commerce number' focus={true}/>
          <InputField name='tax_number' label='VAT number (EU)' optional={true}/>
          <InputField name='prefix_reference' label='Invoice reference number' optional={true}/>
        </FormGroup>
        <FormGroup title='Financial contact person'>
          <FormInline>
            <InputField name='firstname' label='First name'/>
            <InputField name='lastname' label='Last name'/>
          </FormInline>
          <InputField name='email' label='Email address'/>
        </FormGroup>
        <FormGroup title='Billing address'>
          <InputField name='address1' label='Address line 1'/>
          <InputField name='address2' label='Address line 2' optional={true}/>
          <InputField name='zipcode' label='Zip code'/>
          <FormInline>
            <InputField name='city' label='City'/>
            <InputField name='country' label='Country' choices={countries} restrict={false} enterIsSubmit={true}/>
          </FormInline>
        </FormGroup>
      </Form>
    </div>
  )
};

AdditionalFinancialSection.propTypes = {
  goNext: PropTypes.func,
  updateCreditor: PropTypes.func
};

const mapDispatchToProps = (dispatch) => {
  return {
    updateCreditor: (data) => dispatch(accountActions.updateCreditor(data))
  }
};

const mapStateToProps = (state) => ({
  admin: state.account.admin,
  isPendingUpdate: state.account.isPendingUpdate
});

export default connect(mapStateToProps, mapDispatchToProps)(AdditionalFinancialSection);
