import {cmAccountState} from './cmAccount.state';

export function cmAccountReducer(state = cmAccountState, action) {

  switch (action.type) {
    case 'SET_CMACCOUNT_CREDENTIALS':
      return {
        ...state,
        cmSSOAccountGuid: action.cmSSOAccountGuid.UserData.AccountGuid,
        cmLogicalAccountGuid: action.cmLogicalAccountGuid.ID,
        traceDockKey: action.traceDockKey,
        is_agency: action.is_agency,
        organisationId: action.cmLogicalAccountGuid.OrganizationID
      };
      case 'SET_CURRENT_TENANTID':
        return {
          ...state,
          currentTenantId: action.currentTenantId
        };
    default:
      return state;
  }
  
}
