import packageInfo from '../../package.json';
import {setAlertDialog} from "../components/alert/alert";
import { Json } from '../components/json/json';
import {store} from "../index"


class Api {
  // tempUuid = "iuY6OdxFfr6imQWkP6C0m8VKP6tonF";
  baseURL = process.env.REACT_APP_STATE == "production" ? "https://api.cm.com/tracedock/" : process.env.REACT_APP_STATE == "test" ? "https://api.cmtest.nl/tracedock/": "https://localhost:7075/tracedock/";
  with_creds = process.env.REACT_APP_STATE == "production" || process.env.REACT_APP_STATE == "test" ? "include" : "same-origin";
  constructor() {

    try {
      var currentENV = process.env.REACT_APP_STATE;
      this.config = require(`../config/${process.env.REACT_APP_CONFIG}`);
      // if(currentENV == "production"){
      //   this.baseURL = "a28feb2618dd8405a8b4dd32e14edf03-1154516619.eu-central-1.elb.amazonaws.com/api/"
      // }
    } catch(e) {
      //catch for storybook because it does not recognize process
      this.config = require('../config/local');
    }
    this.config = this.config.default;

    // setup after persisting in store
    this.access_token = null;
    this.store = null;
  }

  async get(endpoint = '') {
    return fetch(`${this.config.api_url}/${endpoint}`, {
      method: "GET",
      headers: this.buildHeaders()
    })
      .then((response) => this.handleResponse(endpoint, response))
      .catch( (error) => this.handleError(error))
  }

  async post(endpoint = '', payload = {}) {
    return fetch(`${this.config.api_url}/${endpoint}`, {
      method: "POST",
      body: JSON.stringify(payload),
      headers: this.buildHeaders(),
      credentials: this.with_creds
    })
      .then((response) => this.handleResponse(endpoint, response))
      .catch( (error) => this.handleError(error))
  }

  async getdotnet(endpoint = '', uuid = '') {
    //Temporary hard coded domain uuid, because other DB has no knowledge yet
    return fetch(this.baseURL+endpoint, {
      method: "GET",
      headers: this.buildtestHeaders(),
      credentials: this.with_creds
    })
      .then((response) => this.handleResponse(endpoint, response))
      .catch( (error) => this.handleError(error))
  }

  async posttodotnet(endpoint = '', payload = {}) {
    return fetch(this.baseURL+endpoint, {
      method: "POST",
      body: JSON.stringify(payload),
      headers: this.buildtestHeaders(),
      credentials: this.with_creds
    })
      .then((response) => this.handleResponse(endpoint, response))
      .catch( (error) => this.handleError(error))
  }

  async puttodotnet(endpoint = '', payload = {}) {
    return fetch(this.baseURL+endpoint, {
      method: "PUT",
      body: JSON.stringify(payload),
      headers: this.buildtestHeaders(),
      credentials: this.with_creds
    })
      .then((response) => this.handleResponse(endpoint, response))
      .catch( (error) => this.handleError(error))
  }

  async putexternaltodotnet(endpoint = '', payload = {}) {

    // payload.uuid = "e0d04541-88f4-4e23-ad03-f0689f001357"
    return fetch(this.baseURL+endpoint, {
      method: "PUT",
      body: JSON.stringify(payload),
      headers: this.buildtestHeaders(),
      credentials: this.with_creds
    })
      .then((response) => this.handleResponse(endpoint, response))
      .catch( (error) => this.handleError(error))
  }

  async deletetodotnet(endpoint = '', payload = {}) {
    var uuid = JSON.stringify({uuid : payload.uuid})
    return fetch(this.baseURL+endpoint, {
      method: "DELETE",
      body: uuid,
      headers: this.buildtestHeaders(),
      credentials: this.with_creds
    })
      .then((response) => this.handleResponse(endpoint, response))
      .catch( (error) => this.handleError(error))
  }

  async post_ignore_errors(endpoint = '', payload = {}) {
    return fetch(`${this.config.api_url}/${endpoint}`, {
      method: "POST",
      body: JSON.stringify(payload),
      headers: this.buildHeaders(),
      credentials: this.with_creds
    })
      .then((response) => this.handleResponse(endpoint, response))
      .catch( (error) => console.log('.... Error: ', endpoint, error))
  }

  async post_ignore_errors2(endpoint = '', payload = {}) {
    return fetch(this.baseURL+"elastic/_live", {
      method: "POST",
      body: JSON.stringify(payload),
      headers: this.buildHeaders(),
      credentials: this.with_creds
    })
      .then((response) => this.handleResponse(endpoint, response))
      .catch( (error) => console.log('.... Error: ', endpoint, error))
  }

  buildHeaders() {
    let headers = {
      "Content-Type": "application/json",
      "Version": packageInfo
    };

    if (this.access_token) {
      headers.Authorization = `Bearer ${this.access_token}`
    }
    return headers;
  }

  buildtestHeaders() {
    let headers = {
      "Content-Type": "application/json",
      "Version": packageInfo,
      "Access-Control-Allow-Headers" : "*",
      "X-CM-SSO-ACCOUNTGUID" : store.getState().cmaccount.cmSSOAccountGuid
    };

    if (this.access_token) {
      headers.Authorization = `Bearer ${this.access_token}`
    }
    return headers;
  }

  handleResponse(endpoint, response) {
    if (response.status >= 200 && response.status <= 299) {
      return response.json();
    }
    else if (response.status === 400) {
      response.json().then((message) =>  this.handleError({'detail': JSON.stringify(message)}))
    }
    else if (response.status === 404) {
      this.handleError({title: 'Not found', message: 'Please try again or contact support@tracedock.com'});
    }
    else if (response.status === 405) {
      this.handleError(response.json());
    }
    else if (response.status === 403 || response.status === 418) {
      // this.store.dispatch({
      //   type: 'RESET_PERSIST_STATE'
      // });
      // this.store.dispatch({
      //   type: 'RESET_ACCOUNT_STATE'
      // });
      // this.store.dispatch({
      //   type: 'RESET_DEVICE_STATE'
      // });
      // this.store.dispatch({
      //   type: 'RESET_RULES_STATE'
      // });

      console.error('MUST LOGOUT NOW');

      // this.handleError({title: 'Authentication error', detail: 'Your session expired. Please login again.'});
    }
    else if (response.status === 500) {
      this.handleError('server_error');
    }
  }

  handleError(error) {
    if (error instanceof Promise) {
      error.then((data) => {
        console.error(">> promise error", data);
        setAlertDialog('Sorry', data.detail, data.message ? JSON.stringify(data.message).replace(new RegExp('"', 'g'), '') : '')
      });
    } else {
      if (error instanceof TypeError) {
        console.error('>> Type error', error);
        setAlertDialog('Sorry', 'We are experiencing an unexpected server error. Please try again or contact support@tracedock.com')
      } else {
        console.error('>> Other error', error);
        let title = error.title ? error.title : 'Sorry';
        let err = error.detail ? error.detail : 'Something has gone wrong. Please try again or contact support@tracedock.com';
        setAlertDialog(title, err)
      }
    }
  }
}

export const api = new Api();