import React from "react";
import PropTypes from 'prop-types';
import {Dots} from 'react-activity';
import ReactMarkdown from 'react-markdown';
import {CopyToClipboard} from 'react-copy-to-clipboard';

import {clock_inactive, IconInline, invalid, reload, valid} from "../icon/icon";
import {Button} from "../button/button";
import {colors} from "../../theme/colors";
import {getWiki} from "../wiki/wiki";
import {InstallationCode} from "../../lib/installation_code";
import {Javascript} from "../javascript/javascript";
import {Modal} from "../modal/modal";
import {CNAMEcomponent} from "./cname";
import {Tag} from "../tag/tag";

import './installationModal.scss'
import {Loader} from "../loader/loader";


export class InstallationModule extends React.Component {

  static propTypes = {
    checkStatus: PropTypes.func,
    domain: PropTypes.any,
    dev: PropTypes.any,
    dns_provider: PropTypes.string,
    profile: PropTypes.any,
    pendingCheckStatus: PropTypes.string,
  };

  static defaultProps = {
    checkStatus: () => {
    },
    domain: {},
    dev: {},
    dns_provider: '',
    profile: {},
    pendingCheckStatus: '',
  };


  constructor(props, context) {
    super(props, context);
    
    this.state = {
      showEmailModal: false,
      copied: false,
      subject: '',
      email: '',
    };
  }


  _render_title(title, state, type, pendingCheckStatus) {
    //disable check for code check
    var isNotTypeCodeButIsImplementingForDNS = (type !== 'code' && state === 'implementing')

    return <div className='row bodyTitle'>

      <div className='row' style={{alignItems: 'center'}}>
        <p className='title'>{title}</p>
      </div>

      {isNotTypeCodeButIsImplementingForDNS &&
      <div className='row' style={{alignItems: 'center'}}>
        <div style={{width: 13, display: 'flex'}}><Dots speed={0.5} color={colors.brand.darkgray_light} size={6}/></div>
        <p style={{marginLeft: 8}} className='small light'>Pending</p>
        <div style={{width: 130, marginLeft: 12}}>
          <div className='clickable buttonCheck' onClick={() => this.props.checkStatus(type)}>
            {pendingCheckStatus === type
              ? <Loader size={14} topColor={colors.brand.primary} style={{height: 19, marginTop: 5}}/>
              : <>
                <p className='primary' style={{marginRight: 6}}>Check Status</p>
                <IconInline icon={reload} size={14} color={colors.brand.primary}/>
              </>
            }
          </div>
        </div>
      </div>
      }

      {state === 'done' && <div>
        <Tag>Done</Tag>
      </div>
      }
    </div>
  }

  _render_dns_tips(dns_provider) {
    switch (dns_provider) {
      case 'transip':
        return <p>For TransIP, have a look at these <a className='link' target='_blank' rel="noopener noreferrer" title='See docs'
                                                       href='https://www.transip.eu/knowledgebase/entry/407-setting-a-cname-record/'>docs</a>. Please do not forget the trailing period (.) at the end
          of the value.</p>;

      case 'cloudflare':
        return <p style={{marginLeft: -4}}><IconInline icon={invalid} size={14} color={colors.brand.alert}/> We see you have <span className='bold'>CloudFlare</span>: please review the following <a
          className='link' target='_blank' rel="noopener noreferrer" title='See docs' href='https://docs.tracedock.com/installation/cloudflare'>docs</a>.</p>;
      default:
        return <p>Unsure how to set it up for your provider ({dns_provider})? Search for it online via this <a className='link' target='_blank' rel="noopener noreferrer" title='See docs'
                                                                                                               href={`https://duckduckgo.com/?q=${dns_provider}+cname`}>search link</a>.</p>;
    }
  }

  render({domain, dev, profile, checkStatus, dns_provider, pendingCheckStatus} = this.props) {
    return (
      <div className='installationModalWrapper'>

        <Modal
          width='500px'
          visible={this.state.showEmailModal}
          onClose={() => this.setState({showEmailModal: false})}>
          <p className='bold bigSpacer'>{this.state.subject}</p>

          <div style={{fontSize: '0.72rem'}}>
            <ReactMarkdown source={this.state.email}/>
          </div>

          <div style={{marginTop: 12}} className='row'>

            <CopyToClipboard text={this.state.email} onCopy={() => this.setState({copied: true}, () => setTimeout(() => this.setState({copied: false}), 3000))}>
              <Button
                onClick={() => {
                }}
                style={{margin: 4}}
                title={this.state.copied ? 'Email copied' : 'Copy email'}
                iconRight={this.state.copied ? valid : null}/>
            </CopyToClipboard>

            <Button
              style={{margin: 4}}
              title={this.state.copied ? 'Close' : 'Cancel'}
              onClick={() => this.setState({showEmailModal: false})}
              color={colors.brand.darkgray}
              cancel={true}
              backgroundColor={colors.brand.gray_light}/>
          </div>
        </Modal>


        <div className='InstallationWrapper'>

          <div className='bodyModal'>

            <div className='bodyContent'>
              <h2>Welcome to the user portal.</h2>
              <h2 className='spacer'>Step 1: Install TraceDock for the domain <span className='bold'>{domain.slug}</span></h2>

              <p>If you need more explanation, please have a look at our <a className='link' target='_blank' rel="noopener noreferrer" title='See docs'
                                                                            href='https://docs.tracedock.com/installation/start'>How To Docs</a>.</p>

              <div>
                {this._render_title('Adjust your DNS records', domain.onboarding.pending_technical, 'dns', pendingCheckStatus)}

                <p>Add the following <span className='bold'>A-record</span> to your <span
                  className='bold'>DNS</span> configuration:</p>

                {CNAMEcomponent(dev)}

                <div style={{margin: '24px 0 0 12px'}}>

                  {this._render_dns_tips(dns_provider)}

                  {domain.onboarding.pending_technical === 'implementing' &&
                  <p style={{margin: '12px 0 0 -6px'}}><IconInline color={colors.brand.primary} icon={clock_inactive}/> Please note that it can take up to 24 hours for your DNS provider to update
                    the A-record.</p>}
                </div>
              </div>

              <div>
                {this._render_title('Add the TraceDock code', domain.onboarding.pending_code, 'code', pendingCheckStatus)}

                <p>Copy and paste the following code snippet as high in the <span className='bold'>{`<head>`}</span> of your
                  website as possible, outside of any GTM container:</p>
                <Javascript title='Code' value={InstallationCode(dev)}/>
              </div>

            </div>
          </div>


          <Button
            style={{width: 200, margin: '24px auto 12px auto'}}
            title={`Continue to step 2: ${domain.pricing_plan === 'etp_only' ? 'Events' : 'Cookies'}`}
            onClick={() => this.props.history.push(domain.pricing_plan === 'etp_only' ? 'events' : 'cookies')}
            backgroundColor={colors.brand.blue110}
          />
          <p className='center'>You can continue with the next step while waiting for the technical installation to be completed.</p>

        </div>

      </div>
    );
  }
}