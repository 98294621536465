/*
    Data for base load events
 */

import {classifyJsonAvailableFields} from "../../../components/jsonRaw/json.classify.availableFields";
import {classifyJsonSelectedFields} from "../../../components/jsonRaw/json.classify.selectedFields";

export const baseClick = {
  name: '',
  trigger_once_per_page: 1,
  title: 'Add new click event',
  type: 'click',
  triggers: [],
  eventfields: [
    {field_name: 'ec', field_type: 'l', field_value: '', action: 'add'},
    {field_name: 'ea', field_type: 'l', field_value: '', action: 'add'},
  ],
  is_active: true
};

export const basePageload = {
  name: '',
  type: 'pageload',
  title: 'Add new pageload event',
  triggers: [],
  fields: [
    {field_name: 't', field_type: 'c', field_value: 'pageview', action: 'add'},
  ],
  ga4_fields: [
    {field_name: 'en', field_type: 'c', field_value: 'page_view', action: 'add'},
  ],
  is_active: true
};

export const baseDataLayer = {
  name: '',
  title: 'Add new data layer event',
  type: 'datalayer',
  triggers: [],

  fields: [],
  custom_dimensions: [],
  selectedFieldPaths: [],
  template: '',

  ga4_template: '',
  ga4_fields: [],

  data: null,
  availableFieldPaths: [],

  is_active: true
};

export const getBaseEvent = (type, template = null, dev) => {

  if (type === 'click') return baseClick;
  if (type === 'pageload') return basePageload;

  // for dataLayer add in cookieless otion
  let newTemplate = template ? {...template} : baseDataLayer;
  if (dev && dev.cookieless === 1) {
    if (dev.cookiebanner === 'cookieinfo') {
      newTemplate.triggers = [{key: 'cookie:CookieInformationConsent', lookup_type: 'ex', value: ''}].concat(newTemplate.triggers)
    } else {
      // cookiebot and others
      newTemplate.triggers = [{key: 'cookie:CookieConsent', lookup_type: 'ex', value: ''}].concat(newTemplate.triggers)
    }
  }
  if (type === 'datalayer') {
    return {
      ...baseDataLayer,
      ...newTemplate,
      availableFieldPaths: classifyJsonAvailableFields(newTemplate.data),
      selectedFieldPaths: classifyJsonSelectedFields(newTemplate.fields),
      title: 'Add new data layer event',
      isUpdated: true
    }
  }

};