import React, {useMemo} from "react";
import {connect} from "react-redux";
import {PropTypes} from 'prop-types';
import useMedia from 'use-media';
import {accountActions} from "../../redux/actions";
import InfoContainer from './Info/InfoContainer';
import SectionContainer from './Section/SectionContainer';
import styles from './getStartedContainer.module.scss';
import {getDataForStage} from './dataPerStage';
import logo from "../../static/images/logo_light.png";
import {colors} from "../../theme/colors";

const GetStartedContainer = ({currentStage, history, updateProfile}) => {

  const isWide = useMedia({minWidth: '850px'});
  let stageData = getDataForStage(currentStage);

  const infoContent = useMemo(
    () => {
      if (isWide) {
        return (
          <div className={styles.getStarted_info_big}>
            {stageData && <InfoContainer {...stageData} />}
          </div>
        )
      } else {
        return (
          <div className={styles.getStarted_info_small}>
            <div className={styles.mobileHeader} style={{background: colors.brand.secondary}}>
              <img src={logo} alt="Logo" height='35px'/>
            </div>
          </div>
        )
      }
    },
    [isWide, stageData]
  );

  return (
    <div className={isWide ? styles.getStarted_big : styles.getStarted_small}>
      {infoContent}
      <div className={styles.getStarted_section}>
        <SectionContainer
          currentStage={currentStage}
          updateProfile={updateProfile}
          history={history}
        />
      </div>
    </div>
  );
};

GetStartedContainer.propTypes = {
  currentStage: PropTypes.any,
  history: PropTypes.any,
  updateProfile: PropTypes.func
};

const mapDispatchToProps = (dispatch) => {
  return {
    updateProfile: (data) => dispatch(accountActions.updateProfile(data))
  }
};

export default connect(undefined, mapDispatchToProps)(GetStartedContainer);
