import React from 'react';
import {connect} from 'react-redux';
import {PropTypes} from 'prop-types';
import SectionTitle from '../SectionTitle';
import {toLocale} from "../../../../lib/helpers";

let CorporateAccountSection = ({account, persist}) => {
  return (
    <>
      <SectionTitle
        title={`Dear ${account.profile.first_name}, we are going to contact you personally.`}
      />
      <p className='spacer'>Your website(s) currently have over <b>{toLocale(account.admin.page_views)} page views</b> per
        month. We will contact you via <b className='primary'>{account.profile.email}</b> to discuss the possibilities for a custom offer.
      </p>
      <p>Do you have a question in the meantime?</p>
      <p className='spacer'>Don’t hesitate to contact us via <a className='link' rel="noopener" title='Contact us' href='mailto:hello@tracedock.com'>hello@tracedock.com</a>.</p>
      <p className='black spacer'>Speak to you soon!</p>
    </>
  )
};


CorporateAccountSection.propTypes = {
  account: PropTypes.any,
  persist: PropTypes.any,
};

const mapStateToProps = (state) => ({
  account: state.account,
  persist: state.persist,
});

export default CorporateAccountSection = connect(mapStateToProps, undefined)(CorporateAccountSection);