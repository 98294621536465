import React from "react";
import PropTypes from 'prop-types';

import {Tag} from "../../../../components/tag/tag";
import {BlankCard} from "../../../../components/cards/blankCard";
import {Input} from "../../../../components/input/input";
import {Table} from "../../../../components/table/table";
import {colors} from "../../../../theme/colors";
import {bin, dev_active, Icon} from "../../../../components/icon/icon";

const CodeModal = React.lazy(() => import("../../../../components/dataFieldObject/codeModal"));

const initialScript = `function parse(field_value, field_name) {
  /*
  note: write in Javascript ECMA 5.1
  input:
  - field_value: found value that will be forwarded.
  - field_name: string, lookup name in the data.
  */

  // add here your alteration based in the input
  // ...
  
  // return the value you require 
  return field_value;
}`;

const possibleLookupSources = [
  {key: 'ec', value: 'event category'},
  {key: 'ea', value: 'event action'},
  {key: 'el', value: 'event label'},
  {key: 'ev', value: 'event value'},
  {key: 'cd1', value: 'c. dimension 1'},
  {key: 'cd2', value: 'c. dimension 2'},
  {key: 'cd3', value: 'c. dimension 3'},
  {key: 'cd4', value: 'c. dimension 4'},
  {key: 'cd5', value: 'c. dimension 5'},
  {key: 'cd6', value: 'c. dimension 6'},
  {key: 'cd7', value: 'c. dimension 7'},
  {key: 'cd8', value: 'c. dimension 8'},
  {key: 'cd9', value: 'c. dimension 9'},
  {key: 'cd11', value: 'c. dimension 11'},
  {key: 'cd12', value: 'c. dimension 12'},
  {key: 'cd13', value: 'c. dimension 13'},
  {key: 'cd14', value: 'c. dimension 14'},
  {key: 'cd15', value: 'c. dimension 15'},
  {key: 'cd16', value: 'c. dimension 16'},
  {key: 'cd17', value: 'c. dimension 17'},
  {key: 'cd18', value: 'c. dimension 18'},
  {key: 'cd19', value: 'c. dimension 19'},
  {key: 'cd20', value: 'c. dimension 20'},
];


const possibleLookupTypes = [
  {key: 's', value: 'string'},
  {key: 'a', value: 'attribute'},
];

export const validate_fields = (eventfields) => {
  // validates triggers, returns invalidObjects
  let invalidObjects = {};

  eventfields.forEach((row, idx) => {

    // validates triggers, is choices restricted to list or more than 0
    if (row.action !== 'delete') {
      if (row.field_name.length === 0) {
        invalidObjects[`${idx}_field_name`] = true
      }
      if (!possibleLookupTypes.some((obj) => obj.key === row.field_type)) {
        invalidObjects[`${idx}_field_type`] = true
      }
      if (!row.field_value) {
        invalidObjects[`${idx}_field_value`] = true
      }
    } else {
      return true
    }
  });
  return invalidObjects
};

export class CardClickEventFields extends React.Component {

  static propTypes = {
    eventfields: PropTypes.array,
    invalidObjects: PropTypes.any,
    readOnly: PropTypes.bool,
    onUpdate: PropTypes.func,
  };

  static defaultProps = {
    eventfields: [],
    invalidObjects: {},
    readOnly: false,
    onUpdate: (data) => console.log('updating data: ', data),
  };

  constructor(props, context) {
    super(props, context);

    this.state = {
      newData: props.eventfields,

      // data for script modal
      showCodeModal: false,
      script: '',
      idx: null,
      row: null,
    };
  }

  header = [
    {key: 'field_name', value: 'Field', help: null},
    {key: 'field_type', value: 'Type', help: null},
    {key: 'field_value', value: 'Value', help: null},
    {},
    {},
  ];

  _update_row(idx, newObject) {
    // update element and update element
    let newData = [...this.state.newData];
    newData[idx] = newObject;
    this.setState({newData: newData});
    this.props.onUpdate(newData);
  }

  componentWillUpdate(nextProps, nextState, nextContext) {
    if (nextProps.eventfields !== this.state.newData) {
      this.setState({newData: nextProps.eventfields})
    }
  }

  _saveScript() {
    if (this.state.script !== initialScript) {
      this._update_row(this.state.idx, {
        ...this.state.row,
        script: this.state.script,
        action: this.state.row.action === 'add' ? 'add' : 'update'
      })
    }
    // and close
    this.setState({script: '', showCodeModal: false})
  }

  render({invalidObjects, readOnly} = this.props) {
    return (

      <div>
        <BlankCard
          collapsable={true}
          title='To Google Analytics data'
          subtitle='Determine what data should be forwarded.'
          padding={false}>

          <Table header={this.header}
                 onAddNew={() =>
                   this._update_row(this.state.newData.length, {
                     field_name: 'ec',
                     field_type: 'l',
                     field_value: '',
                     action: 'add'
                   })}>

            {this.state.newData.length > 0 && this.state.newData.map((row, idx) => (

              <tr key={idx} className={row.action === 'delete' ? 'delete' : ''}>
                {/* NOTE: rows are build up c. */}

                <td>
                  <Input
                    type="text"
                    restrict={true}
                    readOnly={false}
                    choices={possibleLookupSources}
                    initial={row.field_name}
                    isInvalid={invalidObjects[`${idx}_field_name`]}
                    onChange={(value) => this._update_row(idx, {
                      ...row,
                      field_name: value,
                      action: row.action === 'add' ? 'add' : 'update'
                    })}
                  />
                </td>

                <td>
                  <Input
                    type="text"
                    readOnly={false}
                    initial={row.field_type}
                    choices={possibleLookupTypes}
                    restrict={true}
                    isInvalid={invalidObjects[`${idx}_field_type`]}
                    onChange={(value) => this._update_row(idx, {
                      ...row,
                      field_type: value,
                      action: row.action === 'add' ? 'add' : 'update'
                    })}
                  />
                  {invalidObjects[`${idx}_field_type`] && (
                    <p className='small alert'>
                      Please select a valid choice
                    </p>
                  )}
                </td>

                <td>
                  <Input
                    type="text"
                    readOnly={false}
                    initial={row.field_value}
                    isInvalid={invalidObjects[`${idx}_field_value`]}
                    onChange={(value) => this._update_row(idx, {
                      ...row,
                      field_value: value,
                      action: row.action === 'add' ? 'add' : 'update'
                    })}
                  />
                </td>

                <td width="110px">
                  {row.action === 'add' && <Tag type='new'>new</Tag>}
                  {row.action === 'update' && <Tag type='update'>updated</Tag>}
                  {row.action === 'delete' && <Tag type='alert'>delete</Tag>}
                </td>

                <td>
                  <Icon className='clickable' icon={dev_active} color={row.script ? colors.brand.primary : colors.brand.gray} size={32}
                        onClick={(script) => this.setState({idx, row, showCodeModal: true, script: row.script ? row.script : initialScript})}
                  />
                </td>

                <td>
                  <Icon size={24} className='clickable' icon={bin} color={colors.brand.primary}
                        onClick={() => this._update_row(idx, {...row, action: row.action !== 'delete' ? 'delete' : 'update'})}/>
                </td>

              </tr>
            ))}
          </Table>

        </BlankCard>

        {this.state.showCodeModal && (

          // fallback component should be some spinner
          <React.Suspense fallback={<div></div>}>
            <CodeModal
              value={this.state.script}
              onChange={(script) => this.setState({script})}
              onCancel={() => this.setState({showCodeModal: false, script: ''})}
              onSubmit={() => this._saveScript()}
            />
          </React.Suspense>
        )}
      </div>
    )
  }
}
