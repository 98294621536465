import React from "react";
import {connect} from "react-redux";

import {PageLayout} from "../../../components/layout/pageLayout";
import {rulesActions} from "../../../redux/actions";
import {Loader} from "../../../components/loader/loader";
import {BlankCard} from "../../../components/cards/blankCard";
import {Table} from "../../../components/table/table";
import {updateIfNeeded} from "../../../lib/helpers";
import {ClickEventModal} from "./click/sectionClickEventModal";
import {DataLayerEventModal} from "./datalayer/section.dataLayer.event.modal";
import {PageLoadModal} from "./pageload/sectionPageLoadModal";
import {Modal} from "../../../components/modal/modal";
import {ecommerceAutocomplete} from "../../../lib/autocomplete_ecommerce";
import {Button} from "../../../components/button/button";
import EventRow from "./eventRow.component";
import {getBaseEvent} from "./data";
import {InputCard} from "../../../components/cards/inputCard";
import {CopyFromDomain} from "./copyFrom.component";
import {Icon, info} from "../../../components/icon/icon";
import {colors} from "../../../theme/colors";
import {CookieConditions} from "./cookieConditions";
import {GoogleAnalyticsSettingsModule} from "../settings/GoogleAnalyticsSettings.module";
import {CDPSettingsModule} from "../settings/CDPSettings";

class EventsPage extends React.Component {

  constructor(props, context) {
    super(props, context);

    this.state = {
      pendingDev: true,
      showModal: false,
      modalType: '',
      addModalVisible: false,
      copyModalVisible: false,
      currentEvent: null,
    };

    props.persist.domain.id && updateIfNeeded(this.props.events_updated,
      () => this.props.getEvents({uuid: props.persist.domain.id}));

    // always refresh dev due to input rendering with same pending flag
    props.persist.domain.id && updateIfNeeded(null,
      () => this.props.getDev({uuid: this.props.persist.domain.id}), () => this.setState({pendingDev: false}));

  }

  _add_new(type, template) {
    // add new and open modal
    this.props.setEventIndex({event_index: -1, event_type: type})
      .then(() => this.setState({addModalVisible: false, showModal: true, modalType: type, currentEvent: getBaseEvent(type, template, this.props.rules.dev)}))
  }

  _open_event(event, idx) {
    // open Event event
    this.props.setEventIndex({event_index: idx, event_type: event.type})
      .then(() => this.setState({
        showModal: true, modalType: event.type, currentEvent: {...event, title: 'Adjust event'}
      }))
  }

  _save(event) {
    // depending on uuid we either add or update
    let data = {
      uuid: this.props.persist.domain.id,
      event_index: this.props.rules.event_index,
      event: event
    };

    if (event.id) {
      return this.props.updateEvent(data)
    } else {
      return this.props.addEvent(data)
    }
  }

  _delete(event) {
    if (event.id) {
      // actual Event event, as such we delete
      this.props.deleteEvent({
        uuid: this.props.persist.domain.id,
        event_index: this.props.rules.event_index,
        event: event
      })
        .then(() => {
          this.setState({showModal: false}),
          this.props.getEvents({uuid: this.props.persist.domain.id})
        })
    } else {
      // we just close the window, no real data
      this.setState({showModal: false})
    }
  }

  render({history, persist, requires_publish, rules, publishBuild} = this.props) {
    let isLoading = rules.isPending;
    var hasGACredentialsRegistered = ((rules.dev && rules.dev.ga_property_id) || (rules.dev && rules.dev.ga4_measurement_id && rules.dev.ga4_api_secret))

    return (
      <PageLayout title={`${
        persist.domain.state === 'implementing'
          ? ((persist.domain.pricing_plan === 'etp_only' ? 'Step 2: ' : 'Step 3: ') + 'Setup events for Google Analytics')
          : 'Client side events'}`}
                  history={this.props.history}
                  date_last_build={rules.date_last_build}
                  requires_publish={rules.requires_publish}
                  domain={persist.domain}
                  publishPending={isLoading}
                  hasPublish={!isLoading && persist.domain.state === 'live'}
                  onPublish={(message) => publishBuild({uuid: persist.domain.id, message: message})}>
        {isLoading && (<Loader style={{marginTop: '20vh', marginLeft: '48%'}}/>)}

        {!isLoading && (
          <CopyFromDomain
            isVisible={this.state.copyModalVisible}
            onClose={() => this.setState({copyModalVisible: false})}
            domains={persist.all_domains}
            currentDomainUuid={persist.domain.id}
            events={rules.copy_events}
            getEvents={(uuid) => this.props.getEventsForCopy(uuid)}
            copyEvents={(d) => {
              this.setState({copyModalVisible: false});
              this.props.copyEvents(d)
            }}
          />
        )}

        {!isLoading && (
          <Modal visible={this.state.addModalVisible} onClose={() => this.setState({addModalVisible: false})}>
            <h2>Add an event</h2>
            <h6 className='spacer' style={{marginBottom: 24}}>Select which type of event you want to add.</h6>

            {/*<Button style={{marginTop: 6}} title='Pageload event' onClick={() => this._add_new('pageload')}/>*/}
            {/* <Button style={{marginTop: 6}} title='Click event' onClick={() => this._add_new('click')}/> */}

            {ecommerceAutocomplete.map((template, idx) => (
              <Button key={idx} style={{marginTop: 6}} title={template.name} onClick={() => this._add_new('datalayer', template)}/>
            ))}

            <Button style={{marginTop: 6}} title='Other datalayer' onClick={() => this._add_new('datalayer')}/>

          </Modal>
        )}

        {!isLoading && persist.domain.state === 'implementing' && rules.dev.ga_property_id && (
          <BlankCard>
            <div className='row' style={{alignItems: 'center', paddingBottom: 8}}>
              <Icon icon={info} color={colors.brand.primary} size={20}/>
              <p className='bold' style={{marginLeft: 24}}>
                We have already set up the first event for you. this is a basic implementation: page views from users with ad blockers or browsers such as Firefox or Brave will be forwarded
                anonymized to Google Analytics. Feel free to adjust this event and to add more conversion events such as click events, e-commerce events or other data Layer events.</p>
            </div>
          </BlankCard>
        )}

        {!isLoading && (persist.domain.state !== 'implementing' || rules.dev.ga_property_id) && (
          <BlankCard
            title='My Events'
            subtitle='Update, change or delete events that will be forwarded to Google Analytics.'
            padding={false}
            onCopy={() => this.setState({copyModalVisible: true})}
            onAddNewTitle='New event'
            onAddNew={() => this.setState({addModalVisible: true})}>
            <Table header={[{key: 'name', value: 'Name'}, {key: 'type', value: 'Event'}, {key: 'is_active', value: 'State'}, {key: 'last_edited', value: 'Last edited'}, {}]}>
              {rules.events.pageload.map((event, idx) => EventRow(history, persist.domain.state, rules.date_last_build, idx, event, (event, idx) => this._open_event(event, idx)))}
              {/* {rules.events.click.map((event, idx) => EventRow(history, persist.domain.state, rules.date_last_build, idx, event, (event, idx) => this._open_event(event, idx)))} */}
              {rules.events.datalayer.map((event, idx) => EventRow(history, persist.domain.state, rules.date_last_build, idx, event, (event, idx) => this._open_event(event, idx)))}
            </Table>

          </BlankCard>
        )}

        {this.state.showModal && rules.event_type === 'pageload' && (
          <PageLoadModal
            has_ga4={rules.dev.has_ga4}
            PageLoad={this.state.currentEvent}
            show={this.state.showModal}
            readOnly={false}
            onClose={() => this.setState({showModal: false})}
            onSave={(event) => this._save(event)}
            onDelete={(event) => this._delete(event)}
          />
        )}

        {/* {this.state.showModal && rules.event_type === 'click' && (
          <ClickEventModal
            clickEvent={this.state.currentEvent}
            show={this.state.showModal}
            onClose={() => this.setState({showModal: false})}
            onSave={(event) => this._save(event)}
            onDelete={(event) => this._delete(event)}
          />
        )} */}

        {this.state.showModal && this.state.modalType === 'datalayer' && (
          <DataLayerEventModal
            domain={persist.domain.name}
            dataLayerEvent={this.state.currentEvent}
            show={this.state.showModal}
            onClose={() => this.setState({showModal: false})}
            onSave={(event) => this._save(event)}
            onDelete={(event) => this._delete(event)}
            dev={rules.dev}
          />
        )}

        {!isLoading && !(hasGACredentialsRegistered) && (
          <BlankCard>
            <div className='row' style={{alignItems: 'center', paddingBottom: 8}}>
              <Icon icon={info} color={colors.brand.primary} size={20}/>
              <p className='bold' style={{marginLeft: 24}}>Before you can send events to Google Analytics, please first add the Google Analytics Property ID.</p>
            </div>
          </BlankCard>
        )}

        <GoogleAnalyticsSettingsModule
          visible={!isLoading && !this.state.pendingDev && rules.dev}
          data={rules.dev}
          pageType='clientside'
          onSave={(data) => this.props.updateDev({
            ...data,
            id: persist.domain.id
          }).then(() => this.props.getDev({uuid: persist.domain.id}))}
        />

        {!isLoading && !this.state.pendingDev && rules.dev && (
          <InputCard
            title='Cookieless tracking'
            subtitle='Set up your website to support cookieless tracking'
            onSave={(data) => this.props.updateDev({
              ...data,
              id: persist.domain.id
            }).then(() => this.props.getDev({uuid: persist.domain.id}))}

            initialData={{
              cookieless: rules.dev.cookieless,
              cookiebanner: rules.dev.cookiebanner,
            }}
            collapsable={!rules.dev.cookieless}
            initialCollapsed={!rules.dev.cookieless}
            objects={[
              {
                key: 'cookieless',
                label: 'Website is Cookieless',
                type: 'text',
                restrict: true,
                choices: [{key: 1, value: 'Yes, setup cookieless'}, {key: 0, value: 'No'}]
              },
              {
                key: 'cookiebanner',
                label: 'Cookie banner',
                type: 'text',
                restrict: true,
                choices: [{key: 'cookiebot', value: 'Cookiebot.io'}, {key: 'cookieinfo', value: 'Cookieinformation.com'}, {key: '', value: 'Custom'}]
              },
              {
                key: 'cookieless',
                type: 'description',
                help: 'If cookieless, TraceDock is setup with cookies only if the user consent is given. ' +
                  'If no consent is given, then TraceDock will generate a privacy-friendly server-side ClientID based on a hash ' +
                  'of the user IP address and User Agent. Being a hash, the value is unique but cannot be decrypted back to the original ' +
                  'value. As such a hash value of these is fully GDPR compliant and safeguards the privacy of your users. '
              }

            ]}
          />
        )}

        {!isLoading && rules.dev && rules.dev.cookieless === 1 && (
          <CookieConditions
            onSave={(data) => this.props.updateDev({
              ...data,
              id: persist.domain.id
            })}
            conditions={rules.dev.cookie_conditions}
          />
        )}

        <CDPSettingsModule
          visible={!isLoading && !this.state.pendingDev && rules.dev && rules.dev.cdp_product_id}
          data={rules.dev}
          onSave={(data) => this.props.updateDev({
            ...data,
            id: persist.domain.id
          }).then(() => this.props.getEvents({uuid: persist.domain.id}))}
        />



      </PageLayout>
    );
  }
}


const mapStateToProps = (state) => ({
  persist: state.persist,
  rules: state.rules,
});

const mapDispatchToProps = (dispatch) => {
  return {
    getEvents: (d) => dispatch(rulesActions.getEvents(d)),
    setEventIndex: (d) => dispatch(rulesActions.setEventIndex(d)),
    addEvent: (d) => dispatch(rulesActions.addEvent(d)),
    updateEvent: (d) => dispatch(rulesActions.updateEvent(d)),
    deleteEvent: (d) => dispatch(rulesActions.deleteEvent(d)),
    publishBuild: (d) => dispatch(rulesActions.publishBuild(d)),
    getDev: (data) => dispatch(rulesActions.getDev(data)),
    updateDev: (data) => dispatch(rulesActions.updateDev(data)),
    getEventsForCopy: (data) => dispatch(rulesActions.getEventsForCopy(data)),
    copyEvents: (data) => dispatch(rulesActions.copyEvents(data)),
  }
};

export default EventsPage = connect(mapStateToProps, mapDispatchToProps)(EventsPage);