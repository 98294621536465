import React from 'react';
import PropTypes from 'prop-types';
import './json.raw.scss';
import {Icon, invalid, valid} from "../icon/icon";
import {colors} from "../../theme/colors";
import {Dots} from 'react-activity';

export class JsonRaw extends React.Component {

  static propTypes = {
    initialData: PropTypes.any,
    readOnly: PropTypes.bool,
    onUpdate: PropTypes.func,
    placeholder: PropTypes.string
  };

  static defaultProps = {
    initialData: '',
    readOnly: false,
    onUpdate: (data) => console.log('Updated Json: ', data),
    placeholder: '// Copy your datalayer object here, e.g.: \n// td.copyDataLayerElement(4)',
  };

  constructor(props, context) {
    super(props, context);

    this.state = {
      json: {},
      jsonString: '',
      typing: false,
      isValid: false,
      isInValid: false,
      validationMessage: ''
    };
  }

  componentDidMount() {
    if (this.props.initialData) {

      // initial data always Json, as such stringify it
      let jsonString = JSON.stringify(this.props.initialData);
      this.updateData(jsonString);

      // we also check it, in case you navigate to other pages
      this.validate_and_send(jsonString, false);
    }
  }

  updateData(value) {
    this.setState({jsonString: value})
  }

  validate_and_send(data, send = true) {

    if (!data || data === '') {
      this.setState({typing: false});
      return null
    }

    // trim off quotes on start and end, if they are "{"a": 1}" >> {"a": 1}
    if (data[0] === `"` && data[data.length - 1] === `"`) {
      data = data.slice(1, data.length - 1)
    }

    // trim off enters
    data = data.replace(/(\r\n|\n|\r)/g, "");

    // test if the object is actually an object or a list
    if (data[0] === `[` || data[data.length - 1] === `]`) {
      this.setState({isValid: false, validationMessage: 'Not valid: we received an array [], but expect an object {...}', typing: false})
    } else if (data[0] !== `{` || data[data.length - 1] !== `}`) {
      this.setState({isValid: false, isInValid: true, validationMessage: 'No valid object {...}', typing: false})
    } else {

      try {
        // Parse as json
        data = JSON.parse(data);
        this.setState({isValid: true, isInValid: false, validationMessage: 'JSON valid', typing: false},
          () => {
            if (send) {
              // parse the data and send onwards
              this.props.onUpdate(data)
            }
          });

      } catch (error) {
        console.log('No valid JSON');
        this.setState({isValid: false, isInValid: true, validationMessage: 'No valid JSON', typing: false});
      }

    }
  }


  render({readOnly, placeholder} = this.props) {
    return (
      <div className='jsonRaw'>

        {this.state.typing && (<div className='validator' style={{marginTop: 8, marginLeft: 3}}>
          <Dots speed={0.5} color={colors.brand.gray} size={6}/>
        </div>)}

        {!this.state.typing && this.state.isValid && (<div className='validator'>
          <Icon icon={valid} color={colors.brand.primary} size={18}/>
          <p className='small primary'>{this.state.validationMessage}</p>
        </div>)}

        {!this.state.typing && this.state.isInValid && (<div className='validator'>
          <Icon icon={invalid} color={colors.brand.alert} size={18}/>
          <p className='small alert'>{this.state.validationMessage}</p>
        </div>)}

        <textarea
          readOnly={false}
          className='jsonInput'
          placeholder={placeholder}
          value={this.state.jsonString}
          onChange={(event) => this.updateData(event.target.value)}
          onFocus={() => {
            if (!readOnly) this.setState({typing: true, isValid: false, validationMessage: ''})
          }}
          onBlur={() => {
            if (!readOnly) this.validate_and_send(this.state.jsonString)
          }}
          onKeyUp={(e) => {

            // also register escape, Meta is cmd, probably a paste
            if (!readOnly && (e.key === "Escape" || e.key === 'Enter' || (e.ctrlKey && e.key === 'v') || (e.metaKey && e.key === 'v') || e.key === 'Meta')) {
              this.validate_and_send(this.state.jsonString);
            }
          }}
        />
      </div>
    );
  }
}