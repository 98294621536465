export const dashboardState = {

  events_all: 0,
  events_count: 0,
  events_blocked: 0,
  events_blocked_perc: '',
  cookie_recovery: 0,

  events: [],

  selectedFilters: {
    timestamp: {
      key: 'timestamp',
      value: 'Timestamp',
      visible: false,
      type: 'radio',
      selectedValues: [1440],
      choices: [
        {key: 10, value: 'Last 10 minutes'},
        {key: 60, value: 'Last hour'},
        {key: 720, value: 'Last 12 hours'},
        {key: 1440, value: 'Last 24 hours'},
      ]
    },
    event: {
      key: 'event',
      value: 'Event',
      visible: false,
      type: 'radio',
      selectedValues: ['all'],
      choices: [
        {key: 'all', value: 'All events'},
        {key: 'datalayer', value: 'DataLayer events'},
        {key: 'id_storage', value: 'Identify events'},
        {key: 'external', value: 'Server side events'},
        {key: 'cookie_save', value: 'Cookie save'},
        {key: 'cookie_recovery', value: 'Cookie recovery'},
        {key: 'cookie_error', value: 'Cookie error'},
        {key: 'unknown', value: 'Unknown events'},
        {key: 'other', value: 'Other:', type: 'text'},
      ]
    },
    blocked: {
      key: 'blocked',
      value: 'Forwarded traffic',
      visible: false,
      type: 'radio',
      selectedValues: ['all'],
      choices: [
        {key: 'all', value: 'All events'},
        {key: 'true', value: 'Only forwarded events'},
        {key: 'false', value: 'Non-forwarded events'},
      ]
    },
    ip: {
      key: 'ip',
      value: 'IP address',
      visible: false,
      type: 'radio',
      selectedValues: ['all'],
      choices: [
        {key: 'all', value: 'All addresses'},
        // {key: 'my_ip', value: 'Only my IP address'},
        {key: 'other', value: 'Other:', type: 'text'},
      ]
    }
  }
};
