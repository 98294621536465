import React from 'react';
import PropTypes from 'prop-types';

import {Button} from "../button/button";
import {plus} from "../icon/icon";
import {colors} from "../../theme/colors";

import './table.scss';

export class Table extends React.Component {

  static propTypes = {
    onAddNew: PropTypes.func,
    header: PropTypes.any,
    style: PropTypes.any,
    narrow: PropTypes.bool,
    addDescription: PropTypes.string,
  };

  static defaultProps = {
    onAddNew: null,
    header: null,
    style: {},
    narrow: false,
    addDescription: 'Add row'
  };

  constructor(props, context) {
    super(props, context);

    this.state = {};
  }

  render({style, addDescription, header, narrow, onAddNew, children} = this.props) {
    return (
      <div className='TableWrapper' style={this.props.style}>

        <table cellSpacing='0' className={narrow ? 'narrow' : 'wide'}>

          <thead>
          <tr>
            {header.map((th, idx) => (
              <th key={idx}>
                <h5>{th.value}</h5>
              </th>
            ))}
          </tr>
          </thead>

          <tbody>
          {children}
          </tbody>
        </table>

        {onAddNew && (
          <Button
            style={{marginLeft: 24, marginTop: 14, marginBottom: 12, maxWidth: 125}}
            title={addDescription}
            iconRight={plus}
            onClick={onAddNew}
            backgroundColor='white'
            color={colors.brand.primary}
          />)}

      </div>
    );
  }
}