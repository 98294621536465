import React, {useCallback} from 'react';
import {connect} from 'react-redux';
import {PropTypes} from 'prop-types';
import SectionTitle from '../SectionTitle';
import styles from '../sectionContainer.module.scss';
import {Form, FormInline, InputField} from '../../../../components/form';
import {accountActions} from '../../../../redux/actions';
import {isEmailValidator, isRequiredValidator} from '../../../../lib/validators';
import {Icon, checkmark} from "../../../../components/icon/icon";
import {colors} from "../../../../theme/colors";
import {InfoPopup} from "../../../../components/popup/infoPopup/InfoPopup";
import {PopupPosition} from "../../../../components/popup";

let RegistrationSection = ({goNext, signUp, history, account}) => {
  const onConfirm = useCallback(
    (data) => {
      // all is well, the registration will automatic
      // From backend we automatically receive the next step
      // for agency, navigate to 6th stage, otherwise goto next
      // signUp(data)
    },
    [goNext]
    )
  ;

  return (
    <div className={styles.section_content}>
      <SectionTitle
        title='Sign up and try TraceDock for free'
        subtitle={<>Already have a TraceDock account? <span
          style={{textDecoration: 'underline'}} className='link'
          onClick={() => history.push('/login')}>Log in here</span></>}
      />

      <div style={{marginBottom: 22}}>
        <div className='row' style={{alignItems: 'center'}}>
          <Icon icon={checkmark} size={22} color={colors.brand.accent_green_dark}/>
          <p style={{marginLeft: 6}}>We offer a 21-day trial period</p>
          <InfoPopup
            position={PopupPosition.TOP_CENTER}
            message='Billing will start only after this trial period.'/>
        </div>
        <div className='row' style={{alignItems: 'center'}}>
          <Icon icon={checkmark} size={22} color={colors.brand.accent_green_dark}/>
          <p style={{marginLeft: 6}}>Trial starts after a complete setup</p>
          <InfoPopup
            position={PopupPosition.TOP_CENTER}
            message='The free trial period of 21 days starts after the setup of TraceDock is complete: when either the first cookies are extended or the first events are sent to Google Analytics.'/>
        </div>
        <div className='row' style={{alignItems: 'center'}}>
          <Icon icon={checkmark} size={22} color={colors.brand.accent_green_dark}/>
          <p style={{marginLeft: 6}}>Get all the features TraceDock has to offer</p>
          <InfoPopup
            position={PopupPosition.TOP_CENTER}
            message='With your account TraceDock extends the cookie duration & forwards missing data to Google Analytics.'/>
        </div>
      </div>

      <Form
        buttonText='Next'
        isLoading={account.isPendingUpdate}
        onSave={onConfirm}
        validators={{
          first_name: isRequiredValidator,
          last_name: isRequiredValidator,
          email: isEmailValidator,
          password: isRequiredValidator
        }}
      >
        <FormInline>
          <InputField name='first_name' label='First name' focus={true}/>
          <InputField name='last_name' label='Last name' type='email'/>
        </FormInline>
        <InputField name='email' label='Work email'/>
        <InputField name='password' label='Set a password' type='password' enterIsSubmit={true}/>

      </Form>
    </div>
  )
};

RegistrationSection.propTypes = {
  goNext: PropTypes.func,
  // signUp: PropTypes.func,
  history: PropTypes.any
};

const mapDispatchToProps = (dispatch) => {
  return {
    // signUp: (data) => dispatch(accountActions.signUp(data))
  }
};

const mapStateToProps = (state) => ({
  account: state.account
});

export default RegistrationSection = connect(mapStateToProps, mapDispatchToProps)(RegistrationSection);
