import React from "react";
import {connect} from "react-redux";

import {Loader} from "../../components/loader/loader";
import {accountActions} from "../../redux/actions";
import {Table} from "../../components/table/table";
import {Input} from "../../components/input/input";
import {Tag} from "../../components/tag/tag";
import {bin, Icon} from "../../components/icon/icon";
import {colors} from "../../theme/colors";
import {BlankCard} from "../../components/cards/blankCard";
import {emailValidator} from "../../lib/validators";
import {updateIfNeeded} from "../../lib/helpers";
import {PageLayout} from "../../components/layout/pageLayout";


class userManagementPage extends React.Component {

  constructor(props, context) {
    super(props, context);

    this.state = {
      newData: this.props.account.members,
      isUpdated: false,
      isLoading: false,
      invalidObjects: {}
    };

    props.persist.domain.id && updateIfNeeded(
      this.props.account.members_updated,
      () => this.props.getMembers({uuid: props.persist.domain.id}),
      (members) => this.setState({newData: members.length > 0 ? [...members] : []})
    );
  }

  header = [
    {key: 'first_name', value: 'first name', help: null},
    {key: 'email', value: 'email', help: null},
    {},
    {},
  ];

  roles = [{key: 'admin', value: 'Domain editor'}, {key: 'readonly', value: 'Read-only'}];

  componentWillUpdate(nextProps, nextState, nextContext) {
    if (nextProps.persist.domain.id && this.props.persist.domain.id !== nextProps.persist.domain.id) {
      this.props.getMembers({uuid: nextProps.persist.domain.id}).then((members) => this.setState({newData: [...members]}))
    }
  }

  _update_row(idx, newObject) {
    // update element and update element
    let newData = [...this.state.newData];
    newData[idx] = newObject;
    this.setState({isUpdated: true, newData: newData})
  }

  _validate_and_save() {

    let invalidObjects = {};

    this.state.newData.map((row, idx) => {
      // check email if add
      if (row.action === 'add') {
        // validate email
        if (!emailValidator(row.email)) {
          invalidObjects[`${idx}_email`] = true
        }
        if (!row.first_name || row.first_name.length < 2) {
          invalidObjects[`${idx}_first_name`] = 'Please enter a first name'
        }
      }
      return true
    });

    // only save if no invalid objects
    if (Object.entries(invalidObjects).length === 0) {

      this.setState({isLoading: true}, () => {
        this.props.updateMembers({
          uuid: this.props.persist.domain.id,
          members: this.state.newData
        }).then((members) => {
            this.setState({isLoading: false, isUpdated: !members, newData: members ? [...members] : []});
            this.props.getProfile();
          }
        )
      });

    }

    this.setState({invalidObjects: invalidObjects})
  }

  _reset_data() {
    this.setState({
      newData: this.props.account.members.concat(),
      isUpdated: false,
      isLoading: false,
      invalidObjects: {}
    })
  }

  render({persist, account, dashboard, updateCreditor} = this.props) {
    return (
      <PageLayout title='Domain user management' history={this.props.history} domain={persist.domain}>

        {account.isPending && (<Loader style={{marginTop: '20vh', marginLeft: '48%'}}/>)}

        {!account.isPending && (
          <BlankCard
            title='All Users'
            subtitle='User management on domain level'
            padding={false}
            onReset={() => this._reset_data()}
            onSave={() => this._validate_and_save()}
            isLoading={this.state.isLoading}
            isUpdated={this.state.isUpdated}>
            <Table header={this.header}
                   addDescription='Another user'
                   onAddNew={() => this._update_row(this.state.newData.length, {first_name: '', email: '', role: 'admin', action: 'add'})}>

              {this.state.newData.length > 0 && this.state.newData.map((row, idx) => (

                <tr key={idx} className={row.action === 'delete' ? 'delete' : ''}>
                  {/* NOTE: rows are build up custom */}

                  <td>
                    <Input
                      type="text"
                      placeholder='first name'
                      readOnly={!!row.uuid}
                      initial={row.first_name}
                      isInvalid={!!this.state.invalidObjects[`${idx}_first_name`]}
                      onChange={(value) => this._update_row(idx, {
                        ...row,
                        first_name: value,
                        action: row.action === 'add' ? 'add' : 'update'
                      })}
                    />
                    {this.state.invalidObjects[`${idx}_first_name`] && (
                      <p className='small alert'>
                        {this.state.invalidObjects[`${idx}_first_name`]}
                      </p>
                    )}
                  </td>

                  <td>
                    <Input
                      type="email"
                      placeholder='email'
                      readOnly={!!row.uuid}
                      initial={row.email}
                      isInvalid={this.state.invalidObjects[`${idx}_email`]}
                      onChange={(value) => this._update_row(idx, {
                        ...row,
                        email: value,
                        action: row.action === 'add' ? 'add' : 'update'
                      })}
                    />
                    {this.state.invalidObjects[`${idx}_email`] && (
                      <p className='small alert'>
                        Please enter a valid email address
                      </p>
                    )}
                  </td>

                  <td width="110px">
                    {row.action === 'add' && <Tag type='new'>new</Tag>}
                    {row.action === 'update' && <Tag type='update'>updated</Tag>}
                    {row.action === 'delete' && <Tag type='alert'>delete</Tag>}
                  </td>

                  <td>
                    <Icon size={24} className='clickable' icon={bin} color={colors.brand.primary}
                          onClick={() => this._update_row(idx, {...row, action: row.action !== 'delete' ? 'delete' : 'update'})}/>
                  </td>

                </tr>
              ))}
            </Table>

          </BlankCard>
        )}

      </PageLayout>
    );
  }
}

const mapStateToProps = (state) => ({
  account: state.account,
  persist: state.persist
});

const mapDispatchToProps = (dispatch) => {
  return {
    getProfile: () => dispatch(accountActions.getProfile()),
    getMembers: (data) => dispatch(accountActions.getMembers(data)),
    updateMembers: (data) => dispatch(accountActions.updateMembers(data)),
  }
};

export default userManagementPage = connect(mapStateToProps, mapDispatchToProps)(userManagementPage);
