import React, {useCallback} from 'react';
import mobileImage from '../../static/images/mobile.png'
import {Button} from './../button/button';
import styles from './mobileView.module.scss'

const MobileView = () => {
  const onClick = useCallback(
    () => {
      window.location.href = 'https://tracedock.com';
    },
    []
  );

  return (
    <div className={styles.mobileView}>
      <div className={styles.mobileView_top}>
        <img src={mobileImage} alt="Logo" height="50%"/>
        <h2 className='bigSpacer'>We noticed you're on a mobile device...</h2>
      </div>

      <div className={styles.mobileView_content}>
        <p className='black spacer'>We're working on our mobile experience. For optimal use,
          we recommend using TraceDock on Desktop for now.
        </p>
        <p>Thank you!</p>
        <p>The TraceDock team</p>

        <Button style={{marginTop: 24}} title='Back to tracedock.com' onClick={onClick}/>
      </div>

    </div>
  )
};

export default MobileView;
