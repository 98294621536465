/*
    Convenience library to get wiki from S3

    Used seperately or can be used directly, in case of emails

 */

const formatMsg = (content, data) => {
  // Content parser based on double {{ }} data

  // Create an array of variables that are found in the content using regular expressions
  let regex = /{{2}\s*((\w|\.)+)\s*}{2}/g;
  let match = content.match(regex);
  match && match.forEach((variablePath) => {

    // for each match, replace the match in the content with the data found in data, e.g. {{domain.name}}
    let path = variablePath.substring(2, variablePath.length - 2).trim();

    // Then perform a walk down data, to achieve the final data value, nested in data
    let replace = data;
    path.substring().split('.').forEach((part) => {
      if (part in replace) {
        replace = replace[part]
      }
      return ''
    });

    if (typeof replace === 'string') {
      // Only if a string is found, replace data globally
      content = content.replace(variablePath, replace);
    }
  });
  return content
};


export const getWiki = (path, data, local=false) => {
  // Legacy, used to have wiki remote, now local
  let url = local ? `/docs/${path}` : `https://s3.eu-central-1.amazonaws.com/tracedock-wikipages/${path}.txt`;

  return fetch(url, {method: "GET"})
    .then((response) => response.text().then(
      (content) => {

        if (content.includes('AccessDenied')) {
          return 'Not Found'
        }

        // Parse message if you have data, and then save to redux
        if (content && data) {
          content = formatMsg(content, data);
        }

        return content
      }))
};
