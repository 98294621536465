import {accountState} from './account.state';
import {normalizeDomains} from "../domain.normalizer";

export function accountReducer(state = accountState, action) {
  switch (action.type) {

    case 'ACCOUNT_PENDING':
      return {
        ...state,
        isPending: true,
      };
    case 'ACCOUNT_UPDATE_PENDING':
      return {
        ...state,
        isPendingUpdate: true,
      };
    case 'ACCOUNT_ERROR':
      return {
        ...state,
        isPending: false,
        isPendingUpdate: false,
      };

    case 'PROFILE_DONE':
      return {
        ...state,
        isPending: false,
        isPendingUpdate: false,
        profile_updated: new Date(),
        profile: action.profile,
        admin: action.admin || state.admin,
        agency: action.agency || state.agency,
      };

    case 'SET_AGENCY':
      return {
        ...state,
        isPending: false,
        isPendingUpdate: false,
        agency: action.agency,
      };

    case 'ADMIN_DONE':
      return {
        ...state,
        isPending: false,
        admin: action.admin
      };

    case 'SET_AGENCY_CREDITORS':
      return {
        ...state,
        creditors: action.creditors
      };

    case 'AGENCY_DONE':
      return {
        ...state,
        isPending: false,
        agency_updated: new Date(),
        agency: action.agency,
        creditors: action.creditors,
        agents: action.agents,
      };

    case 'MEMBERS_DONE':
      return {
        ...state,
        isPending: false,
        members_updated: new Date(),
        members: action.members ? action.members : state.members
      };

    case 'CREDITORS_DONE':
      return {
        ...state,
        isPending: false,
        agency_updated: new Date(),
        creditors: action.creditors ? action.creditors : state.creditors,
      };

    case 'ADD_DOMAINS_OF_ADMIN':
      // Used if agency or admin has added a domain
      let normalizedDomains = state.admin.domains.concat(normalizeDomains(action.domains));
      return {
        ...state,
        isPending: false,
        admin: {
          ...state.admin,
          domains: normalizedDomains
        }
      };

    case 'ADD_DOMAINS_OF_CREDITOR':
      // Used if agency or admin has added a domain
      let creditors = [...state.creditors];
      creditors.some((creditor) => {
        if (creditor.uuid === action.company_uuid) {

          // update the creditor's domains
          creditor.domains = normalizeDomains(action.new_domains).concat(creditor.domains);
          return true
        }
        return false;
      });
      return {
        ...state,
        isPending: false,
        creditors: creditors,
      };

    case 'RESET_ACCOUNT_STATE':
      return {
        ...accountState
      };

    default:
      return state;
  }
}
