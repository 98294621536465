import React from "react";
import PropTypes from 'prop-types';
import {down, Icon} from "../../components/icon/icon";
import {colors} from "../../theme/colors";
import {gaFieldDescriptions, gaOtherFields, ga4FieldDescriptions} from "../../lib/google_analytics/fieldDescriptions";
import {EventDetailData} from "./eventDetail.Data";
import {fbFieldDescriptions} from "../../lib/facebook/fieldDescriptions";

export class EventDetail extends React.Component {
  

  static propTypes = {
    event: PropTypes.any,
    onAdd: PropTypes.any
  };

  static defaultProps = {
    event: {},
    onAdd: null
  };

  constructor(props, context) {
    super(props, context);

    this.state = {};
  }

  render({event, onAdd} = this.props) {
    return (
      <div className='collapsableEvent'>

        <div className='column' style={{width: '100%', maxWidth: '50%', justifyContent: 'flex-start'}}>
          <EventDetailData
            title='Inbound data'
            dataPayload={event.inbound}
            copyPayload={event.inbound.datalayer ? event.inbound.datalayer : event.inbound}
            fieldDescriptions={gaFieldDescriptions}
            otherFields={gaOtherFields}
          />
        </div>

        <div className='rotate'>
          <Icon icon={down} size={40} color={colors.brand.gray}/>
        </div>

        <div className='column' style={{width: '100%', maxWidth: '50%', justifyContent: 'flex-start'}}>

          {event.gA4Payload &&
            <EventDetailData
              title='Google Analytics 4'
              isForwarded={event.is_blocked && !JSON.parse(event.gA4Payload)._message}
              isCookieless={event.cookieless}
              dataPayload={JSON.parse(event.gA4Payload)}
              copyPayload={event.gA4Payload}
              fieldDescriptions={ga4FieldDescriptions}
              otherFields={gaOtherFields}
            />}


          {event.outbound_fb &&
          <EventDetailData
            title='Facebook Conversion API'
            isForwarded={event.outbound_fb.is_forwarded}
            dataPayload={event.outbound_fb}
            copyPayload={event.outbound_fb}
            fieldDescriptions={fbFieldDescriptions}
            otherFields={[]}
          />}

          {event.outbound_cdp &&
          <EventDetailData
            title='Customer Data Platform'
            isForwarded={true}
            dataPayload={event.outbound_cdp}
            copyPayload={event.outbound_cdp}
            fieldDescriptions={fbFieldDescriptions}
            otherFields={[]}
          />}

          {event.payload && !event.ga4Payload && !event.outbound_fb && !event.outbound_cdp &&
            <EventDetailData
              title='No data was forwarded'
              isForwarded={false}
              isCookieless={false}
              dataPayload={JSON.parse(event.payload)}
              fieldDescriptions={[]}
              otherFields={[]}
            />}

          {!event.payload && !event.ga4Payload && !event.outbound_fb && !event.outbound_cdp &&
            <EventDetailData
              title='No data was forwarded'
              isForwarded={false}
              isCookieless={false}
              dataPayload={{_message: 'No data was forwarded to any integration'}}
              fieldDescriptions={[]}
              otherFields={[]}
            />}

        </div>

      </div>
    );
  }
}

