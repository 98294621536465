import React from 'react';
import PropTypes from 'prop-types';

import {Input} from "../input/input";
import {BlankCard} from "./blankCard";
import {Tag} from "../tag/tag";
import {Icon, info} from "../icon/icon";
import {colors} from "../../theme/colors";

import './inputCard.scss';

export class InputCard extends React.Component {

  static propTypes = {
    title: PropTypes.string, // number, string, any
    subtitle: PropTypes.string,
    onSave: PropTypes.func,
    afterSave: PropTypes.func,
    objects: PropTypes.array,
    initialData: PropTypes.any,
    readOnly: PropTypes.bool,
    hasReset: PropTypes.bool,
    collapsable: PropTypes.bool,
    initialCollapsed: PropTypes.bool,
  };

  static defaultProps = {
    title: '',
    subTitle: '',
    onSave: (data) => console.log('saving data: ', data),
    afterSave: null,
    objects: [],
    initialData: {},
    readOnly: false,
    hasReset: true,
    collapsable: false,
    initialCollapsed: false,
  };

  constructor(props, context) {
    super(props, context);
    this.state = {
      newData: props.initialData,
      objects: props.objects,

      invalidObjects: {},
      validObjects: {},

      isUpdated: false,
      isLoading: false
    };
  }

  _update_element(obj, key, value) {
    // update element and check if button bar to change can appear

    let newData = {...this.state.newData};
    newData[key] = value;

    this.setState({newData: newData},
      () => {

        // after update, check if update is required, do it only once
        if (!this.state.isUpdated) {
          if (this.props.initialData[key] !== value) {
            this.setState({isUpdated: true})
          }
        }

        // if value was found invalid, check if it is valid now
        if (this.state.invalidObjects[key]) {
          if (obj.validator && obj.validator(value)) {

            // update invalid and valid objects
            let invalidObjects = {...this.state.invalidObjects};
            invalidObjects[key] = false;
            this.setState({invalidObjects: invalidObjects})
          }
        }
      });
  }

  _validate_and_save() {
    // validates element, if all valid, saves
    let invalidObjects = {};
    let validObjects = {};
    let parsedData = {};

    this.state.objects.map((obj) => {

      // check string if not optional, or if filled
      if (!obj.optional || this.state.newData[obj.key] !== '') {

        // use the validator present in object
        if (obj.validator && !obj.validator(this.state.newData[obj.key])) {
          invalidObjects[obj.key] = true
        } else {
          parsedData[obj.key] = this.state.newData[obj.key];
          validObjects[obj.key] = true
        }
      }
      return true
    });

    // either return or populate, invalidObjects
    if (Object.entries(invalidObjects).length === 0) {

      this.setState({isLoading: true}, () => {
        this.props.onSave && this.props.onSave(parsedData).then((data) => {
            this.setState({isLoading: false, isUpdated: !data});

            // execute after Save
            if (this.props.afterSave) this.props.afterSave()
          }
        )
      });

    }
    this.setState({invalidObjects: invalidObjects, validObjects: validObjects})
  }

  _reset_data() {
    let resetState = {
      newData: this.props.initialData,
      isUpdated: false,
      objects: [],
      invalidObjects: {},
      validObjects: {}
    };
    this.setState(resetState, () => {
      this.setState({objects: this.props.objects,})
    });
  }

  renderHelp = (obj) => (obj.help && (
    <div className='row' style={{alignItems: 'center', padding: '0 24px'}}>
      <Icon icon={info} color={colors.brand.primary}/>
      <p style={{marginLeft: 12}} className='small'>{obj.help}</p>
    </div>
  ))

  renderObject = (initialData, obj) => {
    switch (obj.type) {
      case 'description':
        return <div className='row' style={{alignItems: 'center', minWidth: 200, paddingBottom: 12}}>
          <Icon icon={info} color={colors.brand.primary}/>
          <p style={{marginLeft: 12}} className='small'>{obj.help}</p>
        </div>
      case 'confirmation':
        return <div className='inputSelect column' style={{alignItems: 'flex-start'}}>
          <p className='small'>{obj.label}:</p>
          <div className="selectElement noselect clickable"
               onMouseDown={() => {
                 // toggle on mouse down
                 this._update_element(obj, obj.key, this.state.newData[obj.key] === 0 ? 1 : 0)
               }}
          >
            <span className={`checkmark checkbox ${this.state.newData[obj.key] ? 'active' : ''}`}/>
            <span className='small'>{obj.help}</span>
          </div>
        </div>
      default:
        return <div className='inputElement'>
          <Input
            placeholder={obj.placeholder}
            style={{maxWidth: '45%', minWidth: 200}}
            initial={initialData[obj.key]}
            label={obj.label}
            type={obj.type}
            choices={obj.choices}
            restrict={obj.restrict}
            focus={obj.focus}
            isValid={this.state.validObjects[obj.key]}
            isInvalid={this.state.invalidObjects[obj.key]}
            onChange={(value) => this._update_element(obj, obj.key, value)}
            readOnly={obj.readOnly}
          />
          {obj.optional && (
            <Tag style={{marginLeft: 12}} className='small'>optional</Tag>
          )}
          {this.renderHelp(obj)}
        </div>
    }
  }

  render({hasReset, title, subtitle, initialData, readOnly, collapsable, initialCollapsed} = this.props) {
    return (
      <BlankCard title={title}
                 subtitle={subtitle}
                 padding={false}
                 collapsable={collapsable}
                 initialCollapsed={initialCollapsed}
                 hasReset={hasReset}
                 isUpdated={this.state.isUpdated}
                 isLoading={this.state.isLoading}
                 onReset={() => this._reset_data()}
                 onSave={() => this._validate_and_save()}>

        <div className='inputCard'>

          <div className='inputContainer'>
            {this.state.objects.map((obj, idx) => {
              if (obj.hide) return null;
              return (
                <div key={idx}>

                  {this.renderObject(initialData, obj)}

                  {this.state.invalidObjects[obj.key] && obj.errorMessage && (
                    <p className='small formatIndicator'>
                      {obj.errorMessage}
                    </p>
                  )}
                  {this.state.invalidObjects[obj.key] && obj.exampleFormat && (
                    <p className='small formatIndicator'>
                      We expect the following format: <span className='code'>{obj.exampleFormat}</span>
                    </p>
                  )}
                </div>
              )
            })}

          </div>
        </div>
      </BlankCard>
    )
  }
}
