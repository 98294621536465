import React from "react";
import {connect} from "react-redux";

import {colors} from "../../theme/colors";
import {WikiRenderUnconnected} from "../../components/wiki/wikiRender.unconnected";
import {GDPRConfirmModal} from "../../components/installation/gdprModal";
import {accountActions} from "../../redux/actions";
import {setAlertDialog} from "../../components/alert/alert";

class ReviewTermsPage extends React.Component {

  render({history} = this.props) {
    return (
      <div style={{
        backgroundColor: colors.brand.primary_lighter, width: '100%', height: '100vh',
        display: 'flex', flexDirection: 'column', alignContent: 'center', justifyContent: 'center'
      }}>

        <GDPRConfirmModal
          onConfirm={(data) => this.props.confirm_gdpr(data).then((confirmed) => {
            if (confirmed) {
              setAlertDialog('Thanks', 'We have notified your company admin.');
              history.push('/')
            }
          })}
          role_type='dpo'
          creditor={{
            uuid: this.props.match.params.uuid,
          }}
        >
          <WikiRenderUnconnected path='privacy/gdpr'/>
        </GDPRConfirmModal>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  account: state.account,
  persist: state.persist,
});

const mapDispatchToProps = (dispatch) => {
  return {
    confirm_gdpr: (d) => dispatch(accountActions.confirmGDPR(d)),
  }
};

export default ReviewTermsPage = connect(mapStateToProps, mapDispatchToProps)(ReviewTermsPage);

