import React from "react";
import PropTypes from 'prop-types';

import {BlankCard} from "../../../components/cards/blankCard";
import {DataFieldObject} from "../../../components/dataFieldObject/dataFieldObject";
import {eye, eye_active, Icon} from "../../../components/icon/icon";
import {colors} from "../../../theme/colors";
import {DataFieldPreview} from "../../../components/dataFieldObject/dataFieldPreview";
import {testFieldMapping} from "../../../redux/rules/rules.actions";
import {Popup, PopupPosition} from "../../../components/popup";


export const validate_identify_fields = (eventfields) => {
  // validates triggers, returns invalidObjects
  let invalidObjects = {};

  eventfields.map((row, idx) => {

    // validates triggers, is choices restricted to list or more than 0
    if (row.action !== 'delete') {
      if (!row.field_name || row.field_name.length === 0) {
        invalidObjects[`${idx}_field_name`] = true
      }
      if (!row.field_type || row.field_type.length === 0) {
        invalidObjects[`${idx}_field_type`] = true
      }
      if (!row.isReadOnly && (!row.field_value || row.field_value.length === 0)) {
        invalidObjects[`${idx}_field_value`] = true
      }
    }
    return true
  });

  return invalidObjects
};

export class CardIdentifyFields extends React.Component {

  static propTypes = {
    fields: PropTypes.array,
    data: PropTypes.any,
    invalidObjects: PropTypes.any,
    availableFieldPaths: PropTypes.array,
    readOnly: PropTypes.bool,
    onUpdate: PropTypes.func,
    domain: PropTypes.string,
  };

  static defaultProps = {
    fields: [],
    data: {},
    invalidObjects: {},
    availableFieldPaths: [],
    readOnly: false,
    domain: '',
    onUpdate: (data) => console.log('updating data: ', data),
  };

  constructor(props, context) {
    super(props, context);
    this.state = {
      tab: 'fields'
    };
  }

  render({invalidObjects, availableFieldPaths, fields, data, readOnly, domain} = this.props) {

    return (

      <BlankCard
        collapsable={true}
        title='Identify fields'
        subtitle='Determine what customerID is stored.'
        padding={false}>

        <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'flex-end'}}>

          <Popup
            content={
              <p style={{width: 200}}>Preview which custerID is stored in the identify event. It is calculated based on your raw data as found on the left side.</p>
            }
            initialOpen={false}
            stayOpen={false}
            position={PopupPosition.LEFT_CENTER}
          >
            <div className='clickable' style={{padding: '10px 27px 5px 10px'}} onClick={() => this.setState({tab: this.state.tab === 'fields' ? 'preview' : 'fields'})}>
              <Icon size={25} icon={this.state.tab === 'preview' ? eye_active : eye} color={colors.brand.secondary}/>
            </div>
          </Popup>

        </div>

        {this.state.tab === 'fields' && (
          <DataFieldObject
            editReadOnlyFields={true}
            invalidObjects={invalidObjects}
            readOnly={false}

            availableFieldPaths={availableFieldPaths}
            onUpdate={(data) => this.props.onUpdate(data)}

            fields={fields}
            parent=''
            level={0}

            canAdd={false}
          />
        )}

        {this.state.tab === 'preview' && (
          <div style={{minHeight: 510, padding: 20}}>
            <DataFieldPreview
              testFieldMapping={testFieldMapping}
              domain={domain}
              fields={fields}
              data={data}
            />
          </div>
        )}


      </BlankCard>
    )
  }
}
