export const tracedockFields = {

  //TraceDock fields in dataPayload
  ga_payload: {title: 'GA payload', exclude: 0, caption: ''},
  gA4Payload: {title: 'GA4 payload', exclude: 0, caption: ''},
  bot: {title: 'bot', exclude: 1, caption: ''},
  anon_ip: {title: 'Anonymize IP', exclude: 0, caption: 'If true (value 1), IP address is anonymized in GA, otherwise it is fully visible.'},
  cookieless: {title: 'Cookieless CID', exclude: 1, caption: 'If True then no permission for cookies is given and the ClientID is created server-side.'},
  cookies: {title: 'Client cookies', exclude: 0, caption: 'Client cookies'},
  pc: {title: 'Cookies', exclude: 1, caption: 'Preserved cookies'},
  co: {title: 'cookies', exclude: 0, caption: 'Cookies'},
  method: {title: 'XHR method', exclude: 1, caption: 'method'},
  type: {title: 'Event type', exclude: 1, caption: ''},
  blocking_type: {title: 'Blocking type', exclude: 1, caption: ''},
  "_message": {title: 'API message', exclude: 1, caption: ''},
  deid: {title: 'DataLayer event ID', exclude: 1, caption: ''},
  ceid: {title: 'Click event ID', exclude: 1, caption: ''},
  plid: {title: 'Pageload ID', exclude: 1, caption: ''},
  is_blocked: {title: 'is_blocked', exclude: 1, caption: ''},
  is_forwarded: {title: 'is_forwarded', exclude: 1, caption: ''},
  is_bot: {title: 'is_bot', exclude: 1, caption: ''},
  url_params: {title: 'url_params', exclude: 0, caption: ''},
  data: {title: 'data', exclude: 1, caption: ''},
  origin: {title: 'origin', exclude: 1, caption: ''},
  source: {title: 'source', exclude: 1, caption: ''},
  endpoint: {title: 'TraceDock endpoint', exclude: 1, caption: 'TraceDock endpoint'},

  event_id: {title: 'ID of click or data layer event', exclude: 1, caption: ''},
  event_name: {title: 'Event Name', exclude: 0, caption: 'Click or data layer event name'},

}