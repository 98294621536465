import React from "react";
import { cookieless, forward, IconInline, out, out_active, x } from "../../components/icon/icon";
import { colors } from "../../theme/colors";
import { Json } from "../../components/json/json";
import { CopyIcon } from "../../components/icon/copy";
import { Popup } from "../../components/popup";

export class EventDetailData extends React.Component {

  render({
    title, isForwarded, isCookieless, hitbuilderPayload, dataPayload, copyPayload,
    fieldDescriptions, otherFields
  } = this.props) {
    return (
      <div className='bodyData'>
        <div className='titleAligned'>
          <h5 className='spacer'>{title}</h5>

          <div className='row'>
            {hitbuilderPayload && hitbuilderPayload.length > 0 &&
              <CopyIcon text={hitbuilderPayload} copyIcon={out} copyIcon_active={out_active} onCopy={
                () => {
                  Object.assign(document.createElement('a'), {
                    target: '_blank',
                    href: 'https://ga-dev-tools.appspot.com/hit-builder/',
                  }).click();
                }} />}

            {copyPayload &&
              <CopyIcon text={JSON.stringify(copyPayload)} />}
          </div>
        </div>

        <div className='column' style={{ alignItems: 'flex-start' }}>

          {isForwarded && <p><IconInline icon={forward} color={colors.brand.gray} size={18} /> Data is forwarded {title}</p>
          }

          {isCookieless &&
            <Popup content={<p>No permission for cookies is given, a unique & privacy-friendly ClientID is generated server-side via a "hash" function using the IP and User agent.</p>}>
              <p><IconInline icon={cookieless} color={colors.brand.gray} size={18} /> Cookieless tracking enabled</p>
            </Popup>
          }

          {dataPayload._message &&
            <p><IconInline icon={x} color={colors.brand.gray} size={18} /> Message: {dataPayload._message}</p>
          }

        </div>

          {!dataPayload._message &&  
            <Json
              data={dataPayload}
              translation={fieldDescriptions}
              other_fields={otherFields}
            />
          }

      </div>
    );
  }
}

