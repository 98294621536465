import React from "react";
import PropTypes from 'prop-types';

import {BlankCard} from "../../../components/cards/blankCard";
import {JsonRaw} from "../../../components/jsonRaw/json.raw";
import {Tabs} from "../../../components/tabs/tabs";
import {Json} from "../../../components/json/json";

export class CardIdentifyRawData extends React.Component {

  static propTypes = {
    data: PropTypes.any,
    readOnly: PropTypes.bool,
    onUpdate: PropTypes.func,
    selectedFieldPaths: PropTypes.array,
  };

  static defaultProps = {
    data: {},
    invalidObjects: {},
    selectedFieldPaths: [],
    readOnly: false,
    onUpdate: (data) => console.log('updating data: ', data),
  };

  constructor(props, context) {
    super(props, context);
    this.state = {
      tab: props.data ? 'Preview' : 'Code'
    };
  }

  render({invalidObjects, readOnly, onUpdate, data, selectedFieldPaths} = this.props) {
    return (

      <BlankCard
        title='Raw dataLayer data'
        subtitle='Copy here an example of your raw dataLayer to setup the event'
        collapsable={true}
      >

        <Tabs
          initial={this.state.tab}
          onChangeTab={(tab) => this.setState({tab: tab})}
          tabs={['Code', 'Preview']}
        />

        <div style={{border: 'solid 1px #C4CED8', borderRadius: 1}}>

          {this.state.tab === 'Code' && (
            <JsonRaw
              placeholder='// Copy your dataLayer event object here:'
              readOnly={false}
              initialData={data}
              onUpdate={(data) => onUpdate(data)}
            />
          )}

          {this.state.tab === 'Preview' && (
            <div style={{minHeight: 345, maxHeight: 500, overflow: 'auto', padding: '21px 8px'}}>
              <Json
                data={this.props.data}
                selectedFields={selectedFieldPaths}
              />
            </div>
          )}

        </div>

      </BlankCard>
    )
  }
}
