import React from "react";
import {connect} from "react-redux";

import {Button} from "../../components/button/button";

import {ModalLayout} from "../../components/modalLayout/modalLayout";
import {accountActions} from "../../redux/actions";
import {Loader} from "../../components/loader/loader";

class ActivateEmailPage extends React.Component {

  constructor(props, context) {
    super(props, context);
    this.state = {
      email_code: props.match.params.email_code,
      new_email: props.match.params.new_email,
      isLoading: true,
      completed: false
    };
  }

  componentDidMount() {
    // check email and continue
    // this.props.email_activation_link({new_email: this.state.new_email, email_code: this.state.email_code})
    //   .then((profile) => {
    //       if (profile && this.props.persist.isAuthenticated) {
    //         this.setState({isLoading: false, isCompleted: true});
    //       } else {

    //         // if incorrect, directly goto login page
    //         this.props.history.push('/login');
    //       }
    //     }
    //   )
  }

  render({history, persist, account} = this.props) {
    return (
      <div>

        <ModalLayout>
          {this.state.isLoading
            ?
            <div className='centerWrapper'>
              <p className='center spacer'>Confirming your new email.</p>
              <Loader/>
            </div>
            :
            <div>

              <h2>Email updated.</h2>
              <p className='spacer'>Your email has been updated.</p>

              <p>As requested, we have updated your email address to <span className='bold'>{account.profile.email}</span></p>

              <Button
                style={{marginTop: 12}}
                isLoading={this.state.isLoading}
                onClick={() => this.props.history.push('/')}
                title='Continue to portal'
              />
            </div>
          }
        </ModalLayout>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  persist: state.persist,
  account: state.account,
});

const mapDispatchToProps = (dispatch) => {
  return {
    // email_activation_link: (data) => dispatch(accountActions.email_activation_link(data))
  }
};

export default ActivateEmailPage = connect(mapStateToProps, mapDispatchToProps)(ActivateEmailPage);
