import React from 'react';
import PropTypes from 'prop-types';

import './blankCard.scss';
import {Button} from "../button/button";
import {colors} from "../../theme/colors";
import {down, Icon, plus} from "../icon/icon";

export class BlankCard extends React.Component {

  static propTypes = {
    title: PropTypes.string, // number, string, any
    subtitle: PropTypes.string,
    onAddNewTitle: PropTypes.string,
    padding: PropTypes.bool,
    isUpdated: PropTypes.bool,
    isLoading: PropTypes.bool,
    onSave: PropTypes.func,
    onReset: PropTypes.func,
    hasReset: PropTypes.bool,
    onAddNew: PropTypes.func,
    onCopy: PropTypes.func,
    style: PropTypes.any,
    collapsable: PropTypes.bool,
    initialCollapsed: PropTypes.bool,
  };

  static defaultProps = {
    title: '',
    subTitle: '',
    onAddNewTitle: 'Add new',
    padding: true,
    isUpdated: false,
    isLoading: false,
    hasReset: true,
    onAddNew: null,
    onCopy: null,
    onSave: () => console.log('saved'),
    onReset: () => console.log('reset'),
    style: {},
    collapsable: false,
    initialCollapsed: false
  };

  constructor(props, context) {
    super(props, context);
    this.state = {
      collapsed: props.initialCollapsed
    };
  }

  render({hasReset, collapsable, title, subtitle, padding, style, children, isUpdated, isLoading, onSave, onReset, onAddNew, onCopy, onAddNewTitle} = this.props) {
    return (
      <div className='cardContainer' style={style}>

        {title && (
          <div className='hero'>
            <div className={`title ${collapsable ? 'clickable' : ''}`} onClick={() => {if (collapsable) this.setState({collapsed: !this.state.collapsed})}}>
              <h4>{title}</h4>
                {subtitle && (<h6>{subtitle}</h6>)}   
            </div>
 

            <div className='buttonsRight row'>
              {onCopy && (
                <Button
                  onClick={() => onCopy()}
                  style={{width: 130, marginRight: 12}}
                  title='Copy event from'
                  backgroundColor={colors.brand.blue110}
                />
              )}

              {onAddNew && (
                <Button
                  style={{width: 130}}
                  title={onAddNewTitle}
                  iconRight={plus}
                  onClick={onAddNew}
                  backgroundColor={colors.brand.blue110}
                />
              )}

              {collapsable && (
                <div onClick={() => this.setState({collapsed: !this.state.collapsed})}
                     className={`clickable collapsable ${this.state.collapsed ? 'rotate' : ''}`}>
                  <Icon icon={down} color={colors.brand.darkgray} size={18}/>
                </div>
              )}
            </div>
          </div>
        )}

        <div className={`${padding ? 'paddedContent' : 'NoPaddedContent'}  ${this.state.collapsed ? 'closed' : 'open'}`}>
          <div className={this.state.collapsed ? 'hidden' : ''}>
            {children}
          </div>
        </div>

        <div className={`footer ${isUpdated && !this.state.collapsed ? 'open' : 'closed'}`}>
          {hasReset && (
            <Button
              style={{maxWidth: 120}}
              title='Reset'
              onClick={onReset}
              color={colors.brand.darkgray}
              cancel={true}
              backgroundColor={colors.brand.gray_light}/>
          )}

          <Button
            style={{marginLeft: 12, width: 120}}
            title={hasReset ? 'Save' : 'Continue'}
            onClick={onSave}
            isLoading={isLoading}
            color={colors.brand.darkgray}
            backgroundColor={colors.brand.accent_green}
            loaderColor={colors.brand.secondary}
          />
        </div>

      </div>
    );
  }
}