import React from 'react';

import {down, Icon} from "../icon/icon";
import {colors} from "../../theme/colors";
import {updateIfNeeded} from "../../lib/helpers";

import './domainSelector.scss';
import {DemoPopup, PopupPosition} from "../popup";


export class DomainSelectorComponent extends React.Component {

  static propTypes = {};

  static defaultProps = {};

  constructor(props, context) {
    super(props, context);

    this.state = {

      open: false,

      // for lookup
      lookupValue: '',
      filtered_domains: [],
      currentChoiceIdx: -1,
    };
  }

  _deselect(callback = null) {
    this.setState({
      open: false,
      lookupValue: '',
      filtered_domains: [],
      currentChoiceIdx: -1,
    }, callback);
  }

  _select(domain) {
    
    this.setState({
      open: false,
      lookupValue: '',
      filtered_domains: [],
      currentChoiceIdx: -1,
    }, () => {
      this.props.SetDomain({domain: domain}).then(() => this.props.history.push('/'))
      // if (this.props.SetDomain && domain.id !== this.props.domain.id) {
      //   this.props.SetDomain({domain: domain}).then(() => this.props.history.push('/'))
      // }
    });
  }

  _showAllDomains() {

    updateIfNeeded(this.props.account.profile_updated, this.props.getProfile);

    // then show all if relevant
    if (this.props.all_domains && this.props.all_domains.length >= 1) {
      this.setState({open: true});

      // and set demo
      this.props.setDemo && this.props.setDemo({navigate_domains: false})
    }
  }

  _render_selector({all_domains} = this.props) {

    let domains = this.state.filtered_domains.length > 0 ? this.state.filtered_domains : all_domains;

    return (
      <div className='domainSelector'>

        {this.state.open && <div className='BackDrop' onClick={() => this.setState({open: false})}/>}

        <div className={`domainWrapper ${this.state.open ? 'open' : ''}`}>


          <div className='domain clickable' onClick={() => {
            if (!this.state.open) this._showAllDomains()
          }}>

            {!this.state.open
              ?
              <h6 style={{height: 20, marginTop: 2}} className='maxWidth secondaryColor'>
                All domains > {this.props.domain.short_slug}
              </h6>
              :
              <input
                style={{height: 20, marginTop: 2}}
                className='input secondaryColor'
                autoFocus={true}
                type='text'
                value={this.state.lookupValue}
                autoComplete="new-info"
                onChange={
                  (event) => {
                    // filter down domains
                    let value = event.target.value.toLowerCase();
                    let newChoices = this.props.all_domains.filter((choice) => {
                      return (choice.short_slug && choice.short_slug.toLowerCase().includes(value))
                        ||
                        (choice.creditor_company_slug && choice.creditor_company_slug.includes(value))
                    });
                    this.setState({
                      lookupValue: event.target.value,
                      filtered_domains: newChoices.length > 0 ? newChoices : this.props.all_domains,
                      currentChoiceIdx: -1
                    });
                  }
                }
                onKeyDown={(event) => {

                  // Option A: work with arrows, down/up only if choices
                  if (event.key === 'ArrowDown' || event.key === 'ArrowUp') {

                    // Prevent default means to stop activity that would cause the page to scroll down
                    event.preventDefault();

                    let newIndex = -2;
                    if (event.key === 'ArrowDown' && this.state.currentChoiceIdx < domains.length) {
                      newIndex = this.state.currentChoiceIdx + 1;
                    } else if (event.key === 'ArrowUp' && this.state.currentChoiceIdx > -1) {
                      newIndex = this.state.currentChoiceIdx - 1;
                    }

                    // only continue if newIndex > -1
                    if (newIndex > -1) {
                      this.setState({currentChoiceIdx: newIndex});
                    }

                  } else {

                    // Option B: in nearly all cases upon enter/tab we submit and blur the field
                    // However, if you have choices && tab, we have tab completion and this does not submit or blur.
                    let submit = (event.key === 'Enter' || event.key === 'Tab' || event.key === 'Escape');

                    if (event.key === 'Enter' || event.key === 'Tab') {

                      // either you select the first value, or the value from the index
                      let selectedObject = domains[this.state.currentChoiceIdx > -1 ? this.state.currentChoiceIdx : 0];

                      if (this.state.currentChoiceIdx > -1 || selectedObject.short_slug.startsWith(event.target.value)) {
                        // we set the selected value to the first in the choices
                        this._select(selectedObject);
                      }
                    }

                    // if we submit we need to blur and trigger submit
                    if (submit) {
                      event.target.blur();
                      this._deselect();
                    }
                  }
                }}
              />
            }

            <div className={`down ${this.state.open ? 'rotate' : ''}`}
                 onClick={() => {
                   if (this.state.open) this.setState({open: false})
                 }}>
              <Icon icon={down} color={colors.brand.secondary}/>
            </div>

          </div>

          <div className={`all_domains ${this.state.open ? 'open' : 'closed'}`}>


            {domains && domains.map((domain_obj, idx) => {

              // add newCreditor line for the first, and if the creditor has changed.
              let newCreditor = idx === 0 ? true :  domain_obj.creditor_company_name !== domains[idx - 1].creditor_company_name;
              return (
                <div key={idx}>

                  {newCreditor && <div>
                    <h6 className='secondaryColor maxWidth title creditor'>{""}</h6>
                  </div>}

                  <div
                     className={`row clickable ${this.state.open ? '' : 'closed'}`}
                     onClick={() => {
                       if (this.state.open) this._select(domain_obj)
                     }}>
                    <h6 className={`secondaryColor maxWidth title bold ${idx === this.state.currentChoiceIdx ? 'highlight' : ''}`}>
                      {domain_obj.short_slug}
                    </h6>
                  </div>

                </div>
              )
            })}

          </div>
        </div>

      </div>
    );
  }


  render({all_domains, demo, allowDemo} = this.props) {
    if (allowDemo && demo && demo.navigate_domains && all_domains && all_domains.length > 1) {
      return <DemoPopup
        title='Switch sites'
        message='Switch between the different sites you want to add TraceDock to.'
        position={PopupPosition.BOTTOM_CENTER}
        onClose={() => this.props.setDemo({navigate_domains: false})}
      >
        {this._render_selector()}
      </DemoPopup>
    } else {
      return this._render_selector()
    }
  }

}
