/*
    GA measurement protocol
    https://developers.google.com/analytics/devguides/collection/protocol/v1/reference
*/

// GA possible fields does not contain technical fields for usability
let gaFields = [
  {key:'t', value:  'hit type'},
  {key:'ds', value:  'data source'},
  {key: 'tid', value:'property id'},

  // {key:'ni', value:  'non-interaction'},
  // {key:'linkid', value:  'link id'},

//Client data
  {key:'cid', value:  'client id'},
  {key:'uid', value:  'user id'},
  {key:'anon_ip', value:  'Anonymize IP'},

//location
  {key:'ua', value:  'user agent', readOnlyValue: 'window.userAgent'},
  // {key:'geoid', value:  'geo location id'},
  {key:'uip', value:  'user ip'},
  // {key:'aip', value:  'boolean anonymous ip'},

//event data
  {key:'ec', value:  'event category'},
  {key:'ea', value:  'event action'},
  {key:'el', value:  'event label'},
  {key:'ev', value:  'event value'},

//path url. Import, we only use dh + dp
  {key:'dh', value:  'page hostname'},
  {key:'dp', value:  'page path'},
  {key:'dt', value:  'document title'},
  // {key:'cd', value:  'Page screen name'},

//marketing data
  {key:'dr', value:  'referrer', readOnlyValue: 'window.document.referrer'},
  {key:'cn', value:  'campaign name', readOnlyValue: 'url_params.utm_campaign'},
  {key:'cs', value:  'campaign source', readOnlyValue: 'url_params.utm_source'},
  {key:'cm', value:  'campaign medium', readOnlyValue: 'url_params.utm_medium'},
  {key:'ck', value:  'campaign keywords', readOnlyValue: 'url_params.utm_term'},
  {key:'cc', value:  'campaign content', readOnlyValue: 'url_params.utm_content'},
  {key:'gclid', value:  'google ads id', readOnlyValue: 'url_params.gclid'},
  {key:'dclid', value:  'display ads id', readOnlyValue: 'url_params.dclid'},
  {key:'fbclid', value:  'facebook ads id', readOnlyValue: 'url_params.fbclid'},

//Device data
  {key:'sr', value:  'screen resolution', readOnlyValue: 'window.screen.width/height'},
  {key:'vp', value:  'viewport size', readOnlyValue: 'window.screen.availWidth/Height'},
  {key:'de', value:  'document encoding', readOnlyValue: 'window.document.characterSet'},
  {key:'sd', value:  'screen colors', readOnlyValue: 'window.screen.colorDepth'},
  {key:'ul', value:  'language support', readOnlyValue: 'window.navigator.language'},
  // {key:'je', value:  'javascript enabled'},
  // {key:'fl', value:  'flash version'},

//e-commerce
  {key:'ti', value:  'transaction id'},
  {key:'ta', value:  'transaction affiliation'},
  {key:'tr', value:  'transaction revenue'},
  {key:'ts', value:  'transaction shipping costs'},
  {key:'tt', value:  'transaction tax'},
  {key:'cu', value:  'transaction currency'},
  {key:'tcc', value:  'transaction coupon'},

//product action
  {key:'pa', value:  'product action'},
  {key:'pal', value:  'product action list'},

//product checkout
  {key:'cos', value:  'check out step'},
  {key:'col', value:  'check out step option'},

// Nested list of actions

  //Product impressions (nested view)
  {key:'pr', value:  'product list', type: 'array'},
  {key:'pr.id', value:  'products ID'},
  {key:'pr.nm', value:  'products name'},
  {key:'pr.br', value:  'products brand'},
  {key:'pr.ca', value:  'products category'},
  {key:'pr.va', value:  'products variant'},
  {key:'pr.pr', value:  'products price'},
  {key:'pr.qt', value:  'products quantity'},
  {key:'pr.cc', value:  'products coupon'},
  {key:'pr.ps', value:  'products position'},

  // Product impressions
  {key:'il', value:  'impressions list', type: 'array'},
  {key:'il.nm', value:  'impressions.list_name'},

  // remove this list as it falls under the products
  // {key:'il.pi.nm', value:  'products.name'},
  // {key:'il.pi.id', value:  'products.id'},
  // {key:'il.pi.br', value:  'products.brand'},
  // {key:'il.pi.ca', value:  'products.category'},
  // {key:'il.pi.va', value:  'products.variant'},
  // {key:'il.pi.ps', value:  'products.position'},
  // {key:'il.pi.pr', value:  'products.price'},

  //Product impressions
  {key:'promoa', value:  'promotion action'},
  {key:'promo', value:  'promotions', type: 'array'},
  {key:'promo.nm', value:  'promotions name'},
  {key:'promo.id', value:  'promotions ID'},
  {key:'promo.cr', value:  'promotions creative'},
  {key:'promo.ps', value:  'promotions position'},

//item hit, deprecated
  // {key:'in', value:  'item name'},
  // {key:'ip', value:  'item price'},
  // {key:'iq', value:  'item quantity'},
  // {key:'ic', value:  'item code'},
  // {key:'iv', value:  'item category'},

//social interactions
  {key:'sn', value:  'social network'},
  {key:'sa', value:  'social action'},
  {key:'st', value:  'social target'},

//user timing
  //{key:'utv', value:  'user timing variable'},
  //{key:'utt', value:  'user timing time in milliseconds'},
  //{key:'utc', value:  'user timing category'},
  //{key:'utl', value:  'user timing label'},
  //{key:'plt', value:  'page load time'},
  //{key:'dns', value:  'dns load time'},
  //{key:'pdt', value:  'page download time'},
  //{key:'rrt', value:  'page redirect time'},
  //{key:'tcp', value:  'tcp connection time'},
  //{key:'srt', value:  'server response time'},
  //{key:'dit', value:  'dom interactive time'},
  //{key:'clt', value:  'content load time'},

//exceptions
  {key:'exd', value:  'exception description'},
  {key:'exf', value:  'exception fatal'},

//experiments
  {key:'xid', value:  'experiment id'},
  {key:'xvar', value:  'experiment variant'},

//for tracking apps
  {key:'an', value:  'app name used to track'},
  {key:'ai', value:  'app id used to track'},
  {key:'av', value:  'app version used to track'},
  {key:'aiid', value:  'app installer identifier'},


//content groups to cluster data, currently not in use
  {key:'cg1', value:  'content group 1'},
  {key:'cg2', value:  'content group 2'},
  {key:'cg3', value:  'content group 3'},
  {key:'cg4', value:  'content group 4'},
  {key:'cg5', value:  'content group 5'},
  {key:'cg6', value:  'content group 6'},
  {key:'cg7', value:  'content group 7'},
  {key:'cg8', value:  'content group 8'},
  {key:'cg9', value:  'content group 9'},
  {key:'cg10', value:  'content group 10'},

//custom metrics, not in use
  {key:'cm1', value:  'custom metric 1'},
  {key:'cm2', value:  'custom metric 2'},
  {key:'cm3', value:  'custom metric 3'},
  {key:'cm4', value:  'custom metric 4'},
  {key:'cm5', value:  'custom metric 5'},
  {key:'cm6', value:  'custom metric 6'},
  {key:'cm7', value:  'custom metric 7'},
  {key:'cm8', value:  'custom metric 8'},
  {key:'cm9', value:  'custom metric 9'},
  {key:'cm10', value:  'custom metric 10'},
  {key:'cm11', value:  'custom metric 11'},
  {key:'cm12', value:  'custom metric 12'},
  {key:'cm13', value:  'custom metric 13'},
  {key:'cm14', value:  'custom metric 14'},
  {key:'cm15', value:  'custom metric 15'},
  {key:'cm16', value:  'custom metric 16'},
  {key:'cm17', value:  'custom metric 17'},
  {key:'cm18', value:  'custom metric 18'},
  {key:'cm19', value:  'custom metric 19'},
  {key:'cm20', value:  'custom metric 20'},

];

export let ga_customDimensions = [{key: '', value: 'None'}];
for (let i=1; i <= 100; i ++) {
  ga_customDimensions.push({key:`cd${i}`, value:  `custom dim ${i}`},)
}

export let ga_customIndex = [];
for (let i=1; i <= 200; i ++) {
  ga_customIndex.push({key:`cd${i}`, value:  `Index ${i}`},)
}

export const gaPossibleFields = [...gaFields, ...ga_customDimensions];


export const ga4PossibleFields = [
  {key:'name', value:  'event name'},
  {key: 'tid', value:'property id'},

  // {key:'ni', value:  'non-interaction'},
  // {key:'linkid', value:  'link id'},

//Client data
  {key:'cid', value:  'client_id'},
  {key:'uid', value:  'user id'},

//location
  {key:'ua', value:  'user agent', readOnlyValue: 'window.userAgent'},
  // {key:'geoid', value:  'geo location id'},
  {key:'uip', value:  'user ip'},
  // {key:'aip', value:  'boolean anonymous ip'},

//path url. Import, we only use dh + dp
  {key:'dl', value:  'page location'},
  {key:'dh', value:  'page hostname'},
  {key:'dp', value:  'page path'},
  {key:'dt', value:  'document title'},
  // {key:'cd', value:  'Page screen name'},

//marketing data
  {key:'dr', value:  'referrer', readOnlyValue: 'window.document.referrer'},
  {key:'cn', value:  'campaign name', readOnlyValue: 'url_params.utm_campaign'},
  {key:'cs', value:  'campaign source', readOnlyValue: 'url_params.utm_source'},
  {key:'cm', value:  'campaign medium', readOnlyValue: 'url_params.utm_medium'},
  {key:'ck', value:  'campaign keywords', readOnlyValue: 'url_params.utm_term'},
  {key:'cc', value:  'campaign content', readOnlyValue: 'url_params.utm_content'},
  {key:'gclid', value:  'google ads id', readOnlyValue: 'url_params.gclid'},
  {key:'dclid', value:  'display ads id', readOnlyValue: 'url_params.dclid'},
  {key:'fbclid', value:  'facebook ads id', readOnlyValue: 'url_params.fbclid'},

//Device data
  {key:'sr', value:  'screen resolution', readOnlyValue: 'window.screen.width/height'},
  {key:'vp', value:  'viewport size', readOnlyValue: 'window.screen.availWidth/Height'},
  {key:'de', value:  'document encoding', readOnlyValue: 'window.document.characterSet'},
  {key:'sd', value:  'screen colors', readOnlyValue: 'window.screen.colorDepth'},
  {key:'ul', value:  'language support', readOnlyValue: 'window.navigator.language'},
  // {key:'je', value:  'javascript enabled'},
  // {key:'fl', value:  'flash version'},

  {key: 'params', value: 'params'},

  {key: 'item_list_id', value: 'item_list_id'},
  {key: 'item_list_name', value: 'item_list_name'},
  {key: 'currency', value: 'currency'},

  {key: 'value', value: 'value'},
  {key: 'shipping', value: 'shipping'},
  {key: 'tax', value: 'tax'},
  {key: 'transaction_id', value: 'transaction_id'},
  {key: 'transaction', value: 'transaction'},

  {key: 'items', value: 'items'},
  {key: 'item_id', value: 'item_id'},
  {key: 'item_name', value: 'item_name'},
  {key: 'item_brand', value: 'item_brand'},
  {key: 'item_category', value: 'item_category'},
  {key: 'item_category2', value: 'item_category2'},
  {key: 'item_category3', value: 'item_category3'},
  {key: 'item_category4', value: 'item_category4'},
  {key: 'item_category5', value: 'item_category5'},
  {key: 'item_variant', value: 'item_variant'},
  {key: 'price', value: 'price'},
  {key: 'quantity', value: 'quantity'},
  {key: 'discount', value: 'discount'},
  {key: 'affiliation', value: 'affiliation'},
  {key: 'coupon', value: 'coupon'},
  {key: 'location', value: 'location'},
  {key: 'index', value: 'index'},
  {key: 'payment_type', value: 'payment_type'},
  {key: 'shipping_tier', value: 'shipping_tier'},
  {key: 'creative_name', value: 'creative_name'},
  {key: 'creative_slot', value: 'creative_slot'},
  {key: 'promotion_id', value: 'promotion_id'},
  {key: 'promotion_name', value: 'promotion_name'},
  {key: 'search_term', value: 'search_term'}
];