import React from "react";
import PropTypes from 'prop-types';

import {colors} from "../../../../theme/colors";

import {BlankCard} from "../../../../components/cards/blankCard";
import {Input} from "../../../../components/input/input";
import {SideModal} from "../../../../components/sideModal/sideModal";
import {Button} from "../../../../components/button/button";
import {setAlertDialog} from "../../../../components/alert/alert";
import {CardClickEventTriggers, validate_triggers} from "./cardTriggers";
import {CardClickEventFields, validate_fields} from "./cardFields";

export class ClickEventModal extends React.Component {

  static propTypes = {
    clickEvent: PropTypes.any,
    show: PropTypes.bool,
    readOnly: PropTypes.bool,
    onClose: PropTypes.func,
    onSave: PropTypes.func,
    onDelete: PropTypes.func
  };

  static defaultProps = {
    clickEvent: {},
    show: false,
    readOnly: false,
    onClose: () => console.log('closing'),
    onSave: (data) => console.log('saving data: ', data),
    onDelete: (data) => console.log('deleting data: ', data),
  };

  constructor(props, context) {
    super(props, context);

    this.state = {
      currentClickEvent: props.clickEvent,
      isUpdated: false,
      isLoading: false,
      invalidObjects: {},
    };
  }

  _update_element(key, value) {
    let newData = {...this.state.currentClickEvent};
    newData[key] = value;
    this.setState({isUpdated: true, currentClickEvent: newData});
  }

  _save() {
    // validates element, if all valid, saves
    let invalidObjects = {};

    // check basic data
    if (this.state.currentClickEvent.name.length === 0) invalidObjects['name'] = true;
    if (![true, false].includes(this.state.currentClickEvent.is_active)) invalidObjects['is_active'] = true;
    if (![1, 0].includes(this.state.currentClickEvent.trigger_once_per_page)) invalidObjects['trigger_once_per_page'] = true;

    // check trigger validators
    invalidObjects = {
      ...invalidObjects,
      ...validate_triggers(this.state.currentClickEvent.triggers),
      ...validate_fields(this.state.currentClickEvent.eventfields)
    };

    // either return or populate, invalidObjects
    if (Object.entries(invalidObjects).length === 0) {

      this.setState({isLoading: true}, () => {
        this.props.onSave(this.state.currentClickEvent)
          .then((d) => {
            if (d && d.event) {
              this.setState({isLoading: false, isUpdated: false, currentClickEvent: {...d.event, title: 'Adjust click event'}})
            } else {
              this.setState({isLoading: false, isUpdated: true})
            }
          })
      });

    }
    this.setState({invalidObjects: invalidObjects})
  }

  _reset() {
    let currentClickEvent = {};
    if (this.props.clickEvent.id) {
      // reset
      currentClickEvent = this.props.clickEvent;
      this.setState({
        isUpdated: false,
        currentClickEvent: currentClickEvent,
        invalidObjects: {}
      })
    } else {
    // for new navigate back
    this.props.onClose()
    }
  }


  render({show, readOnly, onClose} = this.props) {
    return (
      <SideModal
        title={this.state.currentClickEvent.title}
        show={show}
        onClose={() => onClose()}
        isUpdated={this.state.isUpdated}
        isLoading={this.state.isLoading}
        onSave={() => this._save()}
        onReset={() => this._reset()}
      >

        <div>
          <BlankCard
            title='Basics'>

            <div style={{display: 'flex', flexDirection: 'row', alignItems: 'flex-start'}}>
              <div>
                <Input
                  style={{maxWidth: 300, marginTop: 6}}
                  initial={this.state.currentClickEvent.name}
                  label='Name'
                  type='text'
                  isInvalid={this.state.invalidObjects['name']}
                  onChange={(value) => this._update_element('name', value)}
                  readOnly={false}
                />
              </div>
              <div style={{marginLeft: 12}}>
                <Input
                  style={{maxWidth: 300, marginTop: 6}}
                  initial={this.state.currentClickEvent.is_active}
                  label='State'
                  type='text'
                  restrict={true}
                  choices={[{key: true, value: 'active'}, {key: false, value: 'inactive'}]}
                  isInvalid={this.state.invalidObjects['is_active']}
                  onChange={(value) => this._update_element('is_active', value)}
                  readOnly={false}
                />
              </div>
              <div style={{marginLeft: 12}}>
                <Input
                  style={{maxWidth: 300, marginTop: 6}}
                  initial={this.state.currentClickEvent.trigger_once_per_page}
                  label='trigger frequency'
                  type='text'
                  restrict={true}
                  choices={[{key: 1, value: 'once per page'}, {key: 0, value: 'unlimited'}]}
                  isInvalid={this.state.invalidObjects['trigger_once_per_page']}
                  onChange={(value) => this._update_element('trigger_once_per_page', value)}
                  readOnly={false}
                />
                {this.state.invalidObjects['trigger_once_per_page'] && (
                  <p className='small alert'>
                    Please select a valid choice
                  </p>
                )}
              </div>
            </div>

          </BlankCard>

          <CardClickEventTriggers
            triggers={this.state.currentClickEvent.triggers}
            readOnly={false}
            invalidObjects={this.state.invalidObjects}
            onUpdate={(value) => this._update_element('triggers', value)}
          />

          <CardClickEventFields
            eventfields={this.state.currentClickEvent.eventfields}
            readOnly={false}
            invalidObjects={this.state.invalidObjects}
            onUpdate={(value) => this._update_element('eventfields', value)}
          />

          {this.state.currentClickEvent.id && (
            <Button
              title='Delete click event'
              backgroundColor={colors.brand.gray_light}
              color={colors.brand.darkgray_light}
              cancel={true}
              style={{width: 150, marginTop: 70}}
              onClick={() => {
                setAlertDialog('Delete click event', 'Are you sure you want to delete this click event?', '',
                  [
                    {
                      title: 'yes',
                      backgroundColor: colors.brand.gray,
                      onPress: () => this.props.onDelete(this.state.currentClickEvent)
                    },
                    {title: 'cancel', backgroundColor: colors.brand.primary},
                  ])
              }}
            />
          )}
        </div>

      </SideModal>
    );
  }
}

