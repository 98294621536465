export const cdpTemplateChoices = [
  {value: 'Empty', key: 'empty'},
  {value: 'Page view', key: 'page_view'},
  {value: 'Product detail page view', key: 'product_detail_page_view'},
  {value: 'Add to cart', key: 'add_to_cart'},
  {value: 'Remove from cart', key: 'remove_from_cart'},
  {value: 'Purchase', key: 'purchase'},
];

export const cdpTemplates = {
  empty: [],

  page_view: [
    {field_name: 'WebtrackerClientId', field_type: 'l', field_value: 'anonymous_id', children: []},
    {field_name: 'WebtrackerSessionId', field_type: 'l', field_value: '', children: []},
    {field_name: 'Domain', field_type: 'l', field_value: '', children: []},
    {field_name: 'IntegrationId', field_type: 'l', field_value: '', children: []},
    {field_name: 'Url', field_type: 'l', field_value: 'href', children: []},
    {field_name: 'UtmId', field_type: 'l', field_value: 'url_params.utm_id', children: []},
    {field_name: 'UtmSource', field_type: 'l', field_value: 'url_params.utm_source', children: []},
    {field_name: 'UtmMedium', field_type: 'l', field_value: 'url_params.utm_medium', children: []},
    {field_name: 'UtmCampaign', field_type: 'l', field_value: 'url_params.utm_campaign', children: []},
    {field_name: 'UrlParams', field_type: 'l', field_value: 'url_params', children: []},
    {field_name: 'MailId', field_type: 'l', field_value: 'url_params.mailId', children: []},
    {field_name: 'MessageId', field_type: 'l', field_value: 'url_params.messageId', children: []},
  ],

  product_detail_page_view: [
    {field_name: 'WebtrackerClientId', field_type: 'l', field_value: 'anonymous_id', children: []},
    {field_name: 'WebtrackerSessionId', field_type: 'l', field_value: '', children: []},
    {field_name: 'Domain', field_type: 'l', field_value: '', children: []},
    {field_name: 'IntegrationId', field_type: 'l', field_value: '', children: []},
    {field_name: 'Items', field_type: 'a', field_value: 'items', children: [
      {field_name: 'ItemId', field_type: 'l', field_value: 'items.item_id', children: []},
      {field_name: 'ItemName', field_type: 'l', field_value: 'items.item_name', children: []},
      {field_name: 'ItemBrand', field_type: 'l', field_value: 'items.item_brand', children: []},
      {field_name: 'ItemCategory', field_type: 'l', field_value: 'items.item_category', children: []},
      {field_name: 'ItemCategory2', field_type: 'l', field_value: 'items.item_category2', children: []},
      {field_name: 'ItemCategory3', field_type: 'l', field_value: 'items.item_category3', children: []},
      {field_name: 'ItemCategory4', field_type: 'l', field_value: 'items.item_category4', children: []},
      {field_name: 'ItemCategory5', field_type: 'l', field_value: 'items.item_category5', children: []},
      {field_name: 'ItemVariant', field_type: 'l', field_value: 'items.item_variant', children: []},
      {field_name: 'Price', field_type: 'l', field_value: 'items.price', children: []},
      {field_name: 'Discount', field_type: 'l', field_value: 'items.discount', children: []}
    ]}
  ],

  add_to_cart: [
    {field_name: 'WebtrackerClientId', field_type: 'l', field_value: 'anonymous_id', children: []},
    {field_name: 'WebtrackerSessionId', field_type: 'l', field_value: '', children: []},
    {field_name: 'Domain', field_type: 'l', field_value: '', children: []},
    {field_name: 'IntegrationId', field_type: 'l', field_value: '', children: []},
    {field_name: 'Items', field_type: 'a', field_value: 'items', children: [
      {field_name: 'ItemId', field_type: 'l', field_value: 'items.item_id', children: []},
      {field_name: 'ItemName', field_type: 'l', field_value: 'items.item_name', children: []},
      {field_name: 'ItemBrand', field_type: 'l', field_value: 'items.item_brand', children: []},
      {field_name: 'ItemCategory', field_type: 'l', field_value: 'items.item_category', children: []},
      {field_name: 'ItemCategory2', field_type: 'l', field_value: 'items.item_category2', children: []},
      {field_name: 'ItemCategory3', field_type: 'l', field_value: 'items.item_category3', children: []},
      {field_name: 'ItemCategory4', field_type: 'l', field_value: 'items.item_category4', children: []},
      {field_name: 'ItemCategory5', field_type: 'l', field_value: 'items.item_category5', children: []},
      {field_name: 'ItemVariant', field_type: 'l', field_value: 'items.item_variant', children: []},
      {field_name: 'Price', field_type: 'l', field_value: 'items.price', children: []},
      {field_name: 'Quantity', field_type: 'l', field_value: 'items.quantity', children: []},
      {field_name: 'Discount', field_type: 'l', field_value: 'items.discount', children: []}
    ]}
  ],
  
  remove_from_cart: [
    {field_name: 'WebtrackerClientId', field_type: 'l', field_value: 'anonymous_id', children: []},
    {field_name: 'WebtrackerSessionId', field_type: 'l', field_value: '', children: []},
    {field_name: 'Domain', field_type: 'l', field_value: '', children: []},
    {field_name: 'IntegrationId', field_type: 'l', field_value: '', children: []},
    {field_name: 'Items', field_type: 'a', field_value: 'items', children: [
      {field_name: 'ItemId', field_type: 'l', field_value: 'items.item_id', children: []},
      {field_name: 'ItemName', field_type: 'l', field_value: 'items.item_name', children: []},
      {field_name: 'ItemBrand', field_type: 'l', field_value: 'items.item_brand', children: []},
      {field_name: 'ItemCategory', field_type: 'l', field_value: 'items.item_category', children: []},
      {field_name: 'ItemCategory2', field_type: 'l', field_value: 'items.item_category2', children: []},
      {field_name: 'ItemCategory3', field_type: 'l', field_value: 'items.item_category3', children: []},
      {field_name: 'ItemCategory4', field_type: 'l', field_value: 'items.item_category4', children: []},
      {field_name: 'ItemCategory5', field_type: 'l', field_value: 'items.item_category5', children: []},
      {field_name: 'ItemVariant', field_type: 'l', field_value: 'items.item_variant', children: []}
    ]}
  ],

  purchase: [
    {field_name: 'WebtrackerClientId', field_type: 'l', field_value: 'anonymous_id', children: []},
    {field_name: 'WebtrackerSessionId', field_type: 'l', field_value: '', children: []}, // how to fill this?
    {field_name: 'Domain', field_type: 'l', field_value: '', children: []},
    {field_name: 'IntegrationId', field_type: 'l', field_value: '', children: []},
    {field_name: 'CustomerId', field_type: 'l', field_value: '', children: []},
    {field_name: 'EmailAddress', field_type: 'l', field_value: '', children: []},
    {field_name: 'Currency', field_type: 'l', field_value: 'currency', children: []},
    {field_name: 'TransactionId', field_type: 'l', field_value: 'transaction_id', children: []},
    {field_name: 'Value', field_type: 'l', field_value: 'value', children: []},
    {field_name: 'Coupon', field_type: 'l', field_value: 'coupon', children: []},
    {field_name: 'Shipping', field_type: 'l', field_value: 'shipping', children: []},
    {field_name: 'Tax', field_type: 'l', field_value: 'tax', children: []},
    {field_name: 'Items', field_type: 'a', field_value: 'items', children: [
      {field_name: 'ItemId', field_type: 'l', field_value: 'items.item_id', children: []},
      {field_name: 'ItemName', field_type: 'l', field_value: 'items.item_name', children: []},
      {field_name: 'ItemBrand', field_type: 'l', field_value: 'items.item_brand', children: []},
      {field_name: 'ItemCategory', field_type: 'l', field_value: 'items.item_category', children: []},
      {field_name: 'ItemCategory2', field_type: 'l', field_value: 'items.item_category2', children: []},
      {field_name: 'ItemCategory3', field_type: 'l', field_value: 'items.item_category3', children: []},
      {field_name: 'ItemCategory4', field_type: 'l', field_value: 'items.item_category4', children: []},
      {field_name: 'ItemCategory5', field_type: 'l', field_value: 'items.item_category5', children: []},
      {field_name: 'ItemVariant', field_type: 'l', field_value: 'items.item_variant', children: []},
      {field_name: 'Price', field_type: 'l', field_value: 'items.price', children: []},
      {field_name: 'Quantity', field_type: 'l', field_value: 'items.quantity', children: []},
      {field_name: 'Discount', field_type: 'l', field_value: 'items.discount', children: []}
    ]}
  ]
};
