import React from "react";
import {store} from "../../index.js";


import { LayoutComponent } from "../layout/layout.js";

import {
  config,
  config_active,
  dev,
  dev_active,
  invoice,
  invoice_active,
  live,
  live_active,
  n1_active,
  n1_inactive,
  n2_active,
  n2_inactive,
  n3_active,
  n3_inactive,
  usermanagement,
  usermanagement_active,
  organisation,
  organisation_active,
  docs,
  docs_active,
} from "../icon/icon";

const _selectPage = (history, slug) => {
  // Check if subpages active, otherwise push to navigation stack
  if (slug !== window.location.pathname) {
    history.push(slug);
  }
}

export const getMenu = (pending_installation, price_plan, onboarding, history) => {
  const is_agency = store.getState().cmaccount.is_agency;
  const agency_tenantid = store.getState().cmaccount.traceDockKey;
  const current_tenantid = store.getState().cmaccount.currentTenantId;

  const cookie = {
    'title': 'Cookies',
    'icon_inactive': config,
    'icon_active': config_active,
    'slug': '/cookies',
    'subpages': [],
    'onClick': () => _selectPage(history, "/cookies"),
  };
  
  const events = {
    'title': 'Client side events',
    'icon_inactive': config,
    'icon_active': config_active,
    'slug': '/events',
    'subpages': [],
    'onClick': () => _selectPage(history, "/events")
  };
  
  const external = {
    'title': 'Server side events',
    'icon_inactive': config,
    'icon_active': config_active,
    'slug': '/serverside_events',
    'subpages': [],
    'onClick': () => _selectPage(history, "/serverside_events")
  };

  // live & implementing scenario's
  let menu = null;

  if (pending_installation) {
    menu = [
      {
        'title': 'Setup TraceDock',
        'pages': [
          {
            'title': 'Installation',
            'icon_inactive': n1_inactive,
            'icon_active': n1_active,
            'slug': '/installation',
            'subpages': [],
            'onClick': () => _selectPage(history, "/installation")
          }
        ]
      },
      {
        'title': 'Domain settings',
        'pages': [

          {
            'title': 'Developer settings',
            'slug': '/developer',
            'icon_inactive': dev,
            'icon_active': dev_active,
            'subpages': [],
            'onClick': () => _selectPage(history, "/developer")
          },
          {
            'title': 'Your domains',
            'slug': '/organisation',
            'icon_inactive': organisation,
            'icon_active': organisation_active,
            'subpages': [],
            'onClick': () => _selectPage(history, "/organisation")
          },
        ]
      }
    ];

    //Dont show Organisation management on agency level
    // if(is_agency && agency_tenantid == current_tenantid){
    //   menu[0]= {
    //     'title': 'Agency'
    //   }
    //   menu[1]= {
    //   }
    // }
    // implementing menu
    if (onboarding) {
      if (onboarding.pending_technical === 'implementing' || onboarding.pending_code === 'implementing') menu[0].pages[0].title = <>Installation <span style={{fontStyle: 'italic'}}>(pending)</span></>;
    }

    if (price_plan !== 'etp_only') {
      menu[0].pages.push({
        ...cookie,
        'icon_inactive': n2_inactive,
        'icon_active': n2_active,
      })
    }

    if (price_plan !== 'itp_only') {
      menu[0].pages.push({
        ...events,
        'icon_inactive': price_plan === 'etp_only' ? n2_inactive : n3_inactive,
        'icon_active': price_plan === 'etp_only' ? n2_active : n3_active,
      })
    }

    var isAgency = store.getState().cmaccount.is_agency;
    if(isAgency){
      //If isAgency, add to profile part of menu
      menu[3] = {
        'title': 'Agency',
        'pages': [
          {
            'title': 'My agency',
            'slug': '/my-agency',
            'icon_inactive': organisation,
            'icon_active': organisation_active,
            'subpages': [],
            'onClick': () => _selectPage(history, "/agency")
          }
        ]
      }

      menu[4] = {
        'title': 'Help',
        'pages': [
          {
            'title': 'Help',
            'slug': '',
            'icon_inactive': docs,
            'icon_active': docs_active,
            'subpages': [],
            'onClick': () => window.open('https://docs.tracedock.com', '_blank')
          },
        ]
      }
    }

    //If is agency and agency is on it's own page, just show their agency managemant
    if(isAgency && current_tenantid == agency_tenantid){
      menu = [
        {
        'title': 'Agency',
        'pages': [
          {
            'title': 'My agency',
            'slug': '/my-agency',
            'icon_inactive': organisation,
            'icon_active': organisation_active,
            'subpages': [],
            'onClick': () => _selectPage(history, "/agency")
          }
        ]
      },
      {
        'title': 'Help',
        'pages': [
          {
            'title': 'Help',
            'slug': '',
            'icon_inactive': docs,
            'icon_active': docs_active,
            'subpages': [],
            'onClick': () => window.open('https://docs.tracedock.com', '_blank')
          },
        ]
      }
    ]
    }
  } else {

    var isAgency = store.getState().cmaccount.is_agency;

    menu = [
      {
        'title': 'Data feed',
        'pages': [
          {
            'title': 'Live events',
            'icon_inactive': live,
            'icon_active': live_active,
            'slug': '/live',
            'subpages': [],
            'onClick': () => _selectPage(history, "/live")
          }
        ]
      },
      {
        'title': 'Configuration',
        'pages': []
      },
      {
        'title': 'Domain settings',
        'pages': [

          {
            'title': 'Developer settings',
            'slug': '/developer',
            'icon_inactive': dev,
            'icon_active': dev_active,
            'subpages': [],
            'onClick': () => _selectPage(history, "/developer")
          },
          {
            'title': 'Your domains',
            'slug': '/organisation',
            'icon_inactive': organisation,
            'icon_active': organisation_active,
            'subpages': [],
            'onClick': () => _selectPage(history, "/organisation")
          },
        ]
      },
      {
        'title': 'Help',
        'pages': [
          {
            'title': 'Help',
            'slug': '',
            'icon_inactive': docs,
            'icon_active': docs_active,
            'subpages': [],
            'onClick': () => window.open('https://docs.tracedock.com', '_blank')
          },
        ]
      },
      {
        //Empty for when isAgency
      }
    ];

    if(isAgency){
      //If isAgency, add to profile part of menu
      menu[3] = {
        'title': 'Agency',
        'pages': [
          {
            'title': 'My agency',
            'slug': '/my-agency',
            'icon_inactive': organisation,
            'icon_active': organisation_active,
            'subpages': [],
            'onClick': () => _selectPage(history, "/agency")
          }
        ]
      }

      menu[4] = {
        'title': 'Help',
        'pages': [
          {
            'title': 'Help',
            'slug': '',
            'icon_inactive': docs,
            'icon_active': docs_active,
            'subpages': [],
            'onClick': () => window.open('https://docs.tracedock.com', '_blank')
          },
        ]
      }
    }

    if(is_agency && agency_tenantid == current_tenantid){
      menu = [
        {
        'title': 'Agency',
        'pages': [
          {
            'title': 'My agency',
            'slug': '/my-agency',
            'icon_inactive': organisation,
            'icon_active': organisation_active,
            'subpages': [],
            'onClick': () => _selectPage(history, "/agency")
          }
        ]
      },
      {
        'title': 'Help',
        'pages': [
          {
            'title': 'Help',
            'slug': '',
            'icon_inactive': docs,
            'icon_active': docs_active,
            'subpages': [],
            'onClick': () => window.open('https://docs.tracedock.com', '_blank')
          },
        ]
      }
    ]
    }

    // live scenario
    if(menu[1]){
      menu[1].pages = [cookie, events, external];
    }
    
    // if (price_plan === 'complete') {
    //   // add external to menu
    // } else {
    //   menu[1].pages = [cookie, events];
    // }
  }
  return menu
};