import React from "react";
import PropTypes from 'prop-types';

import {BlankCard} from "../../../../components/cards/blankCard";
import {Input} from "../../../../components/input/input";
import {DataFieldObject} from "../../../../components/dataFieldObject/dataFieldObject";
import {gaTemplateChoices} from "../../../../lib/google_analytics/templates";
import {gaPossibleFields} from "../../../../lib/google_analytics/possibleFields";
import {gaFieldDescriptions} from "../../../../lib/google_analytics/fieldDescriptions";


export const validate_fields = (eventfields) => {
  // validates triggers, returns invalidObjects
  let invalidObjects = {};

  eventfields.map((row, idx) => {

    // validates triggers, is choices restricted to list or more than 0
    if (row.action !== 'delete') {
      if (row.field_name.length === 0) {
        invalidObjects[`${idx}_field_name`] = true
      }
      if (row.field_type.length === 0) {
        invalidObjects[`${idx}_field_type`] = true
      }
      if (!row.isReadOnly && ( !row.field_value || row.field_value.length === 0)) {
        invalidObjects[`${idx}_field_value`] = true
      }
    }
    return true
  });
  return invalidObjects
};

export class CardPageloadEventFields extends React.Component {

  static propTypes = {
    fields: PropTypes.array,
    data: PropTypes.any,
    invalidObjects: PropTypes.any,
    availableFieldPaths: PropTypes.array,
    readOnly: PropTypes.bool,
    onUpdate: PropTypes.func,
    setTemplate: PropTypes.func,
    template: PropTypes.string,
    domain: PropTypes.string,
  };

  static defaultProps = {
    possible: [],
    title: 'To Google Analytics data',
    templates: {},
    template: '',
    fields: [],
    data: {},
    invalidObjects: {},
    availableFieldPaths: [],
    readOnly: false,
    domain: '',
    onUpdate: (data) => console.log('updating data: ', data),
    setTemplate: (template) => console.log('setting template: ', template),
  };

  constructor(props, context) {
    super(props, context);
    this.state = {
      tab: 'fields'
    };
  }

  _updateTemplate(template) {

    // updating template
    // todo: don't throw away fields if you make a selection. Now we just refresh them
    this.props.onUpdate([], () => {
      this.props.onUpdate(this.props.templates[template], () => this.props.setTemplate(template));
    })
  }

  render({invalidObjects, availableFieldPaths, fields, data, readOnly, template, domain, title, templates} = this.props) {
    return (

      <BlankCard
        collapsable={true}
        title={title}
        subtitle='Determine what data should be forwarded.'
        padding={false}>

        <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between'}}>
          <Input
            style={{padding: '0 24px', maxWidth: 250}}
            type="text"
            label="Select initial template"
            restrict={true}
            readOnly={false}
            choices={gaTemplateChoices}
            initial={template}
            onChange={(template) => this._updateTemplate(template)}
          />

        </div>

        <DataFieldObject
          invalidObjects={invalidObjects}
          readOnly={false}

          availableFieldPaths={availableFieldPaths}
          onUpdate={(data) => this.props.onUpdate(data)}

          fields={fields}
          parent=''
          level={0}

          possibleFields={this.props.possible}
          fieldDescriptions={gaFieldDescriptions}
        />

      </BlankCard>
    )
  }
}
