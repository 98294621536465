import { deviceState } from './device.state';

export function deviceReducer(state = deviceState, action) {
  switch (action.type) {

    case 'ADD_WIKI_PAGE':

      // create copy and update page with content
      let newWiki = {...state.wiki};
      newWiki[action.page] = action.content;
      return {
        ...state,
        wiki: newWiki
      };

    case 'CLEAR_ALERT':
      return {
        ...state,
        alertShown: false,
        alertTitle: '',
        alertMessage: '',
        alertDetails: '',
        alertButtons: [],
      };
    case 'SET_ALERT':
      return {
        ...state,
        alertShown: true,
        alertTitle: action.alertTitle,
        alertMessage: action.alertMessage,
        alertDetails: action.alertDetails,
        alertButtons: action.alertButtons,
      };

    case 'RESET_DEVICE_STATE':
      return {
        ...deviceState
      };

    default:
      return state;
  }
}
