import React from "react";
import PropTypes from 'prop-types';

import {colors} from "../../../../theme/colors";

import {BlankCard} from "../../../../components/cards/blankCard";
import {Input} from "../../../../components/input/input";
import {SideModal} from "../../../../components/sideModal/sideModal";
import {Button} from "../../../../components/button/button";
import {setAlertDialog} from "../../../../components/alert/alert";
import {CardPageloadEventFields, validate_fields} from "./cardPageLoadFields";
import {deleteNested} from "../../../../lib/helpers";
import {Icon, info} from "../../../../components/icon/icon";
import {gaTemplates, ga4Templates} from "../../../../lib/google_analytics/templates";
import { gaFieldDescriptions } from "../../../../lib/google_analytics/fieldDescriptions";
import { ga4PossibleFields, gaPossibleFields } from "../../../../lib/google_analytics/possibleFields";

export class PageLoadModal extends React.Component {

  static propTypes = {
    PageLoad: PropTypes.any,
    show: PropTypes.bool,
    readOnly: PropTypes.bool,
    onClose: PropTypes.func,
    onSave: PropTypes.func,
    onDelete: PropTypes.func
  };

  static defaultProps = {
    has_ga4: false,
    PageLoad: {},
    show: false,
    readOnly: false,
    onClose: () => console.log('closing'),
    onSave: (data) => console.log('saving data: ', data),
    onDelete: (data) => console.log('deleting data: ', data),
  };

  constructor(props, context) {
    super(props, context);

    this.state = {
      currentPageLoad: props.PageLoad,
      isUpdated: false,
      isLoading: false,
      invalidObjects: {},
    };
  }

  _update_element(key, value, callback=null) {
    let newData = {...this.state.currentPageLoad};
    newData[key] = value;
    this.setState({isUpdated: true, currentPageLoad: newData}, () => {if (callback) callback()});
  }

  _save() {
    // validates element, if all valid, saves
    let invalidObjects = {};
    let currentPageLoad = this.state.currentPageLoad;

    // check basic data
    if (currentPageLoad.name.length === 0) invalidObjects['name'] = true;
    if (![true, false].includes(currentPageLoad.is_active)) invalidObjects['is_active'] = true;

    // check trigger validators
    invalidObjects = {
      ...invalidObjects,
      ...validate_fields(currentPageLoad.fields),
      ...validate_fields(currentPageLoad.ga4_fields)
    };

    // either return or populate, invalidObjects
    if (Object.entries(invalidObjects).length === 0) {

      // delete all deleted keys of fields
      currentPageLoad.fields = deleteNested(currentPageLoad.fields);

      this.setState({isLoading: true}, () => {
        this.props.onSave(currentPageLoad)
          .then((d) => {
            if (d && d.event) {
              this.setState({isLoading: false, isUpdated: false, currentPageLoad: {...d.event, title: 'Adjust pageload event'}})
            } else {
              this.setState({isLoading: false, isUpdated: true})
            }
          })
      });

    } else {
      setAlertDialog('Sorry', 'Please review your input, it appears that certain invalid choices have been made');
      this.setState({invalidObjects: invalidObjects})
    }
  }

  _reset() {
    let currentPageLoad = {};
    if (this.props.PageLoad.uuid) {
      // reset the data to the original paylaod
      currentPageLoad = this.props.PageLoad;
      this.setState({
        isUpdated: false,
        currentPageLoad: currentPageLoad,
        invalidObjects: {}
      })
    } else {
      // go back in cases of new events
      this.props.onClose()
    }
  }


  render({show, readOnly, onClose} = this.props) {
    return (
      <SideModal
        title={this.state.currentPageLoad.title}
        show={show}
        onClose={() => onClose()}
        isUpdated={this.state.isUpdated}
        isLoading={this.state.isLoading}
        onSave={() => this._save()}
        onReset={() => this._reset()}
      >

        <div>
          <BlankCard
            title='Basics'>

            <div style={{display: 'flex', flexDirection: 'row', alignItems: 'flex-start'}}>
              <div>
                <Input
                  style={{maxWidth: 300, marginTop: 6}}
                  initial={this.state.currentPageLoad.name}
                  label='Name'
                  type='text'
                  isInvalid={this.state.invalidObjects['name']}
                  onChange={(value) => this._update_element('name', value)}
                  readOnly={this.state.currentPageLoad.is_pageview}
                />
              </div>
              <div style={{marginLeft: 12}}>
                <Input
                  style={{maxWidth: 300, marginTop: 6}}
                  initial={this.state.currentPageLoad.is_active}
                  label='State'
                  type='text'
                  restrict={true}
                  choices={[{key: true, value: 'active'}, {key: false, value: 'inactive'}]}
                  isInvalid={this.state.invalidObjects['is_active']}
                  onChange={(value) => this._update_element('is_active', value)}
                  readOnly={false}
                />
              </div>

              {this.state.currentPageLoad.is_pageview && <div style={{marginLeft: 12}}>
                <Input
                  style={{maxWidth: 300, marginTop: 6}}
                  initial={this.state.currentPageLoad.pageview_spa}
                  label='is Single Page App (SPA)'
                  type='text'
                  restrict={true}
                  choices={[{key: true, value: 'true'}, {key: false, value: 'false'}]}
                  onChange={(value) => this._update_element('pageview_spa', value)}
                  readOnly={false}
                />
              </div>}

            </div>

            <div className='row' style={{alignItems: 'center', marginBottom: 12}}>
              <Icon icon={info} color={colors.brand.primary}/>
              <p style={{marginLeft: 12}} className='small'>
                By default, TraceDock will fire a pageview event on pageload (unless this event is inactive).
                {this.state.currentPageLoad.is_pageview &&  "If the website is a Single Page App (SPA) the event is also triggered whenever the path is updated."}
              </p>
            </div>

          </BlankCard>

          <CardPageloadEventFields
            template={this.state.currentPageLoad.template}
            fields={this.state.currentPageLoad.fields}
            invalidObjects={this.state.invalidObjects}
            setTemplate={(template) => this._update_element('template', template)}
            onUpdate={(data, callback) => this._update_element('fields', data, callback)}
            templates={gaTemplates}
            possible={gaPossibleFields}
          />

          {this.props.has_ga4 && <CardPageloadEventFields
            title='To Google Analytics 4'
            template={this.state.currentPageLoad.ga4_template}
            fields={this.state.currentPageLoad.ga4_fields}
            invalidObjects={this.state.invalidObjects}
            setTemplate={(template) => this._update_element('ga4_template', template)}
            onUpdate={(data, callback) => this._update_element('ga4_fields', data, callback)}
            templates={ga4Templates}
            possible={ga4PossibleFields}
          />}

          {this.state.currentPageLoad.uuid &&  !this.state.currentPageLoad.is_pageview && (
            <Button
              title='Delete pageload event'
              backgroundColor={colors.brand.gray_light}
              color={colors.brand.darkgray_light}
              cancel={true}
              style={{width: 150, marginTop: 70}}
              onClick={() => {
                setAlertDialog('Delete pageload event', 'Are you sure you want to delete this pageload event?', '',
                  [
                    {
                      title: 'yes',
                      backgroundColor: colors.brand.gray,
                      onPress: () => this.props.onDelete(this.state.currentPageLoad)
                    },
                    {title: 'cancel', backgroundColor: colors.brand.primary},
                  ])
              }}
            />
          )}
        </div>

      </SideModal>
    );
  }
}

