import React, { memo } from 'react'
import { useChildrenContentMemo } from '../useChildrenContentMemo';
import { PropTypes } from 'prop-types';
import styles from './formGroup.module.scss';

const FormGroup = ({ title, ...rest }) => {
    const childrenWithProps = useChildrenContentMemo(rest);

    return (
        <div className={styles.formGroup}>
            <div className={`normal black ${styles.formGroup_header}`}>{title}</div>
            {childrenWithProps}
        </div>
    )
}

FormGroup.propTypes = {
    title: PropTypes.string
}

export default memo(FormGroup);
