import React from "react";
import PropTypes from 'prop-types';

import {colors} from "../../../theme/colors";

import {BlankCard} from "../../../components/cards/blankCard";
import {Input} from "../../../components/input/input";
import {SideModal} from "../../../components/sideModal/sideModal";
import {Button} from "../../../components/button/button";
import {setAlertDialog} from "../../../components/alert/alert";
import {CardExternalRawData} from "./card.external.rawData";
import {classifyJsonAvailableFields} from "../../../components/jsonRaw/json.classify.availableFields";
import {Resize} from "../../../components/resize/resize";
import {classifyJsonSelectedFields} from "../../../components/jsonRaw/json.classify.selectedFields";
import {Loader} from "../../../components/loader/loader";
import {deleteNested, slugify} from "../../../lib/helpers";
import {baseEventFieldsData} from "./index";
import {Javascript} from "../../../components/javascript/javascript";
import {CardExternalGaFields, validate_external_fields} from "./card.external.ga.fields";
import {CardExternalTriggers, validate_external_triggers} from "./card.external.triggers";
import {CardExternalFBFields} from "./card.external.fb.fields";
import {CardExternalCDPFields} from "./card.external.cdp.fields";
import { CardExternalGa4Fields } from "./card.external.ga4.fields";

export class ExternalEventModal extends React.Component {

  static propTypes = {
    api_endpoint: PropTypes.string,
    externalEvent: PropTypes.any,
    dev: PropTypes.any,
    show: PropTypes.bool,
    readOnly: PropTypes.bool,
    onClose: PropTypes.func,
    onSave: PropTypes.func,
    onDelete: PropTypes.func,
    domain: PropTypes.string,
  };

  static defaultProps = {
    api_endpoint: 'https://',
    externalEvent: {},
    dev: {},
    show: false,
    readOnly: false,
    onClose: () => console.log('closing'),
    onSave: (data) => console.log('saving data: ', data),
    onDelete: (data) => console.log('deleting data: ', data),
    domain: '',
  };

  constructor(props, context) {
    super(props, context);

    this.state = {
      currentExternalEvent: props.externalEvent,
      isUpdated: false,
      isLoading: false,
      invalidObjects: {},
    };
  }

  _update_customDimensions(fields, callback = null) {
    let newData = {
      ...this.state.currentExternalEvent,
      selectedFieldPaths: classifyJsonSelectedFields(fields)
    };
    newData['custom_dimensions'] = fields;
    this.setState({ isUpdated: true, currentExternalEvent: newData }, () => {
      if (callback){
        callback()
      } 

    });
  }

  _update_element(key, value) {
    let newData = {...this.state.currentExternalEvent};
    newData[key] = value;
    this.setState({isUpdated: true, currentExternalEvent: newData});
  }

  _update_name(name) {
    let newData = {
      ...this.state.currentExternalEvent,
      name: name,
      endpoint: slugify(name)
    };
    this.setState({isUpdated: true, currentExternalEvent: newData});
  }

  _update_rawData(data) {
    // raw data exports slightly more keys, so custom function
    // we take the data as is, but we parse the available fields that are possible.
    let newData = {
      ...this.state.currentExternalEvent,
      data: data,
      availableFieldPaths: classifyJsonAvailableFields(data),
      };
    this.setState({isUpdated: true, currentExternalEvent: newData});
  }

  _update_fields(field_name, fields, callback = null) {
    // DataFields updates two elements, both the fields as well as the selectedFields
    let newData = {
      ...this.state.currentExternalEvent,
      selectedFieldPaths: classifyJsonSelectedFields(fields),
    };
    newData[field_name] = fields;
    this.setState({isUpdated: true, currentExternalEvent: newData}, () => {
      if (callback) callback()
    });
  }

  _save() {
    // validates element, if all valid, saves
    let invalidObjects = {};
    let currentExternalEvent = this.state.currentExternalEvent;

    // check basic data
    if (currentExternalEvent.name.length === 0) invalidObjects['name'] = true;

    // endpoint must start with api
    if (currentExternalEvent.endpoint.length === 0) invalidObjects['endpoint'] = true;

    if (![true, false].includes(currentExternalEvent.is_active)) invalidObjects['is_active'] = true;

    // check validators
    let invalid_ga_fields = validate_external_fields(currentExternalEvent.fields);
    let invalid_ga4_fields = validate_external_fields(currentExternalEvent.ga4_fields);
    let invalid_fb_fields = validate_external_fields(currentExternalEvent.fb_fields);
    let invalid_cdp_fields = validate_external_fields(currentExternalEvent.cdp_fields);
    let invalid_triggers = validate_external_triggers(currentExternalEvent.triggers);

    // either return or populate, invalidObjects
    let invalid = (obj) => Object.entries(obj).length === 0
    if (invalid(invalidObjects) && invalid(invalid_ga_fields) && invalid(invalid_ga4_fields) && invalid(invalid_fb_fields) 
      && invalid(invalid_cdp_fields) && invalid(invalid_triggers)) {

      // delete all deleted keys of fields
      currentExternalEvent.fields = deleteNested(currentExternalEvent.fields);
      currentExternalEvent.ga4_fields = deleteNested(currentExternalEvent.ga4_fields);
      currentExternalEvent.custom_dimensions = deleteNested(currentExternalEvent.custom_dimensions);
      currentExternalEvent.fb_fields = deleteNested(currentExternalEvent.fb_fields);
      currentExternalEvent.cdp_fields = deleteNested(currentExternalEvent.cdp_fields);

      this.setState({isLoading: true}, () => {
        this.props.onSave(this.state.currentExternalEvent)
          .then((d) => {
            if (d && d.external_event) {
              this.setState({isLoading: false, isUpdated: false, currentExternalEvent: {...d.external_event, title: 'Adjust server side event'}})
            } else {
              this.setState({isLoading: false, isUpdated: true})
            }
          })
      });

    }
    this.setState({invalidObjects: {...invalidObjects, invalid_ga_fields, invalid_ga4_fields, invalid_fb_fields, invalid_cdp_fields, invalid_triggers}})
  }

  _reset() {
    let currentExternalEvent = {};
    if (!this.state.currentExternalEvent) {
      // data is new, e.g. reset is to base
      currentExternalEvent = {...baseEventFieldsData}
    } else {
      // data exists, so reset to props
      currentExternalEvent = {...this.props.externalEvent, title: 'Adjust server side event'}
    }
    this.setState({
      isUpdated: false,
      currentExternalEvent: currentExternalEvent,
      invalidObjects: {}
    })
  }

  render({show, api_endpoint, readOnly, onClose, domain, dev} = this.props) {

    let endpoint = `https://${dev.subdomain}.${dev.domain}${dev.api_endpoint}/${this.state.currentExternalEvent.endpoint}`;

    return (
      <SideModal
        title={this.state.currentExternalEvent.title}
        show={show}
        onClose={() => onClose()}
        isUpdated={this.state.isUpdated}
        isLoading={this.state.isLoading}
        onSave={() => this._save()}
        onReset={() => this._reset()}
      >

        {this.state.isLoading && (<Loader style={{marginTop: '20vh', marginLeft: '48%'}}/>)}

        {!this.state.isLoading && (
          <div style={{width: '100%'}}>
            <BlankCard
              title='Basics'>

              <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>

                <Input
                  style={{maxWidth: 300}}
                  initial={this.state.currentExternalEvent.name}
                  label='Name'
                  type='text'
                  isInvalid={this.state.invalidObjects['name']}
                  onChange={(value) => this._update_name(value)}
                  readOnly={false}
                />
                <div style={{marginLeft: 12}}>
                  <Input
                    style={{maxWidth: 300}}
                    initial={this.state.currentExternalEvent.is_active}
                    label='State'
                    type='text'
                    restrict={true}
                    choices={[{key: true, value: 'active'}, {key: false, value: 'inactive'}]}
                    isInvalid={this.state.invalidObjects['is_active']}
                    onChange={(value) => this._update_element('is_active', value)}
                    readOnly={false}
                  />
                </div>


              </div>

              <div style={{maxWidth: 500, marginBottom: 6}}>
                <Javascript title='TraceDock endpoint' value={endpoint}/>
              </div>

            </BlankCard>

            <Resize
              minLeft={250}
              minRight={300}
              startLeft={500}
              leftChild={
                <div>
                  <h5 style={{marginLeft: 6}}>INCOMING DATA</h5>
                  <CardExternalRawData
                    data={this.state.currentExternalEvent.data}
                    selectedFieldPaths={this.state.currentExternalEvent.selectedFieldPaths}
                    readOnly={false}
                    onUpdate={(data) => this._update_rawData(data)}
                  />
                  <CardExternalTriggers
                    triggers={this.state.currentExternalEvent.triggers}
                    availableFieldPaths={this.state.currentExternalEvent.availableFieldPaths}
                    readOnly={false}
                    invalidObjects={this.state.invalidObjects.invalid_triggeres}
                    onUpdate={(value) => this._update_element('triggers', value)}
                  />
                </div>
              }
              rightChild={
                <div>
                  <h5 style={{marginLeft: 6}}>FORWARDED DATA</h5>

                  {dev.has_ga4 && <CardExternalGa4Fields
                    domain={domain}
                    collapsed={!!dev.fb_enabled || !!dev.cdp_enabled || !!dev.has_ga4 }
                    template={this.state.currentExternalEvent.ga4_template}
                    fields={this.state.currentExternalEvent.ga4_fields}
                    customDimensions={this.state.currentExternalEvent.custom_dimensions ?? []}
                    data={this.state.currentExternalEvent.data}
                    availableFieldPaths={this.state.currentExternalEvent.availableFieldPaths}
                    readOnly={false}
                    invalidObjects={this.state.invalidObjects.invalid_ga4_fields}
                    setTemplate={(template) => this._update_element('ga4_template', template)}
                    onUpdate={(fields, callback) => this._update_fields('ga4_fields', fields, callback)}
                    onUpdateCustomDimensions={(customDimensions, callback) => this._update_customDimensions(customDimensions, callback)}
                  />}

                  {dev.fb_enabled ?
                    <CardExternalFBFields
                      collapsed={!!dev.fb_enabled || !!dev.cdp_enabled || !!dev.has_ga4 }
                      domain={domain}
                      template={this.state.currentExternalEvent.fb_template}
                      fields={this.state.currentExternalEvent.fb_fields}
                      data={this.state.currentExternalEvent.data}
                      availableFieldPaths={this.state.currentExternalEvent.availableFieldPaths}
                      readOnly={false}
                      invalidObjects={this.state.invalidObjects.invalid_fb_fields}
                      setTemplate={(template) => this._update_element('fb_template', template)}
                      onUpdate={(fields, callback) => this._update_fields('fb_fields', fields, callback)}
                    /> : <></>}

                  {dev.cdp_enabled ?
                    <CardExternalCDPFields
                      collapsed={!!dev.fb_enabled || !!dev.cdp_enabled }
                      domain={domain}
                      template={this.state.currentExternalEvent.cdp_template}
                      fields={this.state.currentExternalEvent.cdp_fields}
                      data={this.state.currentExternalEvent.data}
                      availableFieldPaths={this.state.currentExternalEvent.availableFieldPaths}
                      readOnly={false}
                      invalidObjects={this.state.invalidObjects.invalid_cdp_fields}
                      setTemplate={(template) => this._update_element('cdp_template', template)}
                      onUpdate={(fields, callback) => this._update_fields('cdp_fields', fields, callback)}
                    /> : <></>}
                </div>
              }
            />
          </div>)}

        {!this.state.isLoading && this.state.currentExternalEvent.id && (
          <Button
            title='Delete server side event'
            backgroundColor={colors.brand.gray_light}
            color={colors.brand.darkgray_light}
            cancel={true}
            style={{width: 150, marginTop: 70}}
            onClick={() => {
              setAlertDialog('Delete server side event', 'Are you sure you want to delete this server side event?', '',
                [
                  {
                    title: 'yes',
                    backgroundColor: colors.brand.gray,
                    onPress: () => this.props.onDelete(this.state.currentExternalEvent)
                  },
                  {title: 'cancel', backgroundColor: colors.brand.primary},
                ])
            }}
          />
        )}
      </SideModal>
    );
  }
}

