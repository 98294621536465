import React from "react";
import {connect} from "react-redux";

import {Layout} from "../../components/layout/layout";
import {accountActions, rulesActions} from "../../redux/actions";

import {updateIfNeeded} from "../../lib/helpers";
import {Loader} from "../../components/loader/loader";
import {InstallationModule} from "../../components/installation/installationModule";

import './installation.scss';

class InstallationPage extends React.Component {

  constructor(props, context) {
    super(props, context);

    this.state = {
      modal: 'overview',
    };

    updateIfNeeded(this.props.account.profile_updated, this.props.getProfile,
      () => this._navNeeded());

    props.persist.domain.id && updateIfNeeded(this.props.account.dev_updated,
      () => this.props.getDev({uuid: this.props.persist.domain.id}));
  }

  componentDidUpdate(nextProps, nextState, nextContext) {

    if (nextProps.persist.domain.id && this.props.persist.domain.id !== nextProps.persist.domain.id) {
      // always update if domain changes
      this.props.getProfile().then(() => this._navNeeded())
    }
  }

  _navNeeded() {
    if (this.props.persist.domain.state !== 'implementing') {
      this.props.history.push('/live')
    }
  }

  render({account, persist, rules, updateCreditor, history} = this.props) {

    let isLoading = rules.isPending || account.isPending;

    return (
      <Layout withPadding={false} history={this.props.history}>
        <div className='installationPage'>

          {isLoading && (<Loader style={{marginTop: '20vh', marginLeft: '48%'}}/>)}

          {!isLoading && (
            <InstallationModule
              history={history}
              domain={persist.domain}
              dev={rules.dev}
              dns_provider={persist.domain.onboarding.dns_provider}
              profile={account.profile}
              pendingCheckStatus={rules.pendingCheckStatus}
              checkStatus={async (type) => {
                let res = await this.props.checkStatus({type: type, id: persist.domain.id});
                // if (res.domain.state === 'live') {
                //   history.push('/')
                // }
              }}
            />
          )}

        </div>
      </Layout>
    );
  }
}

const mapStateToProps = (state) => ({
  account: state.account,
  persist: state.persist,
  rules: state.rules,
});

const mapDispatchToProps = (dispatch) => {
  return {
    createDomain: (data) => dispatch(accountActions.createDomain(data)),
    getProfile: () => dispatch(accountActions.getProfile()),
    getDev: (data) => dispatch(rulesActions.getDev(data)),
    checkStatus: (data) => dispatch(rulesActions.checkStatus(data)),
  }
};

export default InstallationPage = connect(mapStateToProps, mapDispatchToProps)(InstallationPage);
