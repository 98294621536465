import React from 'react';
import PropTypes from 'prop-types';

import './json.scss';
import { Tag } from "../tag/tag";
import { Icon, down } from "../icon/icon";
import { colors } from "../../theme/colors";
import { Popup, PopupPosition } from "../popup";

export class Json extends React.Component {

  static propTypes = {
    data: PropTypes.any,
    other_fields: PropTypes.array,
    translation: PropTypes.any,

    selectedFields: PropTypes.array,
    parent: PropTypes.string,
  };

  static defaultProps = {
    data: {},
    other_fields: [],
    translation: null,

    selectedFields: [],
    parent: '',
  };

  constructor(props, context) {
    super(props, context);

    this.state = {

      // we keep counters here for dictionary and array lookups
      open_index: {
        keys: [],
        data: {},

        other_fields: [],
        other_data: {}
      }
    };

    // update the state with the data
    this.state = { ...this.state, ...this._getData(props.data, props.other_fields) }
  }

  componentWillUpdate(nextProps, nextState, nextContext) {
    if (this.props.data !== nextProps.data) {
      this.setState(this._getData(nextProps.data, nextProps.other_fields))
    }
  }

  _getData(data, other_fields) {

    if (!data) {
      return { keys: [], data: {} }
    }

    if (other_fields.length === 0) {

      let d = { keys: Object.keys(data).sort(), data: data }

      // open by default if required, e.g. length 1
      if (d.keys.length === 1) {
        d.open_index = {
          keys: [],
          data: {},

          other_fields: [],
          other_data: {}
        };
        d.open_index[d.keys[0]] = 0;
      }
      return d

    } else {

      // collect a list of other Keys, and remove field from data if it is found
      let otherKeys = [];
      let other_data = {};
      let newData = { ...data };
      for (let field of other_fields) {
        if (field in data) {
          otherKeys.push(field);
          other_data[field] = data[field];
          delete newData[field];
        }
      }

      // sort all keys remaining and return data and other data
      return { keys: Object.keys(newData).sort(), data: newData, other_fields: otherKeys, other_data: other_data }

    }
  }

  _toggleRowOpen(_key, open) {
    // close is -1, or update index
    let new_open = { ...this.state.open_index };
    new_open[_key] = open;
    this.setState({ open_index: new_open })
  }

  _renderElement(idx, key, row) {
    // Determine type: dictionary, array_nested, array_plain, string

    let translate = (this.props.translation && this.props.translation[key]) ? this.props.translation[key] : {
      unknown: this.props.translation ? true : false,
      exclude: 0,
      title: key,
      caption: `field "${key}"`
    };

    // see if tag is active or nog
    let currentPath = this.props.parent === '' ? key : `${this.props.parent}.${key}`;
    let isSelected = 'default';
    if (this.props.selectedFields.length > 0) {
      isSelected = this.props.selectedFields.includes(currentPath) ? 'active' : 'default';
    }

    if (!translate.exclude) {
      // if no translation file, or we could not exclude it, show it anyway
      let rowType = (row === false || row === true) ? 'boolean' : (
        !row ? 'plain' :
          row.constructor === Object ? 'dic' :
            (Array.isArray(row) ?
              (row[0] && row[0].constructor === Object ? 'array_nested' : 'array')
              : 'plain'
            )
      );
      return (
        <div key={idx}>
          <div className='Jsonrow'>
            <div className='keyCell'>
              {this.props.translation ?
                <Popup
                  content={translate.caption && !translate.unknown && <span className='small'>{translate.caption} {translate.caption && key !== translate.title && ` (${key})`}</span>
                  }
                  minWidth={120}
                  visible={translate.title !== 0 && (!!translate.caption || translate.title !== key)}
                  initialOpen={false}
                  stayOpen={false}
                  position={PopupPosition.RIGHT_CENTER}
                >  <Tag className='keyTag' type={isSelected}>{translate.title || key}</Tag>
                </Popup>
                :
                <Tag className='keyTag' type={isSelected}>{translate.title || key}</Tag>
              }
            </div>
            {this._renderRow(rowType, key, row, translate)}
          </div>

          {rowType === 'dic' && (
            <div className={`nested ${this.state.open_index[key] >= 0 ? 'Jsonopen' : 'Jsonclosed'}`}>
              <Json data={row} translation={this.props.translation}
                parent={currentPath} selectedFields={this.props.selectedFields} />
            </div>
          )}

          {rowType === 'array_nested' && (
            <div className={`nested ${this.state.open_index[key] >= 0 ? 'Jsonopen' : 'Jsonclosed'}`}>
              <Json data={row[this.state.open_index[key]]} translation={this.props.translation}
                parent={currentPath} selectedFields={this.props.selectedFields} />
            </div>
          )}
        </div>
      )
    }
  }

  _renderRow(rowType, _key, row) {
    let isOpen = this.state.open_index[_key] >= 0;
    switch (rowType) {
      case 'other':
        return (
          <div className='toggleCell clickable noBorder'
            onClick={() => this._toggleRowOpen(_key, isOpen ? -1 : 0)}>
            <div className={`arrow ${isOpen ? 'downRotate' : ''}`}>
              <Icon icon={down} color={colors.brand.darkgray} />
            </div>
            <p className='small center'>{isOpen ? 'show less' : 'show more'}</p>
          </div>
        );
      case 'dic':
        return (
          <div className='toggleCell clickable'
            onClick={() => this._toggleRowOpen(_key, isOpen ? -1 : 0)}>
            <div className='arrow noOpac'><Icon icon={down} /></div>
            <span className='small center'>{isOpen ? 'hide' : 'show'} {Object.keys(row).length} fields</span>
            <div className={`arrow ${isOpen ? 'downRotate' : ''}`}>
              <Icon icon={down} color={colors.brand.darkgray} />
            </div>
          </div>
        );
      case 'array_nested':
        return (
          <div className='toggleCell'>
            <div className={`arrow clickable ${isOpen ? 'leftRotate' : 'noOpac'}`}
              onClick={() => this._toggleRowOpen(_key, isOpen ? this.state.open_index[_key] - 1 : 0)}
            >
              <Icon icon={down} color={colors.brand.darkgray} />
            </div>
            <span className='small center clickable' onClick={() => this._toggleRowOpen(_key, isOpen ? -1 : 0)}>
              {isOpen ? `${this.state.open_index[_key] + 1} out of ` : 'show all'} {row.length}
            </span>
            <div className={`arrow clickable ${isOpen ? 'rightRotate' : ''}`}
              onClick={() => this._toggleRowOpen(_key, isOpen ? (this.state.open_index[_key] === row.length - 1 ? 0 : this.state.open_index[_key] + 1) : 0)}
            >
              <Icon icon={down} color={colors.brand.darkgray} />
            </div>
          </div>
        );
      case 'array':
        return <p>[{row.join(', ')}]</p>;
      case 'boolean':
        return <p>{row ? 'true' : 'false'}</p>
      default:
        return <p>{row}</p>;
    }
  }

  render({ translation } = this.props) {
    return (
      <div className='jsonWrapper' style={this.props.style}>

        {this.state.keys.length > 0 && this.state.keys.map((_key, idx) => {
          return this._renderElement(idx, _key, this.state.data[_key])
        })}

        {this.state.other_fields && this.state.other_fields.length > 0 && (
          <div>

            <div className='row'>
              {this._renderRow('other', '__other__', this.state.other_data)}
            </div>

            <div className={this.state.open_index['__other__'] >= 0 ? 'Jsonopen' : 'Jsonclosed'}>
              {this.state.open_index['__other__'] >= 0 &&
                <Json data={this.state.other_data} translation={this.props.translation} />
              }
            </div>
          </div>
        )}
      </div>
    );
  }
}