import React from "react";
import PropTypes from 'prop-types';

import {BlankCard} from "../../../components/cards/blankCard";
import {Input} from "../../../components/input/input";
import {DataFieldObject} from "../../../components/dataFieldObject/dataFieldObject";
import {gaTemplateChoices, gaTemplates} from "../../../lib/google_analytics/templates";
import {gaPossibleFields} from "../../../lib/google_analytics/possibleFields";
import {gaFieldDescriptions, gaOtherFields} from "../../../lib/google_analytics/fieldDescriptions";

import {Popup, PopupPosition} from "../../../components/popup";
import {eye, eye_active, Icon} from "../../../components/icon/icon";
import {colors} from "../../../theme/colors";
import {DataFieldPreview} from "../../../components/dataFieldObject/dataFieldPreview";
import {testFieldMapping} from "../../../redux/rules/rules.actions";
import {Tag} from "../../../components/tag/tag";


export const validate_external_fields = (eventfields) => {
  // validates triggers, returns invalidObjects
  let invalidObjects = {};

  eventfields.forEach((row, idx) => {

    // validates triggers, is choices restricted to list or more than 0
    if (row.action !== 'delete') {
      if (!row.field_name || row.field_name.length === 0) {
        invalidObjects[`${idx}_${row.field_name}_field_name`] = true
      }
      if (!row.field_type || row.field_type.length === 0) {
        invalidObjects[`${idx}_${row.field_name}_field_type`] = true
      }
      if (!row.isReadOnly && (row.field_type !== 'o' && (!row.field_value || row.field_value.length === 0))) {
        invalidObjects[`${idx}_${row.field_name}_field_value`] = true
      }
    }
  });

  return invalidObjects
};

export class CardExternalGaFields extends React.Component {

  static propTypes = {
    fields: PropTypes.array,
    data: PropTypes.any,
    invalidObjects: PropTypes.any,
    availableFieldPaths: PropTypes.array,
    readOnly: PropTypes.bool,
    collapsed: PropTypes.bool,
    onUpdate: PropTypes.func,
    setUniqueField: PropTypes.func,
    setTemplate: PropTypes.func,
    template: PropTypes.string,
    domain: PropTypes.string,
    unique_field: PropTypes.string,
  };

  static defaultProps = {
    template: 'blank',
    fields: [],
    data: {},
    invalidObjects: {},
    availableFieldPaths: [],
    readOnly: false,
    domain: '',
    onUpdate: (data) => console.log('updating data: ', data),
    setTemplate: (template) => console.log('setting template: ', template),
    setUniqueField: (setUniqueField) => console.log('setting setUniqueField: ', setUniqueField),
    unique_field: null,
  };

  constructor(props, context) {
    super(props, context);
    this.state = {
      tab: 'fields'
    };
  }

  _updateTemplate(template) {

    // updating template
    // todo: don't throw away fields if you make a selection. Now we just refresh them
    this.props.onUpdate([], () => {

      // add 'cid' for external events
      this.props.onUpdate(
        [{field_name: 'cid', field_type: 'l', stitch: true, children: []}].concat(gaTemplates[template])
        , () => this.props.setTemplate(template));
    })
  }

  render({invalidObjects, collapsed, availableFieldPaths, fields, data, readOnly, template, domain, unique_field, setUniqueField} = this.props) {

    // get the field name of the unique key
    let unique_field_name = unique_field
    if (unique_field) {
      let possible = gaPossibleFields.filter((choice) => choice.key === unique_field);
      if (possible[0] && possible[0]['value']) unique_field_name = possible[0]['value']
    }

    return (

      <BlankCard
        collapsable={true}
        initialCollapsed={collapsed}
        title='To Google Analytics'
        subtitle='Determine what data should be forwarded.'
        padding={false}>

        <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between'}}>
          <Input
            style={{padding: '0 24px', maxWidth: 250}}
            type="text"
            label="Select initial template"
            restrict={true}
            readOnly={false}
            choices={gaTemplateChoices}
            initial={template}
            onChange={(template) => this._updateTemplate(template)}
          />

          <Popup
            content={
              <p style={{width: 200}}>Preview your forwarded data here. It is calculated based on your raw data as found on the left side.</p>
            }
            initialOpen={false}
            stayOpen={false}
            position={PopupPosition.LEFT_CENTER}
          >
            <div className='clickable' style={{padding: '10px 27px 5px 10px'}} onClick={() => this.setState({tab: this.state.tab === 'fields' ? 'preview' : 'fields'})}>
              <Icon size={25} icon={this.state.tab === 'preview' ? eye_active : eye} color={colors.brand.secondary}/>
            </div>
          </Popup>
        </div>

        <Popup
          content={
            <p>With this key we de-duplicate events, e.g. we can make sure that we forward transaction ID's only once.</p>
          }
          initialOpen={false}
          stayOpen={false}
          position={PopupPosition.RIGHT_CENTER}
        >
          <div className='clickable' style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
            <p style={{marginLeft: 24, marginRight: 12}}>Unique key:</p> <Tag>{unique_field_name || 'no key selected'}</Tag>
          </div>
        </Popup>


        {this.state.tab === 'fields' && (
          <DataFieldObject
            editReadOnlyFields={true}
            invalidObjects={invalidObjects}
            readOnly={false}

            unique_field={unique_field}
            setUniqueField={setUniqueField}

            availableFieldPaths={availableFieldPaths}
            onUpdate={(data) => this.props.onUpdate(data)}

            fields={fields}
            parent=''
            level={0}

            possibleFields={gaPossibleFields}
            fieldDescriptions={gaFieldDescriptions}
            
            stitchfield='cid'
          />
        )}

        {this.state.tab === 'preview' && (
          <div style={{minHeight: 510, padding: 20}}>
            <DataFieldPreview
              testFieldMapping={testFieldMapping}
              domain={domain}
              fields={fields}
              data={data}

              type='ga'
              FieldDescriptions={gaFieldDescriptions}
              OtherFields={gaOtherFields}
            />
          </div>
        )}


      </BlankCard>
    )
  }
}
