import React from "react";
import {InputCard} from "../../../components/cards/inputCard";

export const GoogleAnalyticsSettingsModule = ({visible, data, onSave, pageType}) => {
  if (!visible) return null;

  return (
     <InputCard
      title='Google Analytics 4'
      subtitle='Set up your Measurement ID and API secret'
      onSave={onSave}
      initialData={{
        ga_property_id: data.ga_property_id,
        ga4_measurement_id: data.ga4_measurement_id,
        ga4_api_secret: data.ga4_api_secret,
      }}
      collapsable={!!data.ga_property_id}
      initialCollapsed={!!data.ga_property_id}
      objects={[
        {
          key: 'ga4_measurement_id',
          label: 'GA4 Measurement ID',
          placeholder: "",
          type: 'text',
          exampleFormat: 'G-XXXX',
          optional: false,
          focus: false,
          help: 'The measurement ID of the stream used for you GA4 property. Found under Admin > Data Streams > (select default stream) > Measurement ID'
        },
        {
          key: 'ga4_api_secret',
          label: 'GA4 API secret',
          placeholder: "",
          type: 'password',
          exampleFormat: 'I-V-XXXXX',
          optional: false,
          focus: false,
          help: 'A new secret for TraceDock can be created in your property under: Admin > Data Streams > (select default stream) > Measurement Protocol API secrets > Create'
        },
        {
          key: 'tracedock_hit',
          type: 'description',
          help: pageType === 'clientside' ?
            'Data gets forwarded to Google Analytics 4 in case that it is blocked due to adblocking plugins or browsers with tracking prevention. '
            :
            'Data is stitched with the browser session and forwarded to Google Analytics 4. '
        }
      ]}
    />
  );
};
