import React, { memo } from 'react'
import { useChildrenContentMemo } from '../useChildrenContentMemo';
import styles from './formInline.module.scss';

const FormInline = ({ ...rest }) => {
    const childrenWithProps = useChildrenContentMemo(rest);

    return (
        <div className={styles.formInline} >
            {childrenWithProps}
        </div>
    )
}

export default memo(FormInline);
