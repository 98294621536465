import React from "react";
import { connect } from "react-redux";

import { accountActions, persistActions, rulesActions } from "../../redux/actions";
import { Loader } from "../../components/loader/loader";
import { updateIfNeeded } from "../../lib/helpers";

import { DomainSection } from "./sections/domainSection";
import { PageLayout } from "../../components/layout/pageLayout";

class OrganisationPage extends React.Component {

    constructor(props, context) {
        super(props, context);

        this.state = {
            // currentCreditor: props.account.admin,

            showPaymentModal: false,
            pendingPayment: false,
        };

        updateIfNeeded(this.props.account.profile_updated, this.props.getProfile);

        // for migration in case you don't have admin
        // if (!this.props.account.admin) {
        //     this.props.logout()
        // }
    }

    async _gotoImplementation(data) {
        await this.props.SetDomain(data);
        await this.props.getDev({ uuid: data.domain.id });
        this.props.history.push('/installation');
    }

    _gotoLive(data) {
        this.props.SetDomain(data).then(
            () => this.props.history.push('/live')
        )
    }


    render({ history, persist, publishBuild, account, show, onClose, confirmPaymentMethod } = this.props) {
        return (
            <div>
                <PageLayout title='Organisation overview'
                    //   date_last_build={rules.date_last_build}
                    //   requires_publish={rules.requires_publish}
                    //   publishPending={rules.isPending}
                    //   hasPublish={!rules.isPending && persist.domain.state === 'live'}
                    //   onPublish={(message) => publishBuild({uuid: persist.domain.uuid, message: message})}
                    domains={persist.all_domains}
                    history={this.props.history} domain={persist.domain}>



                    {account.isPending && (<Loader style={{ marginTop: '20vh', marginLeft: '48%' }} />)}

                    {!account.isPending && (
                        <DomainSection
                            // user_email={this.props.account.profile.email}
                            admin={account.admin}
                            // inviteAdmin={(data) => this.props.inviteAdmin(data).then(() => this.props.getProfile())}
                            domains={persist.all_domains}
                            gotoImplementation={(data) => this._gotoImplementation(data)}
                            gotoLive={(data) => this._gotoLive(data)}
                            onAddDomain={(domains, callback) => {
                                this.props.createDomain({
                                    domains: domains,
                                    company_uuid: account.admin.id,
                                    as_agent: false,
                                }).then((domains) => {
                                    this.props.getProfile();
                                    if (callback) {
                                        callback()
                                    }
                                });
                            }}

                        />
                    )}
{/* 
                    {!account.isPending && (
                        <BlankCard title='Current price plan' subtitle='For monthly license fee'>

                            <p>Current monthly fee <span className='bold'>€{account.admin.flat_fee - account.admin.discount}</span>.</p>
                            {account.admin.page_views > 0 && <p>Monthly page views: <span className='bold'>{toLocale(account.admin.page_views)}</span>.</p>}
                            {account.admin.state !== 'live' && account.admin.trial_days > 0 && <p>The first billing period will start after the trial period has ended.</p>}
                            {account.admin.state !== 'live' && account.admin.trial_days === 0 && <p>The first billing period will start after the implementation is successful.</p>}
                            {account.admin.state === 'live' && account.admin.next_invoice_date && <p>Your next payment is due on <span className='bold'>{account.admin.next_invoice_date}</span>.</p>}

                        </BlankCard>
                    )} */}

                    <div style={{ height: 100 }} />
                </PageLayout>
            </div>
        );
    }
}


const mapStateToProps = (state) => ({
    persist: state.persist,
    account: state.account,
});

const mapDispatchToProps = (dispatch) => {
    return {
        getProfile: () => dispatch(accountActions.getProfile()),
        // logout: () => dispatch(accountActions.logout()),
        SetDomain: (d) => dispatch(persistActions.SetDomain(d)),
        // checkStatus: (data) => dispatch(accountActions.checkStatus(data)),
        createDomain: (data) => dispatch(accountActions.createDomain(data)),
        updateCreditor: (data) => dispatch(accountActions.updateCreditor(data)),
        inviteAdmin: (data) => dispatch(accountActions.inviteAdmin(data)),
        confirmPaymentMethod: (d) => dispatch(accountActions.confirmPaymentMethod(d)),
        getDev: (d) => dispatch(rulesActions.getDev(d)),
    }
};

export default OrganisationPage = connect(mapStateToProps, mapDispatchToProps)(OrganisationPage);