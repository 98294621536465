import React from "react";
import PropTypes from 'prop-types';
import {Json} from "../json/json";

import {Loader} from "../loader/loader";
import {Icon, invalid} from "../icon/icon";
import {colors} from "../../theme/colors";
import './dataFieldPreview.scss';
import {store} from '../../../src/index.js'

export class DataFieldPreview extends React.Component {

  /*
      Preview data field mapping
  */
  static propTypes = {
    fields: PropTypes.any,
    data: PropTypes.any,
    domain: PropTypes.string,
    type: PropTypes.string,
    testFieldMapping: PropTypes.any,
    FieldDescriptions: PropTypes.any,
    OtherFields: PropTypes.any,
  };

  static defaultProps = {
    fields: {},
    data: {},
    domain: '',
    type: 'ga',
    testFieldMapping: null,
  };

  constructor(props, context) {
    super(props, context);
    this.state = {
      isLoading: true,
      preview: null,
      valid: false,
      invalidKeys: [],
    };
  }

  _getData(data, fields, type) {
    // console.log("PROPS = ", store.getState().persist.domain.id)
    this.props.testFieldMapping && this.props.testFieldMapping({field_mapping: fields, data: data, domain: this.props.domain, type: type, id:store.getState().persist.domain.id})
      .then((res) => {
        if (res) this.setState({preview: res.data, isLoading: false, valid: res.valid, invalidKeys: res.invalidKeys})
      })
  }

  componentDidMount() {
    this._getData(this.props.data, this.props.fields, this.props.type)
  }

  componentWillUpdate(nextProps, nextState, nextContext) {
    if ((nextProps.data && this.props.data !== nextProps.data) || (nextProps.fields && this.props.fields !== nextProps.fields)) {
      // update data if fields or data changes
      this._getData(nextProps.data, nextProps.fields, nextProps.type);
    }
  }

  render({fields, data} = this.props) {
    return (
      <div className='dataFieldPreview'>

        {this.state.isLoading && (<Loader style={{marginTop: '20%', marginLeft: '48%'}}/>)}

        {/*{!this.state.isLoading && !this.state.valid  && (*/}
        {/*  <div className='invalid'>*/}
        {/*    <Icon icon={invalid} color={colors.brand.alert}/>*/}
        {/*    <p className='small msg'>*/}
        {/*      <span className='bold'>Invalid fields identified:*/}
        {/*      </span> {this.state.invalidKeys.join(', ')} </p>*/}
        {/*  </div>*/}
        {/*)}*/}

        {!this.state.isLoading && (
          <Json
            other_fields={this.props.OtherFields}
            translation={this.props.FieldDescriptions}
            data={this.state.preview}
          />
        )}

      </div>
    )
  }
}
