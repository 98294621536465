/*
    Facebook Conversion API
    https://developers.facebook.com/docs/marketing-api/conversions-api/parameters
*/


export const fbPossibleFields = [

//client data
  {key: 'user_data', value: 'user_data'},

// event data
  {key: 'event_name', value: 'event_name'},
  {key: 'event_id', value: 'event_id'},
  {key: 'custom_data', value: 'custom_data'},
  {key: 'action_source', value: 'action_source'},
  {key: 'event_source_url', value: 'event_source_url'},

// custom data
  {key: 'value', value: 'value'},
  {key: 'currency', value: 'currency'},
  {key: 'order_id', value: 'order_id'},

// products
  {key: 'contents', value: 'contents'},
  {key: 'id', value: 'id'},
  {key: 'quantity', value: 'quantity'},
  {key: 'price', value: 'price'},
  {key: 'category', value: 'category'},

]

