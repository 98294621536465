import React from 'react';
import PropTypes from 'prop-types';

import './modal.scss';
import {Icon, x} from "../icon/icon";
import {colors} from "../../theme/colors";

export class Modal extends React.Component {

  static propTypes = {
    onClose: PropTypes.func,
    visible: PropTypes.bool,
    width: PropTypes.string,
    position: PropTypes.string,
  };

  static defaultProps = {
    onClose: () => {},
    visible: false,
    width: '350px',
    position: 'fixed', // or absolute
  };

  render({children, width, onClose, visible, position} = this.props) {
    return (
      <div className='Modal'>

        {visible && (
          <div className='modalContainer' style={{position: position}}>

          <div className={`backLayer ${onClose && 'mouseclick'}`} onClick={() => {if (onClose) onClose()}}/>

            <div className='modalContent slideInFromTop' style={{maxWidth: width}}>

              {onClose && (<Icon size={14} icon={x} color={colors.brand.darkgray_light} className='close clickable' onClick={onClose}/>)}

              <div className='interior'>
                {children}
              </div>
            </div>

          </div>
          )}
      </div>
    );
  }
}