import React from "react";
import PropTypes from 'prop-types';

import {InputSelect} from "../inputSelect/inputSelect";
import {Button} from "../button/button";
import {colors} from "../../theme/colors";
import {Icon, valid} from "../icon/icon";

import './priceModal.scss'
import {toLocale} from "../../lib/helpers";

export class PriceConfirmModal extends React.Component {

  static propTypes = {
    onConfirm: PropTypes.func,
    admin: PropTypes.any,
    confirmPending: PropTypes.bool,
    trial_days: PropTypes.number,
  };

  constructor(props, context) {
    super(props, context);

    this.state = {
      selectedValues: []
    };
  }

  render_line = (text) => (
    <div className='row'>
      <div style={{marginRight: 6}}><Icon icon={valid} color={colors.brand.primary_light} size={24}/></div>
      {text}
    </div>
  );

  render({onConfirm, admin, trial_days} = this.props) {

    return (
      <div className='priceModal'>

        <h3 className='center' style={{marginBottom: 24}}>Price plan for {admin.company_name}</h3>


        <div className='column' style={{justifyContent: 'center', alignItems: 'center', marginBottom: 42}}>
          <p className='xtitle primary light'>€ {admin.flat_fee - admin.discount}</p>
          <p className='small bold'>billed monthly {trial_days > 0 ? ' after the trial period' : ''}</p>
        </div>


        {admin.discount > 0 && this.render_line(<p>A monthly discount of <span className='bold'>€{admin.discount}</span></p>)}

        {this.render_line(<p><span className='bold'>{toLocale(admin.page_views)} page views</span> per month, under a fair use policy</p>)}

        {this.render_line(<p>Connect up to <span className='bold'>{admin.n_domains} domains</span></p>)}

        {trial_days > 0 && this.render_line(<p>A trial period of <span className='bold'>{trial_days} days</span>, after the first events are received</p>)}
        {trial_days === 0 && this.render_line(<p>The first billing period will start after the first events are received</p>)}

        {this.render_line(<p>The contract with TraceDock can be cancelled each month</p>)}


        <div style={{marginTop: 32, marginLeft: 6}}>
          <InputSelect
            size='bold normal'
            type='checkbox'
            selectedValues={this.state.selectedValues}
            choices={[
              {key: 'confirm', value: <span>I accept the <a target="_blank" rel="noopener noreferrer" title='TraceDock terms' href="https://portal.tracedock.com/terms">General Terms of Service</a>.</span>}
            ]}
            onChange={(values) => this.setState({selectedValues: values})}
          />

          <Button
            disabled={!this.state.selectedValues.length > 0}
            backgroundColor={this.state.selectedValues.length > 0 ? colors.brand.primary : colors.brand.gray}
            onClick={() => onConfirm()}
            isLoading={this.props.confirmPending}
            title='Confirm'/>
        </div>
      </div>

    );
  }
}