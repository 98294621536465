import React, {Component} from 'react';
import PropTypes from 'prop-types';
import styles from './slider.module.scss';
import {colors} from "../../theme/colors";
import {arrows, IconInline} from "../icon/icon";

export class Slider extends Component {

  constructor(props) {
    super(props);

    this.container = React.createRef();

    this.state = {
      value: this.props.defaultValue
    };

    this.onMouseUp = this.onMouseUp.bind(this);
    this.onMouseDown = this.onMouseDown.bind(this);
    this.onChange = this.onChange.bind(this);
    this.onChangeComplete = this.onChangeComplete.bind(this);
    this.onTouchEnd = this.onTouchEnd.bind(this);
  }

  calculateChange(e) {
    const container = this.container.current;
    const containerWidth = container.clientWidth;

    // mouse event e.pageX, touchstart and touchmove e.touches, touchend e.changedTouches
    const x = typeof e.pageX === 'number' ? e.pageX : e.touches.length > 0 ? e.touches[0].pageX : e.changedTouches[0].pageX;
    const left = x - (container.getBoundingClientRect().left + window.pageXOffset);

    let value;
    if (left <= 0) {
      value = this.props.min;
    } else if (left >= containerWidth) {
      value = this.props.max;
    } else {
      const coeff = left / containerWidth;
      value = this.props.min + (this.props.max - this.props.min) * coeff
    }

    return Math.floor(value / this.props.step) * this.props.step;
  }

  onChange(e) {
    const value = this.calculateChange(e);
    this.setState(state => state.value === value ? state : {value});
    this.props.onChange(value);
  }

  onChangeComplete(e) {
    const value = this.calculateChange(e);
    this.setState(state => state.value === value ? state : {value});
    this.props.onChange(value);
    this.props.onChangeComplete(value);
  }

  onMouseDown() {
    window.addEventListener('mousemove', this.onChange);
    window.addEventListener('mouseup', this.onMouseUp);
  }

  onMouseUp(e) {
    this.unbindEventListeners();
    this.onChangeComplete(e);
  }

  onTouchEnd(e) {
    this.unbindEventListeners();
    e.preventDefault();
    this.onChangeComplete(e);
  }

  unbindEventListeners() {
    window.removeEventListener('mousemove', this.onChange);
    window.removeEventListener('mouseup', this.onMouseUp);
  }

  render() {
    const left = (this.state.value - this.props.min) / (this.props.max - this.props.min) * 100 + '%';

    return (
      <div className={styles.slider_wrapper} style={this.props.style}>
        <div
          className={styles.slider}
          ref={this.container}
          onMouseDown={this.onMouseDown}
          onTouchStart={this.onChange}
          onTouchMove={this.onChange}
          onTouchEnd={this.onTouchEnd}
        >
          <div className={styles.slider_rail}/>
          <div className={styles.slider_track}
               style={{
                 width: left,
                 backgroundColor: this.props.color
               }}
          />
          <div
            className={styles.slider_thumb}
            style={{
              left,
              color: this.props.color // box-shadow color
            }}
          >
            <IconInline icon={arrows} size={32} color={colors.brand.primary_light} style={{margin: '3px 4px'}}/>
          </div>
        </div>
      </div>
    );
  }

}

Slider.propTypes = {
  defaultValue: PropTypes.number,
  min: PropTypes.number,
  max: PropTypes.number,
  step: PropTypes.number,
  color: PropTypes.string,
  onChangeComplete: PropTypes.func,
  onChange: PropTypes.func,
  style: PropTypes.any
};

Slider.defaultProps = {
  min: 1,
  max: 10,
  step: 1,
  color: colors.brand.primary_light,
  onChangeComplete: () => {
  },
  onChange: () => {
  }
};
