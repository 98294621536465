export const fbTemplateChoices = [
  // created for dropdown list
  {value: 'Blank template', key: 'blank'},

  {value: 'PageView', key: 'pageview'},
  {value: 'Purchase', key: 'purchase'},
];

export const fbTemplates = {

  // created to populate fields

  blank: [],

  pageview: [
    {field_name: 'event_name', field_type: 'c', field_value: 'Purchase', children: []},
    {field_name: 'event_time', field_type: 'c', field_value: 'event', children: []},
    {field_name: 'event_id', field_type: 'l', field_value: 'order_id', children: []}, // event used to de-duplicate the event on the side of Facebook
    {field_name: 'action_source', field_type: 'c', field_value: 'website', children: []}, // add choices email, website, phone_call, chat, physical_store, system_generated, other
    {field_name: 'user_data', field_type: 'l', field_value: 'userId'},
  ],

  purchase: [
    {field_name: 'event_name', field_type: 'c', field_value: 'Purchase', children: []},
    {field_name: 'event_time', field_type: 'c', field_value: 'event', children: []},
    {field_name: 'event_id', field_type: 'l', field_value: 'order_id', children: []}, // event used to de-duplicate the event on the side of Facebook
    {field_name: 'action_source', field_type: 'c', field_value: 'website', children: []}, // add choices email, website, phone_call, chat, physical_store, system_generated, other
    {field_name: 'user_data', field_type: 'l', field_value: 'userId', stitch: true},
    {field_name: 'custom_data', field_type: 'o', children: [
        {field_name: 'value', field_type: 'l', field_value: 'transaction_revenue', children: []},
        {field_name: 'currency', field_type: 'l', field_value: 'transaction_currency', optional: true, children: []},
        {field_name: 'order_id', field_type: 'l', field_value: 'transaction_id', optional: true, children: []},
        {field_name: 'contents', field_type: 'a', field_value: 'products', optional: true, children: [
            {field_name: 'id', field_type: 'l', field_value: 'products.id', children: []},
            {field_name: 'category', field_type: 'l', field_value: 'products.category', optional: true, children: []},
            {field_name: 'quantity', field_type: 'l', field_value: 'products.quantity', children: []},
            {field_name: 'price', field_type: 'l', field_value: 'products.price', children: []},
          ]},
      ]},
  ],

};
