import React, {useCallback, useMemo} from 'react';
import {PropTypes} from 'prop-types';

import LoginSection from './LoginSection/LoginSection';
import RegistrationSection from './RegistrationSection/RegistrationSection';
import PricePlanSection from './PricePlanSection/PricePlanSection';
import CreditCardSection from './CreditCardSection/CreditCardSection';
import AdditionalFinancialSection from './AdditionalFinancialSection/AdditionalFinancialSection';
import AgencyDemoSection from './AgencyDemoSection/AgencyDemoSection';
import CorporateAccountSection from './CorporateAccountSection/CorporateAccountSection';
import DomainSection from './DomainSection/DomainSection';
import CompanySection from './CompanySection/CompanySection';
import {SectionDialog} from './SectionDialog';

import {STAGE} from '../constants';

import {Loader} from '../../../components/loader/loader';
import TimeLine from '../../../components/timeLine/TimeLine';

import styles from './sectionContainer.module.scss';

const SectionContainer = ({currentStage, updateProfile, history}) => {

  const goNext = useCallback(
    async (newStage) => {

      // update profile which automatically updates the getStarted screen
      await updateProfile({
        get_started: newStage
      });

      if (newStage === 0) {
        history.push('/')
      }

    },
    [updateProfile, history]
  );

  let content = useMemo(
    () => {
      let isSectionDialog = false;
      let previousStage = null;
      let hasTimeLine = true;

      switch (currentStage) {

        case STAGE.LOADING:
          content = <div style={{height: '100%', width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
            <Loader style={{margin: 'auto'}}/>
          </div>;
          break;
        case STAGE.LOGIN:
          hasTimeLine = false;
          content = <LoginSection goNext={goNext} history={history}/>;
          break;
        case STAGE.REGISTRATION:
          hasTimeLine = false;
          content = <RegistrationSection goNext={goNext} history={history}/>;
          break;
        case STAGE.PRICE_PLAN:
          content = <PricePlanSection goNext={goNext}/>;
          break;
        case STAGE.COMPANY:
          content = <CompanySection goNext={goNext}/>;
          break;
        case STAGE.CREDIT_CARD:
          content = <CreditCardSection goNext={goNext}/>;
          break;
        case STAGE.ADDITIONAL_FINANCIAL:
          content = <AdditionalFinancialSection goNext={goNext}/>;
          break;
        case STAGE.DOMAIN:
          content = <DomainSection goNext={goNext}/>;
          break;
        case STAGE.AGENCY_DEMO:
          isSectionDialog = true;
          previousStage = STAGE.COMPANY;
          content = <AgencyDemoSection/>;
          break;
        case STAGE.CORPORATE_ACCOUNT:
          isSectionDialog = true;
          previousStage = STAGE.PRICE_PLAN;
          content = <CorporateAccountSection/>;
          break;
        default:
      }

      if (isSectionDialog) {
        return (
          <SectionDialog goBack={() => goNext(previousStage)}>
            {content}
          </SectionDialog>
        );
      } else {
        return (
          <div className={styles.section}>
            {hasTimeLine &&
            <TimeLine
              step={currentStage}
              noOfSteps={6}
              style={{margin: '30px 0'}}
            />}
            {content}
          </div>
        );
      }
    },
    [currentStage, goNext, history]
  );

  return (
    <>
      {content}
    </>
  )
};

SectionContainer.propTypes = {
  currentStage: PropTypes.number,
  updateProfile: PropTypes.func,
  history: PropTypes.any
};

export default SectionContainer;
