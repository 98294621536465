import {convertFromJson} from "../../lib/helpers";

export const normalizeEvents = (events) => {
  // normalizes event data for frontend
  let normalizedEvents = [];
  let now = new Date();

  events.map((event) => {
    return normalizedEvents.push(normalizeEvent(event, now));
  });

  return normalizedEvents;
};


export const normalizeEvent = (event, now) => {
  let time = new Date(event.timestamp);
  let delta = Math.round((time - now) * 0.001);
  return {
    ...event,
    time: delta > -5 ? 'Now' : time.toLocaleTimeString([], {hour: '2-digit', minute: '2-digit', hour12: false}),
    cid: event.cid && event.cid.length > 11 ? `${event.cid.substring(0, 11)}...` : event.cid,
    inbound: convertFromJson(event.inbound),
    outbound: convertFromJson(event.outbound),
    outbound_fb: event.outbound_fb ? convertFromJson(event.outbound_fb) : '',
    outbound_cdp: event.outbound_cdp ? convertFromJson(event.outbound_cdp) : '',
    uip: event.uip ? event.uip.split('.').splice(0, 3).join('.') + '.***' : '?',
    user_agent_light: event.user_agent.split(' ').slice(-1)[0]
  }
};