import React, {useCallback, useMemo, useState} from 'react';
import {connect} from 'react-redux';
import {PropTypes} from 'prop-types';
import SectionTitle from '../SectionTitle';
import {accountActions} from '../../../../redux/actions';
import styles from '../sectionContainer.module.scss';
import {Form, InputField, LabelField} from '../../../../components/form';
import {Button} from '../../../../components/button/button';
import {plus} from '../../../../components/icon/icon';
import {colors} from '../../../../theme/colors';
import {isURLvalidator} from "../../../../lib/validators";
import {STAGE} from "../../constants";
import {setAlertDialog} from "../../../../components/alert/alert";
import {store} from "../../../../index";


const extractDomainData = (data) => {
  // helper function to extract data from Form dictionary data

  let keys = Object.values(data);
  let newDomains = [];

  keys.forEach((domain) => {

    // hardcoded add https
    domain = `https://${domain.trim().replace('https://', '').replace('http://', '')}`;

    // remove trailing slash
    if (domain.substring(domain.length - 1) === '/') domain = domain.slice(0, -1);

    // only add domain if not already present in list
    if (!newDomains.includes(domain)) newDomains.push(domain);
  });

  return newDomains;
};

// test:
// console.log('no domains valid: ', extractDomainData({}));
// console.log(' 2 domains valid: ', extractDomainData({domain1: 'test.nl', domain2: 'https://test.nl/', domain3: 'https://www.test2.nl/', domain4: 'http://www.test2.nl/'}));


const DomainSection = ({goNext, createDomain, admin, isPendingUpdate}) => {
  const [noOfDomains, setNoOfDomains] = useState(admin.n_domains <= 3 ? admin.n_domains : 3);
  const onConfirm = useCallback(
    async (data) => {

      setPending(true);
      let newDomains = extractDomainData(data);

      if (newDomains.length > 0) {

        // continue with create
        let domains = await createDomain({
          setPending: true,
          domains: newDomains,
          company_uuid: admin.uuid
        });

        // only continue if we added domains
        if (domains && domains.length > 0) {
          goNext(STAGE.COMPLETED);
          setAlertDialog('🎉 Congratulations', 'We have succesfully setup your user, your company and your domains within TraceDock.', 'You are now ready to installing your first domain.');
        } else {
          setPending(false);
        }
      } else {
        setPending(false);
      }


    },
    [goNext, createDomain]
  );

  const [pending, setPending] = useState(false);

  const onAddDomain = useCallback(
    () => setNoOfDomains(state => state + 1),
    []
  );

  const formContent = useMemo(
    () => {
      const contents = [];

      for (let i = 1; i <= noOfDomains; i++) {
        contents.push(
          <InputField
            key={i}
            name={`domain${i}`}
            label={`Domain ${admin.n_domains > 1 ? (i + '/' + admin.n_domains) : ''}`}
            placeholder={`website${admin.n_domains > 1 ? ('-' + i) : ''}.com`}
            focus={i === 1}
          >
            <LabelField title='https://'/>
          </InputField>
        )
      }

      return contents;
    },
    [noOfDomains, admin]
  );

  const formValidators = useMemo(
    () => {
      const validators = {};

      for (let i = 1; i <= noOfDomains; i++) {
        validators[`domain${i}`] = isURLvalidator;
      }

      return validators
    },
    [noOfDomains]
  );

  return (
    is_agency = store.getState().cmaccount.is_agency,
    <div className={styles.section_content}>
      <SectionTitle
        title={`Add your domain${admin.n_domains > 1 ? 's' : ''}`}
        subtitle={admin.n_domains > 1 ? `You can add up to ${admin.n_domains} domains` : 'You can add 1 domain within your price plan'}
      />

      <Form
        isLoading={isPendingUpdate || pending}
        buttonText='Continue'
        onSave={onConfirm}
        validators={formValidators}
        footerContent={
          (noOfDomains < admin.n_domains)
          ? <Button
            title='Add another domain'
            color={colors.brand.primary}
            backgroundColor='white'
            onClick={onAddDomain}
            iconRight={plus}
            style={{width: '50%', marginTop: 18}}
          />
          :
            (
              admin.n_domains > 1 && <p style={{marginTop: 6}}>Do you want more than {admin.n_domains} domains? Click on "Continue" to do this in the User Portal.</p>
            )
        }
      >
        {formContent}
      </Form>
    </div>
  )
};

DomainSection.propTypes = {
  goNext: PropTypes.func,
  createDomain: PropTypes.func
};

const mapDispatchToProps = (dispatch) => {
  return {
    createDomain: (data) => dispatch(accountActions.createDomain(data))
  }
};

const mapStateToProps = (state) => ({
  admin: state.account.admin,
  isPendingUpdate: state.account.isPendingUpdate
});

export default connect(mapStateToProps, mapDispatchToProps)(DomainSection);
