export const STAGE = {
    LOGIN: -99,

    LOADING: -1,
    COMPLETED: 0,
    REGISTRATION: 1,
    COMPANY: 2,
    PRICE_PLAN: 3,
    CREDIT_CARD: 4,
    ADDITIONAL_FINANCIAL: 5,
    DOMAIN: 6,
    AGENCY_DEMO: 7,
    CORPORATE_ACCOUNT: 8
};
