import React from "react";
import {InputCard} from "../../../components/cards/inputCard";

export const FacebookConversionSettingsModule = ({visible, data, onSave}) => {

  if (!visible) return null;
  return (
    <InputCard
      title='Facebook Conversion API'
      subtitle='Set up your Facebook Conversion API connection'
      onSave={onSave}
      initialData={{
        fb_enabled: data.fb_enabled,
        fb_id: data.fb_id,
        fb_token: data.fb_token
      }}
      collapsable={true}
      initialCollapsed={!!data.fb_id}
      objects={[
        {
          key: 'description',
          type: 'description',
          help: "Data can be forwarded to Facebook through the Conversions API. However, this is only done for users that have given consent for placing marketing cookies in their browsers, " +
            "and only if they are not actively blocking Facebook through tracking prevention. Only then will TraceDock forward events to Facebook, stitched to the user's browser cookie. " +
            "Stitching with Facebook through personal identifiable information such as email or phone number is not considered privacy friendly or GDPR compliant, and can thus not be done with TraceDock."
        },

        {
          key: 'fb_enabled',
          label: 'Consent received',
          type: 'confirmation',
          help: 'You hereby declare that only with proper consent Facebook is loaded into your website.',
          optional: false,
          restrict: true,
          focus: false,
        },
        {
          key: 'fb_id',
          label: 'Pixel ID',
          placeholder: "",
          type: 'text',
          validator: (value) => (value === '' || /^[0-9]+$/),
          exampleFormat: '123123123',
          optional: false,
          focus: false,
          help: 'Event Manager > Selected datasource > Settings tab'
        },
        {
          key: 'fb_token',
          label: 'Conversions API access token',
          type: 'text',
          validator: (value) => (value === '' || value.length > 25),
          optional: false,
          focus: false,
          help: 'Event Manager > Selected datasource > Settings tab. Scroll down to Conversions API and press on "generate access token".'
        }
      ]}
    />
  );
};
