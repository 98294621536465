import React, {useState} from 'react';
import {Button} from "../../../components/button/button";
import {Modal} from "../../../components/modal/modal";
import {Loader} from "../../../components/loader/loader";
import {InputSelect} from "../../../components/inputSelect/inputSelect";
import {Input} from "../../../components/input/input";

import './copyFrom.component.scss';

export const CopyFromDomain = ({isVisible, onClose, domains, currentDomainUuid, events, getEvents, copyEvents} = this.props) => {

  const [domain, setDomain] = useState('');
  const [filter, setFilter] = useState('');
  const [selectedEvents, setEvents] = useState([]);


  return <Modal visible={isVisible} onClose={() => onClose()}>
    <div className='copyFromModal'>

      <h2>Copy event</h2>
      <h6 className='spacer' style={{marginBottom: 24}}>Select which event(s) you would like to copy.</h6>

      {!domain && domains && domains.length > 8 && (
        <Input
          label="Filter"
          focus={true}
          onChange={setFilter}
        />
      )}

      {!domain && domains && domains.map((domain, idx) => (
        (filter === '' || domain.name.includes(filter)) &&
         <Button key={idx} style={{marginTop: 6}} title={domain.slug} onClick={(value) => {
          setDomain(domain);
          getEvents({uuid: domain.id})
        }}/>
      ))}


      {domain && (!events || events.length === 0) && (
        <Loader style={{marginLeft: '40%'}}/>
      )}

      {domain && events && events.length > 0 && <div>

        <InputSelect
          type='checkbox'
          selectedValues={selectedEvents}
          choices={[{key: 'all', value: 'select all'}].concat(events)}
          onChange={(selected) => {

            if (selected.includes('all') && !selectedEvents.includes('all')) {
              // selected events is list of uuid's
              selected = ['all'];
              events.map((event) => {
                selected.push(event.key);
                return true
              });
            }
            setEvents(selected)
          }}
        />

        <Button style={{marginTop: 6}} disabled={selectedEvents.length === 0} title='import events' onClick={(value) => {

          let eventIds = [];
          events.map((event) => {if (selectedEvents.includes(event.key)) eventIds.push(event.data.id); return true});
          copyEvents({uuid: currentDomainUuid, eventIds: eventIds})
        }}/>

      </div>
      }

    </div>
  </Modal>
};
