
export function addWikiPage({path, content}) {
  return async (dispatch) => {
    dispatch({
      type: 'ADD_WIKI_PAGE',
      path: path,
      content: content,
    });
  }
}

export function showAlert(alertTitle, alertMessage, alertDetails, alertButtons) {
  return async (dispatch) => {
    dispatch({
      type: 'SET_ALERT',
      alertTitle: alertTitle,
      alertMessage: alertMessage,
      alertDetails: alertDetails,
      alertButtons: alertButtons || [],
    });
  }
}

export function clearAlert() {
  return async (dispatch) => {
    dispatch({
      type: 'CLEAR_ALERT'
    });
  }
}

