import React from 'react';
import {planData} from "../../lib/priceData";
import {Button} from "../button/button";
import {colors} from "../../theme/colors";


export const PriceTable = ({activePlan, isLoading, selectPlan, uuid}) => {

  return (
    <div className='table'>

      <div className='row header'>

        <p className='head column wider'>pageviews:</p>
        <p className='head column narrow'>domains:</p>
        <p className='head column narrow'>price:</p>

      </div>

      {planData.map((plan, idx) => {

        let isActive = activePlan.idx === idx;

        return <div className={isActive ? 'row active' : 'row'} key={idx}>
          <p className='column wider'>{plan.pageviewText}</p>
          <p className='column narrow'>{plan.domains}</p>
          <p className='column narrow'>{plan.price}</p>
          <div className='column narrow'>
            <Button
              onClick={() => selectPlan(plan, uuid)}
              minHeight={28}
              isLoading={isActive && isLoading}
              title={plan.button}
              color={isActive ? 'white' : colors.brand.primary}
              loaderColor={isActive ? colors.brand.gray : colors.brand.primary}
              backgroundColor={isActive ? colors.brand.primary : 'white'}
            />
          </div>
        </div>

      })}

    </div>
  )
};



// // add custom pricing or not
// if (data.isCustom) {
//   data.price = '';
//   data.button = 'Talk to sales';
// } else {
//   data.price = `€${price}/mo`;
//   data.button = 'Choose plan';
// }