import React from 'react';
import PropTypes from 'prop-types';

import {valid, invalid, Icon, down, eye, eye_active} from "../icon/icon";
import {colors} from "../../theme/colors";

import './input.scss';

export class Input extends React.Component {

  static propTypes = {
    backgroundColor: PropTypes.string,
    ref: PropTypes.any,
    label: PropTypes.any,
    focus: PropTypes.bool,
    type: PropTypes.any,
    initial: PropTypes.any,
    placeholder: PropTypes.any,
    isValid: PropTypes.bool,
    isInvalid: PropTypes.bool,
    onChange: PropTypes.any,
    onSubmit: PropTypes.any,
    style: PropTypes.any,
    readOnly: PropTypes.bool,
    choices: PropTypes.array,
    restrict: PropTypes.bool,
  };

  static defaultProps = {
    backgroundColor: colors.brand.white,
    ref: null,
    label: '',
    focus: false,
    type: 'text',
    initial: '',
    placeholder: '',
    isValid: false,
    isInvalid: false,
    onChange: null,
    onSubmit: null,
    style: {},
    readOnly: false,
    restrict: false,
    choices: []
  };


  _get_initial(initial, choices) {
    // determine initial under choices
    let new_initial = '';
    if (choices.length > 0) {
      if (initial !== '') {
        // check if initial fits the choices, otherwise leave blank
        new_initial = initial;

        // check if the key corresponds to a value, if so, filter that our
        let possible = choices.filter((choice) => choice.key === initial);
        if (possible[0] && possible[0]['value']) new_initial = possible[0]['value']
      }

    } else if (initial) {
      new_initial = initial;
    }
    return new_initial
  }

  constructor(props, context) {
    super(props, context);

    this.state = {
      focus: false,
      value: this._get_initial(props.initial, props.choices),
      choices: props.choices,
      currentChoiceIdx: -1,
      passwordVisible: false,
    }
  }

  render({ref, label, placeholder, backgroundColor, focus, type, value, onChange, onSubmit, style, isInvalid, isValid, readOnly, choices, restrict} = this.props) {
    let hasChoices = choices.length > 0;
    return (
      <div className='inputElement' style={style}>

        <div
          style={{backgroundColor: backgroundColor}}
          className={`Input ${isInvalid ? 'invalid' : ''} ${this.state.focus ? 'focus' : ''}`}
        >

          {label && (
            <label style={{backgroundColor: backgroundColor}}
              className={`label noselect ${this.state.focus || this.state.value || placeholder ? 'focus' : ''} ${isInvalid ? 'invalid' : ''}`}>
              {label}
            </label>
          )}
          <div className='inputWrapper'>

            <input
              ref={(reference) => ref = reference}
              className='input'
              autoFocus={focus}
              type={type !== 'password' ? type : (this.state.passwordVisible ? 'text' : 'password')}
              placeholder={placeholder}
              value={this.state.value}
              readOnly={readOnly || restrict}
              autoComplete="new-info"
              onChange={
                (event) => {
                  // filter down choices if needed
                  if (this.props.choices) {

                    let newChoices = this.props.choices.filter((choice) => {
                      return choice.value && choice.value.toLowerCase().includes(event.target.value.toLowerCase())
                    });
                    this.setState({
                      value: event.target.value,
                      choices: newChoices.length > 0 ? newChoices : this.props.choices,
                      currentChoiceIdx: -1
                    });
                  } else {
                    this.setState({value: event.target.value});
                  }
                  if (onChange) onChange(event.target.value)
                }
              }
              onKeyDown={(event) => {

                // Option A: work with arrows, down/up only if choices
                if (hasChoices && (event.key === 'ArrowDown' || event.key === 'ArrowUp')) {

                  // Prevent default means to stop activity that would cause the page to scroll down
                  event.preventDefault();

                  let newIndex = -2;
                  if (event.key === 'ArrowDown' && this.state.currentChoiceIdx < this.state.choices.length) {
                    newIndex = this.state.currentChoiceIdx + 1;
                  } else if (event.key === 'ArrowUp' && this.state.currentChoiceIdx > -1) {
                    newIndex = this.state.currentChoiceIdx - 1;
                  }

                  // only continue if newIndex > -1
                  if (newIndex > -2) {
                    this.setState({currentChoiceIdx: newIndex});
                    // then scroll to element
                  }

                } else {

                  // Option B: in nearly all cases upon enter/tab we submit and blur the field
                  // However, if you have choices && tab, we have tab completion and this does not submit or blur.
                  let submit = (event.key === 'Enter' || event.key === 'Tab' || event.key === 'Escape');

                  if (hasChoices && (event.key === 'Enter' || event.key === 'Tab')) {

                    // either you select the first value, or the value from the index
                    let selectedObject = this.state.choices[this.state.currentChoiceIdx > -1 ? this.state.currentChoiceIdx : 0];

                    if (this.state.currentChoiceIdx > -1 || selectedObject.value.startsWith(event.target.value)) {

                      // we set the selected value to the first in the choices
                      this.setState({value: selectedObject.value}, () => {
                        if (onChange) onChange(selectedObject.key);
                      });
                    }
                  }

                  // if we submit we need to blur and trigger submit
                  if (submit) {
                    event.target.blur();
                    if (onSubmit) onSubmit();
                  }
                }
              }}
              onFocus={() => {
                if (!readOnly) this.setState({focus: true, choices: this.props.choices})
              }}
              onBlur={(event) => {
                if (!readOnly) this.setState({focus: false})
              }}
            />

            {!isValid && !isInvalid && hasChoices && !this.state.value &&  !this.state.focus && !readOnly && (
              <div className='validator' onClick={() => this.setState({focus: true})}>
                <Icon icon={down} color={colors.brand.primary} size={14}/>
              </div>)}

            {isValid && type !== 'password' && (<div className='validator'>
              <Icon icon={valid} color={colors.brand.gray} size={18}/>
            </div>)}
            {isInvalid && type !== 'password' && (<div className='validator'>
              <Icon icon={invalid} color={colors.brand.alert} size={18}/>
            </div>)}

            {type === 'password' && (<div className='validator clickable' onClick={() => this.setState({passwordVisible: !this.state.passwordVisible})}>
              <Icon icon={this.state.passwordVisible ? eye_active : eye} color={this.state.passwordVisible ? colors.brand.primary : colors.brand.gray} size={18}/>
            </div>)}

          </div>
        </div>

        <div
          className={`choices ${this.state.choices.length > 8 ? 'scrollable' : ''} ${this.state.choices && this.state.focus ? 'open' : 'closed'}`}
          style={{maxHeight: this.state.choices.length > 8 ? 152 : 26 * this.state.choices.length}}>
          {this.state.choices && this.state.choices.map((choice, idx) => {
            return (
              <div key={idx} className={`choice clickable ${idx === this.state.currentChoiceIdx ? 'highlight' : ''}`}
                   onMouseOver={() => {this.setState({currentChoiceIdx: idx})}}
                   onMouseDown={() => {
                     // remove all choices, cascade to onChange if needed
                     this.setState({value: choice.value, focus: false});
                     if (this.props.onChange) {
                       this.props.onChange(choice.key)
                     }
                   }}>
                <span className='small'>{choice.value}</span>
              </div>
            )
          })}
        </div>
      </div>
    );
  }
}
