/*

    Autocomplete Ecommerce
    https://developers.google.com/tag-manager/enhanced-ecommerce

    based on input data, return conditions & template filled in

    see: https://developers.google.com/tag-manager/enhanced-ecommerce

*/

let ecommerce_autocomplete = [];

// ---> Pageview:
ecommerce_autocomplete.push({
  name: 'Pageview',
  template: 'Pageview',
  ga4_template: 'Pageview',
  data: {
    "event": "td.init",
  },
  ga4_fields: [
    { field_name: "name", field_type: "c", field_value: 'page_view', children: [] },
  ],

  fields: [
    {field_name: 'name', field_type: 'c', field_value: 'pageview', children: []},
  ],

  triggers: [
    { key: "event", lookup_type: "e", value: "td.init" },
  ],

});

// ---> Impressions:
ecommerce_autocomplete.push({
  name: 'Impressions',
  template: 'impression',
  ga4_template: 'impression',
  data: {
    'ecommerce': {
      'currencyCode': 'EUR',                       // Local currency is optional.
      'impressions': [
        {
          'name': 'Triblend TraceDock T-Shirt',
          'id': '12345',
          'position': '1',
          'price': '15.25',
          'brand': 'TraceDock',
          'category': 'Apparel',
          'variant': 'Gray',
          'list': 'Search Results',
        }]
    }
  },
  fields: [
    { field_name: "t", field_type: "c", field_value: 'event', children: [] },
    { field_name: 'ec', field_type: 'c', field_value: 'Ecommerce', children: [] },
    { field_name: 'ea', field_type: 'c', field_value: 'ProductImpressions', children: [] },

    {
      field_name: "il", field_type: "a", field_value: "ecommerce.impressions",
      children: [
        { field_name: "il.nm", field_type: 'l', field_value: "ecommerce.impressions.list", children: [] },
        { field_name: "pr.ps", field_type: 'l', field_value: "ecommerce.impressions.position", children: [], optional: true, },
        { field_name: "pr.id", field_type: 'l', field_value: "ecommerce.impressions.id", children: [], },
        { field_name: "pr.nm", field_type: 'l', field_value: "ecommerce.impressions.name", children: [], optional: true, },
        { field_name: "pr.br", field_type: 'l', field_value: "ecommerce.impressions.brand", children: [], optional: true, },
        { field_name: "pr.ca", field_type: 'l', field_value: "ecommerce.impressions.category", children: [], optional: true, },
        { field_name: "pr.va", field_type: 'l', field_value: "ecommerce.impressions.variant", children: [], optional: true, },
        { field_name: "pr.pr", field_type: 'l', field_value: "ecommerce.impressions.price", children: [], optional: true, },
      ],
    }],

  ga4_fields: [
    { field_name: 'name', field_type: 'c', field_value: 'view_item_list', children: [] },
    {
      field_name: 'params', field_type: 'o', children: [
        {
          field_name: 'items', field_type: 'a', field_value: "ecommerce.impressions", children: [
            { field_name: 'item_id', field_type: 'l', field_value: 'ecommerce.impressions.id', children: [] },
            { field_name: 'item_name', field_type: 'l', field_value: 'ecommerce.impressions.name', children: [] },
            { field_name: 'item_brand', field_type: 'l', field_value: 'ecommerce.impressions.brand', optional: true, children: [] },
            { field_name: 'item_category', field_type: 'l', field_value: 'ecommerce.impressions.category', optional: true, children: [] },
            { field_name: 'item_variant', field_type: 'l', field_value: 'ecommerce.impressions.variant', optional: true, children: [] },
            { field_name: 'price', field_type: 'l', field_value: 'ecommerce.impressions.price', optional: true, children: [] },
            { field_name: 'item_list_id', field_type: 'l', field_value: 'ecommerce.impressions.list', optional: true, children: [] },
            { field_name: 'item_list_name', field_type: 'l', field_value: 'ecommerce.impressions.list', optional: true, children: [] },
          ]
        }
      ]
    },
  ],

  triggers: [
    { key: "ecommerce", lookup_type: "c", value: "impressions" },
    { key: "ecommerce.impressions", lookup_type: "lgte", value: 1 }
  ],

});


// ---> Detail:
//
ecommerce_autocomplete.push({
  name: 'Detail',
  template: 'product_detail',
  ga4_template: 'product_detail',
  data: {
    'ecommerce': {
      'currencyCode': 'EUR',                       // Local currency is optional.
      'detail': {
        'actionField': { 'list': 'Apparel Gallery', 'revenue': '15.25' },    // 'detail' actions have an optional list property.
        'products': [{
          'name': 'Triblend TraceDock T-Shirt',
          'id': '12345',
          'price': '15.25',
          'brand': 'TraceDock',
          'category': 'Apparel',
          'variant': 'Gray'
        }]
      }
    }
  },
  fields: [
    { field_name: 't', field_type: 'c', field_value: 'event', children: [] },
    { field_name: 'ec', field_type: 'c', field_value: 'Ecommerce', children: [] },
    { field_name: 'ea', field_type: 'c', field_value: 'ProductDetail', children: [] },

    { field_name: 'pa', field_type: 'c', field_value: 'detail', children: [] },

    { field_name: 'pal', field_type: 'l', field_value: 'ecommerce.detail.actionField.list', optional: true, children: [] },
    {
      field_name: 'pr', field_type: 'a', field_value: 'ecommerce.detail.products', children: [
        { field_name: 'pr.id', field_type: 'l', field_value: 'ecommerce.detail.products.id', children: [] },
        { field_name: 'pr.nm', field_type: 'l', field_value: 'ecommerce.detail.products.name', optional: true, children: [] },
        { field_name: 'pr.br', field_type: 'l', field_value: 'ecommerce.detail.products.brand', optional: true, children: [] },
        { field_name: 'pr.ca', field_type: 'l', field_value: 'ecommerce.detail.products.category', optional: true, children: [] },
        { field_name: 'pr.va', field_type: 'l', field_value: 'ecommerce.detail.products.variant', optional: true, children: [] },
        { field_name: 'pr.pr', field_type: 'l', field_value: 'ecommerce.detail.products.price', optional: true, children: [] },
      ]
    },
  ],

  ga4_fields: [
    { field_name: 'name', field_type: 'c', field_value: 'view_item', children: [] },
    {
      field_name: 'params', field_type: 'o', children: [
        { field_name: 'currency', field_type: 'l', field_value: 'ecommerce.currencyCode', children: [] },
        { field_name: 'value', field_type: 'l', field_value: 'ecommerce.detail.actionField.revenue', children: [] },
        {
          field_name: 'items', field_type: 'a', field_value: 'ecommerce.detail.products', children: [
            { field_name: 'item_id', field_type: 'l', field_value: 'ecommerce.detail.products.id', children: [] },
            { field_name: 'item_name', field_type: 'l', field_value: 'ecommerce.detail.products.name', children: [] },
            { field_name: 'item_brand', field_type: 'l', field_value: 'ecommerce.detail.products.brand', optional: true, children: [] },
            { field_name: 'item_category', field_type: 'l', field_value: 'ecommerce.detail.products.category', optional: true, children: [] },
            { field_name: 'item_variant', field_type: 'l', field_value: 'ecommerce.detail.products.variant', optional: true, children: [] },
            { field_name: 'price', field_type: 'l', field_value: 'ecommerce.detail.products.price', optional: true, children: [] },
          ]
        }
      ]
    },
  ],

  cdp_template: 'detail',
  cdp_fields: [

    { field_name: 'eventCategory', field_type: 'c', field_value: 'Ecommerce', children: [] },
    { field_name: 'eventAction', field_type: 'c', field_value: 'DetailView', children: [] },
    { field_name: 'eventLabel', field_type: 'l', optional: true, field_value: 'ecommerce.detail.products.0.id', children: [] },
    { field_name: 'eventValue', field_type: 'l', optional: true, field_value: 'ecommerce.detail.products.0.price', children: [] },

    { field_name: 'productId', field_type: 'l', field_value: 'ecommerce.detail.products.0.id', children: [] },
    { field_name: 'productName', field_type: 'l', field_value: 'ecommerce.detail.products.0.name', children: [] },
    { field_name: 'productBrand', optional: true, field_type: 'l', field_value: 'ecommerce.detail.products.0.brand', children: [] },
    { field_name: 'productCategory', optional: true, field_type: 'l', field_value: 'ecommerce.detail.products.0.category', children: [] },
    { field_name: 'productVariant', optional: true, field_type: 'l', field_value: 'ecommerce.detail.products.0.variant', children: [] },
    { field_name: 'productPrice', optional: true, field_type: 'l', field_value: 'ecommerce.detail.products.0.price', children: [] },

  ],

  triggers: [
    { key: "ecommerce", lookup_type: "c", value: "detail" },
    { key: "ecommerce.detail.products", lookup_type: "lgte", value: 1 },
  ],

});

// ---> Purchase:
//

ecommerce_autocomplete.push({
  name: 'Purchase',
  template: 'product_purchase',
  ga4_template: 'product_purchase',
  data: {
    'ecommerce': {
      'currencyCode': 'EUR',                       // Local currency is optional.
      'purchase': {
        'actionField': {
          'id': 'T12345',                         // Transaction ID. Required for purchases and refunds.
          'affiliation': 'Online Store',
          'revenue': '35.43',                     // Total transaction value (incl. tax and shipping)
          'tax': '4.90',
          'shipping': '5.99',
          'coupon': 'SUMMER_SALE',
          'affiliation': 'Webshop'
        },
        'products': [{                            // List of productFieldObjects.
          'name': 'Triblend TraceDock T-Shirt',
          'id': '12345',
          'price': '15.25',
          'brand': 'TraceDock',
          'category': 'Apparel',
          'variant': 'Gray',
          'quantity': 1,
          'coupon': '',                // Optional fields may be omitted or set to empty string.
          'affiliation': 'Webshop'
        }
        ]
      }
    }
  },
  fields: [
    { field_name: 't', field_type: 'c', field_value: 'event', children: [] },
    { field_name: 'ec', field_type: 'c', field_value: 'Ecommerce', children: [] },
    { field_name: 'ea', field_type: 'c', field_value: 'Purchase', children: [] },

    { field_name: 'pa', field_type: 'c', field_value: 'purchase', children: [] },

    { field_name: 'ti', field_type: 'l', field_value: 'ecommerce.purchase.actionField.id', children: [] },
    { field_name: 'tr', field_type: 'l', field_value: 'ecommerce.purchase.actionField.revenue', children: [] },
    { field_name: 'ts', field_type: 'l', field_value: 'ecommerce.purchase.actionField.shipping', optional: true, children: [] },
    { field_name: 'tt', field_type: 'l', field_value: 'ecommerce.purchase.actionField.tax', optional: true, children: [] },
    { field_name: 'ta', field_type: 'l', field_value: 'ecommerce.purchase.actionField.affiliation', optional: true, children: [] },
    { field_name: 'tcc', field_type: 'l', field_value: 'ecommerce.purchase.actionField.coupon', optional: true, children: [] },
    { field_name: 'cu', field_type: 'c', field_value: 'EUR', optional: true, children: [] },

    {
      field_name: 'pr', field_type: 'a', field_value: 'ecommerce.purchase.products', children: [
        { field_name: 'pr.id', field_type: 'l', field_value: 'ecommerce.purchase.products.id', children: [] },
        { field_name: 'pr.nm', field_type: 'l', field_value: 'ecommerce.purchase.products.name', optional: true, children: [] },
        { field_name: 'pr.br', field_type: 'l', field_value: 'ecommerce.purchase.products.brand', optional: true, children: [] },
        { field_name: 'pr.ca', field_type: 'l', field_value: 'ecommerce.purchase.products.category', optional: true, children: [] },
        { field_name: 'pr.va', field_type: 'l', field_value: 'ecommerce.purchase.products.variant', optional: true, children: [] },
        { field_name: 'pr.pr', field_type: 'l', field_value: 'ecommerce.purchase.products.price', optional: true, children: [] },
        { field_name: 'pr.qt', field_type: 'l', field_value: 'ecommerce.purchase.products.quantity', optional: true, children: [] },
        { field_name: 'pr.cc', field_type: 'l', field_value: 'ecommerce.purchase.products.coupon', optional: true, children: [] },
      ]
    },
  ],
  ga4_fields: [
    { field_name: 'name', field_type: 'c', field_value: 'purchase', children: [] },
    {
      field_name: 'params', field_type: 'o', children: [
        { field_name: 'transaction_id', field_type: 'l', field_value: 'ecommerce.purchase.actionField.id', children: [] },
        { field_name: 'currency', field_type: 'l', field_value: 'ecommerce.currencyCode', children: [] },
        { field_name: 'value', field_type: 'l', field_value: 'ecommerce.purchase.actionField.revenue', children: [] },
        { field_name: 'shipping', field_type: 'l', field_value: 'ecommerce.purchase.actionField.shipping', children: [] },
        { field_name: 'tax', field_type: 'l', field_value: 'ecommerce.purchase.actionField.tax', children: [] },
        { field_name: 'affiliation', field_type: 'l', field_value: 'ecommerce.purchase.actionField.affiliation', children: [] },
        { field_name: 'coupon', field_type: 'l', field_value: 'ecommerce.purchase.actionField.coupon', children: [] },
        {
          field_name: 'items', field_type: 'a', field_value: 'ecommerce.purchase.products', children: [
            { field_name: 'item_id', field_type: 'l', field_value: 'ecommerce.purchase.products.id', children: [] },
            { field_name: 'item_name', field_type: 'l', field_value: 'ecommerce.purchase.products.name', children: [] },
            { field_name: 'item_brand', field_type: 'l', field_value: 'ecommerce.purchase.products.brand', optional: true, children: [] },
            { field_name: 'item_category', field_type: 'l', field_value: 'ecommerce.purchase.products.category', optional: true, children: [] },
            { field_name: 'item_variant', field_type: 'l', field_value: 'ecommerce.purchase.products.variant', optional: true, children: [] },
            { field_name: 'quantity', field_type: 'l', field_value: 'ecommerce.purchase.products.quantity', optional: true, children: [] },
            { field_name: 'price', field_type: 'l', field_value: 'ecommerce.purchase.products.price', optional: true, children: [] },
            { field_name: 'affiliation', field_type: 'l', field_value: 'ecommerce.purchase.products.affiliation', optional: true, children: [] },
            { field_name: 'coupon', field_type: 'l', field_value: 'ecommerce.purchase.products.coupon', optional: true, children: [] },
          ]
        }
      ]
    },
  ],

  cdp_template: 'purchase',
  cdp_fields: [

    { field_name: 'eventCategory', field_type: 'c', field_value: 'Ecommerce', children: [] },
    { field_name: 'eventAction', field_type: 'c', field_value: 'Purchase', children: [] },
    { field_name: 'eventLabel', field_type: 'l', optional: true, field_value: 'ecommerce.purchase.actionField.id', children: [] },
    { field_name: 'eventValue', field_type: 'l', optional: true, field_value: 'ecommerce.purchase.actionField.revenue', children: [] },

    { field_name: 'transactionId', field_type: 'l', field_value: 'ecommerce.purchase.actionField.id', children: [] },
    { field_name: 'transactionRevenue', field_type: 'l', field_value: 'ecommerce.purchase.actionField.revenue', children: [] },
    { field_name: 'transactionShipping', optional: true, field_type: 'l', field_value: 'ecommerce.purchase.actionField.shipping', children: [] },
    { field_name: 'transactionTax', optional: true, field_type: 'l', field_value: 'ecommerce.purchase.actionField.tax', children: [] },
    { field_name: 'transactionAffiliation', optional: true, field_type: 'l', field_value: 'ecommerce.purchase.actionField.affiliation', children: [] },
    { field_name: 'transactionCurrency', optional: true, field_type: 'l', field_value: 'ecommerce.purchase.actionField.coupon', children: [] },
  ],

  triggers: [
    { key: "ecommerce", lookup_type: "c", value: "purchase" },
    { key: "ecommerce.purchase.products", lookup_type: "lgte", value: 1 },
  ],

});


export const ecommerceAutocomplete = ecommerce_autocomplete;


const fb_purchase = {
  fb_template: 'purchase',
  fb_fields: [
    { field_name: 'event_name', field_type: 'c', field_value: 'Purchase', children: [] },
    { field_name: 'event_id', field_type: 'l', field_value: 'transaction_id', children: [] }, // event used to de-duplicate the event on the side of Facebook
    { field_name: 'user_data', field_type: 'l', field_value: 'userId', stitch: true },
    {
      field_name: 'custom_data', field_type: 'o', children: [
        { field_name: 'value', field_type: 'l', field_value: 'transaction_revenue', children: [] },
        { field_name: 'currency', field_type: 'l', field_value: 'transaction_currency', optional: true, children: [] },
        { field_name: 'order_id', field_type: 'l', field_value: 'transaction_id', optional: true, children: [] },
        {
          field_name: 'contents', field_type: 'a', field_value: 'products', optional: true, children: [
            { field_name: 'id', field_type: 'l', field_value: 'products.id', children: [] },
            { field_name: 'category', field_type: 'l', field_value: 'products.category', optional: true, children: [] },
            { field_name: 'quantity', field_type: 'l', field_value: 'products.quantity', children: [] },
            { field_name: 'price', field_type: 'l', field_value: 'products.price', children: [] },
          ]
        },
      ]
    },
  ]
}

const cdp_purchase = {
  cdp_template: 'purchase',
  cdp_fields: [
    { field_name: 'traceDockId', field_type: 'l', field_value: 'userId', children: [], stitch: true },
    { field_name: 'eventCategory', field_type: 'c', field_value: 'Ecommerce', children: [] },
    { field_name: 'eventAction', field_type: 'c', field_value: 'Purchase', children: [] },
    { field_name: 'eventLabel', field_type: 'l', optional: true, field_value: 'transaction_id', children: [] },
    { field_name: 'eventValue', field_type: 'l', optional: true, field_value: 'transaction_revenue', children: [] },

    { field_name: 'transactionId', field_type: 'l', field_value: 'transaction_id', children: [] },
    { field_name: 'transactionRevenue', field_type: 'l', field_value: 'transaction_revenue', children: [] },
    { field_name: 'transactionShipping', optional: true, field_type: 'l', field_value: 'transaction_shipping', children: [] },
    { field_name: 'transactionTax', optional: true, field_type: 'l', field_value: 'transaction_tax', children: [] },
    { field_name: 'transactionAffiliation', optional: true, field_type: 'l', field_value: 'transaction_affiliation', children: [] },
    { field_name: 'transactionCurrency', optional: true, field_type: 'l', field_value: 'transaction_currency', children: [] },
  ]
}

const server_side_transactions = []

// Commented this out on request of Jasmijn (CSM).
// In GA4, we have no difference between a testing event and a production event
// server_side_transactions.push({
//   button: 'Transaction - regular event for testing',
//   name: 'transaction',
//   endpoint: 'transaction',
//   template: 'event',
//   data: {
//     "env": "production",
//     "userId": "123ABC",
//     "transaction_id": "0001",
//     "transaction_revenue": "181.1",
//     "transaction_shipping": "6.5",
//     "transaction_tax": "12.1",
//     "transaction_affiliation": "optional",
//     "transaction_coupon": "optional",
//     "transaction_currency": "EUR",
//     "products": [
//       {
//         "id": "12345",
//         "name": "TD T-Shirt",
//         "price": 181.1,
//         "quantity": 1,
//         "brand": "optional",
//         "category": "optional",
//         "variant": "optional",
//         "coupon": "optional"
//       }
//     ],
//   },
//   unique_field: 'el',
//   fields: [
//     { field_name: 'cid', field_type: 'l', field_value: 'userId', children: [], stitch: true },
//     { field_name: 't', field_type: 'c', field_value: 'event', children: [] },
//     { field_name: 'ec', field_type: 'c', field_value: 'Ecommerce S2S', children: [] },
//     { field_name: 'ea', field_type: 'c', field_value: 'Purchase', children: [] },
//     { field_name: 'el', field_type: 'l', field_value: 'transaction_id', children: [] },
//     { field_name: 'ev', field_type: 'l', field_value: 'transaction_revenue', children: [], script: "function parse(field_value, field_name, inbound_data) {\n return parseInt(field_value) || 0\n}" }
//   ],
//   ga4_fields: [
//     { field_name: 'cid', field_type: 'l', field_value: 'userId', children: [], stitch: true },
//     { field_name: 'name', field_type: 'c', field_value: 'event', children: [] },
//     {
//       field_name: 'params', field_type: 'o', children: [
//         { field_name: 'transaction', field_type: 'l', field_value: 'transaction_id', children: [] },
//         { field_name: 'value', field_type: 'l', field_value: 'transaction_id', children: [] },
//         { field_name: 'currency', field_type: 'l', field_value: '', children: [] },
//         {
//           field_name: 'items', field_type: 'a', field_value: '', children: [
//             { field_name: 'item_id', field_type: 'l', field_value: '', children: [] },
//             { field_name: 'item_name', field_type: 'l', field_value: '', children: [] }
//           ]
//         }
//       ]
//     }
//   ],
//   ...fb_purchase,
//   ...cdp_purchase,
//   triggers: [],
// });



server_side_transactions.push({
  button: 'Transaction event (Ecommerce)',
  name: 'transaction',
  endpoint: 'transaction',
  template: 'product_purchase',
  ga4_template: 'product_purchase',
  data: {
    "env": "production",
    "userId": "123ABC",
    "transaction_id": "0001",
    "transaction_revenue": "181.1",
    "transaction_shipping": "6.5",
    "transaction_tax": "12.1",
    "transaction_affiliation": "optional",
    "transaction_coupon": "optional",
    "transaction_currency": "EUR",
    "products": [
      {
        "id": "12345",
        "name": "TD T-Shirt",
        "price": 181.1,
        "quantity": 1,
        "brand": "optional",
        "category": "optional",
        "variant": "optional",
        "coupon": "optional"
      }
    ],
  },
  unique_field: 'ti',
  fields: [
    { field_name: 'cid', field_type: 'l', field_value: 'userId', children: [], stitch: true },

    { field_name: 't', field_type: 'c', field_value: 'event', children: [] },
    { field_name: 'ec', field_type: 'c', field_value: 'Ecommerce', children: [] },
    { field_name: 'ea', field_type: 'c', field_value: 'Purchase', children: [] },
    { field_name: 'el', field_type: 'l', field_value: 'transaction_id', children: [] },
    { field_name: 'ev', field_type: 'l', field_value: 'transaction_revenue', children: [], script: "function parse(field_value, field_name, inbound_data) {\n return parseInt(field_value) || 0\n}" },

    { field_name: 'pa', field_type: 'c', field_value: 'purchase', children: [] },

    { field_name: 'ti', field_type: 'l', field_value: 'transaction_id', children: [] },
    { field_name: 'tr', field_type: 'l', field_value: 'transaction_revenue', children: [] },
    { field_name: 'ts', field_type: 'l', field_value: 'transaction_shipping', optional: true, children: [] },
    { field_name: 'tt', field_type: 'l', field_value: 'transaction_tax', optional: true, children: [] },
    { field_name: 'ta', field_type: 'l', field_value: 'transaction_affiliation', optional: true, children: [] },
    { field_name: 'tcc', field_type: 'l', field_value: 'transaction_coupon', optional: true, children: [] },
    { field_name: 'cu', field_type: 'l', field_value: 'transaction_currency', optional: true, children: [] },
    {
      field_name: 'pr', field_type: 'a', field_value: 'products', children: [
        { field_name: 'pr.id', field_type: 'l', field_value: 'products.id', children: [] },
        { field_name: 'pr.nm', field_type: 'l', field_value: 'products.name', optional: true, children: [] },
        { field_name: 'pr.br', field_type: 'l', field_value: 'products.brand', optional: true, children: [] },
        { field_name: 'pr.ca', field_type: 'l', field_value: 'products.category', optional: true, children: [] },
        { field_name: 'pr.va', field_type: 'l', field_value: 'products.variant', optional: true, children: [] },
        { field_name: 'pr.pr', field_type: 'l', field_value: 'products.price', optional: true, children: [] },
        { field_name: 'pr.qt', field_type: 'l', field_value: 'products.quantity', optional: true, children: [] },
        { field_name: 'pr.cc', field_type: 'l', field_value: 'products.coupon', optional: true, children: [] },
      ]
    },
  ],
  ga4_fields: [
    { field_name: 'cid', field_type: 'l', field_value: 'userId', children: [], stitch: true },
    { field_name: 'name', field_type: 'c', field_value: 'purchase', children: [] },
    {
      field_name: 'params', field_type: 'o', children: [
        { field_name: 'transaction_id', field_type: 'l', field_value: '', children: [] },
        { field_name: 'currency', field_type: 'l', field_value: '', children: [] },
        { field_name: 'value', field_type: 'l', field_value: '', children: [] },
        { field_name: 'shipping', field_type: 'l', field_value: '', optional: true, children: [] },
        { field_name: 'tax', field_type: 'l', field_value: '', optional: true, children: [] },
        { field_name: 'coupon', field_type: 'l', field_value: '', optional: true, children: [] },
        {
          field_name: 'items', field_type: 'a', field_value: '', children: [
            { field_name: 'item_id', field_type: 'l', field_value: '', children: [] },
            { field_name: 'item_name', field_type: 'l', field_value: '', children: [] },
            { field_name: 'item_brand', field_type: 'l', field_value: '', optional: true, children: [] },
            { field_name: 'item_category', field_type: 'l', field_value: '', optional: true, children: [] },
            { field_name: 'item_category2', field_type: 'l', field_value: '', optional: true, children: [] },
            { field_name: 'item_category3', field_type: 'l', field_value: '', optional: true, children: [] },
            { field_name: 'item_category4', field_type: 'l', field_value: '', optional: true, children: [] },
            { field_name: 'item_category5', field_type: 'l', field_value: '', optional: true, children: [] },
            { field_name: 'item_list_id', field_type: 'l', field_value: '', optional: true, children: [] },
            { field_name: 'item_list_name', field_type: 'l', field_value: '', optional: true, children: [] },
            { field_name: 'item_variant', field_type: 'l', field_value: '', optional: true, children: [] },
            { field_name: 'quantity', field_type: 'l', field_value: '', optional: true, children: [] },
            { field_name: 'price', field_type: 'l', field_value: '', optional: true, children: [] },
            { field_name: 'discount', field_type: 'l', field_value: '', optional: true, children: [] },
            { field_name: 'affiliation', field_type: 'l', field_value: '', optional: true, children: [] },
            { field_name: 'coupon', field_type: 'l', field_value: '', optional: true, children: [] },
            { field_name: 'location_id', field_type: 'l', field_value: '', optional: true, children: [] },
            { field_name: 'index', field_type: 'l', field_value: '', optional: true, children: [] }
          ]
        }
      ]
    },
  ],
  ...fb_purchase,
  ...cdp_purchase,
  triggers: [],
});

export const ExternalEventsAutocomplete = server_side_transactions;

