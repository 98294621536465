import React from "react";
import PropTypes from 'prop-types';

import {Tag} from "../../../../components/tag/tag";
import {BlankCard} from "../../../../components/cards/blankCard";
import {Input} from "../../../../components/input/input";
import {Table} from "../../../../components/table/table";
import {colors} from "../../../../theme/colors";
import {Icon, bin} from "../../../../components/icon/icon";


const possibleLookupElements = [
  {key: 'a', value: 'attribute'},
  {key: 'c', value: 'class'},
  {key: 'i', value: 'id'},
  {key: 's', value: 'src'},
  {key: 'h', value: 'href'},
  {key: 't', value: 'text'},
  {key: 'u', value: 'page_url'},
];

const possibleLookupTypes = [
  {key: 'c', value: 'contains'},
  {key: 'nc', value: 'does not contain'},
  {key: 'e', value: 'equals'},
  {key: 'ne', value: 'does not equal'},
  {key: 'sw', value: 'starts with'},
  {key: 'ew', value: 'ends with'},
  {key: 'lgte', value: 'length >='},
  {key: 'gte', value: '>='},
  {key: 'gt', value: '>'},
  {key: 'lte', value: '<='},
  {key: 'lt', value: '<'},
];

export const validate_triggers = (triggers) => {
  // validates triggers, returns invalidObjects
  let invalidObjects = {};

  triggers.map((row, idx) => {

    // validates triggers, is choices restricted to list or more than 0
    if (row.action !== 'delete') {
      if (!possibleLookupElements.some((obj) => obj.key === row.lookup_element)) {
        invalidObjects[`${idx}_lookup_element`] = true
      }
      if (!possibleLookupTypes.some((obj) => obj.key === row.lookup_type)) {
        invalidObjects[`${idx}_lookup_type`] = true
      }
      if (row.lookup_value.length === 0) {
        invalidObjects[`${idx}_lookup_value`] = true
      }
    }
    return true
  });

  return invalidObjects
};

export class CardClickEventTriggers extends React.Component {

  static propTypes = {
    triggers: PropTypes.array,
    invalidObjects: PropTypes.any,
    readOnly: PropTypes.bool,
    onUpdate: PropTypes.func,
  };

  static defaultProps = {
    triggers: [],
    invalidObjects: {},
    readOnly: false,
    onUpdate: (data) => console.log('updating data: ', data),
  };

  constructor(props, context) {
    super(props, context);

    this.state = {
      newData: props.triggers
    };
  }

  header = [
    {key: 'lookup_element', value: 'element', help: null},
    {key: 'lookup_type', value: 'action', help: null},
    {key: 'lookup_value', value: 'value', help: null},
    {},
    {},
  ];

  _update_row(idx, newObject) {
    // update element and update element
    let newData = [...this.state.newData];
    newData[idx] = newObject;
    this.setState({newData: newData});
    this.props.onUpdate(newData);
  }

  componentWillUpdate(nextProps, nextState, nextContext) {
    if (nextProps.triggers !== this.state.newData) {
      this.setState({newData: nextProps.triggers})
    }
  }

  render({invalidObjects, readOnly} = this.props) {
    return (

      <BlankCard
        collapsable={true}
        title='Conditions'
        subtitle='This click event will only be activated when it meets all the following conditions.'
        padding={false}>

        <Table header={this.header}
               onAddNew={() => {
                 this._update_row(this.state.newData.length, {
                   lookup_element: 'a',
                   lookup_type: 'c',
                   lookup_value: '',
                   action: 'add'
                 })
               }}>

          {this.state.newData.length > 0 && this.state.newData.map((row, idx) => (

            <tr key={idx} className={row.action === 'delete' ? 'delete' : ''}>
              {/* NOTE: rows are build up custom */}

              <td>
                <Input
                  type="text"
                  readOnly={false}
                  initial={row.lookup_element}
                  restrict={true}
                  choices={possibleLookupElements}
                  isInvalid={invalidObjects[`${idx}_lookup_element`]}
                  onChange={(value) => this._update_row(idx, {
                    ...row,
                    lookup_element: value,
                    action: row.action === 'add' ? 'add' : 'update'
                  })}
                />
              </td>

              <td>
                <Input
                  type="text"
                  readOnly={false}
                  initial={row.lookup_type}
                  restrict={true}
                  choices={possibleLookupTypes}
                  isInvalid={invalidObjects[`${idx}_lookup_type`]}
                  onChange={(value) => this._update_row(idx, {
                    ...row,
                    lookup_type: value,
                    action: row.action === 'add' ? 'add' : 'update'
                  })}
                />
                {invalidObjects[`${idx}_lookup_type`] && (
                  <p className='small alert'>
                    Please select a valid choice
                  </p>
                )}
              </td>

              <td>
                <Input
                  type="text"
                  readOnly={false}
                  initial={row.lookup_value}
                  isInvalid={invalidObjects[`${idx}_lookup_value`]}
                  onChange={(value) => this._update_row(idx, {
                    ...row,
                    lookup_value: value,
                    action: row.action === 'add' ? 'add' : 'update'
                  })}
                />
              </td>

              <td width="110px">
                {row.action === 'add' && <Tag type='new'>new</Tag>}
                {row.action === 'update' && <Tag type='update'>updated</Tag>}
                {row.action === 'delete' && <Tag type='alert'>delete</Tag>}
              </td>

              <td>
                <Icon size={24} className='clickable' icon={bin} color={colors.brand.primary}
                      onClick={() => this._update_row(idx, {...row, action: row.action !== 'delete' ? 'delete' : 'update'})}/>
              </td>

            </tr>
          ))}
        </Table>
      </BlankCard>
    )
  }
}
