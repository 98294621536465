import React from "react";
import PropTypes from 'prop-types';

import {BlankCard} from "../../../../components/cards/blankCard";
import {Input} from "../../../../components/input/input";
import {DataFieldObject} from "../../../../components/dataFieldObject/dataFieldObject";
import {gaTemplateChoices, gaTemplates} from "../../../../lib/google_analytics/templates";
import {eye, eye_active, Icon} from "../../../../components/icon/icon";
import {colors} from "../../../../theme/colors";
import {DataFieldPreview} from "../../../../components/dataFieldObject/dataFieldPreview";
import {testFieldMapping} from "../../../../redux/rules/rules.actions";
import {Popup, PopupPosition} from "../../../../components/popup";
import {gaFieldDescriptions, gaOtherFields} from "../../../../lib/google_analytics/fieldDescriptions";
import {gaPossibleFields} from "../../../../lib/google_analytics/possibleFields";


export const validate_dataLayer_fields = (eventfields, strict=true) => {
  // validates triggers, returns invalidObjects
  let invalidObjects = {};

  eventfields && eventfields.map((row, idx) => {

    // validates triggers, is choices restricted to list or more than 0
    if (row.action !== 'delete') {
      if (row.field_name.length === 0) {
        invalidObjects[`${idx}_field_name`] = true
      } else if (row.field_name.substr(0, 2) !== 'cd') {
        // either a mapping can be that it is strict, e.g. in case of GA/FB, in case of CDP it can be all values
        if (strict && !gaFieldDescriptions[row.field_name]) {
          invalidObjects[`${idx}_${row.field_name}_field_name`] = true
        }
      }
      if (row.field_type.length === 0) {
        invalidObjects[`${idx}_${row.field_name}_field_type`] = true
      }
      if (!row.isReadOnly && (row.field_type !== 'o' && (!row.field_value || row.field_value.length === 0))) {
        invalidObjects[`${idx}_${row.field_name}_field_value`] = true
      }
    }
    return true
  });

  return invalidObjects
};

export class CardDataLayerGAFields extends React.Component {

  static propTypes = {
    fields: PropTypes.array,
    data: PropTypes.any,
    invalidObjects: PropTypes.any,
    availableFieldPaths: PropTypes.array,
    readOnly: PropTypes.bool,
    onUpdate: PropTypes.func,
    setTemplate: PropTypes.func,
    template: PropTypes.string,
    domain: PropTypes.string,
  };

  static defaultProps = {
    template: '',
    fields: [],
    data: {},
    invalidObjects: {},
    availableFieldPaths: [],
    readOnly: false,
    domain: '',
    onUpdate: (data) => console.log('updating data: ', data),
    setTemplate: (template) => console.log('setting template: ', template),
  };

  constructor(props, context) {
    super(props, context);
    this.state = {
      tab: 'fields'
    };
  }

  _updateTemplate(template) {

    // updating template
    // todo: don't throw away fields if you make a selection. Now we just refresh them
    this.props.onUpdate([], () => {
      this.props.onUpdate(gaTemplates[template], () => this.props.setTemplate(template));
    })
  }

  render({invalidObjects, collapsed, availableFieldPaths, fields, data, readOnly, template, domain} = this.props) {
    return (

      <BlankCard
        collapsable={true}
        initialCollapsed={collapsed}
        title='To Google Analytics'
        subtitle='Determine what data should be forwarded.'
        padding={false}>

        <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between'}}>
          {this.state.tab !== 'preview' && <Input
            style={{padding: '0 24px', maxWidth: 250}}
            type="text"
            label="Select initial template"
            restrict={true}
            readOnly={false}
            choices={gaTemplateChoices}
            initial={template}
            onChange={(template) => this._updateTemplate(template)}
            />}


          <Popup
            content={
              <p style={{width: 200}}>Preview your forwarded data here. It is calculated based on your raw data as found on the left side.</p>
            }
            initialOpen={false}
            stayOpen={false}
            position={PopupPosition.LEFT_CENTER}
          >
            <div className='clickable' style={{padding: '10px 27px 5px 10px', marginLeft: 20}}  onClick={() => this.setState({tab: this.state.tab === 'fields' ? 'preview' : 'fields'})}>
              <Icon size={25} icon={this.state.tab === 'preview' ? eye_active : eye} color={colors.brand.secondary}/>
            </div>
          </Popup>
        </div>

        {this.state.tab === 'fields' && (
          <DataFieldObject
            invalidObjects={invalidObjects}
            readOnly={false}

            availableFieldPaths={availableFieldPaths}
            onUpdate={(data) => this.props.onUpdate(data)}

            possibleFields={gaPossibleFields}
            fieldDescriptions={gaFieldDescriptions}

            fields={fields}
            parent=''
            level={0}
          />
        )}

        {this.state.tab === 'preview' && (
          <div style={{minHeight: 510, padding: 20}}>
            <DataFieldPreview
              testFieldMapping={testFieldMapping}
              domain={domain}
              fields={fields}
              data={data}

              type='ga'
              FieldDescriptions={gaFieldDescriptions}
              OtherFields={gaOtherFields}
            />
          </div>
        )}


      </BlankCard>
    )
  }
}
