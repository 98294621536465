import React from 'react';
import {connect} from 'react-redux';
import {PropTypes} from 'prop-types';
import SectionTitle from '../SectionTitle';

let AgencyDemoSection = ({account}) => {
  return (
    <>
      <SectionTitle
        title={`Dear ${account.profile.first_name}, we are going to contact you personally.`}
      />
      <p>Because you work for an online marketing agency, we are going to contact
        you via <b className='primary'>{account.profile.email}</b> to arrange a personal demo and tell you more about our partner program.
      </p>
      <p className='spacer'>During this demo, we will directly onboard your agency to TraceDock and
        we will show you how you can onboard your own clients to TraceDock via
        our easy-to-use user portal.
      </p>
      <p>Do you have a question in the meantime?</p>
      <p className='spacer'>Don’t hesitate to contact us via <a className='link' rel="noopener" title='Contact us' href='mailto:hello@tracedock.com'>hello@tracedock.com</a>.</p>
      <p className='black spacer'>Speak to you soon!</p>
    </>
  )
};


AgencyDemoSection.propTypes = {
  account: PropTypes.any
};

const mapStateToProps = (state) => ({
  account: state.account
});

export default AgencyDemoSection = connect(mapStateToProps, undefined)(AgencyDemoSection);