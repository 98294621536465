import {connect} from 'react-redux';
import {accountActions, persistActions} from "../../redux/actions";
import {DomainSelectorComponent} from "./domainSelector.component";


const mapStateToProps = (state) => {
  return {
    domain: state.persist.domain,
    all_domains: state.persist.all_domains,
    account: state.account,
    demo: state.persist.demo ? state.persist.demo : {},
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    SetDomain: (data) => dispatch(persistActions.SetDomain(data)),
    getProfile: () => dispatch(accountActions.getProfile()),
    setDemo: (d) => dispatch(persistActions.setDemo(d)),
  }
};

const connectedContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(DomainSelectorComponent);

export {connectedContainer as DomainSelector};
