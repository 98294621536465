// production server settings
export default {
  mode: 'develop',
  debug_redux: false,
  domain: 'tracedock.com',
  web_url: 'https://portal.tracedock.com',
  api_url: 'https://dev-api.tracedock.com',
  td_script: 'https://alpha.tracedock.com/maas.js',
  td_serverside: 'https://alpha.tracedock.com/api/transaction',
  client_secret: 'yZQRXby3AfUAPzmYPVjZereUL',
  stripe_key: 'pk_live_7I6DXl7nVx7zTlC1g5WVQnKE0090lAPMQ7',
  sentry_key: 'https://a39140149e7348c9a840f88768f2556a@sentry.io/4267016',
}
