import React from 'react';
import styles from './sectionTitle.module.scss';
import {PropTypes} from 'prop-types';

const SectionTitle = ({title, subtitle}) => {
  return (
    <div className={styles.section_title_wrapper}>
      <h2 className={styles.section_title}>{title}</h2>
      {subtitle && <h6 className='spacer'>{subtitle}</h6>}
    </div>
  )
};

SectionTitle.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.any
};


export default SectionTitle;
