import React from "react";
import {connect} from "react-redux";

import {Loader} from "../../../components/loader/loader";
import {Table} from "../../../components/table/table";
import {Input} from "../../../components/input/input";
import {Tag} from "../../../components/tag/tag";
import {bin, Icon, info} from "../../../components/icon/icon";
import {colors} from "../../../theme/colors";
import {BlankCard} from "../../../components/cards/blankCard";
import {updateIfNeeded} from "../../../lib/helpers";
import {rulesActions} from "../../../redux/actions";
import {PageLayout} from "../../../components/layout/pageLayout";
import {Button} from "../../../components/button/button";
import {TagOrInput} from "../../../components/tagOrInput/tagOrInput";

let cookie_names = [
  {key: '_ga', value: 'Google Analytics'},
  {key: '_gcl_au', value: 'Google Ads pixel'},
  {key: '_fbp', value: 'Facebook'},
  {key: '_uetvid', value: 'Bing'},
  {key: '', value: 'Other'},
];
let preselect_cookies = ['_ga', '_gcl_au', '_fbp', '_uetvid'];

class CookiesPage extends React.Component {

  constructor(props, context) {
    super(props, context);

    this.state = {
      newData: (this.props.rules.dev && this.props.rules.dev.cookies && this.props.rules.dev.cookies.length) > 0 ? [...this.props.rules.dev.cookies] : [],
      isUpdated: false,
      invalidObjects: {}
    };

    //force refresh on each visit, else it's buggy
      this.props.getDev({uuid: this.props.persist.domain.id})
      this.setState({newData: this.props.rules.dev && this.props.rules.dev.cookies && this.props.rules.dev.cookies})
  }

  header = [
    {key: 'cookie_name', value: 'Cookie Name', help: null},
    {key: 'cookie_key', value: 'Key', help: null},
    {key: 'days', value: 'Days valid', help: null},
    {key: 'enabled', value: 'Enabled', help: null},
    {},
    {},
  ];

  _update_row(idx, newObject) {
    // update element and update element
    let newData = [...this.state.newData];
    newData[idx] = newObject;
    this.setState({isUpdated: true, newData: newData})
  }

  _validate_and_save() {

    let invalidObjects = {};
    let newCookies = [];
    let cookieKeys = [];
    this.state.newData.map((row, idx) => {
      // check email if add

      if (row.action !== 'delete') {

        if (row.cookie.length === 0) {
          invalidObjects[`${idx}_cookie`] = 'This field cannot be empty';
        } else {
          if (cookieKeys.indexOf(row.cookie) > -1) invalidObjects[`${idx}_cookie`] = 'The cookie is already defined above';
          cookieKeys.push(row.cookie);
        }
        if (row.days < 1 || row.days > 180) invalidObjects[`${idx}_days`] = 'Select a value between 1 and 180 days';

        newCookies.push({cookie: row.cookie, days: parseInt(row.days), enabled: row.enabled, cookie_name: row.cookie_name});
      }
      return true
    });

    // only save if no invalid objects
    if (Object.entries(invalidObjects).length === 0) {

      this.setState({newData: []}, () => {
        this.props.updateDev({
          id: this.props.persist.domain.id,
          cookies: newCookies,
        }).then((domain) => {
            this.setState({isUpdated: false, newData: newCookies});
          }
        )
      });

    }

    this.setState({invalidObjects: invalidObjects})
  }

  _reset_data() {
    this.setState({
      newData: this.props.rules.dev && this.props.rules.dev.cookies && this.props.rules.dev.cookies && this.props.rules.dev.cookies.length > 0 ? [...this.props.rules.dev.cookies] : [],
      isUpdated: false,
      invalidObjects: {}
    })
  }

  render({persist, rules, publishBuild} = this.props) {

    let isLoading = rules.isPending;

    return (
      <PageLayout title={`${persist.domain.state === 'implementing' ? 'Step 2: Set up cookie protection' : 'Cookie protection'}`}
                  history={this.props.history}
                  date_last_build={rules.date_last_build}
                  requires_publish={rules.requires_publish}
                  publishPending={isLoading}
                  hasPublish={!isLoading && persist.domain.state === 'live'}
                  onPublish={(message) => publishBuild({uuid: persist.domain.id, message: message})}
                  domain={persist.domain}>

        {isLoading && (<Loader style={{marginTop: '20vh', marginLeft: '48%'}}/>)}

        {!isLoading && persist.domain.state === 'implementing' && (
          <BlankCard>
            <div className='row' style={{alignItems: 'center', paddingBottom: 8}}>
              <Icon icon={info} color={colors.brand.primary} size={20}/>
              <p className='bold' style={{marginLeft: 24}}>
                The cookie for your Google Analytics is already protected: they will be extended to 180 days.
                If you want to protect more cookies, for example cookies for Facebook or Google Ads, click on "add cookie".</p>
            </div>
          </BlankCard>
        )}

        {!isLoading && (
          <BlankCard
            title='Cookies'
            subtitle='Select which cookies you want to protect.'
            padding={false}
            onReset={() => this._reset_data()}
            onSave={() => this._validate_and_save()}
            isLoading={isLoading}
            isUpdated={this.state.isUpdated}>
            <Table header={this.header}
                   addDescription='Add cookie'
                   onAddNew={() => this._update_row(this.state.newData.length,
                     {cookie: '', days: 180, enabled: 1, cookie_name: ''})}>

              {this.state.newData.length > 0 && this.state.newData.map((row, idx) => (

                <tr key={idx} className={row.action === 'delete' ? 'delete' : ''}>
                  {/* NOTE: rows are build up custom */}

                  <td>
                    <Input
                      type="text"
                      placeholder='cookie name'
                      readOnly={false}
                      initial={row.cookie_name}
                      choices={cookie_names}
                      restrict={false}
                      onChange={(value) => {
                        // cookie name can also be a custom, only update cookie if preselected is choosen
                        let new_cookie = preselect_cookies.indexOf(value) > -1 ? value : row.cookie;
                        this._update_row(idx, {
                          ...row,
                          cookie_name: value === '' ? 'Other' : value,
                          cookie: new_cookie,
                          action: row.action === 'add' ? 'add' : 'update'
                        })
                      }}
                    />
                  </td>

                  <td>
                    <TagOrInput
                      placeholder='cookie key'
                      showTagIfUnknown={true}
                      value={row.cookie}
                      isInvalid={this.state.invalidObjects[`${idx}_cookie`]}
                      onChange={(value) => this._update_row(idx, {
                        ...row,
                        cookie: value,
                        action: row.action === 'sadd' ? 'add' : 'update'
                      })}
                    />
                    {this.state.invalidObjects[`${idx}_cookie`] && (
                      <p className='small alert'>
                        {this.state.invalidObjects[`${idx}_cookie`]}
                      </p>
                    )}
                  </td>

                  <td>
                    <Input
                      type="number"
                      placeholder='days'
                      readOnly={false}
                      initial={row.days}
                      isInvalid={this.state.invalidObjects[`${idx}_days`]}
                      onChange={(value) => this._update_row(idx, {
                        ...row,
                        days: value,
                        action: row.action === 'add' ? 'add' : 'update'
                      })}
                    />
                    {this.state.invalidObjects[`${idx}_days`] && (
                      <p className='small alert'>
                        {this.state.invalidObjects[`${idx}_days`]}
                      </p>
                    )}
                  </td>

                  <td>
                    <Input
                      style={{maxWidth: 300}}
                      initial={row.enabled}
                      type='text'
                      restrict={true}
                      choices={[{key: 1, value: 'enabled'}, {key: 0, value: 'disabled'}]}
                      isInvalid={this.state.invalidObjects['is_active']}
                      onChange={(value) => this._update_row(idx, {
                        ...row,
                        enabled: value,
                        action: row.action === 'add' ? 'add' : 'update'
                      })}
                      readOnly={false}
                    />
                  </td>

                  <td width="110px">
                    {row.action === 'add' && <Tag type='new'>new</Tag>}
                    {row.action === 'update' && <Tag type='update'>updated</Tag>}
                    {row.action === 'delete' && <Tag type='alert'>delete</Tag>}
                  </td>

                  <td>
                    <Icon size={24} className='clickable' icon={bin} color={colors.brand.primary}
                          onClick={() => this._update_row(idx, {...row, action: row.action !== 'delete' ? 'delete' : 'update'})}/>
                  </td>

                </tr>
              ))}
            </Table>

          </BlankCard>
        )}

        {!isLoading && persist.domain.state === 'implementing' && persist.domain.pricing_plan !== 'itp_only' && (
          <div style={{maxWidth: 950, display: 'flex', flexDirection: 'row', justifyContent: 'center'}}>
            <Button
              style={{marginTop: 24, width: 200, marginLeft: '42px auto 24px auto'}}
              title='Continue to step 3: Events'
              onClick={() => this.props.history.push('events')}
              backgroundColor={colors.brand.blue110}
            />
          </div>
        )}

      </PageLayout>
    );
  }
}

const mapStateToProps = (state) => ({
  rules: state.rules,
  persist: state.persist,
});

const mapDispatchToProps = (dispatch) => {
  return {
    getDev: (data) => dispatch(rulesActions.getDev(data)),
    updateDev: (data) => dispatch(rulesActions.updateDev(data)),
    publishBuild: (d) => dispatch(rulesActions.publishBuild(d)),
  }
};

export default CookiesPage = connect(mapStateToProps, mapDispatchToProps)(CookiesPage);
