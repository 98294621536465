
export const deviceState = {

	alertShown: false,
	alertTitle: '',
	alertMessage: '',
	alertDetails: '',
	alertButtons: [],

	wiki: {},

	isLoading: false,
	docs_menu_updated: null,
	docs_menu: [

	],
};
