import React from "react";

import {Input} from "../../components/input/input";
import {Button} from "../../components/button/button";
import {colors} from "../../theme/colors";

import {api} from "../../lib/api";
import {ModalLayout} from "../../components/modalLayout/modalLayout";

class PasswordResetPage extends React.Component {

  constructor(props, context) {
    super(props, context);
    this.state = {
      initial: props.history.location.state ? props.history.location.state.email : '',
      email: props.history.location.state ? props.history.location.state.email : '',
      isLoading: false,
      completed: false
    };
  }

  render({history} = this.props) {
    return (
      <div>

        <ModalLayout
          title={this.state.completed ? 'Email sent' : 'Reset your password' }
          subTitle={this.state.completed ? '' : 'We will fix that for you.'}
          onClose={() => history.push('/')}>
            {this.state.completed
              ?
              <p>Please check your <span className='bold'>{this.state.email}</span> inbox for instructions on how to reset your password.</p>
              :
              <div style={{width: 300}}>
                <p className='spacer'>To reset your password, please enter the email address you used to sign up for TraceDock.</p>
                <Input
                  backgroundColor={colors.func.background}
                  style={{width: 200}}
                  ref={this.email}
                  label="Email"
                  type="email"
                  focus={true}
                  initial={this.state.initial}
                  onChange={(email) => this.setState({email})}
                  onSubmit={() => this._getLink()}
                />

                <Button
                  style={{marginTop: 12, width: 200}}
                  isLoading={this.state.isLoading}
                  onClick={() => this._getLink()}
                  title='Get reset link'

                />
              </div>
            }
        </ModalLayout>
      </div>
    );
  }

  _getLink() {
    if (!this.state.email) {
      alert('Please enter your email')
    } else {
      this.setState({isLoading: true});

      api.post('auth/reset_password.json', {email: this.state.email})
        .then((data) => {
          this.setState({isLoading: false, completed: true});
        })
    }
  }
}

export default PasswordResetPage
