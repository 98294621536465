import React from "react";
import PropTypes from 'prop-types';
import {SideModal} from "../../../components/sideModal/sideModal";
import {BillingSection} from "./billingSection";
import {InputCard} from "../../../components/cards/inputCard";
import {DomainSection} from "./domainSection";
import {planData} from "../../../lib/priceData";


export class CreditorModal extends React.Component {

  static propTypes = {
    creditor: PropTypes.any,
    show: PropTypes.bool,
    onClose: PropTypes.func,
    onSave: PropTypes.func,
    onAddDomain: PropTypes.func,
    gotoImplementation: PropTypes.func,
    gotoLive: PropTypes.func,
    user_email: PropTypes.string,
    maxWidth: PropTypes.number,
    level: PropTypes.number,
  };

  static defaultProps = {
    creditor: {},
    show: false,
    maxWidth: null,
    level: 1,
  };

  constructor(props, context) {
    super(props, context);
    this.state = {

      currentCreditor: {
        ...props.creditor,
        price_confirmed: props.creditor.price_confirmed ? props.creditor.price_confirmed.split('T')[0] : 'not yet'
      },
      isUpdated: false,
      invalidObjects: {},
    };
  }

  render({show, readOnly, onClose, maxWidth, level} = this.props) {
    return (

      <SideModal
        hasSave={false}
        level={level}
        maxWidth={maxWidth}
        title={`Editing "${this.state.currentCreditor.company_name}"`}
        show={show} onClose={() => onClose()}>

        <DomainSection
          user_email={this.props.user_email}
          admin={this.state.currentCreditor}
          inviteAdmin={(data) => this.props.inviteAdmin(data)}
          gotoImplementation={(data) => this.props.gotoImplementation(data)}
          gotoLive={(data) => this.props.gotoLive(data)}
          onAddDomain={(domains, callback) => {
            this.props.onAddDomain({
              domains: domains,
              company_uuid: this.state.currentCreditor.uuid,
              as_agent: true,
            }).then((new_domains) => {
              this.setState({
                showNewModal: false,
                currentCreditor: {
                  ...this.props.creditor,
                  domains: new_domains ? new_domains.concat(this.state.currentCreditor.domains) : this.state.currentCreditor.domains
                }
              });
              if (callback) {
                callback()
              }
            });
          }}

        />

        <InputCard
          title='Price Plan'
          subtitle='Set the company price plan'
          onSave={(data) => this.props.onSave({uuid: this.state.currentCreditor.uuid, creditor: data})}
          initialData={this.state.currentCreditor}
          readOnly={false}
          objects={[

            {
              key: 'price_confirmed',
              label: 'Approval date',
              type: 'text',
              readOnly: true,
              help: `Date that the customer approved the price plan.`,
            },

            {
              key: 'flat_fee',
              label: 'Monthly fee',
              choices: planData,
              type: 'text',
              restrict: false,
              validator: (value) => parseInt(value) >= 99,
              errorMessage: 'Please choose a valid monthly fee.',
              optional: false,
              readOnly: this.state.currentCreditor.state !== 'implementing',
              help: `${
                this.state.currentCreditor.state !== 'implementing'
                  ? 'To update the price plan of creditors that are live please contact support@tracedock.com.'
                  : 'Select an available plan, or enter a custom , in €.'
                }`,
            },

            {
              key: 'trial_days',
              label: 'Number of free trial days',
              type: 'number',
              readOnly: true,
              hide: this.state.currentCreditor.state === 'live',
              help: 'The free trial period starts after the setup is complete. When the first cookies are extended or the first events are sent to Google Analytics.',
            },

          ]}
        />

        <BillingSection
          creditor={this.state.currentCreditor}
          readOnly={false}
          uuid={this.state.currentCreditor.uuid}
          updateCreditor={this.props.onSave}
        />

      </SideModal>
    );
  }
}

