import {combineReducers} from 'redux';

import {persistReducer} from "./persist/persist.reducer";
import {accountReducer} from "./account/account.reducer";
import {deviceReducer} from "./device/device.reducer";
import {dashboardReducer} from "./dashboard/dashboard.reducer";
import {rulesReducer} from "./rules/rules.reducer";
import {cmAccountReducer} from "./cmaccount/cmAccount.reducer";


export const resetNonPersistedStates = (dispatch) => {
  // convenience function used everywhere where you need to reset states
  localStorage.clear();
  dispatch({
    type: 'RESET_DASHBOARD_STATE'
  });
  dispatch({
    type: 'RESET_ACCOUNT_STATE'
  });
  dispatch({
    type: 'RESET_RULES_STATE'
  });
};


export const rootReducer = combineReducers({
  persist: persistReducer,
  account: accountReducer,
  cmaccount: cmAccountReducer,
  device: deviceReducer,
  dashboard: dashboardReducer,
  rules: rulesReducer
});
