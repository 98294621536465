import * as Sentry from '@sentry/browser';
import packageInformation from '../../package.json';

export const setupSentry = (token, environment) => {
  // Init sentry if enables (config.sentry_key is filled)
  if (token) {
    Sentry.init({
      dsn: token,
      environment: environment,
      release: packageInformation
    });
  }
};


export const identifyUser = (token, username) => {
  // Init sentry if enables (config.sentry_key is filled)
  if (token) {
    Sentry.configureScope((scope) => {
      scope.setUser({username: username});
    });
  }
};