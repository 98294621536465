import React, {memo} from 'react';
import {PropTypes} from 'prop-types';
import styles from './field.module.scss';

const LabelField = ({title}) => {
  return (
    <div className={styles.labelField}>
      <p>{title}</p>
    </div>
  )
};

LabelField.propTypes = {
  title: PropTypes.string
};

export default memo(LabelField);
