import React from "react";
import {WikiRender} from "../../components/wiki/wikiRender";
import {ModalLayout} from "../../components/modalLayout/modalLayout";

class SecurityPage extends React.Component {

  render({history, persist, account} = this.props) {
    return (
      <div>

        <ModalLayout width='800px' withLogo={true}>

          <h2>Responsible disclosure of security vulnerabilities</h2>
          <h6 style={{marginBottom: 42}}>TraceDock's policy on rewarding disclosing bugs</h6>

          <WikiRender path='privacy/security-disclosure'/>

        </ModalLayout>
      </div>
    );
  }
}

// no redux
export default SecurityPage;
