import React, {useCallback} from 'react';
import {connect} from 'react-redux';
import {PropTypes} from 'prop-types';
import SectionTitle from '../SectionTitle';
import styles from '../sectionContainer.module.scss';
import {Form, InputField} from '../../../../components/form';
import accountActions from '../../../../redux/actions';
import {trackEvent} from "../../../../lib/trackers";
import {isEmailValidator, isRequiredValidator} from '../../../../lib/validators';

let LoginSection = ({goNext, logIn, history, account, persist}) => {
  const onConfirm = useCallback(
    async (data) => {

      await logIn(data);

      // wait for result, if correct navigate onward
      if (persist.isAuthenticated) {
        this.props.history.push('/');

        trackEvent('customer', 'login', 'success', 0, false, true);
        window.dataLayer.push({
          'event': 'LoginSuccess',
          'formAction': 'login'
        });
      }
    },
    [goNext, logIn]
    )
  ;

  return (
    <div className={styles.section_content} style={{marginTop: 104}}>
      <SectionTitle
        title='Log in to the TraceDock User Portal'
        subtitle={<>Need a TraceDock account? <span
          style={{textDecoration: 'underline'}} className='link'
          onClick={() => history.push('/get_started')}>Sign up here</span></>}
      />
      <Form
        buttonText='Login'
        isLoading={account.isPending}
        onSave={onConfirm}
        validators={{
          email: isEmailValidator,
          password: isRequiredValidator
        }}
      >
        <InputField name='email' label='Work email'/>
        <InputField name='password' label='Password' type='password' enterIsSubmit={true}/>
      </Form>

      <p className='small link bigSpacer' style={{textAlign: 'center'}}
         onClick={() => history.push('/reset_password')}>Forgot password?</p>
    </div>
  )
};

LoginSection.propTypes = {
  goNext: PropTypes.func,
  login: PropTypes.func,
  history: PropTypes.any
};

const mapDispatchToProps = (dispatch) => {
  return {
    // logIn: (data) => dispatch(accountActions.logIn(data))
  }
};

const mapStateToProps = (state) => ({
  account: state.account,
  persist: state.persist,
});

export default LoginSection = connect(mapStateToProps, mapDispatchToProps)(LoginSection);
