import React from "react";
import {connect} from "react-redux";

import {Input} from "../../components/input/input";
import {Button} from "../../components/button/button";
import {ModalLayout} from "../../components/modalLayout/modalLayout";
import {setAlertDialog} from "../../components/alert/alert";

import {colors} from "../../theme/colors";
import {accountActions} from '../../redux/actions';
import {identify, trackEvent} from "../../lib/trackers";


class PasswordLinkPage extends React.Component {

  constructor(props, context) {

    if (props.persist.isAuthenticated) {
      // force logout if needed
      // props.logout()
    }

    super(props, context);
    this.state = {
      email: props.match.params.email,
      password_reset_code: props.match.params.password_reset_code,
      password: '',
    };
  }

  render({account} = this.props) {
    return (
      <div>
        <ModalLayout
          title='Password Reset'
          subTitle={`Enter your new password for your TraceDock account with email ${this.state.email}`}
        >

          <Input
            backgroundColor={colors.func.background}
            ref={this.password}
            label="Password"
            type="password"
            focus={true}
            onChange={(password) => this.setState({password})}
            onSubmit={() => this._change()}
          />

          <Button
            style={{marginTop: 12}}
            isLoading={account.isPending}
            onClick={() => this._change()}
            title='Change my password & login'
          />

        </ModalLayout>
      </div>
    );
  }

  _change() {
    // if (!this.state.password) {
    //   setAlertDialog('Sorry','Please enter a valid password')
    // } else {
    //   this.props.password_link({email: this.state.email, password: this.state.password, password_reset_code: this.state.password_reset_code})
    //     .then(() => {
    //         if (this.props.persist.isAuthenticated) {

    //           this.props.history.push('/');
    //           identify(this.state.email);
    //           trackEvent('customer', 'password_reset', 'success', 0, false, true);
    //         } else {
    //           trackEvent('customer', 'password_reset', 'success', 0, false, true);
    //         }
    //       }
    //     )
    // }
  // }
}
}

const mapStateToProps = (state) => ({
  persist: state.persist,
  account: state.account
});

const mapDispatchToProps = (dispatch) => {
  return {
    // password_link: (data) => dispatch(accountActions.password_link(data)),
    // logout: () => dispatch(accountActions.logout())
  }
};

export default PasswordLinkPage = connect(mapStateToProps, mapDispatchToProps)(PasswordLinkPage);
