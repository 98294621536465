import {Javascript} from "../javascript/javascript";
import React from "react";


export const CNAMEcomponent = (dev) => {
  return <div style={{display: 'flex', flexDirection: 'row'}}>
    <div style={{width: '60%'}}>
      <Javascript
        value={`${dev.subdomain}.${dev.domain}`}
        title='Name'
        overflow={false}
      />
    </div>
    <div style={{margin: '12px', display: 'flex', alignItems: 'center'}}>
      <h5 style={{margin: '0 6px'}}>▶</h5>
      <h5>A-record</h5>
      <h5 style={{margin: '0 6px'}}>▶</h5>
    </div>
    <Javascript overflow={false}
                title='Value'
                value={dev.arecord ? dev.arecord : '<test>'} />
  </div>
};