import React from 'react';
import PropTypes from 'prop-types';

import './resize.scss';

export class Resize extends React.Component {

  // two columns, resize component in between

  static propTypes = {
    startLeft: PropTypes.number,
    minLeft: PropTypes.number,
    minRight: PropTypes.number,
    leftChild: PropTypes.any,
    rightChild: PropTypes.any,
  };

  static defaultProps = {
    startLeft: 50,
    minLeft: 50,
    minRight: 50,
    leftChild: <div><p>Hi</p></div>,
    rightChild: <div><p>Hi</p></div>,
  };

  constructor(props, context) {
    super(props, context);
    this.state = {
      offsetStart: 0,
      leftModalWidth: props.startLeft,
      rightModalWidth: 300
    };

    // to determine modal width
    this.resizeRef = React.createRef();
  }

  _setRightModalWidth() {
    this.setState({rightModalWidth: this.resizeRef.current ? this.resizeRef.current.offsetWidth : 800 - this.state.leftModalWidth - 18});
  }
  componentDidMount() {
    this._setRightModalWidth();
    window.addEventListener("resize", () => this._setRightModalWidth());
  }
  componentWillUnmount() {
    window.removeEventListener("resize", () => this._setRightModalWidth());
  }


  render({leftChild, rightChild, startLeft, minLeft, minRight} = this.props) {
    return (
      <div className='resizeWrapper' style={{width: '100%'}} ref={this.resizeRef}>

        <div style={{width: this.state.leftModalWidth, minWidth: minLeft}}>{leftChild}</div>

        <div className="resize"
             draggable={true}
             onDragStart={(e) => {
               this.setState({offsetStart: e.pageX});
             }}
             onDrop={(e) => {

               // does not work on Firefox
               if (e.pageX === 0) return 0;

               let offset = this.state.offsetStart - e.pageX;

               // TODO: if you ever find time, fix resize scenario under min limits
               this.setState({
                 leftModalWidth: this.state.leftModalWidth - offset,
                 rightModalWidth: this.state.rightModalWidth + offset,
               });
             }}>
          <div className="borders"/>
        </div>

        <div style={{width: this.state.rightModalWidth, minWidth: minRight}}>{rightChild}</div>

      </div>
    );
  }
}