import React from 'react';

import './tableRow.scss';
import {Icon, pencil} from "../icon/icon";
import {colors} from "../../theme/colors";


export const TableCell = (text, icon, icon2) => (
  <div className='tableCell'>
    {icon && <Icon icon={icon} color={colors.brand.gray} size={24}/>}
    {icon2 && <Icon icon={icon2} color={colors.brand.gray} size={24}/>}
    <p style={{marginLeft: icon ? 4 : 0, maxWidth: 140}} className='truncate'>{text}</p>
  </div>
);

export class TableRow extends React.Component {

  render({
           type = 'row',  // 'header', 'line', 'line active'
           cells = [],    // content, in 4
           edit = false   // edit button right
         } = this.props) {
    return (
      <div className="tableRow">

        <div className={`wrapper ${type}`}>

          {cells.map((cell, index) => (
            <div className='cell' key={index}>
              {cell}
            </div>
          ))}

          {type === 'line'
            ?
            <div className='edit clickable'>
              <Icon icon={pencil} color={colors.brand.gray} size={24}/>
            </div>
            :
            <div className='edit'>
              &nbsp;
            </div>
          }

        </div>

      </div>
    );
  }
}
