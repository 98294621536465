import React from 'react';
import PropTypes from 'prop-types';

import './sideModal.scss';
import {Button} from "../button/button";
import {colors} from "../../theme/colors";
import {Icon, back} from "../icon/icon";
import {registerShortcut, removeShortcut} from "../../redux/keyboard";

export class SideModal extends React.Component {

  static propTypes = {
    hasSave: PropTypes.bool,
    title: PropTypes.string, // number, string, any
    show: PropTypes.bool,
    isUpdated: PropTypes.bool,
    isLoading: PropTypes.bool,
    onClose: PropTypes.func,
    onSave: PropTypes.func,
    onReset: PropTypes.func,
    onAddNew: PropTypes.func,
    maxWidth: PropTypes.number,
    level: PropTypes.number,
  };

  static defaultProps = {
    title: '',
    hasSave: true,
    show: false,
    isUpdated: false,
    isLoading: false,
    onClose: () => console.log('closed'),
    onSave: () => console.log('saved'),
    onReset: () => console.log('reset'),
    maxWidth: null,
    level: 1,
  };

  constructor(props, context) {
    super(props, context);
    this.state = {};

    // register event
    registerShortcut('Escape', () => {
      if (!this.props.isUpdated) this.props.onClose()
    }, true)
  }



  componentWillUnmount() {
    // remove eventlistener
    removeShortcut('Escape', () => this.props.onClose())
  }

  render({title, show, children, level, isUpdated, isLoading, onClose, onSave, onReset, hasSave, maxWidth} = this.props) {
    return (
      <div className='sideModal'
           style={{zIndex: 800 + 100 * level}}
      >

        <div className={`BackLayer ${isUpdated ? 'not-allowed': ''}`}
             style={{zIndex: 810 + 100 * level}}
             onClick={() => {if (!isUpdated) onClose()}}/>

        <div className='sideModalWrapper'
               style={{zIndex: 850 + 100 * level, maxWidth: maxWidth}}>

          <div className={`navBar ${isUpdated ? 'active' : 'inactive'}`}
               style={{zIndex: 880 + 100 * level}}
          >

            <div className={`title ${isUpdated ? '' : 'clickable'}`} onClick={isUpdated ? () => {} : onClose}>
              {!isUpdated && <Icon icon={back} color={isUpdated ? colors.brand.white : colors.brand.primary} size={20}/>}
              <h3 className={isUpdated ? 'white' : 'primary'} style={{marginLeft: 12}}>{title}</h3>
            </div>

              <div className='buttons'>
                {isUpdated &&
                <Button
                  style={{width: 120}}
                  title='Reset changes'
                  onClick={onReset}
                  color={colors.brand.darkgray}
                  cancel={true}
                  backgroundColor={colors.brand.gray_light}/>
                }

                {hasSave && <Button
                  style={{marginLeft: 12, width: 120}}
                  title='Save changes'
                  onClick={onSave}
                  isLoading={isLoading}
                  color={isUpdated ? colors.brand.darkgray : colors.brand.primary}
                  backgroundColor={isUpdated ? colors.brand.accent_green : 'white'}
                  loaderColor={colors.brand.secondary}
                />}

              </div>

          </div>

          <div className='body'>
            {children}
          </div>
        </div>

      </div>
    );
  }
}