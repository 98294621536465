import React from 'react';
import PropTypes from 'prop-types';
import { livePage } from "../../pages/live"
import { Router } from '../../router';
import { AlertDialog } from '../alert/alert';
import { Outlet, Link } from "react-router-dom";
import { Redirect } from "react-router-dom";
import { useHistory } from "react-router-dom";
import { Routes, Route, useNavigate } from 'react-router-dom';

import { store } from "../../index"

export class AwaitLogin extends React.Component {

  showLoader = true;

  static propTypes = {
    size: PropTypes.number, // number, string, any
    topColor: PropTypes.string,
    style: PropTypes.any
  };

  static defaultProps = {
    size: 50,
    topColor: '#3498db',
    style: {}
  };


  constructor(props, context) {
    super(props, context);
    // getCmSSOWhenAvailable();  
    this.state = {};

    const awaitLoader = () => {
      setTimeout(() => {
        this.showLoader = false;
        this.forceUpdate()
      }, 4000);
    }

    awaitLoader();
  }

  render() {
    return (
      !this.showLoader ?
        <div >
          <h2>Please login or select account</h2>
        </div>
        :
        <div className='loaderWrapper' style={this.props.style}>

          <div className="loader" style={{
            width: this.props.size,
            height: this.props.size,
            borderWidth: (this.props.size / 6),
            borderTopColor: this.props.topColor
          }} />

        </div>
    );
  }



}
