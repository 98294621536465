import React, {useState} from 'react';
import SectionTitle from '../SectionTitle';
import {accountActions} from '../../../../redux/actions';
import {connect} from 'react-redux';
import {PropTypes} from 'prop-types';
import {STAGE} from "../../constants";
import {PriceSelector} from "../../../../components/priceSelector/priceSelector";


const PricePlanSection = ({goNext, updateCreditor, uuid}) => {

  const [pending, setPending] = useState(false);

  const onConfirm = async (newPlan, cuuid) => {
      // navigate to next screen

      setPending(true);

      let res = await updateCreditor({
        uuid: uuid,
        creditor: {
          pricing_plan: 'insights',
          page_views: newPlan.page_views,
          flat_fee: newPlan.key,
          n_domains: newPlan.domains,
          is_custom: newPlan.isCustom,
          action: 'get_started',
        }
      });

      // only navigate onwards if save went oke
      if (res) {

        // go to next page depends on if the price is custom or not, if custom navigate to corporate screen
        goNext(newPlan.isCustom ? STAGE.CORPORATE_ACCOUNT : STAGE.CREDIT_CARD);

      } else {
        setPending(false);
      }
  };

  return (
    <div style={{paddingBottom: 48}}>
      <SectionTitle
        title='Choose a price plan that suits you'
        subtitle='Based on your current page views per month'
      />

      <PriceSelector selectPlan={onConfirm} pending={pending} uuid={uuid}/>

    </div>
  )
};

PricePlanSection.propTypes = {
  goNext: PropTypes.func,
  updateCreditor: PropTypes.func,
  uuid: PropTypes.string
};

const mapStateToProps = (state) => ({
  uuid: state.account.admin ? state.account.admin.uuid : null,
  isPendingUpdate: state.account.isPendingUpdate,
});

const mapDispatchToProps = (dispatch) => {
  return {
    updateCreditor: (data) => dispatch(accountActions.updateCreditor(data))
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(PricePlanSection);
