import React from 'react';
import {Explainer, PopupPosition} from "../popup";
import {valid, Icon} from "../icon/icon";
import {colors} from "../../theme/colors";


const featureLine = (children) => (
  <div className='feature'>
    <Icon icon={valid} size={32} color={colors.brand.accent_green_dark} style={{marginRight: 12}}/>
    {children}
  </div>
);

export const PriceExplainer = () => {

  return (
    <div className='priceExplainer'>

      <h3 className='center regular'>All plans include:</h3>

      <div className='features'>

        <div className='column'>
          {featureLine(<>
              <Explainer
                position={PopupPosition.RIGHT_CENTER}
                message='All plans include first-party cookies, first-party data collection and server-side transaction tracking.'
                word='Future proof'/> data
            </>
          )}

          {featureLine(<>
              <Explainer
                position={PopupPosition.RIGHT_CENTER}
                message={
                  <>
                    <span>Implement TraceDock within 20 minutes through a simple DNS change and website code snippet.</span><br/>
                    <span>We will guide you through each step.</span>
                  </>}
                word='Easy'/> implementation
            </>
          )}

          {featureLine(<>
              <Explainer
                position={PopupPosition.RIGHT_CENTER}
                message='All plans include first-party cookies, first-party data collection and server-side transaction tracking.'
                word='Privacy'/> by design
            </>
          )}
        </div>

        <div className='column'>


          {featureLine(<>
              <Explainer
                position={PopupPosition.LEFT_CENTER}
                message='Our servers run in multiple redundant clusters in Frankfurt by Amazon with a 99.9% uptime.'
                word='Reliable'/> service
            </>
          )}

          {featureLine(<>
              <Explainer
                position={PopupPosition.LEFT_CENTER}
                message='All plans include a monthly cancellation policy.'
                word='Monthly'/> cancellation
            </>
          )}

          {featureLine(<>
              21-day <Explainer
              position={PopupPosition.LEFT_CENTER}
              message='The free trial period of 21 days starts after the setup of TraceDock is complete: when either the first cookies are extended or the first events are sent to Google Analytics.'
              word='trial'/> period
            </>
          )}
        </div>

      </div>

    </div>
  )
};
