import React from 'react';
import {connect} from 'react-redux';

import {Button} from "../button/button";
import {store} from "../../index";
import {deviceActions} from "../../redux/actions";

import './alert.scss'

export function setAlertDialog(alertTitle, alertMessage, alertDetails, alertButtons) {
  store.dispatch(deviceActions.showAlert(alertTitle, alertMessage, alertDetails, alertButtons));
}

class AlertDialog extends React.Component {

  render({alertTitle, alertMessage, alertShown, alertDetails, alertButtons} = this.props) {
    return (
      <div>

        {alertShown && (
          <div className='alertContainer'>

            <div className='modal'>

              {alertTitle && (
                <h4 className="center spacer">
                  {alertTitle}
                </h4>
              )}

              <p className="center spacer">
                {alertMessage}
              </p>


              {alertDetails && <p className="center small darkgray">
                {alertDetails}
              </p>}

              {(alertButtons && alertButtons.length > 0)
                ?
                <div className='buttonContainer'>
                  {alertButtons.map((button, index) => (
                    <Button
                      key={index}
                      style={{marginLeft: 2, marginRight: 2}}
                      title={button.title}
                      backgroundColor={button.backgroundColor}
                      onClick={() => this._close(button.onPress)}
                    />
                  ))}
                </div>
                :
                <div className='buttonContainer'>
                  <Button
                    title={'Continue'}
                    onClick={() => this._close()}
                  />
                </div>
              }
            </div>
          </div>
        )}

      </div>
    )
  }

  _close(onPressFunction) {
    if (onPressFunction) {
      onPressFunction();
    }
    this.props.clearAlert()
  }
}

const mapStateToProps = (state) => {
  return {
    alertShown: state.device.alertShown,
    alertTitle: state.device.alertTitle,
    alertMessage: state.device.alertMessage,
    alertDetails: state.device.alertDetails,
    alertButtons: state.device.alertButtons,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    clearAlert: () => dispatch(deviceActions.clearAlert()),
  }
};

const connectedContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(AlertDialog);

export {connectedContainer as AlertDialog};
