import React from "react";
import PropTypes from 'prop-types';

import {BlankCard} from "../../../components/cards/blankCard";
import {Input} from "../../../components/input/input";
import {DataFieldObject} from "../../../components/dataFieldObject/dataFieldObject";
import {fbTemplateChoices, fbTemplates} from "../../../lib/facebook/templates";
import {fbPossibleFields} from "../../../lib/facebook/possibleFields";
import {fbFieldDescriptions} from "../../../lib/facebook/fieldDescriptions";
import {eye, eye_active, Icon, out} from "../../../components/icon/icon";
import {colors} from "../../../theme/colors";
import {DataFieldPreview} from "../../../components/dataFieldObject/dataFieldPreview";
import {testFieldMapping} from "../../../redux/rules/rules.actions";
import {Popup, PopupPosition} from "../../../components/popup";
import {setAlertDialog} from "../../../components/alert/alert";

export class CardExternalFBFields extends React.Component {

  static propTypes = {
    fields: PropTypes.array,
    data: PropTypes.any,
    invalidObjects: PropTypes.any,
    availableFieldPaths: PropTypes.array,
    readOnly: PropTypes.bool,
    collapsed: PropTypes.bool,
    onUpdate: PropTypes.func,
    setTemplate: PropTypes.func,
    template: PropTypes.string,
    domain: PropTypes.string,
  };

  static defaultProps = {
    template: 'blank',
    fields: [],
    data: {},
    invalidObjects: {},
    availableFieldPaths: [],
    readOnly: false,
    domain: '',
    onUpdate: (data) => console.log('updating data: ', data),
    setTemplate: (template) => console.log('setting template: ', template),
  };

  constructor(props, context) {
    super(props, context);
    this.state = {
      tab: 'fields',
      loading: false,
      test_event_code: '',
    };
  }

  _updateTemplate(template) {

    // updating template first clear then update
    this.props.onUpdate([], () => {

      // then update
      this.props.onUpdate(fbTemplates[template], () => this.props.setTemplate(template));
    })
  }

  sendToFB = async () => {

    if (!this.state.test_event_code || !this.state.test_event_code.substr(0, 5) === 'TEST') {
      setAlertDialog('Enter Test Code', 'Please enter the facebook test event code found in the business manager.')
      return null
    }

    this.setState({loading: true})
    let data = await testFieldMapping({
      field_mapping: this.props.fields,
      data: this.props.data,
      domain: this.props.domain,
      test_event_code: this.state.test_event_code,
      type: 'facebook_test'})

    if (data) {
      setAlertDialog(data.valid ? 'Data succesfully forwarded' : 'Sorry, error found', data.message)
    }
    this.setState({loading: false})
  }

  render({invalidObjects, collapsed, availableFieldPaths, fields, data, readOnly, template, domain} = this.props) {
    return (

      <BlankCard
        collapsable={true}
        initialCollapsed={collapsed}
        title='To Facebook Conversion API'
        subtitle='Determine what data should be forwarded.'
        padding={false}>

        <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between'}}>
          <Input
            style={{padding: '0 24px', maxWidth: 250}}
            type="text"
            label="Select initial template"
            restrict={true}
            readOnly={false}
            choices={fbTemplateChoices}
            initial={template}
            onChange={(template) => this._updateTemplate(template)}
          />

          <div className='row'>
            <Popup
              content={
                <p style={{width: 200}}>Send over data to your Conversion API test portal.</p>
              }
              initialOpen={false}
              stayOpen={false}
              position={PopupPosition.LEFT_CENTER}
            >
              <div className='row'>
                <Input
                  style={{maxWidth: 250}}
                  type="text"
                  label="test_event_code"
                  placeholder='TEST'
                  onChange={(test_event_code) => this.setState({test_event_code})}
                />
                <div className='clickable' style={{padding: '10px 27px 5px 10px'}} onClick={this.state.loading ? null : () => this.sendToFB()}>
                  <Icon size={25} icon={out} color={colors.brand.secondary}/>
                </div>

              </div>
            </Popup>

            <Popup
              content={
                <p style={{width: 200}}>Preview your forwarded data here. It is calculated based on your raw data as found on the left side.</p>
              }
              initialOpen={false}
              stayOpen={false}
              position={PopupPosition.LEFT_CENTER}
            >
              <div className='clickable' style={{padding: '10px 27px 5px 10px'}} onClick={() => this.setState({tab: this.state.tab === 'fields' ? 'preview' : 'fields'})}>
                <Icon size={25} icon={this.state.tab === 'preview' ? eye_active : eye} color={colors.brand.secondary}/>
              </div>
            </Popup>
          </div>
        </div>

        {this.state.tab === 'fields' && (
          <DataFieldObject
            editReadOnlyFields={true}
            invalidObjects={invalidObjects}
            readOnly={false}

            availableFieldPaths={availableFieldPaths}
            onUpdate={(data) => this.props.onUpdate(data)}

            fields={fields}
            parent=''
            level={0}
            possibleFields={fbPossibleFields}
            fieldDescriptions={fbFieldDescriptions}

            stitchfield='user_data'
          />
        )}

        {this.state.tab === 'preview' && (
          <div style={{minHeight: 510, padding: 20}}>
            <DataFieldPreview
              testFieldMapping={testFieldMapping}
              domain={domain}
              fields={fields}
              data={data}

              type='facebook'
              FieldDescriptions={fbFieldDescriptions}
              OtherFields={[]}
            />
          </div>
        )}

      </BlankCard>
    )
  }
}
