export const cdpPossibleFields = [
  {key: 'WebtrackerClientId', value: 'WebtrackerClientId'},
  {key: 'WebtrackerSessionId', value: 'WebtrackerSessionId'},
  {key: 'Domain', value: 'Domain'},
  {key: 'IntegrationId', value: 'IntegrationId'},
  {key: 'CustomerId', value: 'CustomerId'},
  {key: 'EmailAddress', value: 'EmailAddress'},
  {key: 'Currency', value: 'Currency'},
  {key: 'TransactionId', value: 'TransactionId'},
  {key: 'Value', value: 'Value'},
  {key: 'Coupon', value: 'Coupon'},
  {key: 'Shipping', value: 'Shipping'},
  {key: 'Tax', value: 'Tax'},
  {key: 'Url', value: 'Url'},
  {key: 'UtmId', value: 'UtmId'},
  {key: 'UtmSource', value: 'UtmSource'},
  {key: 'UtmMedium', value: 'UtmMedium'},
  {key: 'UtmCampaign', value: 'UtmCampaign'},
  {key: 'UrlParams', value: 'UrlParams'},
  {key: 'MailId', value: 'MailId'},
  {key: 'Items', value: 'Items'},
  {key: 'ItemId', value: 'ItemId'},
  {key: 'ItemName', value: 'ItemName'},
  {key: 'ItemBrand', value: 'ItemBrand'},
  {key: 'ItemCategory', value: 'ItemCategory'},
  {key: 'ItemCategory2', value: 'ItemCategory2'},
  {key: 'ItemCategory3', value: 'ItemCategory3'},
  {key: 'ItemCategory4', value: 'ItemCategory4'},
  {key: 'ItemCategory5', value: 'ItemCategory5'},
  {key: 'ItemVariant', value: 'ItemVariant'},
  {key: 'Price', value: 'Price'},
  {key: 'Quantity', value: 'Quantity'},
  {key: 'Discount', value: 'Discount'},
];
