
export function classifyJsonSelectedFields(data, branche='') {
  // returns array of all nested objects for data structure of fields object
  // [{field_value:**name**, children: [recursive]}]
  // returns array of objects

  // emptycase
  if (!data  || !data.map || data.length === 0) return [];

  // iterate over array
  let output = [];

  data.map((field) => {

    // setup current branche, exclude constant fields, e.g. field 'c' (constant) is 'event', should not lookup event
    if (field.field_value && field.field_type !== 'c') {
      output.push(field.field_value);
    }

    if (field.children && field.children.length > 0) {

      // concatenate the field's children with the current branche
      output = output.concat(classifyJsonSelectedFields(field.children, field.field_value));
    }
    return true
  });

  // catch all empty or filled
  return output
}

export const classificationTestsSelectedFields = [
  {input: null, expected: []},
  {input: '', expected: []},
  {input: [], expected: []},
  {input: {}, expected: []},
  {input: 'a', expected: []},

  {
    input: [{field_value: 'a', children: []}],
    expected: ['a']
  },
  {
    input: [{field_value: 'a', children:[{field_value: 'a.b', children: []}, {field_value: 'a.c', children: [{field_value: 'a.c.b', children: []}, {field_value: 'a.c.c', children: []}]}]}],
    expected: ['a', 'a.b', 'a.c', 'a.c.b', 'a.c.c']
  }
];
