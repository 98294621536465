import React from 'react';
import PropTypes from 'prop-types';
import {CopyToClipboard} from 'react-copy-to-clipboard';
import {colors} from "../../theme/colors";
import {Icon, copy, copy_active} from "./icon";

export class CopyIcon extends React.Component {

  static propTypes = {
    text: PropTypes.string,
    title: PropTypes.string,
    onCopy: PropTypes.func,
    copyIcon: PropTypes.any,
    copyIcon_active: PropTypes.any,
    callBack: PropTypes.func,
  };

  static defaultProps = {
    text: 'test',
    title: null,
    onCopy: () => {},
    copyIcon: copy,
    copyIcon_active: copy_active,
  };

  constructor(props, context) {
    super(props, context);

    this.state = {
      copied: false,
    };
  }

  render({text, title, onCopy, copyIcon, copyIcon_active} = this.props) {
    return (
      <CopyToClipboard text={text} onCopy={() => {
        this.setState({copied: true}, () => setTimeout(() => onCopy(), 250));
        setTimeout(() => this.setState({copied: false}) , 2000)
      }}>
        <div className='row' style={{alignItems: 'center'}}>
          <Icon className='clickable' color={colors.brand.primary} size={28} icon={this.state.copied ? copyIcon_active : copyIcon}/>
          {title && <p  className='primary'>{title}</p>}
        </div>
      </CopyToClipboard>
    );
  }
}