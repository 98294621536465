import React from "react";

import {Button} from "../../components/button/button";

import './404_not_found.scss';

class NotFound extends React.Component {

  render({history} = this.props) {
    return (
      <div className='not_found'>

            {/* <div className='content'>
              <img style={{marginBottom: 32}} width='100' src={require('../../static/images/logo_grey.png')} alt='tracedock'/>

              <div className='title spacer'>
                <h2>404.</h2>
                <p>Page not found.</p>
              </div>

              <img style={{marginBottom: 32}} width='250' src={require('../../static/images/404.gif')} alt='not_found'/>

              <Button
                style={{marginTop: 12, width: 250}}
                isLoading={false}
                onClick={() => this.props.history.push('/')}
                title='Return to home'
              />
            </div> */}
      </div>
    );
  }
}

// no Redux
export default NotFound;
