import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { DomainSelector } from "../domainSelector/domainSelector";
import { Menu } from "../menu/menu";
import { docs, docs_active, Icon, IconInline, invalid, organisation, organisation_active, profile, profile_active, stopwatch } from "../icon/icon";
import { colors } from "../../theme/colors";
import { PriceConfirmModal } from "../installation/priceModal";
import { accountActions } from "../../redux/actions";
import { api } from "../../lib/api";

import AgencyPage from '../../pages/admin/agency';
import AdminPage from '../../pages/admin/admin';
import ProfilePage from '../../pages/admin/profile';
import { Input } from '../input/input';
import { Button } from '../button/button';
import { updateIfNeeded } from "../../lib/helpers";
import { store } from "../../index";
// import { DomainSection } from '../../pages/organisation/sections/domainSection';

import './layout.scss';


export class LayoutComponent extends React.Component {

  static propTypes = {
    onAddDomain: PropTypes.func,
    withPadding: PropTypes.bool,
    domain: PropTypes.any,
  };

  static defaultProps = {
    withPadding: true,
    domain: {},
  };

  constructor(props, context) {
    super(props, context);

    this.state = {
      confirmPending: false,
      showModal: '',
      showPriceOrPayment: true,
      newDomainName: '',
      newDomainNameInvalid: false,
      isCreating: false
    };

    // update admin is persist boolean, if not visible or old then retrieve all profile data
    updateIfNeeded(this.props.admin_updated, this.props.getProfile);
  }

  _addNewDomain() {

    let isValid = true;
    let newDomains = [];
    this.state.newDomainName.split(',').forEach((domain) => {
      domain = `https://${domain.trim().replace('https://', '').replace('http://', '')}`;
      if (domain.split('.').length < 2) {
        isValid = false;
      }

      // remove trailing slash
      if (domain.substring(domain.length - 1) === '/') domain = domain.slice(0, -1);

      newDomains.push(domain);
    });

    if (!isValid) {
      this.setState({newDomainNameInvalid: true})
    } else {
      const currentTenant = store.getState().cmaccount.currentTenantId
      this.setState({isCreating: true}, () => {
        this.props.createDomain({domains:newDomains, company_uuid:currentTenant},
          () => this.setState({isCreating: false})
        )
      });
    }
  }

  _renderNavBut(icon, icon_active, slug, title, onClick) {
    return (
      <div className='clickable navButton' onClick={() => onClick()}>
        <Icon icon={icon} />
        <p style={{ marginLeft: 4 }} className='white bold'>{title}</p>
      </div>
    )
  }

  _showModal(modal) {
    this.setState({ showModal: modal, showPriceOrPayment: false })
  }

  _closeModal(navigateTo) {
    this.setState({ showModal: null, showPriceOrPayment: true },
      () => {
        if (navigateTo) this.props.history.push(navigateTo)
      })
  }

  componentDidMount() {

    // requestAnimationFrame(() => {

    // })

  }

  render({ domainName, domain, admin, is_agent, withPadding, history, children, has_domains, demo } = this.props) {
    is_agent = store.getState().cmaccount.is_agency;
    const is_not_agent_and_has_no_domains = has_domains == true && is_agent == true
    var allDomains = store.getState().persist.all_domains.length;
    
    // let showPrice = is_admin && (admin.company_name && !admin.price_confirmed) && !window.location.pathname.includes('installation');

    // let width = domain.onboarding.trial_days_left / domain.onboarding.trial_days;

    return (
      <div className='layoutContainer'>

        {/* TODO: rework this to use the left side nav */}
        {/* <div className='topMenu'>

          <div className='navGroup'>

            {api.config.mode !== 'develop' &&
              <div className='testBadge'>
                <IconInline size={20} icon={invalid} color='#fff' /> Test environment
              </div>
            }

            <div className='brand'>
              <img alt='tracedock' height='100%' src={require('../../static/images/avatar_light.png')} />
            </div>

            <DomainSelector history={history} allowDemo={this.state.showModal === ''} />
          </div>

          <div className='navGroup'>
            {is_agent && this._renderNavBut(organisation, organisation_active, 'agency', 'My Agency', () => this._showModal('agency'))}
            {is_admin && this._renderNavBut(organisation, organisation_active, 'admin', 'My Organisation', () => this._showModal('admin'))}
            {this._renderNavBut(profile, profile_active, 'profile', 'My Profile', () => this._showModal('profile'))}
            {this._renderNavBut(docs, docs_active, 'docs', 'Help', () => window.open('https://docs.tracedock.com', '_blank'))}
          </div>
        </div> */}

        <div className='layout'>
          <div className='sideBar'>

            <div className='selector'>

            </div>
            <Menu history={history} domain={domain} />


            {/* {domain.onboarding && domain.onboarding.trial_days_left > 0 && (
              <div className='trialPeriod'>

                <div className='trialBar'>

                  <div className='activeBar slideInFromLeft' style={{ width: width < 1 ? 200 * width : 200 }} />

                </div>

                <p className='secondary center'>
                  <IconInline icon={stopwatch} color={colors.brand.accent_green} />
                  <span style={{ marginLeft: 6 }}
                    className='bold'>{domain.onboarding.trial_days_left}</span> {domain.onboarding.trial_days > 0 ? `out of ${domain.onboarding.trial_days}` : ''} trial days left
                </p>
              </div>
            )} */}

          </div>

          <div className={`mainContent ${withPadding ? 'padding' : ''}`}>

            <div className='contentContainer'>

              {/* {showPrice && this.state.showPriceOrPayment && (
                <div style={{
                  backgroundColor: colors.brand.primary_lighter,
                  width: '100%', height: 'calc(100vh - 60px)',
                  display: 'flex', flexDirection: 'column', alignContent: 'center', justifyContent: 'center'
                }}>

                  <Modal onClose={null} width='450px' visible={true} position='absolute'>
                    <PriceConfirmModal
                      onConfirm={async () => {
                        this.setState({ confirmPending: true });
                        await this.props.confirm_price();
                        this.setState({ confirmPending: false })
                      }}
                      confirmPending={this.state.confirmPending}
                      admin={admin}
                      trial_days={admin.trial_days}
                    />
                  </Modal>
                </div>
              )} */}

              {this.state.showModal === 'admin' && (
                <AdminPage
                  show={this.state.showModal === 'admin'}
                  onClose={(navigateTo) => this._closeModal(navigateTo)}
                />
              )}

              {this.state.showModal === 'agency' && (
                <AgencyPage
                  show={this.state.showModal === 'agency'}
                  onClose={(navigateTo) => this._closeModal(navigateTo)}
                />
              )}

              {this.state.showModal === 'profile' && (
                <ProfilePage
                  show={this.state.showModal === 'profile'}
                  onClose={(navigateTo) => this._closeModal(navigateTo)}
                />
              )}

              {(has_domains || is_agent) && (
                children
              )}

              {/* {is_agent && (
                children
              )} */}

              {(!has_domains && !is_agent) && (

                <div style={{ margin: '120px 100px' }}>
                  <p>You have not yet added any domains. Please add a domain or contact <a className='link clickable' target="_blank" rel="noopener noreferrer" title='Mail us'
                    href="mailto:support@tracedock.com?subject=Adding domains">support@tracedock.com</a> for additional support.
                  </p>
                  
                  <Input
                    label='Domain address'
                    initial=''
                    type='text'
                    focus={true}
                    // isInvalid={this.state.newDomainNameInvalid}
                    onChange={(newDomainName) => this.setState({ newDomainName })}
                    onSubmit={() => this._addNewDomain()}
                  />
                  <Button
                    style={{ margin: 4 }}
                    title='Add domain(s)'
                    onClick={() => this._addNewDomain()}
                    isLoading={this.state.isCreating}
                  />
                </div>

              )}

            </div>
          </div>
        </div>

      </div>
    );
  }
}


const mapStateToProps = (state) => {
  return {
    domain: state.persist.domain,
    // is_admin: state.persist.is_admin,
    // is_agent: state.cmaccount.is_agent,
    // admin: state.account.admin,
    // admin_updated: state.persist.admin_updated,
    has_domains: state.persist.has_domains,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getProfile: () => dispatch(accountActions.getProfile()),
    confirm_price: (d) => dispatch(accountActions.confirmPrice(d)),
    createDomain: (data) => dispatch(accountActions.createDomain(data)),
  }
};

const connectedContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(LayoutComponent);

export { connectedContainer as Layout };