import React from 'react';
import PropTypes from 'prop-types';

import './tag.scss';

export class Tag extends React.Component {

  static propTypes = {
    type: PropTypes.oneOf(['light', 'default', 'update', 'active', 'new', 'alert']),
    style: PropTypes.any,
    caption: PropTypes.string,
    className: PropTypes.string
  };

  static defaultProps = {
    type: 'default',
    className: '',
    caption: '',
    style: {}
  };

  constructor(props, context) {
    super(props, context);
    this.state = {};
  }

  render({type, className, style, caption, children} = this.props) {
    return (
      <div className={`Tag  ${type} ${className}`}
           style={style}>
        <p title={caption} className={`small center ${type === 'alert' ? 'white' : ''}`}>
          {children}
        </p>
      </div>
    );
  }
}