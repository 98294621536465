import { persistState } from './persist.state';
import { normalizeDomain, normalizeDomains, sortDomains } from "../domain.normalizer";

export function persistReducer(state = persistState, action) {
  switch (action.type) {

    case 'SET_AUTH_TOKEN':
      return {
        ...state,
        isAuthenticated: true,
        access_token: action.access_token,
      };

    case 'SET_AUTHENTICATED':
      return {
        ...state,
        isAuthenticated: true,
      };

    case 'SET_BASEURL':
      return {
        ...state,
        baseURL: action.baseURL
      };

    case 'SET_LOGGED_OUT':
      return {
        ...state,
        isAuthenticated: false,
      };

    case 'SET_PERSIST_PROFILE':
      return {
        ...state,
        admin_updated: new Date(),
        get_started: action.get_started,
        // is_staff: action.is_staff,
        // is_admin: action.is_admin,
        // is_agent: action.is_agent,
      };

    case 'SET_ALL_DOMAINS':
      // normalize to slug
      let selected_domain = null;

      if (action.domains && action.domains.length > 0) {

        // In base case the selected_domain is the first one you find in the dataset
        selected_domain = action.domains[0];

        // But if you already have a domain selected, check out if it exists and if so then you update it accordingly
        if (state.domain.id) {
          action.domains.forEach((domain) => {
            if (state.domain.id === domain.id) {
              selected_domain = normalizeDomain(domain);
            }
          });
        }
      }
      return {
        ...state,
        domain: normalizeDomain(selected_domain || state.domain),
        all_domains: sortDomains(action.domains && action.domains.length > 0 ? normalizeDomains(action.domains) : state.domains),
        has_domains: action.domains && action.domains.length > 0
      };

    case 'ADD_DOMAINS':
      return {
        ...state,
        all_domains: sortDomains(state.all_domains ? state.all_domains.concat(normalizeDomains(action.new_domains)) : normalizeDomains(action.new_domains)),
        has_domains: true
      };

    case 'UPDATE_DOMAIN':
      // Used if agency or admin has added a domain
      let updated_domain = normalizeDomain(action.domain);
      let all_domains = state.all_domains.filter((domain) => domain.id !== action.domain.id);
      return {
        ...state,
        all_domains: sortDomains([updated_domain].concat(all_domains)),
        domain: updated_domain,
        has_domains: true
      };

    case 'SET_DOMAIN':
      // only select domain if you have access to this domain
      let found = false;
      state.all_domains.some((element) => {
        if (element.id === action.domain.id) {
          found = true;
        }
        return found
      });

      return {
        ...state,
        domain: found ? normalizeDomain(action.domain) : state.domain,
        has_domains: true,
      };

    case 'SET_AGENCIES':

      return {
        ...state,
        all_agencies: action.all_agencies
      };

    case 'RESET_PERSIST_STATE':
      return {
        ...persistState
      };

    case 'SET_DEMO':
      return {
        ...state,
        demo: { ...state.demo, ...action.items }
      };

      // case 'SET_AGENCY':
      //   return {
      //     ...state,
      //     is_agent: action.is_agent,
      //   };

    default:
      return state;
  }
}
