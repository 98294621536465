import React from 'react';
import PropTypes from 'prop-types';

import './tagOrInput.scss';
import {Tag} from "../tag/tag";
import {Input} from "../input/input";
import {back, Icon} from "../icon/icon";
import {colors} from "../../theme/colors";

export class TagOrInput extends React.Component {

  static propTypes = {
    value: PropTypes.string,
    placeholder: PropTypes.string,
    readOnly: PropTypes.bool,
    availableFieldPaths: PropTypes.array,
    isInvalid: PropTypes.any,
    showTagIfUnknown: PropTypes.bool,
    onChange: PropTypes.func,
    choices: PropTypes.any,
  };

  static defaultProps = {
    value: '',
    placeholder: '',
    readOnly: false,
    availableFieldPaths: [],
    isInvalid: false,
    showTagIfUnknown: false,
    onChange: () => console.log('change'),
    choices: null,
  };

  constructor(props, context) {
    super(props, context);
    this.state = {
      focus: false
    };
  }

  render({value, inputOnly, choices, placeholder, availableFieldPaths, readOnly, isInvalid, onChange, showTagIfUnknown} = this.props) {
    return (
      <div
        className={`tagOrInput ${this.state.focus ? 'active' : 'inactive'}`}
        onClick={() => this.setState({focus: true})}
        onBlur={() => this.setState({focus: false})}
      >

        {( this.state.focus || isInvalid || (showTagIfUnknown && !value) || (!showTagIfUnknown && !availableFieldPaths.some((choice) => choice.key === value)))
          ?
          <Input
            type="text"
            placeholder={placeholder}
            focus={this.state.focus}
            readOnly={readOnly}
            initial={value}
            choices={choices || availableFieldPaths}
            restrict={choices && choices.length > 1}
            isInvalid={isInvalid}
            onChange={(value) => onChange(value)}
          />
          :
          <div className='breadcrumbs'>
            {
              value.split('.').map((value, idx) => (
                  <div className='breadcrumb' key={idx}>
                    {idx > 0 && <Icon icon={back} color={colors.brand.gray}/>}
                    <Tag>{value}</Tag>
                  </div>
                )
              )
            }
          </div>
        }
      </div>
    );
  }
}