import React from 'react';
import PropTypes from 'prop-types';

import './filter.scss';
import {colors} from "../../theme/colors";
import {Icon, plus} from "../icon/icon";
import {InputSelect} from "../inputSelect/inputSelect";
import {Button} from "../button/button";


export class Filter extends React.Component {

  static propTypes = {
    currentFilters: PropTypes.any,
    onUpdateFilter: PropTypes.any,
    onResetFilter: PropTypes.any,
  };

  static defaultProps = {
    currentFilters: {},
    onUpdateFilter: (filter) => console.log('updating filter: ', filter),
    onResetFilter: (key) => console.log('resetting filter: ', key),
  };

  constructor(props, context) {
    super(props, context);
    this.state = {
      hasMounted: false,
      openFilter: '',
      openAllFilters: false,
      newFilters: props.currentFilters,
    };
  }

  componentDidMount() {
    this.setState({hasMounted: true})
  }

  componentDidUpdate(prevProps) {
    // after resetting and updating reset filters
    if (this.props.currentFilters !== prevProps.currentFilters) {
      this.setState({newfilters: this.props.currentFilters})
    }
  }

  _setFilter(filter) {
    let newFilters = {...this.state.newFilters};
    newFilters[filter.key] = filter;
    this.setState({newFilters})
  }

  render({onUpdateFilter, onResetFilter} = this.props) {

    // populate active filter keys and nonVisible filter keys
    let visibleFilters = [];
    let nonVisibleFilters= [];
    Object.keys(this.state.newFilters).map((key) => {
      if (this.state.newFilters[key].visible) {visibleFilters.push(this.state.newFilters[key])}
      else {nonVisibleFilters.push(this.state.newFilters[key])}
      return null
    });

    if (!this.state.hasMounted) return null;

    return (
      <div className='Filter'>

        {/* Map each active filter */}
        {visibleFilters.length > 0 && visibleFilters.map((visibleFilter, idx) => {

          // determine the value of the selected value
          let selectedValue = visibleFilter.selectedValues.length;
          if (selectedValue > 0) {
            visibleFilter.choices.some((choice) => {
              // continue on some, untill you found a match
              if (choice.key === visibleFilter.selectedValues[0]) {
                selectedValue = choice.value;

                // add other value
                if (visibleFilter.selectedValues.length > 1) selectedValue = selectedValue +  visibleFilter.selectedValues[1];
                return true
              }
              return false
            })
          }

          return  (
            <div className='filterWrapper' key={idx}>
              <div
                className='filterButton clickable'
                onClick={() => {
                  // select filter
                  this.setState({openFilter: this.state.openFilter === visibleFilter.key ? '' : visibleFilter.key, openAllFilters: false})
                }}>
                <span className='bold small'>{visibleFilter.value}</span>
                &nbsp;
                <span className='small'>({selectedValue})</span>
              </div>

              <div className={`filterList ${this.state.openFilter === visibleFilter.key ? 'open' : 'closed'}`}>
                <div className='filterOptions'>
                  <InputSelect
                    type={visibleFilter.type}
                    choices={visibleFilter.choices}
                    selectedValues={visibleFilter.selectedValues}
                    onChange={(newValues) =>  {
                      this._setFilter({...visibleFilter, selectedValues: newValues});
                    }}
                  />
                </div>
                <div className='filterButtons'>

                  <Button
                    title='Apply'
                    onClick={() => {
                      // simply close
                      this.setState({openAllFilters: false, openFilter: null});
                      onUpdateFilter({filters: this.state.newFilters});
                    }}
                  />
                  <Button
                    style={{marginLeft: 6}}
                    title='Reset'
                    backgroundColor='white'
                    color={colors.brand.darkgray}
                    cancel={true}
                    onClick={() => {
                      // cancel and delete filter
                      this.setState({openAllFilters: false, openFilter: null});
                      this._setFilter({...visibleFilter, visible: false});
                      onResetFilter({key: visibleFilter.key})
                    }}
                  />

                </div>
              </div>

            </div>
          )
        })}

        {/* Add Filter mapping */}
        {nonVisibleFilters.length > 0 && (
          <div className='filterWrapper'>

            <div
              className='filterButton clickable'
              onClick={() => this.setState({openAllFilters: !this.state.openAllFilters, openFilter: null})}>
              <Icon icon={plus} color={colors.brand.primary} size={15}/>
              <span className='small'> Add filter</span>
            </div>

            <div className={`filterList ${this.state.openAllFilters ? 'open' : 'closed'}`}>
              {nonVisibleFilters.map((nonVisibleFilter, idx) => {
                return  (
                  <div className='filterListElement' key={idx}
                       onMouseDown={() => {
                         // add filter to open key
                         this.setState({
                           openAllFilters: false,
                           openFilter: nonVisibleFilter.key
                         }, () => {
                           this._setFilter({...nonVisibleFilter, visible: true})
                         });
                       }}>
                    <span className='small clickable noselect'>{nonVisibleFilter.value}</span>
                  </div>
                )
              })}
            </div>
          </div>
        )}

      </div>
    );
  }
}