import React from "react";
import PropTypes from 'prop-types';

import {BlankCard} from "../../../components/cards/blankCard";
import {Button} from "../../../components/button/button";
import {bin, Icon} from "../../../components/icon/icon";
import {colors} from "../../../theme/colors";
import {Table} from "../../../components/table/table";
import {Tag} from "../../../components/tag/tag";
import {Input} from "../../../components/input/input";
import {emailValidator} from "../../../lib/validators";
import {Modal} from "../../../components/modal/modal";
import {store} from "../../../index"


export class DomainSection extends React.Component {

  static propTypes = {
    onAddDomain: PropTypes.func,
    // inviteAdmin: PropTypes.func,
    gotoImplementation: PropTypes.func,
    gotoLive: PropTypes.func,
    // admin: PropTypes.any,
    user_email: PropTypes.string,
  };

  constructor(props, context) {
    
    super(props, context);
    this.state = {

      isUpdated: false,
      invalidObjects: {},

      showNewModal: false,
      newDomainName: '',
      newDomainNameInvalid: false,
      isCreating: false,

      // admins: this.props.admin && this.props.admin.admins && this.props.admin.admins.length > 0 ? this.props.admin.admins : [],
      isLoading: false,
    };
  }

  _addNewDomain() {

    let isValid = true;
    let newDomains = [];
    this.state.newDomainName.split(',').forEach((domain) => {
      domain = `https://${domain.trim().replace('https://', '').replace('http://', '')}`;
      if (domain.split('.').length < 2) {
        isValid = false;
      }

      // remove trailing slash
      if (domain.substring(domain.length - 1) === '/') domain = domain.slice(0, -1);

      newDomains.push(domain);
    });

    if (!isValid) {
      this.setState({newDomainNameInvalid: true})
    } else {
      this.setState({isCreating: true}, () => {
        this.props.onAddDomain(newDomains,
          () => this.setState({isCreating: false, showNewModal: false})
        )
      });
    }
  }

  // _update_row(idx, newObject) {
  //   // update element and update element
  //   let admins = [...this.state.admins];
  //   admins[idx] = newObject;
  //   this.setState({isUpdated: true, admins: admins})
  // }

  _validate_and_save() {

    let invalidObjects = {};
    let existing = [];
    let updated_admins = [];

    this.state.admins.forEach((row, idx) => {

      // clean up
      row.email = row.email.trim().toLowerCase();

      // check email if add
      if (row.action === 'add') {
        // validate email
        if (!emailValidator(row.email)) {
          invalidObjects[`${idx}_email`] = 'Please enter a valid email address'
        }
        if (!row.first_name || row.first_name.length < 2) {
          invalidObjects[`${idx}_first_name`] = 'Please enter a first name'
        }
      }

      // check if email already exists
      if (!existing.includes(row.email)) {
        existing.push(row.email);
        updated_admins.push(row);
      } else {

        // ok, double but ok if we delete, in that case we just don't add it ot the update list
        if (row.action !== 'delete') {
          invalidObjects[`${idx}_email`] = 'This email address is already added in the list';
        }
      }
    });

    // only save if no invalid objects
    if (Object.entries(invalidObjects).length === 0) {

      this.setState({isLoading: true, admins: []}, async () => {

        let admins = await this.props.inviteAdmin({
          uuid: this.props.admin.uuid,
          admins: updated_admins
        });

        // only continue if admins can be added
        if (admins) {
          this.setState({isLoading: false, isUpdated: false, admins: admins})
        } else {
          this.setState({isLoading: false, isUpdated: true, admins: updated_admins})
        }
      });

    }
    this.setState({invalidObjects: invalidObjects})
  }

  render({show, readOnly, onClose, admin, domains} = this.props) {
    const is_agency = store.getState().cmaccount.is_agency;
    const agency_tenantid = store.getState().cmaccount.traceDockKey;
    const current_tenantid = store.getState().cmaccount.currentTenantId;

    if(is_agency && agency_tenantid == current_tenantid){
      return (
      
        <div>
          <p>Cannot add domains for agency.</p>

  
        </div>
      );
    } else {
      return (
      
        <div>
          <Modal visible={this.state.showNewModal} onClose={() => this.setState({showNewModal: false})}>
            <h2>Add domain(s).</h2>
  
            <div className='row' style={{alignItems: 'center'}}>
              <p style={{marginRight: 6, color: colors.brand.darkgray_light}}>http(s)://</p>
  
              <Input
                label='Domain address'
                initial=''
                type='text'
                focus={true}
                isInvalid={this.state.newDomainNameInvalid}
                onChange={(newDomainName) => this.setState({newDomainName})}
                onSubmit={() => this._addNewDomain()}
              />
            </div>
  
            {this.state.newDomainNameInvalid
              ?
              <p className='small formatIndicator spacer'>
                We expect the following format: <span
                className='code'>https://albert.com</span>
              </p>
              :
              <p className='small'>Comma-seperated for multiple domains.</p>
  
            }
  
            <div style={{margin: '24px auto', display: 'flex'}}>
              <Button
                style={{margin: 4}}
                title='Cancel'
                onClick={() => this.setState({showNewModal: false, isCreating: false})}
                color={colors.brand.darkgray}
                cancel={true}
                backgroundColor={colors.brand.gray_light}/>
  
              <Button
                style={{margin: 4}}
                title='Add domain(s)'
                onClick={() => this._addNewDomain()}
                isLoading={this.state.isCreating}
              />
  
            </div>
  
          </Modal>
  
          <div style={{width: '100%'}}>
  
            <BlankCard
              title='Domains'
              padding={false}
              onAddNewTitle='Add domain(s)'
              onAddNew={(this.props.domains.length >= this.props.admin.n_domains) ? null : () => this.setState({showNewModal: true})}>
              <div>
                {this.props.domains.length === 0
                  ? <p style={{padding: '0 24px 12px'}}>{
                   'Add your first domain.'
                  }</p>
                  :
                  <Table header={[
                    {key: 'name', value: 'Name', help: null},
                    {key: 'state', value: 'State', help: null},
                  ]}>
                    {this.props.domains.length > 0 && this.props.domains.map((domain, idx) => (
                      <tr key={idx}>
                          
                        <td className='clickable' onClick={() => {
                          if (domain.state === 'live') this.props.gotoLive({domain: domain});
                          if (domain.state === 'implementing') this.props.gotoImplementation({domain: domain});
                        }}>
                          <p className='verticalpadding'>{domain.name}</p>
                        </td>
  
                        <td className={domain.state === 'implementing' ? 'clickable ' : ''} onClick={() => {
                          if (domain.state === 'implementing') this.props.gotoImplementation({domain: domain})
                        }}>
                          <Tag type={domain.state === 'live' ? 'default' : 'update'}>
                            {domain.state}
                          </Tag>
                        </td>
                      </tr>
                    ))}
                  </Table>
                }
  
                {this.props.domains.length >= this.props.admin.n_domains &&
                <p style={{margin: 24}}>You can only add {this.props.admin.n_domains} domain{this.props.admin.n_domains > 1 ? 's' : ''} within
                  your price plan. Want to add more? <a className='link' rel="noopener" title='More domains' href='mailto:hello@tracedock.com'>Contact us</a>.</p>}
              </div>
            </BlankCard>
  
          </div>
  
        </div>
      );
    }

    // <div>
    //   <p>test</p></div>
    // );
  }
}

