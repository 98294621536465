export const accountState = {

  isPending: false,
  isPendingUpdate: false,

  creditor_updated: null,
  creditor: {},

  profile_updated: null,
  profile: {

    uuid: null,

    email: "",
    first_name: "",
    last_name: "",
    company_name: "",
    type: "",
  },

  admin: {
    uuid: null,

    price_confirmed: true,
    payment_method_confirmed: true,
    gdpr_confirmed: true,

    domains: [],
  },

  // for overview of agency
  agency: {
    uuid: null,
    state: null,
    is_principle: false,
    principle_email: null,
    logicalAccountGuid: null
  },
  creditors: [],
  agents: [],

  members_updated: null,
  members: [],

};
