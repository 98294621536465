/*
    Validators check
 */


export const regexValidator = (value, regex) => {
  // Checks regular expression on validator
  if (!value) return false;
  return regex.test(value.toUpperCase().replace(/\s/g, ""))
};

export const emailValidator = (value) => {
  if (value === undefined || value.length === 0) return false;
  return regexValidator(value,  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)
};

export const emailValidatorList = (value) => {
  // Should be one or more valid email addresses, separated by a comma.
  if (value.length < 5 || value.length > 999) return false;

  let valid = true;
  value.split(',').forEach((email) => {
    if (!emailValidator(email)) {
      valid = false;
      return valid
    }
  });
  return valid
};

// console.log('TEST email list validator: ');
// console.log('false non valid: ', emailValidatorList('123'));
// console.log('true single: ', emailValidatorList('boris@tracedock.com'));
// console.log('false single: ', emailValidatorList('boris@.com'));
// console.log('true multiple: ', emailValidatorList('boris@tracedock.com,carlos@tracedock.com'));
// console.log('false multiple: ', emailValidatorList('boris@tracedock.com,carlos@.com'));

export const numberValidator = (value) => {
  return regexValidator(value,  /^[0-9]+$/)
};

// TODO: IBAN validator
// TODO: VAT validator
// (?xi)^(
// (AT)?U[0-9]{8} |                                 # Austria
// (BE)?0[0-9]{9} |                                 # Belgium
// (BG)?[0-9]{9,10} |                               # Bulgaria
// (HR)?[0-9]{11} |                                 # Croatia
// (CY)?[0-9]{8}L |                                 # Cyprus
// (CZ)?[0-9]{8,10} |                               # Czech Republic
// (DE)?[0-9]{9} |                                  # Germany
// (DK)?[0-9]{8} |                                  # Denmark
// (EE)?[0-9]{9} |                                  # Estonia
// (EL)?[0-9]{9} |                                  # Greece
// ES[A-Z][0-9]{7}(?:[0-9]|[A-Z]) |                 # Spain
// (FI)?[0-9]{8} |                                  # Finland
// (FR)?[0-9A-Z]{2}[0-9]{9} |                       # France
// (GB)?([0-9]{9}([0-9]{3})?|[A-Z]{2}[0-9]{3}) |    # United Kingdom
// (HU)?[0-9]{8} |                                  # Hungary
// (IE)?[0-9]{7}[A-Z]{1,2}   |                      # Ireland
// (IE)?[0-9][A-Z][0-9]{5}[A-Z] |                   # Ireland (2)
// (IT)?[0-9]{11} |                                 # Italy
// (LT)?([0-9]{9}|[0-9]{12}) |                      # Lithuania
// (LU)?[0-9]{8} |                                  # Luxembourg
// (LV)?[0-9]{11} |                                 # Latvia
// (MT)?[0-9]{8} |                                  # Malta
// (NL)?[0-9]{9}B[0-9]{2} |                         # Netherlands
// (PL)?[0-9]{10} |                                 # Poland
// (PT)?[0-9]{9} |                                  # Portugal
// (RO)?[0-9]{2,10} |                               # Romania
// (SE)?[0-9]{12} |                                 # Sweden
// (SI)?[0-9]{8} |                                  # Slovenia
// (SK)?[0-9]{10}                                   # Slovakia
// )$


export const isRequiredValidator = {
	errorMessage: '',
	validatorFunction: function(value) {
    // return if empty or not
    return !!value;
  }
};

export const isEmailValidator = {
  errorMessage: 'Field value needs to be a valid email address',
  validatorFunction: emailValidator
};

export const isCountryValidator = {
  errorMessage: 'Please fill in a 2-letter country code',
  validatorFunction: (value) => regexValidator(value, /^[A-Z]{2}/),
};

export const isVATvalidator = {
  errorMessage: 'Please fill in a valid VAT number, format "NL123456789B01"',
  validatorFunction: (value) => !value || regexValidator(value, /^[A-Z]{2}:?/),
};

export const isURLvalidator = {
  errorMessage: 'Please fill in a valid URL',
  // simple validator
  validatorFunction: (value) => !value || value.split('.').length > 1,
};