import React from "react";
import {STAGE} from './constants';

import logo_client_dpgmediaLogo from '../../static/images/clients/logo_client_dpgmedia.png'
import logo_client_brunelLogo from '../../static/images/clients/logo_client_brunel.png'
import logo_client_vanmoofLogo from '../../static/images/clients/logo_client_vanmoof.png'
import logo_partner_artefactLogo from '../../static/images/clients/logo_partner_artefact.png'
import logo_partner_deptLogo from '../../static/images/clients/logo_partner_dept.png'
import logo_partner_merkleLogo from '../../static/images/clients/logo_partner_merkle.png'

import logo_client_rvshare from '../../static/images/clients/logo_client_rvshare.png'
import logo_client_wickey from '../../static/images/clients/logo_client_wickey.png'
import logo_client_youngcapital from '../../static/images/clients/logo_client_youngcapital.png'
import logo_partner_bossdata from '../../static/images/clients/logo_partner_bossdata.png'
import logo_partner_spindle360 from '../../static/images/clients/logo_partner_spindle360.png'
import {chooseRandom} from "../../lib/helpers";

export const client_logo = [
  {id: 'dpgmedia', src: logo_client_dpgmediaLogo},
  {id: 'brunel', src: logo_client_brunelLogo},
  {id: 'vanmoof', src: logo_client_vanmoofLogo},
  {id: 'logo_client_rvshare', src: logo_client_rvshare},
  {id: 'logo_client_wickey', src: logo_client_wickey},
  {id: 'logo_client_youngcapital', src: logo_client_youngcapital},
];

export const partner_logo = [
  {id: 'artefact', src: logo_partner_artefactLogo},
  {id: 'dept', src: logo_partner_deptLogo},
  {id: 'merkle', src: logo_partner_merkleLogo},
  {id: 'logo_partner_bossdata', src: logo_partner_bossdata},
  {id: 'logo_partner_spindle360', src: logo_partner_spindle360},
];

const stage1 = {
  title: <>Improve marketing attribution<br/> without infringing upon users' privacy.</>,
  body: `By default, TraceDock fully anonymizes all data that is
        sent to Google Analytics. In this way our clients see
        the big picture, but cannot re-target single users.`,
  testemonial: 'TRUSTED BY 200+ CLIENTS',
  logos: client_logo
};

const stage2 = {
  title: <>Extend the lifetime of your cookies and<br/> add blocked data to Google Analytics.</>,
  body: `The growing popularity of ad-blockers, ETP in Firefox and ITP in Safari
        has only increased the importance to improve your analytics data,
        whilst respecting the privacy of your users.`,
  testemonial: 'SERVICED THROUGH 40+ PARTNERS',
  logos: partner_logo
};

function getBaseDataForStage(stage) {
  switch (stage) {
    case STAGE.LOGIN:
      return stage2;
    case STAGE.REGISTRATION:
      return stage1;
    case STAGE.COMPANY:
      return stage2;
    case STAGE.PRICE_PLAN:
      return stage1;
    case STAGE.CREDIT_CARD:
      return stage2;
    case STAGE.ADDITIONAL_FINANCIAL:
      return stage1;
    case STAGE.DOMAIN:
      return stage2;
    case STAGE.AGENCY_DEMO:
      return stage1;
    case STAGE.CORPORATE_ACCOUNT:
      return stage2;
    default:
      return {logos: []};
  }
}

function getDataForStage(stage) {
  let data = getBaseDataForStage(stage);
  return {
    ...data,
    logos: chooseRandom(data.logos, 3)
  }
}

export {
  getDataForStage
}
