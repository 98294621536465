import {toLocale} from "./helpers";

export var planData = [
  {
    stepSize: 100000,
    maxViews: 1000000,
    domains: 5,
    price: 99,
  },
  {
    stepSize: 200000,
    maxViews: 3000000,
    domains: 10,
    price: 199,
  },
  {
    stepSize: 250000,
    maxViews: 6000000,
    domains: 15,
    price: 449,
  },
  {
    stepSize: 1000000,
    maxViews: 15000000,
    domains: 20,
    price: 849,
  },
  {
    stepSize: 1000000,
    maxViews: 25000000,
    domains: 20,
    price: 999,
    isCustom: true
  },
];

// to adjust for a semi-non-linear scale
// we ensure that each section has approximately 10 bits

// calculate the maxSteps each basket has by keeping track of how many there are in each basket
let prevToStep = 0;
let prevMax = 0;

planData.map((view, idx) => {
  // calculate how large this bucket is in terms of slider points, to ensure later each bucker is nearly as large
  // also calculate from and to step that is used in rolling calculation later
  view.idx = idx;

  view.minViews = prevMax;
  view.bucketSize = Math.floor((view.maxViews - view.minViews) / view.stepSize);
  view.fromStep = prevToStep;
  view.toStep = prevToStep + view.bucketSize;

  // for next
  prevMax = view.maxViews;
  prevToStep = view.toStep;

  // for selector in agency data
  view.key = view.price;
  view.value = view.isCustom
    ? `>${view.minViews / 1000000}m pageviews - Custom price (we will contact you)`
    : `${view.maxViews / 1000000}m pageviews, ${view.domains} domains, €${view.price} p.m.`

  // for custom
  if (view.isCustom) {
    view.price = '';
    view.button = 'Talk to sales';
    view.pageviewText = `> ${toLocale(view.minViews)} / mo`;
    view.domains = `> ${view.domains}`
  } else {
    view.price = `€${view.price} / mo`;
    view.button = 'Choose plan';
    view.pageviewText = `${toLocale(view.maxViews)} / mo`;
  }
  return null
});


export const sliderData = {

  // max is depending on the last toStep, add 1 step
  max: planData[planData.length - 1].toStep,

  step: 1,

  min: 1,

  // this should be choosen depending on what you want
  defaultValue: 10,
};

export const getPlan = (step) => {

  // we have a semi-linear stepsize, as such we must calculate the pageViews according to each step
  for (const plan of planData) {

    // in this basket we do these steps, except for the last slider slot, this is custom
    if (step <= plan.toStep) {
      let data = {
        ...plan,
        step: step,
        flat_fee: plan.price,
        n_domains: plan.domains,
        page_views: plan.minViews + (step - plan.fromStep) * plan.stepSize,
        isCustom: !!plan.isCustom,
      };

      return data;
    }
  }
};
