import React from "react";
import PropTypes from 'prop-types';
import { Button } from "../button/button";



export class PaymentForm extends React.Component {

  static propTypes = {
    admin: PropTypes.any,
    updatePayment: PropTypes.func,
    pendingPayment: PropTypes.bool,
  };

  static defaultProps = {
    admin: {},
    updatePayment: () => {
    },
  };

  constructor(props, context) {
    super(props, context);

    this.state = {
      payment_method: 'monthly invoice'
    };
  }

  render({admin, updatePayment, pendingPayment} = this.props) {
    return (

        <div style={{minHeight: 300, marginTop: 24}}>


              <p>We will send you an invoice each month,
                which you can pay within <span className='bold'>14 days</span> after receiving.</p>

              <Button
                style={{marginTop: 24}}
                title='Confirm payment plan'
                isLoading={pendingPayment}
                onClick={() => updatePayment({uuid: admin.uuid, payment_method: 'monthly invoice'})}
              />

              <p style={{textAlign: 'center', marginTop: 6}}>You can cancel the subscription each month</p>

          </div>
    );
  }
}
