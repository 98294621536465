import React from 'react';
import PropTypes from 'prop-types';
import {livePage} from "../../pages/live"
import { Router } from '../../router';
import { AlertDialog } from '../alert/alert';
import { Outlet, Link } from "react-router-dom";
import { Redirect } from "react-router-dom";
import { useHistory } from "react-router-dom";
import {Routes, Route, useNavigate} from 'react-router-dom';

import './loader.scss';

import {store} from "../../index"

export class Loader extends React.Component {

  static propTypes = {
    size: PropTypes.number, // number, string, any
    topColor: PropTypes.string,
    style: PropTypes.any
  };

  static defaultProps = {
    size: 50,
    topColor: '#3498db',
    style: {}
  };

  constructor(props, context) {
    super(props, context);
    // getCmSSOWhenAvailable();  
    this.state = {};
  }

  render() {
    return (
      <div className='loaderWrapper' style={this.props.style}>

        <div className="loader" style={{
          width: this.props.size,
          height: this.props.size,
          borderWidth: (this.props.size / 6),
          borderTopColor: this.props.topColor
        }} />

      </div>
    );
  }

}

// function getCmSSOWhenAvailable() {
//   let cmSSO;
//   let account;
//   //Set interval to check if header information is available
//   var interval = window.setInterval(function () {
//     if (window && window.getCmSSO && window.getCmAccount) {
//       cmSSO = window.getCmSSO();
//       account = window.getCmAccount();
//       if (cmSSO === null) {
//         //not logged in
//       } else if (account === null) {
//         //Not logged into an organization/account
//       }
//       //Check if logged in and into account
//       if (cmSSO !== undefined && cmSSO !== null && account !== undefined && account !== null) {
//         //If we get the results we can clear interval and store data
//         window.clearInterval(interval)
//         //Add to redux state
//         store.dispatch({
//           type: 'SET_CMACCOUNT_CREDENTIALS',
//           cmSSOAccountGuid: window.getCmSSO(),
//           cmLogicalAccountGuid: window.getCmAccount()
//         })
//         store.dispatch({
//           type: 'SET_AUTHENTICATED'
//         })
//           console.log("Navigating to live")
//       }
//     }

//   }, 1000);
// }

