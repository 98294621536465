import React from "react";
import {connect} from "react-redux";
import GetStartedContainer from "./GetStartedContainer";

import {STAGE} from "./constants";


let LoginPage = ({account, history, persist}) => {

  if (persist.isAuthenticated) {
    // navigate to main page
    if (account.get_started > 0) {
      history.push('/get_started')
    } else {
      history.push('/')
    }
  }
  return (
    <GetStartedContainer currentStage={STAGE.LOGIN} history={history}/>
  )
};

const mapStateToProps = (state) => ({
  persist: state.persist,
  account: state.account
});

export default LoginPage = connect(mapStateToProps, undefined)(LoginPage);
