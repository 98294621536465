import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';

import {deviceActions} from "../../redux/actions";
import {WikiRenderUnconnected} from "./wikiRender.unconnected";

class WikiRender extends React.Component {
  static propTypes = {
    // only aspect required, remaining is in connect
    path: PropTypes.string,
    data: PropTypes.any,
    local: PropTypes.bool,
  };

  static defaultProps = {
    path: 'privacy/privacy-statement',
    data: null,
    local: false
  };

  render({path, wiki, addWikiPage, data, local} = this.props) {
    return <WikiRenderUnconnected path={path} data={data} wiki={wiki} addWikiPage={addWikiPage} local={local}/>
  }
}

const mapStateToProps = (state) => {
  return {
    wiki: state.device.wiki
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    addWikiPage: (data) => dispatch(deviceActions.addWikiPage(data)),
  }
};

const connectedContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(WikiRender);

export {connectedContainer as WikiRender};
