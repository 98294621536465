import {createStore, applyMiddleware, compose} from "redux";
import thunk from 'redux-thunk';
import logger from 'redux-logger'
import {persistStore, persistReducer} from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import {api} from "../lib/api";
import {rootReducer} from "./reducer";


export const initStore = () => {

  let persistConfig = {
    key: 'root',
    storage: storage,
    whitelist: ['persist']
  };

  // Logger only during development
  let middleWares = [thunk];
  // if (api.config.debug_redux) {
  //   middleWares.push(logger)
  // }

  const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
  let store = createStore(
    persistReducer(persistConfig, rootReducer),
    composeEnhancers(
      applyMiddleware(...middleWares)
    )
  );

  let persistor = persistStore(store, null, () => {
    // export token to api
    let state = store.getState();

    // set token in api so we can make auth calls again, and store for dispatch
    api.token = state.persist.access_token;
    api.store = store;


  });

  let purge = persistor.purge();

  return {store, persistor}

};
