import React from 'react';
import {PropTypes} from 'prop-types';
import styles from './field.module.scss';

const FieldError = ({message}) => {
  return (
    <>
      {message && <p className={`small ${styles.fieldError}`}>{message}</p>}
    </>
  )
};

FieldError.propTypes = {
  message: PropTypes.string
};

export default FieldError;
