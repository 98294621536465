import {dashboardState} from './dashboard.state';
import {normalizeEvents} from "./dashboard.normalizers";
import {toLocale} from "../../lib/helpers";

export function dashboardReducer(state = dashboardState, action) {
  switch (action.type) {

    case 'SET_FILTER':
      return {
        ...state,
        selectedFilters: action.filters
      };

    case 'RESET_FILTER':
      let newDeletedFilters = {...state.selectedFilters};
      newDeletedFilters[action.key] = {...dashboardState.selectedFilters[action.key]};
      return {
        ...state,
        selectedFilters: newDeletedFilters
      };

    case 'SET_LIVE_EVENTS':
      // reset current events of initial one's
      let events_set = normalizeEvents(action.events ? action.events : state.events);
      return {
        ...state,

        events: events_set,

        events_all: toLocale(action.data.events_all, 0),
        events_count: toLocale(action.data.events_count, 0),
        events_blocked: toLocale(action.data.events_blocked, 0),
        events_blocked_perc: Math.round(1000 * action.data.events_blocked / action.data.events_count) / 10 + '%',
        cookie_recovery: toLocale(action.data.cookie_recovery, 0),
      };

    case 'RESET_DASHBOARD_STATE':
      return {
        ...dashboardState
      };

    default:
      return state;
  }
}
