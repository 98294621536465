import React, {useMemo} from 'react';
import styles from './infoContainer.module.scss';
import logo from '../../../static/images/logo_light.png'

const InfoContainer = ({title, body, testemonial, logos}) => {

  const logosContent = useMemo(
    () => logos.map((logo) => <img key={logo.id} src={logo.src} alt={logo.alt} height='45px'/>),
    [logos]
  );

  return (
    <div className={styles.info}>
      <div className={styles.content}>
        <img src={logo} alt="Logo" height="45px"/>
        <div className={styles.content_text}>
          <h3 className='spacer'>{title}</h3>
          <p className='white'>{body}</p>
        </div>
      </div>
      <div className={styles.footer}>
        <h5 className='white spacer' style={{paddingTop: 12}}>{testemonial}</h5>
        <div>
          {logosContent}
        </div>
      </div>
    </div>
  )
};

export default InfoContainer;
