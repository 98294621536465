import React from "react";
import PropTypes from 'prop-types';

import { Input } from "../input/input";
import { Tag } from "../tag/tag";
import { bin, dev_active, exclamation, Icon, idstitching, idstitching_active, nested, plus, question } from "../icon/icon";
import { colors } from "../../theme/colors";
import { setDeepVal } from "../../lib/helpers";
import { TagOrInput } from "../tagOrInput/tagOrInput";

import './customDimensionsObject.scss'
import { Loader } from "../loader/loader";
import { Popup, PopupPosition } from "../popup";

const CodeModal = React.lazy(() => import("./codeModal"));

// config
export const baseFieldsData = {
    field_name: '',
    field_type: 'l',
    field_value: '',
    script: '',
    action: 'add',
    children: []
};


const initialScript = `function parse(field_value, field_name, inbound_data) {
  /*
  note: write in Javascript ECMA 5.1
  input:
  - field_value: found value that will be forwarded.
  - field_name: string, lookup name in the data.
  - inbound_data: object, raw data as in the example.
  */

  // add here your alteration based in the input
  // ...
  
  // return the value you require 
  return field_value;
}`;


const possibleLookupTypes = [
    { key: 'l', value: 'lookup' },
    { key: 'c', value: 'constant' },
    { key: 'a', value: 'array' },
    // { key: 'ia', value: 'index array' },
    { key: 'o', value: 'object' },
];

const possibleCustomDimensionTypes = [
    { key: 'event_param', value: 'event_params' },
    { key: 'user_property', value: 'user_properties' }
];

export class CustomDimensionsObject extends React.Component {

    /*
        Convenience component as the rows in the eventData is nested
    */
    static propTypes = {
        readOnly: PropTypes.bool,
        fields: PropTypes.any,
        customDimensions: PropTypes.any,
        availableFieldPaths: PropTypes.array,
        parent: PropTypes.string,
        level: PropTypes.number,
        onUpdate: PropTypes.func,
        onUpdateCustomDimensions: PropTypes.func,
        invalidObjects: PropTypes.any,
        editReadOnlyFields: PropTypes.bool,
        canAdd: PropTypes.bool,

        unique_field: PropTypes.string,
        setUniqueField: PropTypes.func,

        possibleFields: PropTypes.any,
        fieldDescriptions: PropTypes.any,

        stitchfield: PropTypes.string,
    };

    static defaultProps = {
        readOnly: false,
        invalidObjects: {},
        editReadOnlyFields: false,
        canAdd: true,

        unique_field: null,
        setUniqueField: null,
        fieldDescriptions: null
    };

    constructor(props, context) {
        super(props, context);
        this.state = {

            // data for script modal
            showCodeModal: false,
            script: '',
            path: null,
            idx: null,
            row: null,
        };
    }

    _update_row(path, idx, newObject) {
        // update element within parent of fields
        let newFields = [...this.props.customDimensions];
        setDeepVal(newFields, path, idx, newObject);
        this.props.onUpdateCustomDimensions(newFields)
    }

    _saveScript() {
        if (this.state.script !== initialScript) {
            this._update_row(this.state.path, this.state.idx, {
                ...this.state.row,
                script: this.state.script,
                action: this.state.row.action === 'add' ? 'add' : 'update'
            })
        }
        // and close
        this.setState({ script: '', showCodeModal: false })

    }

    _render_row_custom_dimensions(idx, row, availableFieldPaths, path, parent, level, editReadOnlyFields) {
        let readOnlyValue = !editReadOnlyFields && this.props.fieldDescriptions && this.props.fieldDescriptions[row.field_name] && this.props.fieldDescriptions[row.field_name].readOnlyValue;
        let fieldDescription = this.props.fieldDescriptions && this.props.fieldDescriptions[row.field_name];

        return (

            <div className={`row ${row.action === 'delete' ? 'delete' : ''}`} key={`${level}_el_${idx}`}>
                {/* NOTE: rows are build up c. */}

                {level > 0 && Array.apply(0, new Array(level)).map((_n, idx) => {
                    return (
                        <div style={{ width: 25 }} key={`${level}_white_${idx}`} className='cell'>
                            {idx === level - 1
                                ? <Icon icon={nested} color={colors.brand.primary} size={18} />
                                : <p>{' '}</p>
                            }
                        </div>
                    )
                })}

                <div className='cell' style={{ width: 200, display: 'flex', alignItems: 'center' }}>

                    <Input
                        style={{ minWidth: 120 }}
                        type="text"
                        readOnly={this.props.readOnly || !this.props.canAdd}
                        choices={this.props.possibleFields}
                        initial={row.field_name}
                        isInvalid={this.props.invalidObjects[`${idx}_${row.field_name}_field_name`]}
                        onChange={(value) => this._update_row(path, idx, {
                            ...row,
                            field_name: value,
                            action: row.action === 'add' ? 'add' : 'update',
                            isReadOnly: (this.props.fieldDescriptions && !!this.props.fieldDescriptions[value]),
                            field_value: (this.props.fieldDescriptions && this.props.fieldDescriptions[value]) ? this.props.fieldDescriptions[value].readOnlyValue : row.field_value,
                            field_type: (this.props.fieldDescriptions && this.props.fieldDescriptions[value]) ? 'l' : row.field_type
                        })}
                    />
                    {this.props.fieldDescriptions && <Popup
                        visible={fieldDescription && !!fieldDescription.caption}
                        content={<span
                            className='small'>{fieldDescription ? fieldDescription.caption : 'unknown field'} {fieldDescription && row.field_name !== fieldDescription.title && ` (${row.field_name})`}</span>}
                        initialOpen={false}
                        stayOpen={false}
                        position={PopupPosition.RIGHT_CENTER}
                        minWidth={150}
                    >
                        <Icon icon={fieldDescription ? question : exclamation} color={fieldDescription ? colors.brand.darkgray_lighter : colors.brand.alert} size={16} />
                    </Popup>}
                </div>

                <div className='cell' style={{ width: 150 }}>
                    {readOnlyValue ? <Tag>Automatic</Tag> :
                        <Input
                            type="text"
                            readOnly={this.props.readOnly}
                            initial={row.field_type}
                            choices={possibleLookupTypes}
                            isInvalid={this.props.invalidObjects[`${idx}_${row.field_name}_field_type`]}
                            onChange={(value) => {
                                this._update_row(path, idx, {
                                    ...row,
                                    field_type: value,
                                    children: row.children && row.children.length === 0 && (value === 'a' || value === 'o' || value === 'ia') ? [{ ...baseFieldsData }] : row.children,
                                    action: row.action === 'add' ? 'add' : 'update'
                                })
                            }}
                        />
                    }
                </div>

                <div className='cell' >
                    <TagOrInput
                        value={row.field_value}
                        readOnly={this.props.readOnly || row.field_type === 'o'}
                        availableFieldPaths={row.field_type === 'c' ? [] : availableFieldPaths}
                        choices={fieldDescription ? fieldDescription.choices : null}
                        isInvalid={this.props.invalidObjects[`${idx}_${row.field_name}_field_value`]}
                        onChange={(value) => this._update_row(path, idx, {
                            ...row,
                            field_value: value,
                            action: row.action === 'add' ? 'add' : 'update'
                        })}
                    />
                </div>
                <div className='cell' style={{ width: 250 }}>
                    <TagOrInput
                        value={row.event_type}
                        readOnly={false}
                        // availableFieldPaths={["event_property", "user_property"]}
                        choices={possibleCustomDimensionTypes}
                        // isInvalid={this.props.invalidObjects[`${idx}_${row.field_name}_field_value`]}
                        onChange={(value) => 
                            this._update_row(path, idx, {
                            ...row,
                            // field_value: value,
                            action: row.action === 'add' ? 'add' : 'update',
                            event_type: value
                        })
                    }
                    />
                </div>

                {row.optional && (
                    <div className='cell' style={{ width: 65 }}>
                        <Tag>optional</Tag>
                    </div>
                )}

                {!this.props.readOnly && this.props.editReadOnlyFields
                    && (row.field_name === this.props.stitchfield) && (
                        <Popup
                            content={
                                <p style={{ width: 200 }}>This field is used to stitch your user to the client session as is stored in the identify event.</p>
                            }
                            initialOpen={false}
                            stayOpen={false}
                            position={PopupPosition.LEFT_CENTER}
                        >
                            <div className='cell clickable'
                                style={{ width: 30 }}
                                onClick={() => {
                                    // setup ID stitching
                                    this._update_row(path, idx, {
                                        ...row,
                                        stitch: !row.stitch,
                                        action: row.action === 'add' ? 'add' : 'update'
                                    })
                                }}
                            >
                                <Icon icon={row.stitch ? idstitching_active : idstitching} color={colors.brand.primary} size={32} />
                            </div>
                        </Popup>
                    )}

                {this.props.canAdd &&
                    <div className='cell clickable'
                        style={{ width: 34 }}
                        onClick={(script) => this.setState({ path, idx, row, showCodeModal: true, script: row.script ? row.script : initialScript })}>
                        <Icon icon={dev_active} color={row.script ? colors.brand.primary : colors.brand.gray} size={32} />
                    </div>}

                {this.props.setUniqueField && (
                    <Popup
                        content={
                            <p style={{ width: 200 }}>Select the field to de-duplicate your events.</p>
                        }
                        initialOpen={false}
                        stayOpen={false}
                        position={PopupPosition.LEFT_CENTER}
                    >
                        <div className='cell clickable'
                            style={{ width: 50 }}
                            onClick={() => this.props.setUniqueField(row.field_name === this.props.unique_field ? null : row.field_name)}
                        >
                            <Tag type={row.field_name === this.props.unique_field ? 'new' : null}>unique</Tag>
                        </div>
                    </Popup>
                )}

                <div className='cell' style={{ width: 30 }}>
                    {(!this.props.readOnly && this.props.canAdd)
                        ? <Icon size={24} className='clickable' icon={row.action === 'delete' ? plus : bin}
                            color={colors.brand.primary}
                            onClick={() => {
                                let newAction = row.action !== 'delete' ? 'delete' : 'update';
                                // walk over all items to delete
                                this._update_row(path, idx, {
                                    ...row,
                                    action: newAction
                                })
                                this.setState();
                            }} />

                        : <p>{' '}</p>
                    }
                </div>
                
            </div>
        )
    }

    _render_set_fields(customDimensions, availableFieldPaths, path, parent, level, editReadOnlyFields) {
        // recursive model to render children of pieces of fields with spacing
        let output = [];
        if (customDimensions.length > 0) {
            customDimensions.map((row, idx) => {

                // 1. depending on row type, we either create a new row or map a new object
                output.push(this._render_row_custom_dimensions(idx, row, availableFieldPaths, path, parent, level, editReadOnlyFields));

                // if there are any children, render those choices
                if ((row.field_type === 'a' || row.field_type === 'o' || row.field_type === 'ia') && row.children && row.children.length > 0) {

                    // 2. parent nesting with value to filter down choice, nest if parent exists (not null or not -)
                    let newParent = parent && parent !== '-' ? parent + '.' + row.field_value : row.field_value;

                    let newAvailableFieldPaths = [];
                    if (!newParent || newParent === '-' || row.field_type === 'ia') {
                        newAvailableFieldPaths = availableFieldPaths;
                    } else {
                        // filter the existing ones, but replace the value without the parent string to make it nicer looking.
                        availableFieldPaths.map((path) => {
                            if (path.value.startsWith(newParent)) {
                                newAvailableFieldPaths.push({ key: path.key, value: path.value.replace(newParent, '') })
                            }
                            return null
                        });
                    }

                    let newPath = (path ? path + '.' : '') + idx + '.children';
                    output = output.concat(this._render_set_fields(row.children, newAvailableFieldPaths, newPath, newParent, level + 1, editReadOnlyFields));
                }
                return output
            })
        }

        // add new button below
        if (!this.props.readOnly && this.props.canAdd) {
            output.push(<div className='row' key={`bt_${level}`}>
                {level > 0 && Array.apply(0, new Array(level)).map((_n, idx) => {
                    return (
                        <div style={{ width: 25 }} key={`${parent}_w2_${idx}`} className='cell'>
                            {idx === level - 1
                                ? <Icon icon={nested} color={colors.brand.primary} size={18} />
                                : <p>{' '}</p>
                            }
                        </div>
                    )
                })}

                <div className='cell'>
                    <div className='addNew clickable'
                        onClick={() => this._update_row(path, -1, { ...baseFieldsData })}
                    >
                        <p className='small center'>+ add new</p>
                    </div>
                </div>
            </div>);
        }
        return output
    }

    render({ fields, availableFieldPaths, editReadOnlyFields, customDimensions } = this.props) {
        return (
            <div className='DataFieldObject'>

                {this.state.showCodeModal && (
                    // fallback component should be some spinner
                    <React.Suspense fallback={<Loader style={{ marginTop: '20vh', marginLeft: '48%' }} />}>
                        <CodeModal
                            value={this.state.script}
                            onChange={(script) => this.setState({ script })}
                            onCancel={() => this.setState({ showCodeModal: false, script: '' })}
                            onSubmit={() => this._saveScript()}
                        />
                    </React.Suspense>
                )}

                <div className='row'>
                    <div className='header' style={{ width: 250 }}><h5>Field</h5></div>
                    <div className='header' style={{ width: 150 }}><h5>Type</h5></div>
                    <div className='header' style={{ width: 300 }}><h5>Value</h5></div>
                    <div className='header'><h5 className="propertyTypeHeader">PropertyType</h5></div>
                </div>

                {this._render_set_fields(customDimensions, availableFieldPaths, '', '', 0, editReadOnlyFields)}
            </div>
        )
    }
}
