import React, {useCallback, useState} from 'react';
import {connect} from 'react-redux';
import {PropTypes} from 'prop-types';
import SectionTitle from '../SectionTitle';
import styles from '../sectionContainer.module.scss';
import {Form, InputField} from '../../../../components/form';
import {accountActions} from '../../../../redux/actions';
import {isRequiredValidator} from '../../../../lib/validators';
import {STAGE} from "../../constants";

let CompanySection = ({goNext, isPendingUpdate, saveCompany}) => {
  const onConfirm = useCallback(
    async (data) => {

      setPending(true);
      await saveCompany(data);

      goNext(data.type === 'agency' ? STAGE.AGENCY_DEMO : STAGE.PRICE_PLAN);

      // after this we set pending false, pending is also realized via redux
      setPending(false);
    },
    [goNext]
    )
  ;

  const [pending, setPending] = useState(false);

  return (
    <div className={styles.section_content}>
      <SectionTitle
        title='Your company'
        subtitle='Tell us about your company so we can customize your account.'
      />
      <Form
        buttonText='Next'
        isLoading={isPendingUpdate || pending}
        onSave={onConfirm}
        validators={{
          company_name: isRequiredValidator,
          type: isRequiredValidator,
        }}
      >

        <InputField
          name='company_name'
          label='Company name'
          focus={true}
        />
        <InputField
          name='type'
          label='Company type'
          enterIsSubmit={true}
          choices={[{key: 'ecommerce', value: 'E-commerce'},
            {key: 'saas', value: 'SaaS'},
            {key: 'marketplace', value: 'Marketplace'},
            {key: 'media', value: 'Media'},
            {key: 'agency', value: 'Online agency / consultancy'},
            {key: 'other', value: 'Other'}]}
        />
      </Form>
    </div>
  )
};

CompanySection.propTypes = {
  goNext: PropTypes.func,
  history: PropTypes.any
};

const mapDispatchToProps = (dispatch) => {
  return {
    saveCompany: (data) => dispatch(accountActions.saveCompany(data))
  }
};

const mapStateToProps = (state) => ({
  isPendingUpdate: state.account.isPendingUpdate
});

export default CompanySection = connect(mapStateToProps, mapDispatchToProps)(CompanySection);
