import React from "react";
import {connect} from "react-redux";

import {colors} from "../../theme/colors";
import {anchor, clock, cookieless, forward, Icon, info, lock_active, pause, person_fill, star} from "../../components/icon/icon";
import {dashboardActions} from '../../redux/actions';

import {Dots} from 'react-activity';

import './live.scss';
import {TableCell, TableRow} from "../../components/tableRow/tableRow";
import {Layout} from "../../components/layout/layout";
import {EventDetail} from "./eventDetail";
import {Filter} from "../../components/filter/filter";
import {Modal} from "../../components/modal/modal";
import {trackEvent} from "../../lib/trackers";
import { store } from "../../index.js";
import { DomainSelector } from "../../components/domainSelector/domainSelector";


const MAXRUN = 4;

class LivePage extends React.Component {

  constructor(props, context) {

    store.subscribe(() => {
      const cmacc = store.getState().cmaccount;
      if(cmacc.cmSSOAccountGuid && cmacc.cmLogicalAccountGuid && cmacc.traceDockKey){
        //We got the Guids and accountKey, now it's possible to retrieve anything related to this account+user
      }

    });
    super(props, context);

    this.state = {
      paused: false,
      run: 0,
      openEventIdx: -1,
      showInfo: false,
    };

    // for hln set to 1hour
    if (props.persist.domain && props.persist.domain.name.includes('hln')) {
      let filters = {...props.dashboard.selectedFilters};
      filters['timestamp']['selectedValues'] = ['60min'];
      filters['timestamp']['visible'] = true;
      props.setFilter({filters})
    }

    // if navigated with props on filter, add this
    if (props.history.location.state && props.history.location.state.event) {
      let filters = {...props.dashboard.selectedFilters};
      if (props.history.location.state.event === 'id_storage') {
        filters['event']['selectedValues'] = ['id_storage'];
      } else {
        filters['event']['selectedValues'] = ['other', props.history.location.state.event];
      }
      filters['event']['visible'] = true;
      props.setFilter({filters})
    }

    // then setup refresh
    this._setRefresh()
  }

  componentWillUpdate(nextProps, nextState, nextContext) {
    if (nextProps.persist.domain.id && this.props.persist.domain.id !== nextProps.persist.domain.id) {
      this.props.resetDashboardState()
    }
  }

  _resetSearch() {
    this.setState({run: 0, paused: false}, () => this._refresh());
  }

  _setRefresh() {
    setTimeout(()=> this._refresh(), 1000);
    // this._refresh();
    this.reloadEvents = setInterval(() => this._refresh(), 10000)
  }

  _clearRefresh() {
    this.setState({run: 0});
    clearInterval(this.reloadEvents)
  }

  componentWillUnmount() {
    // clear timeout on unmount
    this._clearRefresh()
  }

  _triggerPause() {
    this.setState({paused: !this.state.paused, openEventIdx: -1}, () => {
      // clear or set reloads
      if (this.state.paused) {
        this._clearRefresh()
      } else {
        this._setRefresh()
      }
    })
  }

  _refresh() {
    if (this.state.run < MAXRUN && !this.state.paused) {
      if (this.props.persist.domain.id){
        this.props.getLiveEvents({uuid: this.props.persist.domain.id, filters: this.props.dashboard.selectedFilters})
        this.setState({run: this.state.run + 1});
      }      
    } else {
      this.setState({paused: true, run: 0})
    }
  }

  _selectEvent(index) {
    // close event or open it, but either way pauze data
    this.setState({paused: true, openEventIdx: index === this.state.openEventIdx ? -1 : index},
      () => {
        this.state.paused
        // if (this.state.paused) {
        //   this._clearRefresh()
        // } else {
        //   this._setRefresh()
        // }
      })
  }

  _createExternalRule(row) {
    this.props.history.push('/external_events', {data: row.inbound})
  }

  render({account, persist, dashboard, history} = this.props) {

    // determine timestamp value
    let selectedTimeStamp = '';
    dashboard.selectedFilters.timestamp.choices.some((choice) => {
      // continue on some, untill you found a match
      if (choice.key === dashboard.selectedFilters.timestamp.selectedValues[0]) {
        selectedTimeStamp = choice.value;
        return true
      }
      return false
    });

    return (
      <Layout history={this.props.history} withPadding={false}>

        <div className='livePage'>

          <Modal visible={this.state.showInfo} onClose={() => this.setState({showInfo: false})} width='400px'>
            <h2>Docs</h2>
            <h6 className='spacer' style={{marginBottom: 24}}>Explaining the numbers</h6>

            <p>The live overview contains all latest events and can be used to verify if the installation of TraceDock is done properly. In the top-right you see an overview of your selection containing the following:</p>

            <p style={{marginTop: 12}}><span className='bold'>Forwarded events</span>: all events that are forwarded, such as cases of ad blockers and browsers with tracking prevention in which Google
              Analytics is blocked and forwarded.</p>
            <p><span className='bold'>Recovered sessions</span>: all sessions where a cookie has successfully recovered in cases of ITP. Please note that you will see a recovery of sessions starting 7
              days after TraceDock has been installed on your website.</p>

            <p style={{marginTop: 12}}>
              Furthermore note that you can filter on blocked events and cookie recovery for further analysis.
            </p>

            <p style={{marginTop: 12}}>
              If you have any questions, contact us on <span className='link clickable' target="_blank" rel="noopener noreferrer" title='Mail us'
                                                             onClick={() => trackEvent('contact', 'email', 'home_page', 1)}
                                                             href="mailto:support@tracedock.com">support@tracedock.com
              </span> for more information. We love to help.
            </p>

          </Modal>

          <div className='section'>

            <div className='content liveTop'>

              <div>
              <h6 className='white regular' style={{maxWidth: 400}}>View the most recent events logged in TraceDock.
                  Have a look how your events are
                  set up and adjust them according to your preferences.</h6>
                {/* <h1 className='white' style={{marginBottom: 24}}><span
                  className='light'>https://</span>{persist.domain.slug}</h1> */}
                  <div style={{maxWidth: 400}}><DomainSelector history={history} allowDemo={this.state.showModal === ''} /></div>

              </div>

              <div className='info clickable' onClick={() => this.setState({showInfo: true})}>
                <Icon className='info' icon={info}/>

                <h4 className='white title' style={{marginRight: 24}}>{selectedTimeStamp}: {dashboard.events_all} events</h4>

                <div className='row'>
                  <div className='counter'>
                    <h1 className='white light fadein'>{dashboard.events_blocked}</h1>
                    <h5 className='white' style={{display: 'flex', alignItems: 'center'}}><img alt='blocked' height='20' src={require('./res/blocked.png')}/>forwarded
                      events {dashboard.events_blocked_perc}</h5>
                  </div>
                  <div className='counter' style={{paddingLeft: 12}}>
                    <h1
                      className='white light fadein'>{dashboard.cookie_recovery}</h1>
                    <h5 className='white' style={{display: 'flex', alignItems: 'center'}}><img alt='cookies' height='20' src={require('./res/cookies.png')}/>recovered sessions</h5>
                  </div>
                </div>
              </div>

            </div>

          </div>

          <div className='section'>

            <div className='badge'>

              <div className='badgeButton' onClick={() => this._triggerPause()}
                   style={{
                    backgroundColor: this.state.paused ? colors.brand.blue40 : colors.brand.blue110
                   }}>

                {this.state.paused
                  ? <Icon icon={pause} color={'white'}/>
                  : <Dots speed={0.5} color={'white'} size={12}/>
                }
                <p className='bold' style={{paddingLeft: 9}}>{this.state.paused ? 'paused' : 'Live events'}</p>
              </div>

              <div className='progressbar'
                   style={{backgroundColor: this.state.paused ? colors.brand.white : colors.brand.primary}}/>
            </div>

          </div>


          <div className='section'>

            <div style={{paddingLeft: 37}}>
              <Filter
                currentFilters={dashboard.selectedFilters}
                onUpdateFilter={(data) => {
                  this.props.setFilter(data).then(() => this._resetSearch())
                }}
                onResetFilter={(key) => {
                  this.props.resetFilter(key).then(() => this._resetSearch())
                }}
              />
            </div>

            <div className='content table'>

              {dashboard.events.length === 0
                ?
                <div className='no-events'>
                  <Icon icon={clock} color={colors.brand.gray_light} size={40}/>
                  <p style={{color: colors.brand.gray}}>... waiting for events</p>
                </div>
                :
                <div className='tableWrapper'>
                  <TableRow
                    type='titles'
                    cells={[
                      TableCell('Time', clock),
                      TableCell('Event', star),
                      TableCell('IP address', anchor),
                      TableCell('Client ID', person_fill),
                    ]}
                  />

                  {dashboard.events.map((row, index) => {
                    let active = row.time_ago === 'Now';
                    return (
                      <div key={index}>
                        <div className='clickable' onClick={() => this._selectEvent(index)}>
                          <TableRow
                            type={`line ${active ? 'active' : ''}`}
                            cells={[
                              <p>{row.time}</p>,
                              <p>{row.event_name ? row.event_name : row.event}</p>,
                              TableCell(row.uip, lock_active),
                              TableCell(row.cid, row.is_blocked ? forward : null, row.cookieless ? cookieless : null)
                            ]}
                          />
                        </div>

                        {this.state.openEventIdx === index && (
                          <EventDetail
                            onAdd={row.type === 'unknown' ? () => this._createExternalRule(row) : ''}
                            event={row}
                          />
                        )}
                      </div>
                    )
                  })}
                </div>
              }

            </div>

          </div>

        </div>
      </Layout>
    );
  }
}

// function mapStateToProps(state) {
//   return { state: state.getState() };
// }

// export default connect(mapStateToProps)(MyComponent)

const mapStateToProps = (state) => ({
  persist: state.persist,
  dashboard: state.dashboard,
  account: state.admin,
  cmaccount: state.cmaccount
});

const mapDispatchToProps = (dispatch) => {
  return {
    resetDashboardState: () => dispatch(dashboardActions.resetDashboardState()),
    getLiveEvents: (d) => dispatch(dashboardActions.getLiveEvents(d)),
    setFilter: (d) => dispatch(dashboardActions.setFilter(d)),
    resetFilter: (d) => dispatch(dashboardActions.resetFilter(d)),
  }
};

export default LivePage = connect(mapStateToProps, mapDispatchToProps)(LivePage);
