import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom';
import React from 'react';
import { connect } from "react-redux";
import useMedia from 'use-media';

import LivePage from "./pages/live";
import { AgencyDetail } from './pages/agency/agencyDetail';
import DevPage from "./pages/settings/dev";
import UserManagementPage from "./pages/settings/usermanagement";

import SigningPage from './pages/utils/signing';

import { PricePlanIframe } from './pages/utils/priceFrame';
import { AwaitLogin } from './components/awaitLogin/awaitLogin';
import GetStartedPage from "./pages/get_started/signup.Page";
import LoginPage from "./pages/get_started/login.Page";
import PasswordResetPage from "./pages/auth/password_reset";
import WelcomeLinkPage from "./pages/auth/welcome_link";
import PasswordLinkPage from "./pages/auth/password_link";
import EmailActivationPage from "./pages/auth/activate_email";
import NotFound from "./pages/utils/404_not_found";
import EventsPage from "./pages/rules/events";
import ExternalEvents from "./pages/rules/externalEvents";
import CookiesPage from './pages/rules/cookies';
import OnboardingPage from "./pages/installation/installation";
import ReviewTermsPage from './pages/utils/review_terms';
import GdprPage from "./pages/utils/gdpr";
import TermsPage from './pages/utils/terms';
import SecurityPage from "./pages/utils/security";
import MobileView from './components/mobileView/mobileView';
import accountActions from "./redux/actions";
import { ServerSideDemoPage } from "./pages/rules/serverside_demo";
import { useHistory } from "react-router-dom";

import { store } from "../src/index";
import organisationPage from './pages/organisation/organisationPage';

const getBaseName = (store) => {

  let baseTAG = store.getState().persist.baseURL;

  let l10n = getLanguageFromPath(window.location.href);
  let language = l10n ? l10n.language : null;
  let locale = l10n ? l10n.locale : null;

  const guidRegex = /[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}/;
  var currentLocation = window.location.href;
  //This if check is needed, if you click through platform to another account, it removed the / in the end which causes our baseURL
  //building logic to switch the logicalAccountGuid with the technicalLinkGuid, might be a weird fix but it works
  if(currentLocation.charAt(currentLocation.length - 1) !== '/'){
    currentLocation += '/';
  }
  const segments = currentLocation.split('/');

  var secondGuidParameter;
  var counter = 0;
  segments.forEach((seg) => {
    if(guidRegex.test(seg)){
      counter ++;
      if(counter == 2){
        secondGuidParameter = seg;
      }
    }
  });

  if(!secondGuidParameter){
    window.location.href = segments.slice(0, -1).join("/") + `/${store.getState().cmaccount.traceDockKey}/` + segments.slice(-1);
    secondGuidParameter = store.getState().cmaccount.traceDockKey
  }

  store.dispatch({
    type: 'SET_CURRENT_TENANTID',
    currentTenantId: secondGuidParameter
  })

  let baseURL = '';
  // if (!baseTAG) {
  baseURL = language ? `/${language}-${locale}/app/tracedock/${store.getState().cmaccount.cmLogicalAccountGuid}/${secondGuidParameter}/`
    : `/app/tracedock/${store.getState().cmaccount.cmLogicalAccountGuid}/${secondGuidParameter}/`;
  // }
  return baseURL

}

const getLanguageFromPath = (path) => {


  const parts = path.split("/").slice(1);
  const appPos = parts.indexOf("app");
  // const l10n = parts.slice(0, appPos)[0];
  // if (!l10n) {
  //     return null;
  // }

  for (let i = 0; i < parts.length; i++) {
    if (parts[i].includes("-") && !/\d/.test(parts[i])) {
      const [language, locale] = parts[i].split("-");
      return { language, locale }
    }
  }

  return null;

  // const [language, locale] = parts[2].split("-");
  // if(language === "app"){
  //   //This means language/locale is not found
  //   return null;
  // }
  // return {
  //     language,
  //     locale
  // };
};
// let l10n = getLanguageFromPath(window.location.href);
// let language = l10n ? l10n.language : null;
// let locale = l10n ? l10n.locale : null;



// Home route is connected, but redirects in both cases
const HomePrivateRouteComponent = ({ component: Component, isAuthenticated, is_implementing, ...rest }) => (
  <Route {...rest} render={(props) => {
    const is_agency = store.getState().cmaccount.is_agency;
    const agency_tenantid = store.getState().cmaccount.traceDockKey;
    const current_tenantid = store.getState().cmaccount.currentTenantId;
    if(is_agency && agency_tenantid == current_tenantid){
      return (
        isAuthenticated === true
          ? (
            is_implementing
              ? <Redirect to='/installation' />
              : <Redirect to='/agency' />
          )
          : <Redirect to='/agency' />
      )
    }
    return (
      isAuthenticated === true
        ? (
          is_implementing
            ? <Redirect to='/installation' />
            : <Redirect to='/live' />
        )
        : <Redirect to='/live' />
    )
  }} />
);

const HomePrivateRoute = connect(
  (state => ({
    isAuthenticated: state.persist.isAuthenticated,
    is_implementing: state.persist.domain && state.persist.domain.state === 'implementing',
  })), null, null, { pure: false }
)(HomePrivateRouteComponent);


const RedirectTo = ({ component: Component, url, ...rest }) => {
  Object.assign(document.createElement('a'), {
    target: '_self',
    href: url,
  }).click();
  return null;
}

// Private route is connected to Redux and will redirect to login if not authenticated
const PrivateRouteComponent = ({ component: Component, isAuthenticated, isGettingStarted, ...rest }) => (
  <Route {...rest} render={(props) => {
    const is_agency = store.getState().cmaccount.is_agency;
    const agency_tenantid = store.getState().cmaccount.traceDockKey;
    const current_tenantid = store.getState().cmaccount.currentTenantId;
    if(is_agency && agency_tenantid == current_tenantid){
      return (
        isAuthenticated === true
        ? (
          isGettingStarted
            ? <Redirect to='/get_started' />
            : <Component {...props} />
        )
        : <Redirect to='/agency' />
      )
    }
    return (

      isAuthenticated === true
        ? (
          isGettingStarted
            ? <Redirect to='/get_started' />
            : <Component {...props} />
        )
        : <Redirect to='/live' />

    )
  }} />
);

const PrivateRoute = connect(
  (state => ({ isAuthenticated: state.persist.isAuthenticated, isGettingStarted: state.persist.get_started > 0 })), null, null, { pure: false }
)(PrivateRouteComponent);


// Force logout component, then redirect to login
// const LogOutComponent = ({logout, ...rest}) => (
//   // force logout
//   <Route {...rest} render={(props) => {
//     logout(); return <Redirect to='/login'/>
//   }}/>
// );

// const LogOut = connect(
//   null, dispatch => ({logout: (data) => dispatch(accountActions.logout(data))}), null, {pure: false}
// )(LogOutComponent);

const DesktopRouter = () => {
  return (
    <Switch>
      <HomePrivateRoute exact path='/' />

      {/* Auth */}
      {/* <Route exact path='/password/:password_reset_code/:email' component={PasswordLinkPage}/> */}
      {/* <Route exact path='/welcome/:password_reset_code/:email' component={WelcomeLinkPage}/> */}
      {/* <Route exact path='/activate_email/:email_code/:new_email' component={EmailActivationPage}/> */}
      {/* <Route exact path='/reset_password' component={PasswordResetPage}/> */}

      {/* Login states */}

      <PrivateRoute exact path={`/live`} component={LivePage} />
      <PrivateRoute exact path={`/agency`} component={AgencyDetail} />

      <PrivateRoute exact path='/events' component={EventsPage} />
      <PrivateRoute exact path='/serverside_events' component={ExternalEvents} />
      <PrivateRoute exact path='/cookies' component={CookiesPage} />

      <PrivateRoute exact path='/developer' component={DevPage} />

      <PrivateRoute exact path='/team' component={UserManagementPage} />
      <PrivateRoute exact path='/organisation' component={organisationPage} />

      <PrivateRoute exact path='/installation' component={OnboardingPage} />

      <Route exact path='/review_terms/:uuid' component={ReviewTermsPage} />

      <Route exact path='/gdpr' component={GdprPage} />
      <Route exact path='/terms' component={TermsPage} />
      <Route exact path='/security' component={SecurityPage} />

      <Route exact path='/serverside_demo' component={ServerSideDemoPage} />

      <RedirectTo exact path='/docs/1-quick-startup-guide' url='https://docs.tracedock.com/installation/start' />
      <RedirectTo exact path='/docs/7-cloudflare-support' url='https://docs.tracedock.com/installation/cloudflare' />
      <RedirectTo path='/docs' url='https://docs.tracedock.com' />

      {/* Catch all 404 */}
      <Route component={NotFound} />
    </Switch>
  )
};

export const Router = () => {
  const isWide = useMedia({ minWidth: '400px' }, true);
  return (
    //Add check here, if no logicalAccountGuid, get directed to page that tells you to select an account
    // <BrowserRouter basename={language ? `/${language}-${locale}/app/tracedock/${store.getState().cmaccount.cmLogicalAccountGuid}/`
    // :"/app/tracedock/"+store.getState().cmaccount.cmLogicalAccountGuid+"/"}>
    <BrowserRouter basename={getBaseName(store)}>
      <Switch>

        {/* Pages to access via mobile */}
        {/* <LogOut exact path='/logout'/> */}
        {/* <Route exact path='/price_frame' component={PricePlanIframe}/> */}
        <Route exact path='/get_started' component={GetStartedPage} />
        {/* <Route exact path='/login' component={AwaitLogin}/> */}

        {/* <PrivateRoute exact path='/signing/:document/:uuid' component={SigningPage}/> */}

        {isWide && <DesktopRouter />}
        {!isWide && <Route component={MobileView} />}

      </Switch>
    </BrowserRouter>
  )

};
