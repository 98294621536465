import React from "react";
import PropTypes from 'prop-types';

import {Tag} from "../../../components/tag/tag";
import {BlankCard} from "../../../components/cards/blankCard";
import {Input} from "../../../components/input/input";
import {Table} from "../../../components/table/table";
import {colors} from "../../../theme/colors";
import {Icon, bin} from "../../../components/icon/icon";


const possibleLookupTypes = [
  {key: 'c', value: 'contains'},
  {key: 'nc', value: 'does not contain'},
  {key: 'e', value: 'equals'},
  {key: 'ne', value: 'does not equal'},
  {key: 'ex', value: 'exists (is not null)'},
  {key: 'sw', value: 'starts with'},
  {key: 'ew', value: 'ends with'},
  {key: 'lgte', value: 'length >='},
  {key: 'gte', value: '>='},
  {key: 'gt', value: '>'},
  {key: 'lte', value: '<='},
  {key: 'lt', value: '<'},
];

export const validate_external_triggers = (triggers) => {
  // validates triggers, returns invalidObjects
  let invalidObjects = {};

  // optional
  if (!triggers || triggers.length === 0) return true;

  triggers.map((row, idx) => {

    // validates triggers, is choices restricted to list or more than 0
    if (row.action !== 'delete') {
      if (row.key.length === 0) {
        invalidObjects[`${idx}_key`] = true
      }
      if (!possibleLookupTypes.some((obj) => obj.key === row.lookup_type)) {
        invalidObjects[`${idx}_lookup_type`] = true
      }
      if (row.value.length === 0 && row.lookup_type !== 'ex') {
        invalidObjects[`${idx}_value`] = true
      }
    }
    return true
  });

  return invalidObjects
};

export class CardExternalTriggers extends React.Component {

  static propTypes = {
    availableFieldPaths: PropTypes.array,
    triggers: PropTypes.array,
    invalidObjects: PropTypes.any,
    readOnly: PropTypes.bool,
    onUpdate: PropTypes.func,
  };

  static defaultProps = {
    availableFieldPaths: [],
    triggers: [],
    invalidObjects: {},
    readOnly: false,
    onUpdate: (data) => console.log('updating data: ', data),
  };

  constructor(props, context) {
    super(props, context);

    this.state = {
      newData: props.triggers
    };
  }

  header = [
    {key: 'key', value: 'element', help: null},
    {key: 'lookup_type', value: 'action', help: null},
    {key: 'value', value: 'value', help: null},
    {},
    {},
  ];

  _update_row(idx, newObject) {
    // update element and update element
    let newData = [...this.state.newData];
    newData[idx] = newObject;
    this.setState({newData: newData});
    this.props.onUpdate(newData);
  }

  componentWillUpdate(nextProps, nextState, nextContext) {
    if (nextProps.triggers !== this.state.newData) {
      this.setState({newData: nextProps.triggers})
    }
  }

  render({invalidObjects, readOnly, availableFieldPaths} = this.props) {
    return (

      <BlankCard
        collapsable={true}
        title='Conditions'
        subtitle='Only forward if the incoming data fulfills all conditions.'
        padding={false}>

        <Table header={this.state.newData.length === 0 ? [] : this.header}
               narrow={true}
               addDescription='Add condition'
               onAddNew={() => {
                 this._update_row(this.state.newData.length, {
                   key: '',
                   lookup_type: 'c',
                   value: '',
                   action: 'add'
                 })
               }}>

          {this.state.newData.length === 0
            ? <tr><td><p>No conditions set. Data received is always forwarded.</p></td></tr>
            : this.state.newData.map((row, idx) => (

            <tr key={idx} className={row.action === 'delete' ? 'delete' : ''}>
              {/* NOTE: rows are build up custom */}

              <td>
                <Input
                  type="text"
                  readOnly={false}
                  initial={row.key}
                  choices={availableFieldPaths}
                  isInvalid={invalidObjects[`${idx}_key`]}
                  onChange={(value) => this._update_row(idx, {
                    ...row,
                    key: value,
                    action: row.action === 'add' ? 'add' : 'update'
                  })}
                />
              </td>

              <td>
                <Input
                  type="text"
                  readOnly={false}
                  initial={row.lookup_type}
                  restrict={true}
                  choices={possibleLookupTypes}
                  isInvalid={invalidObjects[`${idx}_lookup_type`]}
                  onChange={(value) => this._update_row(idx, {
                    ...row,
                    lookup_type: value,
                    action: row.action === 'add' ? 'add' : 'update'
                  })}
                />
                {invalidObjects[`${idx}_lookup_type`] && (
                  <p className='small alert'>
                    Please select a valid choice
                  </p>
                )}
              </td>

              <td>
                <Input
                  type="text"
                  readOnly={readOnly || row.lookup_type === 'ex'}
                  initial={row.lookup_type === 'ex' ? '-' : row.value}
                  isInvalid={invalidObjects[`${idx}_value`]}
                  onChange={(value) => this._update_row(idx, {
                    ...row,
                    value: value,
                    action: row.action === 'add' ? 'add' : 'update'
                  })}
                />
              </td>

              <td style={{width: 75}}>
                {row.action === 'add' && <Tag type='new'>new</Tag>}
                {row.action === 'update' && <Tag type='update'>updated</Tag>}
                {row.action === 'delete' && <Tag type='alert'>delete</Tag>}
              </td>

              <td>
                {!readOnly && (
                  <Icon size={24} className='clickable' icon={bin} color={colors.brand.primary}
                        onClick={() => this._update_row(idx, {...row, action: row.action !== 'delete' ? 'delete' : 'update'})}/>
                )}
              </td>

            </tr>
          ))}
        </Table>
      </BlankCard>
    )
  }
}
