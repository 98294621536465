import React from "react";
import ReactMarkdown from 'react-markdown';
import {ModalLayout} from "../../components/modalLayout/modalLayout";
import {getWiki} from "../../components/wiki/wiki";

import './mardown.scss';

class GdprPage extends React.Component {

  constructor(props, context) {
    super(props, context);

    this.state = {
      gdpr_text: '',
    };
  }

  componentDidMount() {

    getWiki('gdpr.md', {}, true).then(
      (content) => {
        if (content !== '') this.setState({gdpr_text: content})
      }
    )
  }

  render({history, persist, account} = this.props) {
    return (
      <div className='markdownPage'>

        {this.state.gdpr_text && <ModalLayout width='800px' withLogo={true}>

          <h2>Data Protection Agreement</h2>
          <h6 style={{marginBottom: 42}}>How TraceDock is compliant with the EU General Data Protection Regulation.</h6>

          <ReactMarkdown source={this.state.gdpr_text}/>

        </ModalLayout>}
      </div>
    );
  }
}

// no redux
export default GdprPage;
