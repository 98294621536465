import React from "react";
import {connect} from "react-redux";

import {Loader} from "../../components/loader/loader";
import {accountActions} from "../../redux/actions";
import {InputCard} from "../../components/cards/inputCard";
import {emailValidator} from "../../lib/validators";
import {Button} from "../../components/button/button";
import {setAlertDialog} from "../../components/alert/alert";
import {SideModal} from "../../components/sideModal/sideModal";
import {updateIfNeeded} from "../../lib/helpers";


class ProfilePage extends React.Component {

  constructor(props, context) {
    super(props, context);

    updateIfNeeded(this.props.account.profile_updated, this.props.getProfile);
  }

  render({account, persist, dashboard, updateCreditor, show, onClose} = this.props) {
    return (
      <SideModal title='My profile' maxWidth={700}
                 hasSave={false}
                 show={show} onClose={() => onClose()}>

        {account.isPending && (<Loader style={{marginTop: '20vh', marginLeft: '48%'}}/>)}

        {!account.isPending && (
          <InputCard
            title='Personal details'
            subtitle='How may we address you?'
            onSave={(data) => this.props.updateProfile(data)}
            initialData={account.profile}
            objects={[
              {
                key: 'first_name',
                label: 'First name',
                type: 'text',
                validator: (value) => value && value.length > 0,
                optional: false,
                help: null
              },
              {
                key: 'last_name',
                label: 'Last name',
                type: 'text',
                validator: (value) => value && value.length > 0,
                optional: true,
                help: null
              }
            ]}
          />
        )}

        {!account.isPending && (
          <InputCard
            title='Email'
            subtitle='Your current email address'
            onSave={(data) => {
              setAlertDialog('Email sent', 'We send you an email on your new address. If you confirm this email we will update your email address accordingly.');
              return this.props.updateEmail(data);
            }}
            initialData={account.profile}
            objects={[
              {
                key: 'email',
                label: 'Email',
                type: 'text',
                validator: (value) => value && value !== account.email && emailValidator(value),
                exampleFormat: "einstein@company.com",
                optional: false,
                help: null
              },
              {
                key: 'password',
                label: 'Current password',
                type: 'password',
                validator: (value) => value && value.length > 0,
                optional: false,
                errorMessage: 'Please enter your password to verify your identify'
              },

            ]}
          />
        )}

        {!account.isPending && (
          <InputCard
            title='Password'
            subtitle='Would you like to change your password?'
            onSave={(data) => this.props.updatePassword(data)}
            objects={[
              {
                key: 'old_password',
                label: 'Current password',
                type: 'password',
                validator: (value) => value && value.length > 0,
                optional: false,
                help: null
              },
              {
                key: 'new_password',
                label: 'New password',
                type: 'password',
                validator: (value) => value && value.length >= 8,
                errorMessage: "Choose a password of 8 characters or digits long.",
                optional: false,
                help: null
              },
            ]}
          />
        )}

        {!account.isPending && (
          <Button
            title='Log out'
            style={{width: 100, marginTop: 70}}
            // onClick={() => this.props.logout()}
          />
        )}

      </SideModal>
    );
  }
}

const mapStateToProps = (state) => ({
  account: state.account,
  persist: state.persist
});

const mapDispatchToProps = (dispatch) => {
  return {
    getProfile: () => dispatch(accountActions.getProfile()),
    // logout: () => dispatch(accountActions.logout()),
    updateProfile: (data) => dispatch(accountActions.updateProfile(data)),
    updateEmail: (data) => dispatch(accountActions.updateEmail(data)),
    updatePassword: (data) => dispatch(accountActions.updatePassword(data)),
  }
};

export default ProfilePage = connect(mapStateToProps, mapDispatchToProps)(ProfilePage);
