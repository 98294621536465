import React from "react";
import { connect } from "react-redux";

import { colors } from "../../theme/colors";

import { accountActions, persistActions, rulesActions } from "../../redux/actions";
import { Loader } from "../../components/loader/loader";
import { BlankCard } from "../../components/cards/blankCard";
import { Table } from "../../components/table/table";
import { Tag } from "../../components/tag/tag";
import { bin, Icon, pencil } from "../../components/icon/icon";
import { formatDate, normalizeCurrency } from "../../lib/normalize_values";
import { updateIfNeeded } from "../../lib/helpers";
import { Input } from "../../components/input/input";
import { Button } from "../../components/button/button";
import { CreditorModal } from "./sections/creditorModal";
import { emailValidator } from "../../lib/validators";
import { planData } from "../../lib/priceData";
import { SideModal } from "../../components/sideModal/sideModal";
import { Modal } from "../../components/modal/modal";
import { countries } from "../../lib/countries";
import { BillingSection } from "./sections/billingSection";
import { Popup, PopupPosition } from "../../components/popup";
import { PageLayout } from "../../components/layout/pageLayout";
import { store } from "../../index";


export class AgencyDetail extends React.Component {

  constructor(props, context) {
    
    super(props, context);

    this.state = {
      showCreditorModal: false,
      currentCreditor: null,

      showNewModal: false,
      invalidObjects: {},
      pendingSave: false,
      newCreditor: {
        company_name: '',
        chamber_of_commerce: '',
        pricing_plan: 'insights',
        country: 'NL',
        page_views: 0,
      },

      pricePlan: [],

      agents: props.account.agents,
      isUpdated: false,
      isLoading: false,

    };

    updateIfNeeded(this.props.account.agency_updated,
      async () => {
        let data = await this.props.getAgency({ uuid: props.account.agency.uuid });
        if (data && data.agents) this.setState({ agents: data.agents })
      }
    );

    // if (this.props.persist.is_staff) updateIfNeeded(this.props.persist.all_agencies_updated, () => this.props.getAllAgencies());
  }

  header = [
    { key: 'name', value: 'Name', help: null },
    { key: 'state', value: 'State', help: null },
    {}
  ];

  _open_creditor(creditor) {
    // open creditor
    this.setState({
      showCreditorModal: true,
      currentCreditor: { ...creditor }
    })
  }

  setValue(new_dict) {
    let newCreditor = { ...this.state.newCreditor, ...new_dict };
    this.setState({ newCreditor: newCreditor })
  }

  _AddNewCreditor() {
    // first we validate
    let invalidObjects = {};
    if (this.state.newCreditor.company_name.length === 0) invalidObjects['company_name'] = true;
    if (!this.state.newCreditor.flat_fee && !(this.state.newCreditor.flat_fee > -1)) invalidObjects['flat_fee'] = true;
    if (this.state.newCreditor.country.length !== 2) invalidObjects['country'] = true;
    if ((this.state.newCreditor.country === 'NL' || this.state.newCreditor.country === 'nl') && this.state.newCreditor.chamber_of_commerce.length < 5) invalidObjects['chamber_of_commerce'] = true;

    if (Object.entries(invalidObjects).length === 0) {

      this.setState({ pendingSave: true }, () => {
        this.props.addCreditor({ creditor: this.state.newCreditor, uuid: this.props.account.agency.uuid })
          .then((c) => {
            this.setState({ pendingSave: false, showNewModal: false })
          })
      });

    } else {
      this.setState({ invalidObjects: invalidObjects })
    }

  }

  async _gotoImplementation(data) {
    // console.log(`Going to implementation`)
    // // this.props.onClose('/installation');
    // this.props.history.push('/installation');
    // let oldUrl = window.location.href;
    // let newValue = this.state.currentCreditor.logicalAccountGuid;
    // let newUrl = oldUrl.replace(/\w{8}-\w{4}-\w{4}-\w{4}-\w{12}/, newValue);
    // newUrl = newUrl.replace("agency", "live")
    // window.open(newUrl);

    // await this.props.SetDomain(data);
    // await this.props.getDev({ uuid: data.domain.id });
    // console.log(`implementation: ${JSON.stringify(data)}`)
    this._gotoLive(data)
  }

  _gotoLive(data) {
    const guidRegex = /[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}/;
    const segments = window.location.href.split('/');

    var secondGuidParameter;
    var counter = 0;
    var indexSecondGuid = 0;
    for(var i=0;i<segments.length;i++){
      //If a part of the segment is a guid we pass if
      if (guidRegex.test(segments[i])) {
        counter++;
        if (counter == 2) {
          secondGuidParameter = segments[i];
          indexSecondGuid = i;
          break;
        }
      }
    }

    this.props.SetDomain(data).then(
      console.log(data),
      // () => this.props.history.push('/live')
      segments[indexSecondGuid] = data.domain.tenantId,
      segments[segments.length-1]= data.domain.state == "live" ? "live":"installation",
      window.location.href = segments.join("/")
    )
  }

  _update_row(idx, newObject) {
    // update element and update element
    let agents = [...this.state.agents];
    agents[idx] = newObject;
    this.setState({ isUpdated: true, agents: agents })
  }

  _validate_and_save_agents() {

    let invalidObjects = {};
    let existing = [];
    let update_agents = [];

    this.state.agents.forEach((row, idx) => {

      // clean up
      row.email = row.email.trim().toLowerCase();

      // check email if add
      if (row.action === 'add') {
        // validate email
        if (!emailValidator(row.email)) {
          invalidObjects[`${idx}_email`] = 'Please enter a valid email address'
        }
        if (!row.first_name || row.first_name.length < 2) {
          invalidObjects[`${idx}_first_name`] = 'Please enter a first name'
        }
      }

      // check if email already exists
      if (!existing.includes(row.email)) {
        existing.push(row.email);
        update_agents.push(row);
      } else {

        // ok, double but ok if we delete, in that case we just don't add it ot the update list
        if (row.action !== 'delete') {
          invalidObjects[`${idx}_email`] = 'This email address is already added in the list';
        }
      }
    });

    // only save if no invalid objects
    if (Object.entries(invalidObjects).length === 0) {

      this.setState({ isLoading: true, agents: [] }, async () => {

        let res = await this.props.inviteAgent({
          uuid: this.props.account.agency.uuid,
          agents: update_agents
        });
        // only update if required
        if (res) {
          this.setState({ isLoading: false, isUpdated: false, agents: res.agents })
        } else {
          this.setState({ isLoading: false, isUpdated: true, agents: update_agents })
        }
      });

    }
    this.setState({ invalidObjects: invalidObjects })
  }

  render({ history, persist, publishBuild, account, show, onClose } = this.props) {

    return (

      //   <SideModal title={account.agency.company_name ? `Agency: ${account.agency.company_name}` : ''}
      //              hasSave={false}
      //              show={show} onClose={() => onClose()}>

      <div>
        <PageLayout title='Agency overview'
          //   date_last_build={rules.date_last_build}
          //   requires_publish={rules.requires_publish}
          //   publishPending={rules.isPending}
          //   hasPublish={!rules.isPending && persist.domain.state === 'live'}
          //   onPublish={(message) => publishBuild({uuid: persist.domain.uuid, message: message})}
          history={this.props.history} domain={persist.domain}>


          {account.isPending && (<Loader style={{ marginTop: '20vh', marginLeft: '48%' }} />)}

          {/* {!account.isPending && account.agency.state === 'pending' && (
          <div style={{padding: 50}}>
            <h6>Account pending</h6>
            <p>Please contact <span className='link clickable' target="_blank" rel="noopener noreferrer"
                                    title='Mail us'
                                    href="mailto:support@tracedock.com?subject=Agency integration">
            hello@tracedock.com
          </span> or your TraceDock implementation partner for more information.</p>
          </div>
        )} */}

          {!account.isPending && (
            <div style={{ width: '100%' }}>

              <BlankCard
                title='My clients'
                subtitle='Add, onboard and manage your clients in the user portal'
                padding={false}
              // onAddNew={() => this.setState({showNewModal: true})}
              >
                <Table header={this.header}>

                  {account.creditors.length > 0 && account.creditors.map((creditor, idx) => (

                    <tr key={idx}>
                      {/* NOTE: rows are build up custom */}

                      <td className='clickable' onClick={() => this._open_creditor(creditor, idx)}>
                        <div className='row'>
                          {/*{creditor.domains && creditor.domains.length > 0 &&*/}
                          {/*  <img src={`http://logo.clearbit.com/${creditor.domains[0].name}`} height="15px"/>*/}
                          {/*}*/}
                          <p className='verticalpadding'>{creditor.name}</p>
                        </div>
                      </td>

                      <td>
                        <Tag type={creditor.state === 'live' ? 'default' : 'update'}>
                          {creditor.state}
                        </Tag>
                      </td>

                      <td>
                        <Icon size={24} className='clickable' icon={pencil}
                          color={colors.brand.primary}
                          onClick={() => this._open_creditor(creditor, idx)} />
                      </td>

                    </tr>
                  ))}
                </Table>

              </BlankCard>


              {/* {account.agency.is_principle && account.agency.has_commission && (
              <BillingSection
                creditor={account.agency}
                withBankAccount={true}
                uuid={account.agency.uuid}
                updateCreditor={this.props.updateAgency}
              />
            )} */}

            </div>
          )}

          {/* <Modal visible={this.state.showNewModal} onClose={() => this.setState({showNewModal: false})} width='400px'>
          <div style={{minHeight: 400}}>
            <h2>Add Client.</h2>
            <h6 className='spacer'>What client do you want to add?</h6>

            <p>TraceDock includes a free trial period of 21 days, starting <span className='bold'>after</span> the setup is completed:
              when the first cookies are extended or when the first events are sent to Google Analytics.</p>

            <p className='spacer'>Invoicing is done straight to the client, who can choose between paying by credit card or by monthly invoice. The client can cancel each month.</p>

            <Input
              label='Company legal name'
              type='text'
              isInvalid={this.state.invalidObjects['company_name']}
              onChange={(value) => this.setValue({company_name: value})}
            />

            <Input
              label='Country'
              initial='Netherlands'
              isInvalid={this.state.invalidObjects['country']}
              type='text'
              choices={countries}
              restrict={false}
              onChange={(value) => this.setValue({country: value})}
            />

            {(this.state.newCreditor.country === 'NL' || this.state.newCreditor.country === 'nl') && (
              <Input
                label='Chamber of Commerce number'
                type='text'
                isInvalid={this.state.invalidObjects['chamber_of_commerce']}
                onChange={(value) => this.setValue({chamber_of_commerce: value})}
              />
            )}

            <Input
              label='Monthly fee'
              type='text'
              restrict={true}
              isInvalid={this.state.invalidObjects['flat_fee']}
              choices={planData}
              onChange={(value) => {

                // find the corresponding planData and set the pageViews
                planData.forEach((plan) => {
                  if (plan.key === value) {
                    this.setValue({page_views:  plan.maxViews, flat_fee: parseInt(value)})
                  }
                })
              }}

              onSubmit={() => this._AddNewCreditor()}
            />

            <div style={{margin: '24px auto', display: 'flex'}}>
              <Button
                style={{margin: 4}}
                title='Cancel'
                onClick={() => this.setState({showNewModal: false})}
                color={colors.brand.darkgray}
                cancel={true}
                backgroundColor={colors.brand.gray_light}/>

              <Button
                style={{margin: 4}}
                title='Add client'
                onClick={() => this._AddNewCreditor()}
                isLoading={this.state.pendingSave}
              />
            </div>
          </div>
        </Modal> */}

          {this.state.showCreditorModal && (
            <CreditorModal
              maxWidth={900}
              level={2}
              // user_email={this.props.account.profile.email}
              creditor={this.state.currentCreditor}
              show={this.state.showCreditorModal}
              onClose={(newPage) => {
                // only navigate onwards if new page received, otherwise only close
                if (newPage) {
                  this.props.onClose(newPage)
                } else {
                  this.setState({ showCreditorModal: false })
                }
              }}
              onSave={this.props.updateAgencyCreditor}
              onAddDomain={(data) => this.props.createDomain(data)}
              gotoImplementation={(data) => this._gotoImplementation(data)}
              gotoLive={(data) => this._gotoLive(data)}
              inviteAdmin={(data) => this.props.inviteAdmin(data)}
            />
          )}
        </PageLayout>
      </div>
      //   </SideModal>

    );
  }
}


const mapStateToProps = (state) => ({
  persist: state.persist,
  account: state.account,
});

const mapDispatchToProps = (dispatch) => {
  return {
    inviteAdmin: (data) => dispatch(accountActions.inviteAdmin(data)),
    createDomain: (data) => dispatch(accountActions.createDomain(data)),
    getAgency: (d) => dispatch(accountActions.getAgency(d)),
    inviteAgent: (d) => dispatch(accountActions.inviteAgent(d)),
    addCreditor: (d) => dispatch(accountActions.addCreditor(d)),
    updateAgencyCreditor: (d) => dispatch(accountActions.updateAgencyCreditor(d)),

    updateAgency: (d) => dispatch(accountActions.updateAgency(d)),

    // getAllAgencies: (d) => dispatch(persistActions.getAllAgencies(d)),
    // setAgency: (d) => dispatch(persistActions.setAgency(d)),
    SetDomain: (d) => dispatch(persistActions.SetDomain(d)),
    getDev: (d) => dispatch(rulesActions.getDev(d)),
  }
};

export default AgencyDetail = connect(mapStateToProps, mapDispatchToProps)(AgencyDetail);