import React from "react";
import {InputCard} from "../../../components/cards/inputCard";

export const CDPSettingsModule = ({visible, data, onSave}) => {

  if (!visible) return null;
  return (
    <InputCard
      title='Customer Data Platform'
      subtitle='Set up your connection to your CDP'
      onSave={onSave}
      initialData={{
        cdp_enabled: data.cdp_enabled,
      }}
      collapsable={true}
      initialCollapsed={true}
      objects={[
        {
          key: 'description',
          type: 'description',
          help: `The connection with your CDP has been activated under the tenant "${data.cdp_product_id}", this is the internal technical ID. You can (temporarily) disable the connection here.`
        },
        {
          key: 'cdp_enabled',
          label: 'Enabled',
          type: 'text',
          choices:[{key: 0, value: 'false'}, {key: 1, value: 'true'}],
          help: 'If false, TraceDock will not forward any data to the CDP.'
        }

      ]}
    />
  );
};
