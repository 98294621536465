import React, {useMemo} from 'react';
import {PropTypes} from 'prop-types';
import styles from './timeLine.module.scss';
import {colors} from "../../theme/colors";

const colorFuture = colors.brand.gray_light;

const TimeLine = ({step, noOfSteps, color, style}) => {
  const content = useMemo(
    () => {
      const contents = [];

      for (let i = 1; i <= noOfSteps; i++) {
        let itemStyle;

        if (i < step) {
          itemStyle = {borderColor: color, background: colors.brand.primary_lighter, opacity: 0.5};
        } else if (i === step) {
          itemStyle = {borderColor: color, background: colors.brand.primary_lighter};
        } else {
          itemStyle = {background: colorFuture};
        }

        contents.push(<div key={i} className={styles.timeLine_circle} style={itemStyle}/>);

        // do not add line after last circle
        if (i === noOfSteps) {
          continue;
        }

        if (i < step) {
          contents.push(<div key={`${i}_line`} className={styles.timeLine_line} style={{background: color}}/>);
        } else {
          contents.push(<div key={`${i}_line`} className={styles.timeLine_line} style={{background: colorFuture}}/>);
        }
      }

      return contents;
    },
    [step, noOfSteps, color]
  );

  if (step <= 0) {
    return <></>
  }

  return (
    <div className={styles.timeLine} style={style}>
      <div className={styles.timeLine_visualization}>
        {content}
      </div>
      <h5 style={{color}}>Step {step} of {noOfSteps}</h5>
    </div>
  )
};

TimeLine.propTypes = {
  step: PropTypes.number,
  noOfSteps: PropTypes.number,
  color: PropTypes.string,
  style: PropTypes.any
};

TimeLine.defaultProps = {
  color: colors.brand.primary_lighter,
};

export default TimeLine;
