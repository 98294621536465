import React from "react";
import {connect} from "react-redux";
import {updateIfNeeded} from "../../lib/helpers";
import {accountActions} from "../../redux/actions";
import {PropTypes} from 'prop-types';
import GetStartedContainer from "./GetStartedContainer";

class GetStartedPage extends React.Component {

  constructor(props, context) {
    super(props, context);

    // if logged in then we check latest data in case use has continued to signup in another device
    props.persist.isAuthenticated && updateIfNeeded(props.account.profile_updated, this.props.getProfile, (res) => {
      // navigate to live if not get started
      if (res.get_started === 0) this.props.history.push('/');
    });

    // check and fill out country if needed
    // TODO fill out city/country from IP address, nice to have
    // if (props.persist.isAuthenticated && props.account.profile.get_started === 4
    //   && props.account.admin && !props.account.admin.country) {
    //   "https://ipinfo.io", function(response) {
    //     console.log(response.city, response.country);
    //   }, "jsonp");
    // }
  }

  render() {
    const props = this.props;
    const currentStage = (
      props.account.isPending
        ? -1
        : (
          props.persist.isAuthenticated ? props.persist.get_started : 1
        )
    );

    return (
      <GetStartedContainer currentStage={currentStage} history={props.history}/>
    );
  }
}

GetStartedPage.propTypes = {
  account: PropTypes.object,
  persist: PropTypes.object,
  getProfile: PropTypes.func,
  history: PropTypes.any
};

const mapStateToProps = (state) => ({
  account: state.account,
  persist: state.persist,
});

const mapDispatchToProps = (dispatch) => {
  return {
    getProfile: () => dispatch(accountActions.getProfile())
  }
};

export default GetStartedPage = connect(mapStateToProps, mapDispatchToProps)(GetStartedPage);
