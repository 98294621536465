import React from "react";
import PropTypes from 'prop-types';

import {Tag} from "../../../components/tag/tag";
import {BlankCard} from "../../../components/cards/blankCard";
import {Input} from "../../../components/input/input";
import {Table} from "../../../components/table/table";
import {colors} from "../../../theme/colors";
import {bin, Icon, info} from "../../../components/icon/icon";


const possibleLookupTypes = [
  {key: 'c', value: 'contains'},
  {key: 'eval', value: 'evaluate'},
  {key: 'nc', value: 'does not contain'},
  {key: 'e', value: 'equals'},
  {key: 'ne', value: 'does not equal'},
  {key: 'ex', value: 'exists (is not null)'},
  {key: 'sw', value: 'starts with'},
  {key: 'ew', value: 'ends with'},
  {key: 'lgte', value: 'length >='},
  {key: 'gte', value: '>='},
  {key: 'gt', value: '>'},
  {key: 'lte', value: '<='},
  {key: 'lt', value: '<'},
];

export class CookieConditions extends React.Component {

  static propTypes = {
    conditions: PropTypes.array,
    invalidObjects: PropTypes.any,
    readOnly: PropTypes.bool,
    onSave: PropTypes.func,
  };

  static defaultProps = {
    conditions: [],
    readOnly: false,
    onSave: (data) => console.log('updating data: ', data),
  };

  constructor(props, context) {
    super(props, context);

    this.state = {
      newData: props.conditions,
      isLoading: false,
      isUpdated: false,
      invalidObjects: {}
    };
  }

  header = [
    {key: 'key', value: 'element', help: null},
    {key: 'lookup_type', value: 'action', help: null},
    {key: 'value', value: 'value', help: null},
    {},
    {},
  ];

  _update_row(idx, newObject) {
    // update element and update element
    let newData = [...this.state.newData];
    newData[idx] = newObject;
    this.setState({newData: newData, isUpdated: true});
  }

  _save() {
    // validates element, if all valid, saves
    let invalidObjects = {};
    let newData = this.state.newData;

    // check trigger validators
    let saveData = [];

    newData.forEach((row, idx) => {

      // validates conditions, is choices restricted to list or more than 0
      if (row.action !== 'delete') {
        if (row.key.length === 0) {
          invalidObjects[`${idx}_key`] = true
        }
        if (!possibleLookupTypes.some((obj) => obj.key === row.lookup_type)) {
          invalidObjects[`${idx}_lookup_type`] = true
        }
        if (row.value.length === 0 && row.lookup_type !== 'ex') {
          invalidObjects[`${idx}_value`] = true
        }

        saveData.push({...row, action: null})
      }
    });

    // either return or populate, invalidObjects
    if (Object.entries(invalidObjects).length === 0) {

      this.setState({isLoading: true}, () => {
        this.props.onSave({cookie_conditions: saveData})
          .then((d) => {
            this.setState({isLoading: false})
          })
      });

    }
    this.setState({invalidObjects: invalidObjects})
  }


  render() {

    return (

      <BlankCard
        title='Cookie tracking conditions'
        subtitle='Use a clientcookie only if all the conditions are met.'
        padding={false}
        onSave={() => this._save()}
        collapsable={true}
        initialCollapsed={false}
        isUpdated={this.state.isUpdated}
      >

        <div className='row' style={{alignItems: 'center', padding: '0 24px'}}>
          <Icon icon={info} color={colors.brand.primary}/>
          <p style={{marginLeft: 12}} className='small'>If the user consents, a user ClientID can be set in a cookie, but only if all of the following conditions are met.
            Conditions can either be based on the dataLayer and/or on a cookie value. Contact <span className='link clickable' target="_blank" rel="noopener noreferrer" title='Mail us'
                                                                                                    href="mailto:support@tracedock.com">support@tracedock.com</span> for more information. We love to
            help.
          </p>
        </div>

        <Table header={this.header}
               narrow={true}
               onAddNew={() => {
                 this._update_row(this.state.newData.length, {
                   key: '',
                   lookup_type: 'c',
                   value: '',
                   action: 'add'
                 })
               }}>

          {this.state.newData.length > 0 && this.state.newData.map((row, idx) => (

            <tr key={idx} className={row.action === 'delete' ? 'delete' : ''}>
              {/* NOTE: rows are build up custom */}

              <td>
                <Input
                  type="text"
                  initial={row.key}
                  isInvalid={this.state.invalidObjects[`${idx}_key`]}
                  onChange={(value) => this._update_row(idx, {
                    ...row,
                    key: value,
                    action: row.action === 'add' ? 'add' : 'update'
                  })}
                />
              </td>

              <td>
                <Input
                  type="text"
                  readOnly={false}
                  initial={row.lookup_type}
                  restrict={true}
                  choices={possibleLookupTypes}
                  isInvalid={this.state.invalidObjects[`${idx}_lookup_type`]}
                  onChange={(value) => this._update_row(idx, {
                    ...row,
                    lookup_type: value,
                    action: row.action === 'add' ? 'add' : 'update'
                  })}
                />
                {this.state.invalidObjects[`${idx}_lookup_type`] && (
                  <p className='small alert'>
                    Please select a valid choice
                  </p>
                )}
              </td>

              <td>
                <Input
                  type="text"
                  readOnly={row.lookup_type === 'ex'}
                  initial={row.lookup_type === 'ex' ? '-' : row.value}
                  isInvalid={this.state.invalidObjects[`${idx}_value`]}
                  onChange={(value) => this._update_row(idx, {
                    ...row,
                    value: value,
                    action: row.action === 'add' ? 'add' : 'update'
                  })}
                />
              </td>

              <td style={{width: 75}}>
                {row.action === 'add' && <Tag type='new'>new</Tag>}
                {row.action === 'update' && <Tag type='update'>updated</Tag>}
                {row.action === 'delete' && <Tag type='alert'>delete</Tag>}
              </td>

              <td>
                <Icon size={24} className='clickable' icon={bin} color={colors.brand.primary}
                      onClick={() => this._update_row(idx, {...row, action: row.action !== 'delete' ? 'delete' : 'update'})}/>
              </td>

            </tr>
          ))}
        </Table>
      </BlankCard>
    )
  }
}
