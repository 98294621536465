import React from 'react';
import {Icon} from "../icon/icon";
import {getMenu} from "./menuData";
import PropTypes from 'prop-types';
import {colors} from "../../theme/colors";

import './menu.scss'

export class Menu extends React.Component {

  static propTypes = {
    page: PropTypes.string,
    menu: PropTypes.any,
    domain: PropTypes.any,
    inverse: PropTypes.bool,
  };

  static defaultProps = {
    page: null,
    menu: null,
    domain: {uuid: 'asdf', state: 'live'},
    inverse: false
  };

  // constructor(props, context) {
  //   super(props, context);
  //
  //   // // pages are /main/subpage
  //   // let page = (window.location.pathname).split('/');
  //   //
  //   // // default as path, but for story it can be passed in as variable
  //   // this.state = {
  //   //   currentPage: null,
  //   //   currentSubPage: page.length > 1 ? page[1] : null,
  //   //   openPageMenu: page.length > 1 ? page[0] : null,
  //   // };
  // }

  _selectSubpage(subpage) {

    // Directly call onNavigate
    this.props.history.push(`/${this.state.openPageMenu}/${subpage.slug}`)
  }

  render({menu, domain, inverse, history} = this.props) {
    // menu is given by docs or you have to get it yourself
    menu = menu ? menu : getMenu(domain.state === 'implementing', domain.pricing_plan, domain.onboarding, history);
    let currentPage = window.location.pathname;

    return (
      <div className='menuContainer'>

        {menu.length > 0 && menu.map((section, ids) => {
          return <div className='section' key={ids}>

            {section.title && <p className={`sectionTitle ${inverse ? 'primary' : 'accent'}`}>{section.title}</p>}

            {section.pages && section.pages.map((page, idp) => {
              return <div className='page' key={idp}>
                <div
                  className={`clickable titleWrapper ${page.subpages.length === 0 && currentPage === page.slug ? `active${inverse ? '_inverse' : ''}` : ''}`}
                  onClick={() => page.onClick()}>
                  <Icon
                    icon={page.subpages.length === 0 && currentPage === page.slug ? page.icon_active : page.icon_inactive}
                    size={24}
                    color={inverse ? colors.brand.primary : (page.subpages.length === 0 && currentPage === page.slug ? colors.brand.accent_black : 'sideBarIcon')}/>
                  <p style={{marginLeft: 4}}
                     className={`${inverse ? '' : 'sideBarFont'} ${page.subpages.length === 0 && currentPage === page.slug ? 'bold' : ''}`}>{page.title}</p>
                </div>

                {/*<div className={`subpageWrapper ${*/}
                  {/*(this.state.openPageMenu === page.slug) || (page.subpages.length === 0 && currentPage === page.slug)*/}
                    {/*? 'open' : 'closed'}`} style={{maxHeight: 32 * page.subpages.length}}>*/}
                  {/*{page.subpages && page.subpages.map((subpage, ids) =>*/}
                    {/*<div className={`clickable subpage ${this.state.currentSubPage === subpage.slug ? 'active' : ''}`}*/}
                         {/*key={ids} onClick={() => this._selectSubpage(subpage)}>*/}
                      {/*<p className={inverse ? '' : 'white'}>{subpage.title}</p>*/}
                    {/*</div>*/}
                  {/*)}*/}
                {/*</div>*/}

              </div>
              }
            )}

          </div>
          }

        )}
      </div>
    )
  }
}