import React from 'react';
import {Icon, info} from "../../icon/icon";

import {Popup} from "../Popup";
import {colors} from '../../../theme/colors';
import styles from './DemoPopup.module.scss';

const DemoPopupContent = ({title, message, icon}) => {
  return (
    <div className={styles.container}>
      {icon &&
      <div className={styles.icon}>
        <Icon icon={icon} size={20} color={colors.brand.primary_light}/>
      </div>
      }
      <div>
        {title &&
        <h3 className={styles.title}>{title}</h3>
        }
        <p>{message}</p>
      </div>
    </div>
  )
};


export const DemoPopup = ({title, message, position, onClose, children}) => {
  return (
    <Popup
      content={
        <DemoPopupContent
          title={title}
          message={message}
          icon={info}
        />
      }
      stayOpen={true}
      initialOpen={true}
      position={position}
      onClose={onClose}
    >
      {children}
    </Popup>
  )
};

