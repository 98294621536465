import React from 'react';

import {Popup} from "../Popup";
import {PopupPosition} from "../constants";
import {Icon, info} from "../../icon/icon";
import {colors} from "../../../theme/colors";

export const InfoPopup = ({style, message, position}) => {
  return (
    <Popup
      content={
        <p style={{minWidth: 300}}> {message}</p>
      }
      position={position || PopupPosition.RIGHT_CENTER}
    >
      <Icon style={{marginLeft: 6, ...style}} className='clickable' icon={info} size={18} color={colors.brand.darkgray_light}/>
    </Popup>
  )
};

