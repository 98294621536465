/*
    GA measurement protocol
    https://developers.google.com/analytics/devguides/collection/protocol/v1/reference
*/


export const gaTemplateChoices = [
  // created for dropdown list
  {value: 'Blank template', key: 'blank'},

  {value: 'PageView', key: 'pageview'},
  {value: 'Event', key: 'event'},

  {value: 'Product impression', key: 'impression'},

  {value: 'Product detail', key: 'product_detail'},
  {value: 'Product checkout', key: 'product_checkout'},
  {value: 'Product purchase', key: 'product_purchase'},


  {value: 'Identify', key: 'identify'},
];

export const gaTemplates = {

  // created to populate fields

  blank: [],

  pageview: [
    {field_name: 't', field_type: 'c', field_value: 'pageview', children: []},
  ],

  event: [
    {field_name: 't', field_type: 'c', field_value: 'event', children: []},
    {field_name: 'ec', field_type: 'l', children: []},
    {field_name: 'ea', field_type: 'l', children: []},
    {field_name: 'el', field_type: 'l', optional: true, children: []},
    {field_name: 'ev', field_type: 'l', optional: true, children: []},
  ],

  impression: [
    {field_name: 't', field_type: 'c', field_value: 'event', children: []},
    {field_name: 'ec', field_type: 'c', field_value: 'Ecommerce', children: []},
    {field_name: 'ea', field_type: 'c', field_value: 'Impressions', children: []},

    {
      field_name: 'il', field_type: 'a', field_value: '', children: [
        {field_name: 'il.nm', field_type: 'l', field_value: '', children: []},
        {field_name: 'pr.id', field_type: 'l', field_value: '', children: []},
        {field_name: 'pr.nm', field_type: 'l', field_value: '', optional: true, children: []},
        {field_name: 'pr.br', field_type: 'l', field_value: '', optional: true, children: []},
        {field_name: 'pr.ca', field_type: 'l', field_value: '', optional: true, children: []},
        {field_name: 'pr.va', field_type: 'l', field_value: '', optional: true, children: []},
        {field_name: 'pr.ps', field_type: 'l', field_value: '', optional: true, children: []},
        {field_name: 'pr.pr', field_type: 'l', field_value: '', optional: true, children: []}
      ]
    },
  ],

  product_detail: [
    {field_name: 't', field_type: 'c', field_value: 'event', children: []},
    {field_name: 'ec', field_type: 'c', field_value: 'Ecommerce', children: []},
    {field_name: 'ea', field_type: 'c', field_value: 'ProductDetail', children: []},

    {field_name: 'pa', field_type: 'c', field_value: 'detail', children: []},

    {field_name: 'pal', field_type: 'l', field_value: '', optional: true, children: []},
    {
      field_name: 'pr', field_type: 'a', field_value: '', children: [
        {field_name: 'pr.id', field_type: 'l', field_value: '', children: []},
        {field_name: 'pr.nm', field_type: 'l', field_value: '', optional: true, children: []},
        {field_name: 'pr.br', field_type: 'l', field_value: '', optional: true, children: []},
        {field_name: 'pr.ca', field_type: 'l', field_value: '', optional: true, children: []},
        {field_name: 'pr.va', field_type: 'l', field_value: '', optional: true, children: []},
        {field_name: 'pr.ps', field_type: 'l', field_value: '', optional: true, children: []},
        {field_name: 'pr.pr', field_type: 'l', field_value: '', optional: true, children: []},
      ]
    },
  ],

  product_checkout: [
    {field_name: 't', field_type: 'c', field_value: 'event', children: []},
    {field_name: 'ec', field_type: 'c', field_value: 'Ecommerce', children: []},
    {field_name: 'ea', field_type: 'c', field_value: 'Checkout', children: []},

    {field_name: 'pa', field_type: 'c', field_value: 'checkout', children: []},

    {field_name: 'cos', field_type: 'l', field_value: '', optional: true, children: []},
    {field_name: 'col', field_type: 'l', field_value: '', optional: true, children: []},

    {
      field_name: 'pr', field_type: 'a', field_value: '', children: [
        {field_name: 'pr.id', field_type: 'l', field_value: '', children: []},
        {field_name: 'pr.nm', field_type: 'l', field_value: '', optional: true, children: []},
        {field_name: 'pr.br', field_type: 'l', field_value: '', optional: true, children: []},
        {field_name: 'pr.ca', field_type: 'l', field_value: '', optional: true, children: []},
        {field_name: 'pr.va', field_type: 'l', field_value: '', optional: true, children: []},
        {field_name: 'pr.ps', field_type: 'l', field_value: '', optional: true, children: []},
        {field_name: 'pr.pr', field_type: 'l', field_value: '', optional: true, children: []},
        {field_name: 'pr.qt', field_type: 'l', field_value: '', optional: true, children: []},
        {field_name: 'pr.cc', field_type: 'l', field_value: '', optional: true, children: []},
      ]
    },
  ],

  product_purchase: [
    {field_name: 't', field_type: 'c', field_value: 'event', children: []},
    {field_name: 'ec', field_type: 'c', field_value: 'Ecommerce', children: []},
    {field_name: 'ea', field_type: 'c', field_value: 'Purchase', children: []},

    {field_name: 'pa', field_type: 'c', field_value: 'purchase', children: []},

    {field_name: 'ti', field_type: 'l', field_value: '', children: []},
    {field_name: 'tr', field_type: 'l', field_value: '', children: []},
    {field_name: 'ts', field_type: 'l', field_value: '', optional: true, children: []},
    {field_name: 'tt', field_type: 'l', field_value: '', optional: true, children: []},
    {field_name: 'ta', field_type: 'l', field_value: '', optional: true, children: []},
    {field_name: 'tcc', field_type: 'l', field_value: '', optional: true, children: []},
    {field_name: 'cu', field_type: 'l', field_value: '', optional: true, children: []},

    {
      field_name: 'pr', field_type: 'a', field_value: '', children: [
        {field_name: 'pr.id', field_type: 'l', field_value: '', children: []},
        {field_name: 'pr.nm', field_type: 'l', field_value: '', optional: true, children: []},
        {field_name: 'pr.br', field_type: 'l', field_value: '', optional: true, children: []},
        {field_name: 'pr.ca', field_type: 'l', field_value: '', optional: true, children: []},
        {field_name: 'pr.va', field_type: 'l', field_value: '', optional: true, children: []},
        {field_name: 'pr.ps', field_type: 'l', field_value: '', optional: true, children: []},
        {field_name: 'pr.pr', field_type: 'l', field_value: '', optional: true, children: []},
        {field_name: 'pr.qt', field_type: 'l', field_value: '', optional: true, children: []},
        {field_name: 'pr.cc', field_type: 'l', field_value: '', optional: true, children: []},
      ]
    },
  ],

  identify: [
    {field_name: 'id', field_type: 'l', field_value: 'customer_identifier', children: []},
  ],

};

export const ga4TemplateChoices = [
  {value: 'Blank template', key: 'blank'},
  {value: 'Add payment info', key: 'add_payment_info'},
  {value: 'Add shipping info', key: 'add_shipping_info'},
  {value: 'Add to cart', key: 'add_to_cart'},
  {value: 'Add to wishlist', key: 'add_to_wishlist'},
  {value: 'Begin checkout', key: 'begin_checkout'},
  // {value: 'Earn virtual currency', key: 'earn_virtual_currency'},
  {value: 'Generate lead', key: 'generate_lead'},
  // {value: 'Join group', key: 'join_group'},
  // {value: 'Level up', key: 'level_up'},
  {value: 'Login', key: 'login'},
  // {value: 'PageView', key: 'pageview'},
  // {value: 'Post score', key: 'post_score'},
  {value: 'Purchase', key: 'purchase'},
  // {value: 'Refund', key: 'refund'},
  {value: 'Remove from cart', key: 'remove_from_cart'},
  // {value: 'Search', key: 'search'},
  // {value: 'Select content', key: 'select_content'},
  {value: 'Select item', key: 'select_item'},
  {value: 'Select promotion', key: 'select_promotion'},
  // {value: 'Share', key: 'share'},
  // {value: 'Sign up', key: 'sign_up'},
  // {value: 'Spend virtual currency', key: 'spend_virtual_currency'},
  // {value: 'Tutorial begin', key: 'tutorial_begin'},
  // {value: 'Tutorial complete', key: 'tutorial_complete'},
  // {value: 'Unlock achievement', key: 'unlock_achievement'},
  {value: 'View cart', key: 'view_cart'},
  {value: 'View item', key: 'view_item'},
  {value: 'View item list', key: 'view_item_list'},
  {value: 'View promotion', key: 'view_promotion'},
  {value: 'View search results', key: 'view_search_results'}
];


export const ga4Templates = {

  // created to populate fields

  blank: [],

  add_payment_info: [
    {field_name: 'name', field_type: 'c', field_value: 'add_payment_info', children: []},
    {field_name: 'params', field_type: 'o', children: [
      {field_name: 'currency', field_type: 'l', field_value: '', children: []},
      {field_name: 'value', field_type: 'l', field_value: '', children: []},
      {field_name: 'coupon', field_type: 'l', field_value: '', optional: true, children: []},
      {field_name: 'payment_type', field_type: 'l', field_value: '', optional: true, children: []},
      {field_name: 'items', field_type: 'a', field_value: '', children: [
        {field_name: 'item_id', field_type: 'l', field_value: '', children: []},
        {field_name: 'item_name', field_type: 'l', field_value: '', children: []},
        {field_name: 'item_brand', field_type: 'l', field_value: '', optional: true, children: []},
        {field_name: 'item_category', field_type: 'l', field_value: '', optional: true, children: []},
        {field_name: 'item_category2', field_type: 'l', field_value: '', optional: true, children: []},
        {field_name: 'item_category3', field_type: 'l', field_value: '', optional: true, children: []},
        {field_name: 'item_category4', field_type: 'l', field_value: '', optional: true, children: []},
        {field_name: 'item_category5', field_type: 'l', field_value: '', optional: true, children: []},
        {field_name: 'item_list_id', field_type: 'l', field_value: '', optional: true, children: []},
        {field_name: 'item_list_name', field_type: 'l', field_value: '', optional: true, children: []},
        {field_name: 'item_variant', field_type: 'l', field_value: '', optional: true, children: []},
        {field_name: 'quantity', field_type: 'l', field_value: '', optional: true, children: []},
        {field_name: 'price', field_type: 'l', field_value: '', optional: true, children: []},
        {field_name: 'discount', field_type: 'l', field_value: '', optional: true, children: []},
        {field_name: 'affiliation', field_type: 'l', field_value: '', optional: true, children: []},
        {field_name: 'coupon', field_type: 'l', field_value: '', optional: true, children: []},
        {field_name: 'location_id', field_type: 'l', field_value: '', optional: true, children: []},
        {field_name: 'index', field_type: 'l', field_value: '', optional: true, children: []}
      ]}
    ]},
  ],

  add_shipping_info: [
    {field_name: 'name', field_type: 'c', field_value: 'add_shipping_info', children: []},
    {field_name: 'params', field_type: 'o', children: [
      {field_name: 'currency', field_type: 'l', field_value: '', children: []},
      {field_name: 'value', field_type: 'l', field_value: '', children: []},
      {field_name: 'coupon', field_type: 'l', field_value: '', optional: true, children: []},
      {field_name: 'shipping_tier', field_type: 'l', field_value: '', optional: true, children: []},
      {field_name: 'items', field_type: 'a', field_value: '', children: [
        {field_name: 'item_id', field_type: 'l', field_value: '', children: []},
        {field_name: 'item_name', field_type: 'l', field_value: '', children: []},
        {field_name: 'item_brand', field_type: 'l', field_value: '', optional: true, children: []},
        {field_name: 'item_category', field_type: 'l', field_value: '', optional: true, children: []},
        {field_name: 'item_category2', field_type: 'l', field_value: '', optional: true, children: []},
        {field_name: 'item_category3', field_type: 'l', field_value: '', optional: true, children: []},
        {field_name: 'item_category4', field_type: 'l', field_value: '', optional: true, children: []},
        {field_name: 'item_category5', field_type: 'l', field_value: '', optional: true, children: []},
        {field_name: 'item_list_id', field_type: 'l', field_value: '', optional: true, children: []},
        {field_name: 'item_list_name', field_type: 'l', field_value: '', optional: true, children: []},
        {field_name: 'item_variant', field_type: 'l', field_value: '', optional: true, children: []},
        {field_name: 'quantity', field_type: 'l', field_value: '', optional: true, children: []},
        {field_name: 'price', field_type: 'l', field_value: '', optional: true, children: []},
        {field_name: 'discount', field_type: 'l', field_value: '', optional: true, children: []},
        {field_name: 'affiliation', field_type: 'l', field_value: '', optional: true, children: []},
        {field_name: 'coupon', field_type: 'l', field_value: '', optional: true, children: []},
        {field_name: 'location_id', field_type: 'l', field_value: '', optional: true, children: []},
        {field_name: 'index', field_type: 'l', field_value: '', optional: true, children: []}
      ]}
    ]},
  ],

  add_to_cart: [
    {field_name: 'name', field_type: 'c', field_value: 'add_to_cart', children: []},
    {field_name: 'params', field_type: 'o', children: [
      {field_name: 'currency', field_type: 'l', field_value: '', children: []},
      {field_name: 'value', field_type: 'l', field_value: '', children: []},
      {field_name: 'items', field_type: 'a', field_value: '', children: [
        {field_name: 'item_id', field_type: 'l', field_value: '', children: []},
        {field_name: 'item_name', field_type: 'l', field_value: '', children: []},
        {field_name: 'item_brand', field_type: 'l', field_value: '', optional: true, children: []},
        {field_name: 'item_category', field_type: 'l', field_value: '', optional: true, children: []},
        {field_name: 'item_category2', field_type: 'l', field_value: '', optional: true, children: []},
        {field_name: 'item_category3', field_type: 'l', field_value: '', optional: true, children: []},
        {field_name: 'item_category4', field_type: 'l', field_value: '', optional: true, children: []},
        {field_name: 'item_category5', field_type: 'l', field_value: '', optional: true, children: []},
        {field_name: 'item_list_id', field_type: 'l', field_value: '', optional: true, children: []},
        {field_name: 'item_list_name', field_type: 'l', field_value: '', optional: true, children: []},
        {field_name: 'item_variant', field_type: 'l', field_value: '', optional: true, children: []},
        {field_name: 'quantity', field_type: 'l', field_value: '', optional: true, children: []},
        {field_name: 'price', field_type: 'l', field_value: '', optional: true, children: []},
        {field_name: 'discount', field_type: 'l', field_value: '', optional: true, children: []},
        {field_name: 'affiliation', field_type: 'l', field_value: '', optional: true, children: []},
        {field_name: 'coupon', field_type: 'l', field_value: '', optional: true, children: []},
        {field_name: 'location_id', field_type: 'l', field_value: '', optional: true, children: []},
        {field_name: 'index', field_type: 'l', field_value: '', optional: true, children: []}
      ]}
    ]},
  ],

  add_to_wishlist: [
    {field_name: 'name', field_type: 'c', field_value: 'add_to_wishlist', children: []},
    {field_name: 'params', field_type: 'o', children: [
      {field_name: 'currency', field_type: 'l', field_value: '', children: []},
      {field_name: 'value', field_type: 'l', field_value: '', children: []},
      {field_name: 'items', field_type: 'a', field_value: '', children: [
        {field_name: 'item_id', field_type: 'l', field_value: '', children: []},
        {field_name: 'item_name', field_type: 'l', field_value: '', children: []},
        {field_name: 'item_brand', field_type: 'l', field_value: '', optional: true, children: []},
        {field_name: 'item_category', field_type: 'l', field_value: '', optional: true, children: []},
        {field_name: 'item_category2', field_type: 'l', field_value: '', optional: true, children: []},
        {field_name: 'item_category3', field_type: 'l', field_value: '', optional: true, children: []},
        {field_name: 'item_category4', field_type: 'l', field_value: '', optional: true, children: []},
        {field_name: 'item_category5', field_type: 'l', field_value: '', optional: true, children: []},
        {field_name: 'item_list_id', field_type: 'l', field_value: '', optional: true, children: []},
        {field_name: 'item_list_name', field_type: 'l', field_value: '', optional: true, children: []},
        {field_name: 'item_variant', field_type: 'l', field_value: '', optional: true, children: []},
        {field_name: 'quantity', field_type: 'l', field_value: '', optional: true, children: []},
        {field_name: 'price', field_type: 'l', field_value: '', optional: true, children: []},
        {field_name: 'discount', field_type: 'l', field_value: '', optional: true, children: []},
        {field_name: 'affiliation', field_type: 'l', field_value: '', optional: true, children: []},
        {field_name: 'coupon', field_type: 'l', field_value: '', optional: true, children: []},
        {field_name: 'location_id', field_type: 'l', field_value: '', optional: true, children: []},
        {field_name: 'index', field_type: 'l', field_value: '', optional: true, children: []}
      ]}
    ]},
  ],

  begin_checkout: [
    {field_name: 'name', field_type: 'c', field_value: 'begin_checkout', children: []},
    {field_name: 'params', field_type: 'o', children: [
      {field_name: 'currency', field_type: 'l', field_value: '', children: []},
      {field_name: 'value', field_type: 'l', field_value: '', children: []},
      {field_name: 'coupon', field_type: 'l', field_value: '', optional: true, children: []},
      {field_name: 'items', field_type: 'a', field_value: '', children: [
        {field_name: 'item_id', field_type: 'l', field_value: '', children: []},
        {field_name: 'item_name', field_type: 'l', field_value: '', children: []},
        {field_name: 'item_brand', field_type: 'l', field_value: '', optional: true, children: []},
        {field_name: 'item_category', field_type: 'l', field_value: '', optional: true, children: []},
        {field_name: 'item_category2', field_type: 'l', field_value: '', optional: true, children: []},
        {field_name: 'item_category3', field_type: 'l', field_value: '', optional: true, children: []},
        {field_name: 'item_category4', field_type: 'l', field_value: '', optional: true, children: []},
        {field_name: 'item_category5', field_type: 'l', field_value: '', optional: true, children: []},
        {field_name: 'item_list_id', field_type: 'l', field_value: '', optional: true, children: []},
        {field_name: 'item_list_name', field_type: 'l', field_value: '', optional: true, children: []},
        {field_name: 'item_variant', field_type: 'l', field_value: '', optional: true, children: []},
        {field_name: 'quantity', field_type: 'l', field_value: '', optional: true, children: []},
        {field_name: 'price', field_type: 'l', field_value: '', optional: true, children: []},
        {field_name: 'discount', field_type: 'l', field_value: '', optional: true, children: []},
        {field_name: 'affiliation', field_type: 'l', field_value: '', optional: true, children: []},
        {field_name: 'coupon', field_type: 'l', field_value: '', optional: true, children: []},
        {field_name: 'location_id', field_type: 'l', field_value: '', optional: true, children: []},
        {field_name: 'index', field_type: 'l', field_value: '', optional: true, children: []}
      ]}
    ]},
  ],

  earn_virtual_currency: [
    {field_name: 'name', field_type: 'c', field_value: 'earn_virtual_currency', children: []},
    {field_name: 'params', field_type: 'o', children: [
      {field_name: 'virtual_currency_name', field_type: 'l', field_value: '', optional: true, children: []},
      {field_name: 'value', field_type: 'l', field_value: '', optional: true, children: []},
    ]},
  ],

  generate_lead: [
    {field_name: 'name', field_type: 'c', field_value: 'generate_lead', children: []},
    {field_name: 'params', field_type: 'o', children: [
      {field_name: 'currency', field_type: 'l', field_value: '', children: []},
      {field_name: 'value', field_type: 'l', field_value: '', children: []},
    ]},
  ],

  join_group: [
    {field_name: 'name', field_type: 'c', field_value: 'join_group', children: []},
    {field_name: 'params', field_type: 'o', children: [
      {field_name: 'group_id', field_type: 'l', field_value: '', optional: true, children: []},
    ]},
  ],

  level_up: [
    {field_name: 'name', field_type: 'c', field_value: 'level_up', children: []},
    {field_name: 'params', field_type: 'o', children: [
      {field_name: 'level', field_type: 'l', field_value: '', optional: true, children: []},
      {field_name: 'character', field_type: 'l', field_value: '', optional: true, children: []},
    ]},
  ],

  login: [
    {field_name: 'name', field_type: 'c', field_value: 'login', children: []},
    {field_name: 'params', field_type: 'o', children: [
      {field_name: 'method', field_type: 'l', field_value: '', optional: true, children: []},
    ]},
  ],

  pageview: [
    {field_name: 'name', field_type: 'c', field_value: 'pageview', children: []},
  ],

  post_score: [
    {field_name: 'name', field_type: 'c', field_value: 'post_score', children: []},
    {field_name: 'params', field_type: 'o', children: [
      {field_name: 'score', field_type: 'l', field_value: '', children: []},
      {field_name: 'level', field_type: 'l', field_value: '', optional: true, children: []},
      {field_name: 'character', field_type: 'l', field_value: '', optional: true, children: []},
    ]},
  ],

  purchase: [
    {field_name: 'name', field_type: 'c', field_value: 'purchase', children: []},
    {field_name: 'params', field_type: 'o', children: [
      {field_name: 'transaction_id', field_type: 'l', field_value: '', children: []},
      {field_name: 'currency', field_type: 'l', field_value: '', children: []},
      {field_name: 'value', field_type: 'l', field_value: '', children: []},
      {field_name: 'shipping', field_type: 'l', field_value: '', optional: true, children: []},
      {field_name: 'tax', field_type: 'l', field_value: '', optional: true, children: []},
      {field_name: 'coupon', field_type: 'l', field_value: '', optional: true, children: []},
      {field_name: 'items', field_type: 'a', field_value: '', children: [
        {field_name: 'item_id', field_type: 'l', field_value: '', children: []},
        {field_name: 'item_name', field_type: 'l', field_value: '', children: []},
        {field_name: 'item_brand', field_type: 'l', field_value: '', optional: true, children: []},
        {field_name: 'item_category', field_type: 'l', field_value: '', optional: true, children: []},
        {field_name: 'item_category2', field_type: 'l', field_value: '', optional: true, children: []},
        {field_name: 'item_category3', field_type: 'l', field_value: '', optional: true, children: []},
        {field_name: 'item_category4', field_type: 'l', field_value: '', optional: true, children: []},
        {field_name: 'item_category5', field_type: 'l', field_value: '', optional: true, children: []},
        {field_name: 'item_list_id', field_type: 'l', field_value: '', optional: true, children: []},
        {field_name: 'item_list_name', field_type: 'l', field_value: '', optional: true, children: []},
        {field_name: 'item_variant', field_type: 'l', field_value: '', optional: true, children: []},
        {field_name: 'quantity', field_type: 'l', field_value: '', optional: true, children: []},
        {field_name: 'price', field_type: 'l', field_value: '', optional: true, children: []},
        {field_name: 'discount', field_type: 'l', field_value: '', optional: true, children: []},
        {field_name: 'affiliation', field_type: 'l', field_value: '', optional: true, children: []},
        {field_name: 'coupon', field_type: 'l', field_value: '', optional: true, children: []},
        {field_name: 'location_id', field_type: 'l', field_value: '', optional: true, children: []},
        {field_name: 'index', field_type: 'l', field_value: '', optional: true, children: []}
      ]}
    ]},
  ],

  refund: [
    {field_name: 'name', field_type: 'c', field_value: 'refund', children: []},
    {field_name: 'params', field_type: 'o', children: [
      {field_name: 'transaction_id', field_type: 'l', field_value: '', children: []},
      {field_name: 'currency', field_type: 'l', field_value: '', children: []},
      {field_name: 'value', field_type: 'l', field_value: '', children: []},
      {field_name: 'shipping', field_type: 'l', field_value: '', optional: true, children: []},
      {field_name: 'tax', field_type: 'l', field_value: '', optional: true, children: []},
      {field_name: 'coupon', field_type: 'l', field_value: '', optional: true, children: []},
      {field_name: 'items', field_type: 'a', field_value: '', optional: true, children: [
        {field_name: 'item_id', field_type: 'l', field_value: '', children: []},
        {field_name: 'item_name', field_type: 'l', field_value: '', children: []},
        {field_name: 'item_brand', field_type: 'l', field_value: '', optional: true, children: []},
        {field_name: 'item_category', field_type: 'l', field_value: '', optional: true, children: []},
        {field_name: 'item_category2', field_type: 'l', field_value: '', optional: true, children: []},
        {field_name: 'item_category3', field_type: 'l', field_value: '', optional: true, children: []},
        {field_name: 'item_category4', field_type: 'l', field_value: '', optional: true, children: []},
        {field_name: 'item_category5', field_type: 'l', field_value: '', optional: true, children: []},
        {field_name: 'item_list_id', field_type: 'l', field_value: '', optional: true, children: []},
        {field_name: 'item_list_name', field_type: 'l', field_value: '', optional: true, children: []},
        {field_name: 'item_variant', field_type: 'l', field_value: '', optional: true, children: []},
        {field_name: 'quantity', field_type: 'l', field_value: '', optional: true, children: []},
        {field_name: 'price', field_type: 'l', field_value: '', optional: true, children: []},
        {field_name: 'discount', field_type: 'l', field_value: '', optional: true, children: []},
        {field_name: 'affiliation', field_type: 'l', field_value: '', optional: true, children: []},
        {field_name: 'coupon', field_type: 'l', field_value: '', optional: true, children: []},
        {field_name: 'location_id', field_type: 'l', field_value: '', optional: true, children: []},
        {field_name: 'index', field_type: 'l', field_value: '', optional: true, children: []}
      ]}
    ]},
  ],

  remove_from_cart: [
    {field_name: 'name', field_type: 'c', field_value: 'remove_from_cart', children: []},
    {field_name: 'params', field_type: 'o', children: [
      {field_name: 'currency', field_type: 'l', field_value: '', children: []},
      {field_name: 'value', field_type: 'l', field_value: '', children: []},
      {field_name: 'items', field_type: 'a', field_value: '', children: [
        {field_name: 'item_id', field_type: 'l', field_value: '', children: []},
        {field_name: 'item_name', field_type: 'l', field_value: '', children: []},
        {field_name: 'item_brand', field_type: 'l', field_value: '', optional: true, children: []},
        {field_name: 'item_category', field_type: 'l', field_value: '', optional: true, children: []},
        {field_name: 'item_category2', field_type: 'l', field_value: '', optional: true, children: []},
        {field_name: 'item_category3', field_type: 'l', field_value: '', optional: true, children: []},
        {field_name: 'item_category4', field_type: 'l', field_value: '', optional: true, children: []},
        {field_name: 'item_category5', field_type: 'l', field_value: '', optional: true, children: []},
        {field_name: 'item_list_id', field_type: 'l', field_value: '', optional: true, children: []},
        {field_name: 'item_list_name', field_type: 'l', field_value: '', optional: true, children: []},
        {field_name: 'item_variant', field_type: 'l', field_value: '', optional: true, children: []},
        {field_name: 'quantity', field_type: 'l', field_value: '', optional: true, children: []},
        {field_name: 'price', field_type: 'l', field_value: '', optional: true, children: []},
        {field_name: 'discount', field_type: 'l', field_value: '', optional: true, children: []},
        {field_name: 'affiliation', field_type: 'l', field_value: '', optional: true, children: []},
        {field_name: 'coupon', field_type: 'l', field_value: '', optional: true, children: []},
        {field_name: 'location_id', field_type: 'l', field_value: '', optional: true, children: []},
        {field_name: 'index', field_type: 'l', field_value: '', optional: true, children: []}
      ]}
    ]},
  ],

  search: [
    {field_name: 'name', field_type: 'c', field_value: 'search', children: []},
    {field_name: 'params', field_type: 'o', children: [
      {field_name: 'search_term', field_type: 'l', field_value: '', children: []},
    ]},
  ],

  select_content: [
    {field_name: 'name', field_type: 'c', field_value: 'select_content', children: []},
    {field_name: 'params', field_type: 'o', children: [
      {field_name: 'content_type', field_type: 'l', field_value: '', optional: true, children: []},
      {field_name: 'content_id', field_type: 'l', field_value: '', optional: true, children: []},
    ]},
  ],

  select_item: [
    {field_name: 'name', field_type: 'c', field_value: 'select_item', children: []},
    {field_name: 'params', field_type: 'o', children: [
      {field_name: 'item_list_id', field_type: 'l', field_value: '', children: []},
      {field_name: 'item_list_name', field_type: 'l', field_value: '', children: []},
      {field_name: 'items', field_type: 'a', field_value: '', children: [
        {field_name: 'item_id', field_type: 'l', field_value: '', children: []},
        {field_name: 'item_name', field_type: 'l', field_value: '', children: []},
        {field_name: 'item_brand', field_type: 'l', field_value: '', optional: true, children: []},
        {field_name: 'item_category', field_type: 'l', field_value: '', optional: true, children: []},
        {field_name: 'item_category2', field_type: 'l', field_value: '', optional: true, children: []},
        {field_name: 'item_category3', field_type: 'l', field_value: '', optional: true, children: []},
        {field_name: 'item_category4', field_type: 'l', field_value: '', optional: true, children: []},
        {field_name: 'item_category5', field_type: 'l', field_value: '', optional: true, children: []},
        {field_name: 'item_list_id', field_type: 'l', field_value: '', optional: true, children: []},
        {field_name: 'item_list_name', field_type: 'l', field_value: '', optional: true, children: []},
        {field_name: 'item_variant', field_type: 'l', field_value: '', optional: true, children: []},
        {field_name: 'quantity', field_type: 'l', field_value: '', optional: true, children: []},
        {field_name: 'price', field_type: 'l', field_value: '', optional: true, children: []},
        {field_name: 'discount', field_type: 'l', field_value: '', optional: true, children: []},
        {field_name: 'affiliation', field_type: 'l', field_value: '', optional: true, children: []},
        {field_name: 'coupon', field_type: 'l', field_value: '', optional: true, children: []},
        {field_name: 'location_id', field_type: 'l', field_value: '', optional: true, children: []},
        {field_name: 'index', field_type: 'l', field_value: '', optional: true, children: []}
      ]}
    ]},
  ],

  select_promotion: [
    {field_name: 'name', field_type: 'c', field_value: 'select_promotion', children: []},
    {field_name: 'params', field_type: 'o', children: [
      {field_name: 'creative_name', field_type: 'l', field_value: '', optional: true, children: []},
      {field_name: 'creative_slot', field_type: 'l', field_value: '', optional: true, children: []},
      {field_name: 'promotion_id', field_type: 'l', field_value: '', optional: true, children: []},
      {field_name: 'promotion_name', field_type: 'l', field_value: '', optional: true, children: []},
      {field_name: 'items', field_type: 'a', field_value: '', optional: true, children: [
        {field_name: 'item_id', field_type: 'l', field_value: '', children: []},
        {field_name: 'item_name', field_type: 'l', field_value: '', children: []},
        {field_name: 'item_brand', field_type: 'l', field_value: '', optional: true, children: []},
        {field_name: 'item_category', field_type: 'l', field_value: '', optional: true, children: []},
        {field_name: 'item_category2', field_type: 'l', field_value: '', optional: true, children: []},
        {field_name: 'item_category3', field_type: 'l', field_value: '', optional: true, children: []},
        {field_name: 'item_category4', field_type: 'l', field_value: '', optional: true, children: []},
        {field_name: 'item_category5', field_type: 'l', field_value: '', optional: true, children: []},
        {field_name: 'item_list_id', field_type: 'l', field_value: '', optional: true, children: []},
        {field_name: 'item_list_name', field_type: 'l', field_value: '', optional: true, children: []},
        {field_name: 'item_variant', field_type: 'l', field_value: '', optional: true, children: []},
        {field_name: 'quantity', field_type: 'l', field_value: '', optional: true, children: []},
        {field_name: 'price', field_type: 'l', field_value: '', optional: true, children: []},
        {field_name: 'discount', field_type: 'l', field_value: '', optional: true, children: []},
        {field_name: 'affiliation', field_type: 'l', field_value: '', optional: true, children: []},
        {field_name: 'coupon', field_type: 'l', field_value: '', optional: true, children: []},
        {field_name: 'location_id', field_type: 'l', field_value: '', optional: true, children: []},
        {field_name: 'index', field_type: 'l', field_value: '', optional: true, children: []}
      ]}
    ]},
  ],

  share: [
    {field_name: 'name', field_type: 'c', field_value: 'share', children: []},
    {field_name: 'params', field_type: 'o', children: [
      {field_name: 'method', field_type: 'l', field_value: '', optional: true, children: []},
      {field_name: 'content_type', field_type: 'l', field_value: '', optional: true, children: []},
      {field_name: 'item_id', field_type: 'l', field_value: '', optional: true, children: []},
    ]},
  ],

  sign_up: [
    {field_name: 'name', field_type: 'c', field_value: 'sign_up', children: []},
    {field_name: 'params', field_type: 'o', children: [
      {field_name: 'method', field_type: 'l', field_value: '', optional: true, children: []},
    ]},
  ],

  spend_virtual_currency: [
    {field_name: 'name', field_type: 'c', field_value: 'spend_virtual_currency', children: []},
    {field_name: 'params', field_type: 'o', children: [
      {field_name: 'value', field_type: 'l', field_value: '', children: []},
      {field_name: 'virtual_currency_name', field_type: 'l', field_value: '', children: []},
      {field_name: 'item_name', field_type: 'l', field_value: '', optional: true, children: []},
    ]},
  ],

  tutorial_begin: [
    {field_name: 'name', field_type: 'c', field_value: 'tutorial_begin', children: []}
  ],

  tutorial_complete: [
    {field_name: 'name', field_type: 'c', field_value: 'tutorial_complete', children: []}
  ],

  unlock_achievement: [
    {field_name: 'name', field_type: 'c', field_value: 'unlock_achievement', children: []},
    {field_name: 'params', field_type: 'o', children: [
      {field_name: 'achievement_id', field_type: 'l', field_value: '', children: []}
    ]},
  ],

  view_cart: [
    {field_name: 'name', field_type: 'c', field_value: 'view_cart', children: []},
    {field_name: 'params', field_type: 'o', children: [
      {field_name: 'currency', field_type: 'l', field_value: '', children: []},
      {field_name: 'value', field_type: 'l', field_value: '', children: []},
      {field_name: 'items', field_type: 'a', field_value: '', children: [
        {field_name: 'item_id', field_type: 'l', field_value: '', children: []},
        {field_name: 'item_name', field_type: 'l', field_value: '', children: []},
        {field_name: 'item_brand', field_type: 'l', field_value: '', optional: true, children: []},
        {field_name: 'item_category', field_type: 'l', field_value: '', optional: true, children: []},
        {field_name: 'item_category2', field_type: 'l', field_value: '', optional: true, children: []},
        {field_name: 'item_category3', field_type: 'l', field_value: '', optional: true, children: []},
        {field_name: 'item_category4', field_type: 'l', field_value: '', optional: true, children: []},
        {field_name: 'item_category5', field_type: 'l', field_value: '', optional: true, children: []},
        {field_name: 'item_list_id', field_type: 'l', field_value: '', optional: true, children: []},
        {field_name: 'item_list_name', field_type: 'l', field_value: '', optional: true, children: []},
        {field_name: 'item_variant', field_type: 'l', field_value: '', optional: true, children: []},
        {field_name: 'quantity', field_type: 'l', field_value: '', optional: true, children: []},
        {field_name: 'price', field_type: 'l', field_value: '', optional: true, children: []},
        {field_name: 'discount', field_type: 'l', field_value: '', optional: true, children: []},
        {field_name: 'affiliation', field_type: 'l', field_value: '', optional: true, children: []},
        {field_name: 'coupon', field_type: 'l', field_value: '', optional: true, children: []},
        {field_name: 'location_id', field_type: 'l', field_value: '', optional: true, children: []},
        {field_name: 'index', field_type: 'l', field_value: '', optional: true, children: []}
      ]}
    ]},
  ],

  view_item: [
    {field_name: 'name', field_type: 'c', field_value: 'view_item', children: []},
    {field_name: 'params', field_type: 'o', children: [
      {field_name: 'currency', field_type: 'l', field_value: '', children: []},
      {field_name: 'value', field_type: 'l', field_value: '', children: []},
      {field_name: 'items', field_type: 'a', field_value: '', optional: true, children: [
        {field_name: 'item_id', field_type: 'l', field_value: '', children: []},
        {field_name: 'item_name', field_type: 'l', field_value: '', children: []},
        {field_name: 'item_brand', field_type: 'l', field_value: '', optional: true, children: []},
        {field_name: 'item_category', field_type: 'l', field_value: '', optional: true, children: []},
        {field_name: 'item_category2', field_type: 'l', field_value: '', optional: true, children: []},
        {field_name: 'item_category3', field_type: 'l', field_value: '', optional: true, children: []},
        {field_name: 'item_category4', field_type: 'l', field_value: '', optional: true, children: []},
        {field_name: 'item_category5', field_type: 'l', field_value: '', optional: true, children: []},
        {field_name: 'item_list_id', field_type: 'l', field_value: '', optional: true, children: []},
        {field_name: 'item_list_name', field_type: 'l', field_value: '', optional: true, children: []},
        {field_name: 'item_variant', field_type: 'l', field_value: '', optional: true, children: []},
        {field_name: 'quantity', field_type: 'l', field_value: '', optional: true, children: []},
        {field_name: 'price', field_type: 'l', field_value: '', optional: true, children: []},
        {field_name: 'discount', field_type: 'l', field_value: '', optional: true, children: []},
        {field_name: 'affiliation', field_type: 'l', field_value: '', optional: true, children: []},
        {field_name: 'coupon', field_type: 'l', field_value: '', optional: true, children: []},
        {field_name: 'location_id', field_type: 'l', field_value: '', optional: true, children: []},
        {field_name: 'index', field_type: 'l', field_value: '', optional: true, children: []}
      ]}
    ]},
  ],

  view_item_list: [
    {field_name: 'name', field_type: 'c', field_value: 'view_item_list', children: []},
    {field_name: 'params', field_type: 'o', children: [
      {field_name: 'item_list_id', field_type: 'l', field_value: '', optional: true, children: []},
      {field_name: 'item_list_name', field_type: 'l', field_value: '', optional: true, children: []},
      {field_name: 'items', field_type: 'a', field_value: '', children: [
        {field_name: 'item_id', field_type: 'l', field_value: '', children: []},
        {field_name: 'item_name', field_type: 'l', field_value: '', children: []},
        {field_name: 'item_brand', field_type: 'l', field_value: '', optional: true, children: []},
        {field_name: 'item_category', field_type: 'l', field_value: '', optional: true, children: []},
        {field_name: 'item_category2', field_type: 'l', field_value: '', optional: true, children: []},
        {field_name: 'item_category3', field_type: 'l', field_value: '', optional: true, children: []},
        {field_name: 'item_category4', field_type: 'l', field_value: '', optional: true, children: []},
        {field_name: 'item_category5', field_type: 'l', field_value: '', optional: true, children: []},
        {field_name: 'item_list_id', field_type: 'l', field_value: '', optional: true, children: []},
        {field_name: 'item_list_name', field_type: 'l', field_value: '', optional: true, children: []},
        {field_name: 'item_variant', field_type: 'l', field_value: '', optional: true, children: []},
        {field_name: 'quantity', field_type: 'l', field_value: '', optional: true, children: []},
        {field_name: 'price', field_type: 'l', field_value: '', optional: true, children: []},
        {field_name: 'discount', field_type: 'l', field_value: '', optional: true, children: []},
        {field_name: 'affiliation', field_type: 'l', field_value: '', optional: true, children: []},
        {field_name: 'coupon', field_type: 'l', field_value: '', optional: true, children: []},
        {field_name: 'location_id', field_type: 'l', field_value: '', optional: true, children: []},
        {field_name: 'index', field_type: 'l', field_value: '', optional: true, children: []}
      ]}
    ]},
  ],

  view_promotion: [
    {field_name: 'name', field_type: 'c', field_value: 'view_promotion', children: []},
    {field_name: 'params', field_type: 'o', children: [
      {field_name: 'creative_name', field_type: 'l', field_value: '', optional: true, children: []},
      {field_name: 'creative_slot', field_type: 'l', field_value: '', optional: true, children: []},
      {field_name: 'promotion_id', field_type: 'l', field_value: '', optional: true, children: []},
      {field_name: 'promotion_name', field_type: 'l', field_value: '', optional: true, children: []},
      {field_name: 'items', field_type: 'a', field_value: '', children: [
        {field_name: 'item_id', field_type: 'l', field_value: '', children: []},
        {field_name: 'item_name', field_type: 'l', field_value: '', children: []},
        {field_name: 'item_brand', field_type: 'l', field_value: '', optional: true, children: []},
        {field_name: 'item_category', field_type: 'l', field_value: '', optional: true, children: []},
        {field_name: 'item_category2', field_type: 'l', field_value: '', optional: true, children: []},
        {field_name: 'item_category3', field_type: 'l', field_value: '', optional: true, children: []},
        {field_name: 'item_category4', field_type: 'l', field_value: '', optional: true, children: []},
        {field_name: 'item_category5', field_type: 'l', field_value: '', optional: true, children: []},
        {field_name: 'item_list_id', field_type: 'l', field_value: '', optional: true, children: []},
        {field_name: 'item_list_name', field_type: 'l', field_value: '', optional: true, children: []},
        {field_name: 'item_variant', field_type: 'l', field_value: '', optional: true, children: []},
        {field_name: 'quantity', field_type: 'l', field_value: '', optional: true, children: []},
        {field_name: 'price', field_type: 'l', field_value: '', optional: true, children: []},
        {field_name: 'discount', field_type: 'l', field_value: '', optional: true, children: []},
        {field_name: 'affiliation', field_type: 'l', field_value: '', optional: true, children: []},
        {field_name: 'coupon', field_type: 'l', field_value: '', optional: true, children: []},
        {field_name: 'location_id', field_type: 'l', field_value: '', optional: true, children: []},
        {field_name: 'index', field_type: 'l', field_value: '', optional: true, children: []}
      ]}
    ]},
  ],

  view_search_results: [
    {field_name: 'name', field_type: 'c', field_value: 'view_search_results', children: []},
    {field_name: 'params', field_type: 'o', children: [
      {field_name: 'search_term', field_type: 'l', field_value: '', optional: true, children: []}
    ]},
  ]
};

const ga4ItemFields = [
  {field_name: 'item_id', field_type: 'l', field_value: '', children: []},
  {field_name: 'item_name', field_type: 'l', field_value: '', children: []},
  {field_name: 'item_brand', field_type: 'l', field_value: '', optional: true, children: []},
  {field_name: 'item_category', field_type: 'l', field_value: '', optional: true, children: []},
  {field_name: 'item_category2', field_type: 'l', field_value: '', optional: true, children: []},
  {field_name: 'item_category3', field_type: 'l', field_value: '', optional: true, children: []},
  {field_name: 'item_category4', field_type: 'l', field_value: '', optional: true, children: []},
  {field_name: 'item_category5', field_type: 'l', field_value: '', optional: true, children: []},
  {field_name: 'item_list_id', field_type: 'l', field_value: '', optional: true, children: []},
  {field_name: 'item_list_name', field_type: 'l', field_value: '', optional: true, children: []},
  {field_name: 'item_variant', field_type: 'l', field_value: '', optional: true, children: []},
  {field_name: 'quantity', field_type: 'l', field_value: '', optional: true, children: []},
  {field_name: 'price', field_type: 'l', field_value: '', optional: true, children: []},
  {field_name: 'discount', field_type: 'l', field_value: '', optional: true, children: []},
  {field_name: 'affiliation', field_type: 'l', field_value: '', optional: true, children: []},
  {field_name: 'coupon', field_type: 'l', field_value: '', optional: true, children: []},
  {field_name: 'location_id', field_type: 'l', field_value: '', optional: true, children: []},
  {field_name: 'index', field_type: 'l', field_value: '', optional: true, children: []}
]