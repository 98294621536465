/*
    Facebook Conversion API
    https://developers.facebook.com/docs/marketing-api/conversions-api/parameters
*/

// List contains all fields, including all technical fields
import {tracedockFields} from "../tracedockFields";

export const fbFieldDescriptions = {

  ...tracedockFields,

//client data
  user_data: {caption: 'Browser ID, IP address, User agent.'},

// event data
  event_name: {choices: [{key: 'Purchase', value: 'Purchase'}, {key: 'Lead', value: 'Lead'}, {key: 'PageView', value: 'PageView'}]},
  event_id: {optional: true, caption: ''},
  event_time: {optional: false, caption: 'Automatic field with timestamp of event'},
  event_source_url: {optional: true, caption: 'Required for website events'},
  action_source: {
    optional: true, choices: [
      {key: 'email', value: 'email'},
      {key: 'website', value: 'website'},
      {key: 'phone_call', value: 'phone_call'},
      {key: 'chat', value: 'chat'},
      {key: 'physical_store', value: 'physical_store'},
      {key: 'system_generated', value: 'system_generated'},
      {key: 'other', value: 'other'}]
  },
  custom_data: {caption: ''},

// custom data
  value: {caption: 'Transaction revenue. Float value, e.g. 41.12'},
  currency: {caption: 'Mandatory, three-letter currency'},
  order_id: {optional: true, caption: ''},

// products
  contents: {optional: true, caption: 'List of products sold'},
  id: {caption: 'Internal product ID or SKU'},
  quantity: {caption: 'Number of products sold'},
  price: {caption: 'Price of the product. Float value, e.g. 41.12'},
  category: {optional: true, caption: 'Category or name of the product purchased'},
};