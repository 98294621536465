import {optionsIfSupported} from "../lib/helpers";

window.shortcuts = window.shortcuts || [];

export const registerShortcut = (key, callback, once) => {
  window.shortcuts = [{key, callback, once}].concat(window.shortcuts.filter((s) => s.key !== key))
};

export const removeShortcut = (key) => {
  window.shortcuts = window.shortcuts.filter((s) => s.key !== key)
};


export const registerKeyboardShortcuts = () => {
  // register event and check all values
  window.addEventListener('keyup', (e) => {

    // console.log('  listened: ', e.key);

    window.shortcuts.some((shortcut) => {
      if (shortcut.key === e.key) {
        // call if keystroke same, otherwise ignore
        e.preventDefault();
        shortcut.callback();

        if (shortcut.once) removeShortcut(shortcut.key);

        return true
      }
      return false
    });

  }, optionsIfSupported);
};