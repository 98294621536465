import React from 'react';
import PropTypes from 'prop-types';

import './inputSelect.scss';

export class InputSelect extends React.Component {

  static propTypes = {
    ref: PropTypes.any,
    type: PropTypes.oneOf(['radio', 'checkbox']),
    choices: PropTypes.array,
    selectedValues: PropTypes.array,
    onChange: PropTypes.any,
    style: PropTypes.any,
    readOnly: PropTypes.bool,
    size: PropTypes.string,
  };

  static defaultProps = {
    ref: null,
    type: 'radio',
    choices: [],
    selectedValues: [],
    onChange: null,
    style: {},
    readOnly: false,
    size: 'small',
  };

  constructor(props, context) {
    super(props, context);
    this.state = {
      other_value: props.selectedValues[0] === 'other' ? props.selectedValues[1] : ''
    };
  }

  render({ref, size, type, choices, selectedValues, onChange, style, readOnly} = this.props) {
    return (
      <div className='inputSelect'
           style={style}
           ref={(reference) => ref = reference}
      >

        {choices && choices.map((choice, idx) => {
          return (
            <div key={idx} className="selectElement noselect clickable"
                 onMouseDown={() => {
                   if (type === 'radio') {
                     // always propogate this value, others are per definition not selected
                     onChange([choice.key])
                   } else if (selectedValues.length > 0 && selectedValues.includes(choice.key)) {
                     // return filtered out
                     onChange(selectedValues.filter((value) => value !== choice.key))
                   } else {
                     onChange(selectedValues.concat(choice.key))
                   }
                 }}
            >

              <span className={`checkmark ${type} ${selectedValues.length > 0 && selectedValues.includes(choice.key) ? 'active' : ''}`}/>

              <span className={size}>{choice.value}</span>
              {choice.type === 'text' && (
                <input
                  className='small otherInput'
                  autoFocus={false}
                  type='text'
                  value={this.state.other_value}
                  autoComplete="new-info"
                  onChange={
                    (event) => {
                      // update choice with key and value in second place as other_value
                      this.setState({other_value: event.target.value},
                        () => onChange([choice.key, this.state.other_value]));
                    }
                  }
                />
              )}

            </div>
          )

        })}

      </div>
    );
  }
}
