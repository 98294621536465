import { api } from "../../lib/api";
import { resetNonPersistedStates } from "../reducer";
import { store } from "../../index.js"
import { getTechnicalLink, getOrganisationId, getLogicalAccount, getSsoAccount, getCurrentTenantId } from "../apiConstants";

// export function _setCMAccount(data) {
//   dispatch({
//     type: 'SET_CMACCOUNT_CREDENTIALS',
//     cmSSOAccountGuid: data.cmSSOAccountGuid,
//     cmLogicalAccountGuid: data.cmLogicalAccountGuid
//   });
// }

//Not an actual reducer function
export async function getCmAccountInformation(data) {
    // console.log("Into getCmAccount info with data: ", data)
    const BASE_TENANTS_ENDPOINT = `v1/agencies/${getTechnicalLink()}/tenants/${getTechnicalLink()}`;
    let res = await api.getdotnet(`${BASE_TENANTS_ENDPOINT}/isreseller`);

    store.dispatch({
        type: 'SET_CMACCOUNT_CREDENTIALS',
        cmSSOAccountGuid: data.sso,
        cmLogicalAccountGuid: data.acc,
        traceDockKey: data.key,
        is_agency: res,
        organisationId: data.acc.OrganizationID
    })

    store.dispatch({
        type: 'SET_AUTHENTICATED'
    })

    // store.dispatch({
    //     type: 'SET_AGENCY',
    //     is_agent: res,
    // })
    

    // return async () => {
    //   try {
    //     let res = await api.getdotnet(`tenants/reseller/${getTechnicalLink()}/${getSsoAccount()}/${data.OrganizationID}`);
    //     console.log("GetCmAccountInfo = ",res)

    //   } catch (error) {
    //     dispatch({
    //       type: 'ACCOUNT_ERROR'
    //     });
    //   }
    // }
};