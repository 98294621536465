import {api} from "../../lib/api";
import {resetNonPersistedStates} from "../reducer";
import {setAlertDialog} from "../../components/alert/alert";
import {store} from "../../index.js"
import { getTechnicalLink, getOrganisationId, getLogicalAccount, getSsoAccount, getCurrentTenantId } from "../apiConstants";
// import { BASE_DOMAINS_ENDPOINT } from "../apiConstants";


function _completeLogin(dispatch, res) {

  // set token in api so we can make auth calls again
  // api.access_token = res.access_token;


  // dispatch({
  //   type: 'SET_AUTH_TOKEN',
  //   access_token: res.access_token,
  // });

  dispatch({
    type: 'SET_PERSIST_PROFILE',
    get_started: res.get_started,
    // is_staff: res.is_staff,
    // is_admin: res.is_admin,
    is_agent: res.is_agent,
  });

  dispatch({
    type: 'SET_ALL_DOMAINS',
    domains: res.domains,
  });

  dispatch({
    type: 'PROFILE_DONE',
    // profile: res.profile,
    // admin: res.admin,
    agency: res.agency,
  });

  return res.profile
}

// export const signUp = ({email, first_name, last_name, company_name, type, password}) => {
//   return async (dispatch) => {
//     try {
//       dispatch({
//         type: 'ACCOUNT_UPDATE_PENDING'
//       });
//       let data = {
//         grant_type: "signup",
//         client_secret: api.config.client_secret,
//         email: email,
//         first_name: first_name,
//         last_name: last_name,
//         company_name: company_name,
//         password: password,
//         type: type,
//       };
//       let res = await api.post('auth/login.json', data);
//       return _completeLogin(dispatch, res)

//     } catch (error) {
//       dispatch({
//         type: 'ACCOUNT_ERROR'
//       });
//     }
//   }
// };

// export const logIn = ({email, password}) => {
//   return async (dispatch) => {
//     try {
//       dispatch({
//         type: 'ACCOUNT_PENDING'
//       });
//       let data = {
//         "grant_type": "login",
//         "client_secret": api.config.client_secret,
//         "email": email,
//         "password": password
//       };
//       let res = await api.post('auth/login.json', data);
//       return _completeLogin(dispatch, res)

//     } catch (error) {
//       dispatch({
//         type: 'ACCOUNT_ERROR'
//       });
//     }
//   }
// };

// export const password_link = ({email, password, password_reset_code}) => {
//   return async (dispatch) => {
//     try {
//       dispatch({
//         type: 'ACCOUNT_PENDING'
//       });
//       let data = {
//         "grant_type": "password_link",
//         "client_secret": api.config.client_secret,
//         "email": email,
//         "password": password,
//         "password_reset_code": password_reset_code
//       };
//       let res = await api.post('auth/login.json', data);
//       return _completeLogin(dispatch, res)

//     } catch (error) {
//       dispatch({
//         type: 'ACCOUNT_ERROR'
//       });
//     }
//   }
// };

// export const email_activation_link = ({email_code, new_email}) => {
//   return async (dispatch) => {
//     try {
//       dispatch({
//         type: 'ACCOUNT_PENDING'
//       });
//       let data = {
//         "grant_type": "email_activation_link",
//         "client_secret": api.config.client_secret,
//         "email_code": email_code,
//         "new_email": new_email
//       };
//       let res = await api.post('auth/login.json', data);
//       return _completeLogin(dispatch, res)

//     } catch (error) {
//       dispatch({
//         type: 'ACCOUNT_ERROR'
//       });
//     }
//   }
// };


// export const logout = () => {
//   return async (dispatch) => {
//     try {
//       dispatch({
//         type: 'RESET_PERSIST_STATE'
//       });
//       resetNonPersistedStates(dispatch);
//       await api.post('auth/logout.json', {});

//       // reset access_token
//       api.access_token = null;

//     } catch (error) {
//       dispatch({
//         type: 'RESET_PERSIST_STATE'
//       });
//       resetNonPersistedStates(dispatch)
//     }
//   }
// };

export const getProfile = () => {
  return async (dispatch) => {
    try {
      dispatch({
        type: 'ACCOUNT_PENDING'
      });
      // let res = await api.get('auth/profile.json');
      //Can only activate BASE_DOMAINS_ENDPOINT here, because if we do it in top of the file, the IDs are not yet extracted from header
      const BASE_DOMAINS_ENDPOINT = `v1/agencies/${getTechnicalLink()}/tenants/${getCurrentTenantId()}/domains`;
      let res = await api.getdotnet(`${BASE_DOMAINS_ENDPOINT}/formatted`);
      // console.log("Response getProfile = ", res)

      dispatch({
        type: 'SET_PERSIST_PROFILE',
        get_started: res.get_started,
        // is_staff: res.is_staff,
        // is_admin: res.is_admin,
        // is_agent: res.is_agent,
      });

      if(res){
        dispatch({
          type: 'SET_ALL_DOMAINS',
          domains: res.domains,
        });
      } else {
        dispatch({
          type: 'SET_ALL_DOMAINS',
          domains: [],
        });
      }
      
      dispatch({
        type: 'PROFILE_DONE',
        // profile: res.profile,
        admin: res.admin,
        // agency: res.agency,
      });

      return res

    } catch (error) {
      dispatch({
        type: 'ACCOUNT_ERROR',
      });
    }
  }
};

export const updateProfile = (data) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: 'ACCOUNT_UPDATE_PENDING'
      });
      let res = await api.post('auth/profile.json', data);


      dispatch({
        type: 'SET_PERSIST_PROFILE',
        get_started: res.get_started,
        // is_staff: res.is_staff,
        // is_admin: res.is_admin,
        is_agent: res.is_agent,
      });

      dispatch({
        type: 'SET_ALL_DOMAINS',
        domains: res.domains,
      });

      dispatch({
        type: 'PROFILE_DONE',
        profile: res.profile,
        admin: res.admin,
        agency: res.agency,
      });

      return res

    } catch (error) {
      dispatch({
        type: 'ACCOUNT_ERROR',
      });
    }
  }
};

export const saveCompany = (data) => {
  return async (dispatch) => {
    try {
      await api.post('auth/save_company.json', data);
    } catch (error) {
      dispatch({
        type: 'ACCOUNT_ERROR',
      });
    }
  }
};

export const updateEmail = ({email, password}) => {
  return async (dispatch) => {
    try {
      let res = await api.post('auth/update_email.json', {new_email: email, password});

      dispatch({
        type: 'ACCOUNT_ERROR'
      });

      return res

    } catch (error) {
      dispatch({
        type: 'ACCOUNT_ERROR',
      });
    }
  }
};


export const updatePassword = ({old_password, new_password}) => {
  return async (dispatch) => {
    try {
      let res = await api.post('auth/update_password.json', {old_password, new_password});

      dispatch({
        type: 'ACCOUNT_ERROR'
      });

      return res

    } catch (error) {
      dispatch({
        type: 'ACCOUNT_ERROR',
      });
    }
  }
};

export const getCreditor = ({uuid}) => {
  // return async (dispatch) => {
  //   try {
  //     dispatch({
  //       type: 'ACCOUNT_PENDING'
  //     });
  //     let res = await api.post('billing/creditor.json', {uuid});
  //     dispatch({
  //       type: 'AGENCY_DONE',
  //       creditor: res
  //     });

  //     return res

  //   } catch (error) {
  //     dispatch({
  //       type: 'ACCOUNT_ERROR',
  //     });
  //   }
  // }
};


export const updateCreditor = ({uuid, creditor}) => {
  return async (dispatch) => {
    try {

      let res = await api.post('billing/update_creditor.json', {uuid, creditor});

      if (res) dispatch({
        type: 'ADMIN_DONE',
        admin: res
      });

      return res

    } catch (error) {
      dispatch({
        type: 'ACCOUNT_ERROR',
      });
    }
  }
};


export const getMembers = ({uuid}) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: 'ACCOUNT_PENDING'
      });
      let res = await api.post('domain/get_members.json', {uuid});

      dispatch({
        type: 'MEMBERS_DONE',
        members: res
      });

      return res

    } catch (error) {
      dispatch({
        type: 'ACCOUNT_ERROR',
      });
    }
  }
};


export const updateMembers = ({uuid, members}) => {
  return async (dispatch) => {
    try {

      let res = await api.post('domain/update_members.json', {uuid, members});

      dispatch({
        type: 'MEMBERS_DONE',
        members: res.domain
      });
      if (res.added) {
        setAlertDialog('Members invited', 'Your new team members have received an invite via the email to join you in TraceDock.')
      }

      return res.domain

    } catch (error) {
      dispatch({
        type: 'ACCOUNT_ERROR',
      });
    }
  }
};


export const inviteAdmin = ({admins, uuid}) => {
  return async (dispatch) => {
    try {

      let res = await api.post('billing/invite_admin.json', {admins, uuid});
      if (res.added) {
        setAlertDialog('Done', `We added and invited ${res.added} users as admin to the company.`)
      }
      return res.admins

    } catch (error) {
      dispatch({
        type: 'ACCOUNT_ERROR',
      });
    }
  }
};

export const createDomain = ({domains, company_uuid, as_agent}) => {
  console.log(`creating domain with : ${domains}, ${company_uuid}`)
  return async (dispatch) => {
    try {

      // let res = await api.post('domain/create_new.json', {domains, company_uuid});
      const BASE_DOMAINS_ENDPOINT = `v1/agencies/${getTechnicalLink()}/tenants/${getTechnicalLink()}/domains`;
      let res = await api.posttodotnet(`${BASE_DOMAINS_ENDPOINT}`, {Domains:domains, TenantId:company_uuid});

      if (res) {
        dispatch({
          type: 'ADD_DOMAINS',
          new_domains: res
        });

        if (as_agent) {
          dispatch({
            type: 'ADD_DOMAINS_OF_CREDITOR',
            new_domains: res,
            company_uuid: company_uuid
          });
        } else {
          dispatch({
            type: 'ADD_DOMAINS_OF_ADMIN',
            new_domains: res,
            company_uuid: company_uuid
          });
        }
      }

      return res

    } catch (error) {
      dispatch({
        type: 'ACCOUNT_ERROR',
      });
    }
  }
};

export const getAgencySignData = ({uuid}) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: 'ACCOUNT_PENDING'
      });
      let res = await api.post('agency/get_sign_data.json', {uuid});

      dispatch({
        type: 'ACCOUNT_ERROR',
      });

      return res

    } catch (error) {
      dispatch({
        type: 'ACCOUNT_ERROR',
      });
    }
  }
};

//This call gets all tenants for the agency.
export const getAgency = ({uuid}) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: 'ACCOUNT_PENDING'
      });
      // let blaa = await api.post('agency/get_agency_data.json', {uuid});
      // console.log("Original getAgency: ",blaa)
      const BASE_AGENCY_ENDPOINT = `v1/agencies/${getTechnicalLink()}/tenants/${getTechnicalLink()}`;
      let res = await api.getdotnet(`${BASE_AGENCY_ENDPOINT}`);

      // dispatch({
      //   type: 'AGENCY_DONE',
      //   agency: blaa.agency,
      //   creditors: blaa.creditors,
      //   // agents: res.agents,
      // });
      
      dispatch({
        type: 'AGENCY_DONE',
        agency: res.agency,
        creditors: res.tenants,
      });

      return res

    } catch (error) {
      dispatch({
        type: 'ACCOUNT_ERROR',
      });
    }
  }
};

export const updateAgency = ({uuid, creditor}) => {
  return async (dispatch) => {
    try {

      let res = await api.post('agency/update_agency.json', {uuid, agency: creditor});

      if (res) dispatch({
        type: 'ADMIN_DONE',
        admin: res
      });

      return res

    } catch (error) {
      dispatch({
        type: 'ACCOUNT_ERROR',
      });
    }
  }
};


export const inviteAgent = ({agents, uuid}) => {
  // return async (dispatch) => {
  //   try {

  //     dispatch({
  //       type: 'ACCOUNT_PENDING'
  //     });
  //     let res = await api.post('agency/invite_agent.json', {agents, uuid});
  //     if (res.added) {
  //       setAlertDialog('Done', `We added and invited ${res.added} users as agent.`)
  //     }
  //     dispatch({
  //       type: 'AGENCY_DONE',
  //       agency: res.agency,
  //       creditors: res.creditors,
  //       agents: res.agents,
  //     });
  //     return res

  //   } catch (error) {
  //     dispatch({
  //       type: 'ACCOUNT_ERROR',
  //     });
  //   }
  // }
};

export const addCreditor = (data) => {
  // return async (dispatch) => {
  //   try {
  //     dispatch({
  //       type: 'ACCOUNT_PENDING'
  //     });
  //     let res = await api.post('agency/add_creditor.json', data);
  //     dispatch({
  //       type: 'AGENCY_DONE',
  //       agency: res.agency,
  //       creditors: res.creditors,
  //       agents: res.agents,
  //     });

  //     return res

  //   } catch (error) {
  //     dispatch({
  //       type: 'ACCOUNT_ERROR',
  //     });
  //   }
  // }
};

export const updateAgencyCreditor = (data) => {
  // return async (dispatch) => {
  //     let creditors = await api.post('agency/update_creditor.json', data);
  //     if (creditors) {
  //       dispatch({
  //         type: 'SET_AGENCY_CREDITORS',
  //         creditors: creditors
  //       });
  //     return creditors
  //   }

  // }
};

export const confirmPrice = (data) => {
  return async (dispatch) => {
    try {
      let res = await api.post('billing/confirm_price.json', data);

      if (res) {
        dispatch({
          type: 'ADMIN_DONE',
          admin: res
        });
        return res
      }

    } catch (error) {
      console.error('Error set Pending')
    }
  }
};

export const confirmGDPR = (data) => {
  return async (dispatch) => {
    try {
      let res = await api.post('billing/confirm_gdpr.json', data);

      if (res) {
        dispatch({
          type: 'ADMIN_DONE',
          admin: res
        });

        return res.gdpr_confirmed
      }

    } catch (error) {
      console.error('Error set Pending')
    }
  }
};


export const confirmPaymentMethod = (data) => {
  return async (dispatch) => {
    try {

      let res = await api.post('billing/confirm_payment.json', data);

      if (res) {
        dispatch({
          type: 'ADMIN_DONE',
          admin: res
        });
      }

      return res

    } catch (error) {
      console.error('Error set Pending')
    }
  }
};


export function resetAccountState() {
  return async (dispatch) => {
    dispatch({
      type: 'RESET_ACCOUNT_STATE'
    })
  }
}

