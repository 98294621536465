import React from 'react';
import {PropTypes} from 'prop-types';
import FieldError from './FieldError';
import styles from './field.module.scss';

const Field = ({children, errorMessage}) => {
  return (
    <div className={styles.field}>
      {children}
      {errorMessage && <FieldError message={errorMessage}/>}
    </div>
  )
};

Field.propTypes = {
  children: PropTypes.node,
  errorMessage: PropTypes.string
};

export default Field;
