import React, {useMemo} from 'react';
import Field from './fields/Field';

export const useChildrenContentMemo = function ({children, values, wasValidated, validation, onChange, onSaveCallback}) {
  return useMemo(
    () => {

      return React.Children.map(
        children,
        child => {
          const name = child.props.name;

          // if there is a name prop, then this is field component
          if (name) {
            const errorMessage = validation[name];
            const element = React.cloneElement(
              child,
              {
                ...child.props,
                onChange: onChange,
                value: values[child.props.name],
                isInvalid: errorMessage !== undefined,
                isValid: wasValidated && errorMessage === undefined,
                onSubmit: child.props.enterIsSubmit ? onSaveCallback : null,
              }
            );

            return (
              <Field errorMessage={errorMessage}>
                {element}
              </Field>
            )
          } else { // if there is no name prop, this is holder component
            const element = React.cloneElement(
              child,
              {
                ...child.props,
                onChange: onChange,
                onSubmit: child.props.isLastElement ? onSaveCallback : null,
                validation,
                wasValidated,
                values,
              }
            );

            return <>{element}</>;
          }

        }
      )
    },
    [children, onChange, validation, values, wasValidated]
  )
};
