import React from "react";
import ReactMarkdown from 'react-markdown';
import {ModalLayout} from "../../components/modalLayout/modalLayout";
import {getWiki} from "../../components/wiki/wiki";

class TermsPage extends React.Component {

  constructor(props, context) {
    super(props, context);

    this.state = {
      gdpr_text: '',
    };
  }

  componentDidMount() {

    getWiki('terms.md', {}, true).then(
      (content) => {
        if (content !== '') this.setState({gdpr_text: content})
      }
    )
  }

  render({history, persist, account} = this.props) {
    return (
      <div className='markdownPage'>

        {this.state.gdpr_text && <ModalLayout width='800px' withLogo={true}>

          <h2>General Terms and Conditions</h2>
          <h6 style={{marginBottom: 42}}>Agreements between TraceDock and our customers.</h6>

          <ReactMarkdown source={this.state.gdpr_text}/>

        </ModalLayout>}
      </div>
    );
  }
}

// no redux
export default TermsPage;
