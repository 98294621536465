import React from 'react';
// import { React, useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import {Button} from "../button/button";
import {Layout} from "./layout";

import './pageLayout.scss';
import {setAlertDialog} from "../../components/alert/alert";
import {Input} from "../../components/input/input";
import {colors} from "../../theme/colors";
import {formatDateTime} from "../../lib/normalize_values";
import { DomainSelector } from '../domainSelector/domainSelector';


export class PageLayout extends React.Component {

  static propTypes = {
    title: PropTypes.string, // number, string, any, func
    page: PropTypes.string, // number, string, any, func
    domain: PropTypes.any,
    description: PropTypes.string,
    withPadding: PropTypes.bool,
    hasPublish: PropTypes.bool,
    onPublish: PropTypes.any,
    requires_publish: PropTypes.bool,
    publishPending: PropTypes.bool,
    date_last_build: PropTypes.string,
  };

  static defaultProps = {
    page: '',
    title: '',
    domain: {},
    description: '',
    withPadding: true,
    hasPublish: false,
    onPublish: null,
    requires_publish: false,
    publishPending: false,
    date_last_build: '',
  };

  constructor(props, context) {
    super(props, context);
    this.state = {
      showPublishModal: false,
      message: '',
      messageInvalid: false
    };
  }

  _save() {
    if (!this.state.message) {
      this.setState({messageInvalid: true})
    } else {
      this.props.onPublish(this.state.message).then(() => {
        this.setState({showPublishModal: false});
        setAlertDialog('Publish started', 'We expect changes to be live within moments of you reading this message.')
      })
    }
  }

  // _ConditionalElement() {
  //     const currentUrl = window.location.href;
  //     if (currentUrl.includes('agency')) {
  //       return false;
  //       setShow(false);
  //     } else{
  //       return true;
  //     }
  //   }



  render({title, requires_publish, hasPublish, onPublish, description, date_last_build, history, children, publishPending, domain} = this.props) {
    
    return (
      <Layout withPadding={false} history={history}>

        <div className='pageLayout'>

          {this.state.showPublishModal && (
            <div className='publishModalContainer'>
              <div className='publishModal slideInFromTop'>
                <h2>Publish.</h2>
                <h6 className='spacer'>What changes have you made?</h6>

                <Input
                  label='Commit Message'
                  type='text'
                  focus={true}
                  isInvalid={this.state.messageInvalid}
                  onChange={(message) => this.setState({message: message})}
                  onSubmit={() => this._save()}
                />

                <div className='buttons'>

                  <Button
                    style={{margin: 4}}
                    title='Cancel'
                    onClick={() => this.setState({showPublishModal: false})}
                    color={colors.brand.darkgray}
                    cancel={true}
                    backgroundColor={colors.brand.gray_light}/>

                  <Button
                    style={{margin: 4}}
                    title='Publish changes'
                    onClick={() => this._save()}
                    isLoading={publishPending}
                  />

                </div>
              </div>
            </div>
          )}

          <div className='rulesHero'>

            <div className='titles'>
              <h2 style={{marginBottom: 4}}>
                {title}
              </h2>

{/* Temp solution to check if we are on agency page, we don't need domain info at that point */}
              {/* {!window.location.href.includes('agency') && <h3 className='darkgray regular' style={{maxWidth: 450}}>
                For the domain <span className='bold'>{domain.slug}</span>
                <DomainSelector history={history} allowDemo={this.state.showModal === ''} />
              </h3>} */}
              {/* Only show selector if a current domain is available (usually when agency is inspecting a tenant) */}
              {(domain.uuid || domain.id) && <h3><DomainSelector history={history} allowDemo={this.state.showModal === ''} /></h3>}
              

            </div>

            {hasPublish && (
              <div className={`publishWrapper ${requires_publish ? 'active' : ''}`}>

                {requires_publish
                  ? <h4 className='title'>Publish changes</h4>
                  : <h5 className='title'>All changes published</h5>
                }

                <p style={{display: 'flex', marginBottom: 18}}>
                  <span className='bold'>Last published:</span> <span style={{marginLeft: 4}}>{formatDateTime(date_last_build)}</span></p>

                {requires_publish && (
                  <Button title='Publish now'
                          disabled={!requires_publish || !onPublish}
                          backgroundColor={colors.brand.blue110}
                          color={colors.brand.white}
                          onClick={() => {if (onPublish) this.setState({showPublishModal: true})}}/>
                )}
              </div>
            )}

          </div>

          <div className='rulesBody'>
            {children}
          </div>

        </div>
      </Layout>
    );
  }
}