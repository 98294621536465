import React, {useCallback, useState} from 'react';
import SectionTitle from '../SectionTitle';
import {accountActions} from '../../../../redux/actions';
import {connect} from 'react-redux';
import {PropTypes} from 'prop-types';
import {Icon, valid} from '../../../../components/icon/icon';
import {colors} from "../../../../theme/colors";
import {PaymentForm} from "../../../../components/payment/paymentForm";
import styles from '../sectionContainer.module.scss';
import {STAGE} from "../../constants";
import {toLocale} from "../../../../lib/helpers";

const CreditCardSection = ({goNext, confirmPaymentMethod, admin}) => {
  const onConfirm = useCallback(
    async (data) => {
      // temporarily set pending for loader in payment form, then add creditcard and if correct we forward to next.
      setPending(true);
      let res = await confirmPaymentMethod(data);

      // only if payment confirmed we goto next
      if (res && res.payment_method_confirmed) {
        goNext(STAGE.ADDITIONAL_FINANCIAL);
      } else {
        setPending(false);
      }
    },
    [goNext, confirmPaymentMethod]
  );

  const [pending, setPending] = useState(false);

  return (
    <div className={styles.content}>
      <SectionTitle
        title='Confirm your payment plan'
        subtitle='You can cancel the subscription each month'
      />

      {!pending && (
        <div>

          <div className={styles.InfoContainer}>
            <p className='primary'>The free trial period of 21 days starts <span className='bold'>after</span> the setup of TraceDock is complete: when either the first cookies are extended or the first events are send to Google Analytics.</p>
          </div>

          <div className='row' style={{alignItems: 'center'}}>
            <Icon icon={valid} size={22} color={colors.brand.primary}/>
            <p  style={{marginLeft: 6}}>Billing starts after the trial period, unless there is a cancellation</p>
          </div>
          <div className='row' style={{alignItems: 'center'}}>
            <Icon icon={valid} size={22} color={colors.brand.primary}/>
            <p  style={{marginLeft: 6}}>You can cancel the subscription each month</p>
          </div>
          <div className='row' style={{alignItems: 'center'}}>
            <Icon icon={valid} size={22} color={colors.brand.primary}/>
            <p  style={{marginLeft: 6}}>We will invoice <span className='bold'>€{admin.flat_fee}</span> from your account per month</p>
          </div>
          <div className='row' style={{alignItems: 'center'}}>
            <Icon icon={valid} size={22} color={colors.brand.primary}/>
            <p  style={{marginLeft: 6}}>Based on <span className='bold'>{toLocale(admin.page_views)}</span> page views per month</p>
          </div>
        </div>
      )}

      <PaymentForm
        admin={admin}
        pendingPayment={pending}
        updatePayment={onConfirm}
      />
    </div>
  )
};

CreditCardSection.propTypes = {
    goNext: PropTypes.func,
    confirmPaymentMethod: PropTypes.func,
    admin: PropTypes.object
};

const mapStateToProps = (state) => ({
  admin: state.account.admin,
});

const mapDispatchToProps = (dispatch) => {
    return {
      confirmPaymentMethod: (data) => dispatch(accountActions.confirmPaymentMethod(data))
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(CreditCardSection);
