


export const normalizeDomain = (domain) => {
  let company_name = domain.creditor_company_name ? domain.creditor_company_name : 'unknown';
  return {
    ...domain,
    slug: domain.name.replace('https://', ''),
    short_slug: domain.name.replace('https://', '').replace('www.', ''),
    creditor_company_name: company_name,
    creditor_company_slug: company_name.toLowerCase(),
  }
};

export const normalizeDomains = (domains) => {
  let newDomains = [];
  domains && domains.map((domain) => {
    return newDomains.push(normalizeDomain(domain));
  });
  return newDomains.sort((a, b) => (a.short_slug < b.short_slug) ? 1 : ((b.short_slug > a.short_slug) ? -1 : 0))
};


export const sortDomains = (domains) => {
  // sort domains by creditor name
  return domains ? domains.sort((a, b) => {
    // chrome requires returning 1 and -1 for sort
    if ((a && a.creditor_company_name && a.creditor_company_slug) > (b && b.creditor_company_name && b.creditor_company_slug)) {
      return 1
    } else {
      return -1
    }
  }) : []
};