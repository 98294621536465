import PropTypes from 'prop-types';
import React from "react";

import { colors } from "../../../../theme/colors";

import { setAlertDialog } from "../../../../components/alert/alert";
import { Button } from "../../../../components/button/button";
import { BlankCard } from "../../../../components/cards/blankCard";
import { Input } from "../../../../components/input/input";
import { classifyJsonAvailableFields } from "../../../../components/jsonRaw/json.classify.availableFields";
import { classifyJsonSelectedFields } from "../../../../components/jsonRaw/json.classify.selectedFields";
import { Loader } from "../../../../components/loader/loader";
import { Resize } from "../../../../components/resize/resize";
import { SideModal } from "../../../../components/sideModal/sideModal";
import { deleteNested } from "../../../../lib/helpers";
import { CardDataLayerCDPFields } from "./card.dataLayer.cdp.fields";
import { CardDataLayerGAFields, validate_dataLayer_fields } from "./card.dataLayer.ga.fields";
import { CardDataLayerGA4Fields } from "./card.dataLayer.ga4.fields";
import { CardDataLayerRawData } from "./card.dataLayer.rawData";
import { CardDataLayerEventTriggers, validate_dataLayer_triggers } from "./card.dataLayer.triggers";

export class DataLayerEventModal extends React.Component {

  static propTypes = {
    dataLayerEvent: PropTypes.any,
    show: PropTypes.bool,
    dev: PropTypes.any,
    readOnly: PropTypes.bool,
    onClose: PropTypes.func,
    onSave: PropTypes.func,
    onDelete: PropTypes.func
  };

  static defaultProps = {
    dataLayerEvent: {},
    show: false,
    dev: {},
    readOnly: false,
    onClose: () => console.log('closing'),
    onSave: (data) => console.log('saving data: ', data),
    onDelete: (data) => console.log('deleting data: ', data),
    domain: PropTypes.string,
  };

  constructor(props, context) {
    super(props, context);

    this.state = {
      currentDataLayerEvent: props.dataLayerEvent,
      isUpdated: false,
      isLoading: false,
      invalidObjects: {},
      domain: '',
    };
  }

  _update_element(key, value) {
    let newData = { ...this.state.currentDataLayerEvent };
    newData[key] = value;
    this.setState({ isUpdated: true, currentDataLayerEvent: newData });
  }

  _update_rawData(data) {
    // raw data exports slightly more keys, so custom function
    // we take the data as is, but we parse the available fields that are possible.
    let newData = {
      ...this.state.currentDataLayerEvent,
      data: data,
      availableFieldPaths: classifyJsonAvailableFields(data),
    };
    this.setState({ isUpdated: true, currentDataLayerEvent: newData });
  }

  _update_fields(field_name, fields, callback = null) {
    // DataFields updates two elements, both the fields as well as the selectedFields
    let newData = {
      ...this.state.currentDataLayerEvent
    };

    if (field_name === 'fields') newData['selectedFieldPaths'] = classifyJsonSelectedFields(fields)

    newData[field_name] = fields;
    this.setState({ isUpdated: true, currentDataLayerEvent: newData }, () => {
      if (callback) callback()
    });
  }

  _update_customDimensions(fields, callback = null) {
    let newData = {
      ...this.state.currentDataLayerEvent,
      selectedFieldPaths: classifyJsonSelectedFields(fields)
    };
    newData['custom_dimensions'] = fields;
    this.setState({ isUpdated: true, currentDataLayerEvent: newData }, () => {
      if (callback) callback()
    });
  }

  _save() {
    // validates element, if all valid, saves
    let invalidObjects = {};
    let currentDataLayerEvent = this.state.currentDataLayerEvent;

    // check basic data
    if (currentDataLayerEvent.name.length === 0) invalidObjects['name'] = true;
    if (![true, false].includes(currentDataLayerEvent.is_active)) invalidObjects['is_active'] = true;

    // check trigger validators
    let invalid_ga_fields = validate_dataLayer_fields(currentDataLayerEvent.fields);
    let invalid_ga4_fields = validate_dataLayer_fields(currentDataLayerEvent.ga4_fields, false);
    let invalid_cdp_fields = validate_dataLayer_fields(currentDataLayerEvent.cdp_fields, false);
    let invalid_triggers = validate_dataLayer_triggers(currentDataLayerEvent.triggers);

    // either return or populate, invalidObjects
    let invalid = (obj) => Object.entries(obj).length === 0
    if (invalid(invalidObjects) && invalid(invalid_ga_fields) && invalid(invalid_ga4_fields) && invalid(invalid_cdp_fields) 
      && invalid(invalid_triggers)) {

      // delete all deleted keys of fields
      currentDataLayerEvent.fields = deleteNested(currentDataLayerEvent.fields);
      currentDataLayerEvent.ga4_fields = deleteNested(currentDataLayerEvent.ga4_fields);
      currentDataLayerEvent.custom_dimensions = deleteNested(currentDataLayerEvent.custom_dimensions);
      currentDataLayerEvent.cdp_fields = deleteNested(currentDataLayerEvent.cdp_fields);

      this.setState({ isLoading: true }, () => {
        this.props.onSave(this.state.currentDataLayerEvent)
          .then((d) => {
            if (d && d.event) {
              this.setState({ isLoading: false, isUpdated: false, currentDataLayerEvent: { ...d.event, title: 'Adjust data layer event' } })
            } else {
              this.setState({ isLoading: false, isUpdated: true })
            }
          })
      });

    } else {
      setAlertDialog('Sorry', 'Please review your input, it appears that certain invalid choices have been made');
    }
    this.setState({ invalidObjects: { ...invalidObjects, invalid_ga_fields, invalid_ga4_fields, invalid_cdp_fields, invalid_triggers } })
  }

  _reset() {
    let currentDataLayerEvent = {};
    if (this.props.dataLayerEvent.id) {
      // reset
      currentDataLayerEvent = this.props.dataLayerEvent;
      this.setState({
        isUpdated: false,
        currentDataLayerEvent: currentDataLayerEvent,
        invalidObjects: {}
      })
    } else {
      // for new navigate back
      this.props.onClose()
    }
  }

  render({ show, readOnly, onClose, domain, dev } = this.props) {
    return (
      <SideModal
        title={this.state.currentDataLayerEvent.title}
        show={show}
        onClose={() => onClose()}
        isUpdated={this.state.isUpdated}
        isLoading={this.state.isLoading}
        onSave={() => this._save()}
        onReset={() => this._reset()}
      >

        {this.state.isLoading && (<Loader style={{ marginTop: '20vh', marginLeft: '48%' }} />)}

        {!this.state.isLoading && (
          <div style={{ width: '100%' }}>
            <BlankCard
              title='Basics'>

              <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'flex-start' }}>
                <Input
                  style={{ maxWidth: 300 }}
                  initial={this.state.currentDataLayerEvent.name}
                  label='Name'
                  type='text'
                  isInvalid={this.state.invalidObjects['name']}
                  onChange={(value) => this._update_element('name', value)}
                  readOnly={false}
                />
                <div style={{ marginLeft: 12 }}>
                  <Input
                    style={{ maxWidth: 300 }}
                    initial={this.state.currentDataLayerEvent.is_active}
                    label='State'
                    type='text'
                    restrict={true}
                    choices={[{ key: true, value: 'active' }, { key: false, value: 'inactive' }]}
                    isInvalid={this.state.invalidObjects['is_active']}
                    onChange={(value) => this._update_element('is_active', value)}
                    readOnly={false}
                  />
                </div>
              </div>

            </BlankCard>

            <Resize
              minLeft={500}
              minRight={200}
              startLeft={600}
              leftChild={
                <div>
                  <h5 style={{ marginLeft: 6 }}>INCOMING DATA</h5>
                  <CardDataLayerRawData
                    data={this.state.currentDataLayerEvent.data}
                    selectedFieldPaths={this.state.currentDataLayerEvent.selectedFieldPaths}
                    readOnly={false}
                    onUpdate={(data) => this._update_rawData(data)}
                  />
                  <CardDataLayerEventTriggers
                    triggers={this.state.currentDataLayerEvent.triggers}
                    availableFieldPaths={this.state.currentDataLayerEvent.availableFieldPaths}
                    readOnly={false}
                    invalidObjects={this.state.invalidObjects}
                    onUpdate={(value) => this._update_element('triggers', value)}
                  />
                </div>
              }
              rightChild={
                <div>
                  <h5 style={{ marginLeft: 6 }}>FORWARDED DATA</h5>

                  {dev.has_ga4 && <CardDataLayerGA4Fields
                    collapsed={!!dev.cdp_enabled || !!dev.has_ga4}
                    domain={domain}
                    template={this.state.currentDataLayerEvent.ga4_template}
                    fields={this.state.currentDataLayerEvent.ga4_fields}
                    customDimensions={this.state.currentDataLayerEvent.custom_dimensions ?? []}
                    data={this.state.currentDataLayerEvent.data}
                    availableFieldPaths={this.state.currentDataLayerEvent.availableFieldPaths}
                    readOnly={false}
                    invalidObjects={this.state.invalidObjects.invalid_ga4_fields}
                    setTemplate={(template) => this._update_element('ga4_template', template)}
                    onUpdate={(fields, callback) => this._update_fields('ga4_fields', fields, callback)}
                    onUpdateCustomDimensions={(customDimensions, callback) => this._update_customDimensions(customDimensions, callback)}

                  />}

                  {dev.cdp_enabled ?
                    <CardDataLayerCDPFields
                      collapsed={!!dev.cdp_enabled || !!dev.has_ga4}
                      domain={domain}
                      template={this.state.currentDataLayerEvent.cdp_template}
                      fields={this.state.currentDataLayerEvent.cdp_fields}
                      data={this.state.currentDataLayerEvent.data}
                      availableFieldPaths={this.state.currentDataLayerEvent.availableFieldPaths}
                      readOnly={false}
                      invalidObjects={this.state.invalidObjects.invalid_cdp_fields}
                      setTemplate={(template) => this._update_element('cdp_template', template)}
                      onUpdate={(fields, callback) => this._update_fields('cdp_fields', fields, callback)}
                    /> : <></>}

                </div>
              }
            />
          </div>)}

        {!this.state.isLoading && this.state.currentDataLayerEvent.id && (
          <Button
            title='Delete datalayer event'
            backgroundColor={colors.brand.gray_light}
            color={colors.brand.darkgray_light}
            cancel={true}
            style={{ width: 150, marginTop: 70 }}
            onClick={() => {
              setAlertDialog('Delete datalayer event', 'Are you sure you want to delete this datalayer event?', '',
                [
                  {
                    title: 'yes',
                    backgroundColor: colors.brand.gray,
                    onPress: () => this.props.onDelete(this.state.currentDataLayerEvent)
                  },
                  { title: 'cancel', backgroundColor: colors.brand.primary },
                ])
            }}
          />
        )}
      </SideModal>
    );
  }
}

