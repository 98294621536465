import React, {useCallback, useEffect, useMemo, useRef, useState} from 'react';
import {PropTypes} from 'prop-types';
import styles from './popup.module.scss';
import {PopupPosition} from './constants';
import {Icon, x} from '../icon/icon';
import {colors} from '../../theme/colors';
import {useArrowDataMemo, useContainerDataMemo} from './useDataMemo';

// depends on $arrow_size from scss
const minSize = 26;

function getMinBBoxData(element) {
  let dx = 0;
  let dy = 0;
  if (!element) return {bBox: null, dx, dy}

  const bBox = element.getBoundingClientRect();

  if (bBox.width < minSize) {
    dx = minSize - bBox.width;
    bBox.x -= dx / 2;
    bBox.width += dx;
  }
  if (bBox.height < minSize) {
    dy = minSize - bBox.height;
    bBox.y -= dy / 2;
    bBox.height += dy;
  }

  return {
    bBox,
    dx,
    dy
  }
}

export const Popup = ({position, visible, content, stayOpen, initialOpen, onClose, children, minWidth}) => {

  // quick escape in case no content do not create a popup
  if (!content) return children;

  const [bBoxData, setBBoxData] = useState({});
  const [isOpened, setIsOpened] = useState(initialOpen);

  const popupContainer = useRef();

  const arrowData = useArrowDataMemo(position, bBoxData);
  const containerData = useContainerDataMemo(position, bBoxData);

  const arrowContent = useMemo(
    () => (
      <div
        className={arrowData.className}
        style={{
          top: arrowData.top && arrowData.top,
          bottom: arrowData.bottom && arrowData.bottom,
          left: arrowData.left && arrowData.left,
          right: arrowData.right && arrowData.right
        }}
      />
    ),
    [arrowData]
  );

  useEffect(
    () => {
      const newBBoxData = getMinBBoxData(popupContainer.current && popupContainer.current.previousSibling);
      setBBoxData(newBBoxData);
    },
    [visible]
  );

  const openPopup = useCallback(
    () => setIsOpened(true),
    []
  );

  const closePopup = useCallback(
    () => {
      setIsOpened(false);
      if (onClose) onClose()
    },
    [onClose]
  );

  // show popup only if visible, otherwise return the bare content
  if (!visible) return children;

  return (
    <div className={styles.wrapper}>
      <div
        onMouseOver={openPopup}
        onMouseLeave={stayOpen ? () => {
        } : closePopup}
      >
        {children}
      </div>
      <div
        onMouseOver={stayOpen ? () => {
        } : openPopup}
        onMouseLeave={stayOpen ? () => {
        } : closePopup}
        ref={popupContainer}
        className={containerData.className}
        style={{
          minWidth: minWidth,
          transform: `${containerData.transform} scale(${isOpened ? 1 : 0})`,
          transformOrigin: containerData.transformOrigin,
          top: containerData.top && containerData.top,
          bottom: containerData.bottom && containerData.bottom,
          left: containerData.left && containerData.left,
          right: containerData.right && containerData.right
        }}
      >
        <div className={styles.popup_inner}>
          {stayOpen &&
          <div onClick={closePopup} className={styles.popup_close}>
            <Icon className='clickable' color={colors.brand.gray} size={18} icon={x}/>
          </div>
          }
          {content}
        </div>
        {arrowContent}
      </div>
    </div>
  )
};

Popup.propTypes = {
  position: PropTypes.any,
  children: PropTypes.node,
  content: PropTypes.node,
  stayOpen: PropTypes.bool,
  initialOpen: PropTypes.bool,
  visible: PropTypes.bool,
  onClose: PropTypes.func,
  minWidth: PropTypes.number,
};

Popup.defaultProps = {
  position: PopupPosition.RIGHT_CENTER,
  visible: true,
  children: <div/>,
  initialOpen: false,
  minWidth: 0,
};
