export const persistState = {
  isAuthenticated: false,
  // access_token: null,

  get_started: -1,
  // is_staff: false,
  // is_admin: false,
  is_agent: false,
  baseURL: '',

  has_domains: true,
  all_domains: [],
  domain: {
    uuid: null,
    name: '',
    slug: '',
    role: 'blocked',

    page_views: 0,

    state: 'implementing', // implementing, trial, live

    onboarding: {
      pending_technical: 'done',
      pending_code: 'done',
      pending_cookies: 'done',
      pending_events: 'done',
      trial_days_left: 0,
      dns_provider: '',
    }
  },

  all_agencies: [],
  all_agencies_updated: null,

  demo: {
    navigate_domains: true
  }

};
