// // NOTE: local settings are ignored, as such copy/paste your own

export default {
  mode: 'local',
  debug_redux: true,
  domain: 'localhost',
  api_url: 'http://localhost:8000',
  web_url: 'http://localhost:8001',
  td_script: 'http://localhost:8010/main.js',
  td_serverside: 'http://localhost:8015/ep/transaction',
  client_secret: 'test',
  stripe_key: 'pk_test_3aPu0t1iJiLT25G5UznmaxWZ00foOdEf1R',
  sentry_key: null,
}
