import React from 'react';
import PropTypes from 'prop-types';
import ReactMarkdown from 'react-markdown';
import {Loader} from "../loader/loader";
import {getWiki} from "./wiki";
import './wikiRender.scss';

export class WikiRenderUnconnected extends React.Component {
  // Unconnected for storybook, works without Redux

  static propTypes = {

    // only aspect required
    path: PropTypes.string,
    data: PropTypes.any,
    local: PropTypes.bool,

    // from Redux
    wiki: PropTypes.any,
    addWikiPage: PropTypes.func,
  };

  static defaultProps = {
    data: null,
    path: 'privacy/privacy-statement',
    wiki: {},
    local: false,
    addWikiPage: (data) => console.log('Saving: ', data.path),
  };

  constructor(props, context) {
    super(props, context);

    let content = props.path ? props.wiki[props.path] : null;

    this.state = {
      isPending: !content,
      content: content
    };
  }

  componentWillReceiveProps(nextProps, nextContext) {
    if (nextProps.path && nextProps.path !== this.props.path) {

      let content = nextProps.wiki[nextProps.path];
      if (content) {
        this.setState({content: content})
      } else {
        this._getPage(nextProps.path, nextProps.data, nextProps.local)
      }

    }
  }

  componentDidMount() {

    // Check if content exists in wiki
    if (this.state.isPending) {
      this._getPage(this.props.path, this.props.data, this.props.local)
    }
  }

  _getPage(path, data, local) {
    // get it again
    getWiki(path, data, local)
      .then((content) => {

        // Save retrieved content to state
        this.setState({isPending: false, content: content});

        // save to redux
        this.props.addWikiPage({path: path, content: content})
      })
  }

  render() {
    return (
      <div>
        {this.state.isPending && <Loader style={{marginTop: '20vh', marginLeft: '42%'}}/>}
        {!this.state.isPending && <div className='wiki'><ReactMarkdown source={this.state.content}/></div>}
      </div>
    );
  }
}
