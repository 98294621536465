import React from "react";
import {InputCard} from "../../../components/cards/inputCard";
import {emailValidatorList, regexValidator} from "../../../lib/validators";
import PropTypes from 'prop-types';
import {countries} from "../../../lib/countries";

export class BillingSection extends React.Component {

  static propTypes = {
    creditor: PropTypes.any,
    withBankAccount: PropTypes.bool,
    uuid: PropTypes.string,
    updateCreditor: PropTypes.func,
  };

  static defaultProps = {
    creditor: {},
    withBankAccount: false,
    uuid: '',
    updateCreditor: () => {}
  };

  render({creditor, withBankAccount, uuid, updateCreditor} = this.props) {
    let company_data = [
      {
        key: 'company_name',
        label: 'Company name',
        type: 'text',
        validator: (value) => value && value.length > 0,
        optional: false,
        help: null
      },
      {
        key: 'chamber_of_commerce',
        label: 'Chamber of Commerce number',
        type: 'text',
        validator: (value) => value && value.length > 0, // note: also other countries
        exampleFormat: '12345678',
        optional: false,
        help: null
      },
      {
        key: 'tax_number',
        label: 'VAT number',
        type: 'text',
        validator: (value) => regexValidator(value, /^[A-Z]{2}:?/),
        exampleFormat: "NL123456789B01",
        optional: true,
        help: creditor.country === 'NL' ? '' : 'Failure to supply a Value Added Tax (VAT) number requires us to add 21% VAT to your total bill.'
      },
      {
        key: 'prefix_reference',
        label: 'Invoice reference number',
        type: 'text',
        validator: (value) => value && value.length > 0,
        optional: true,
        help: null
      },
    ];

    if (withBankAccount) {
      company_data.push({
        key: 'iban',
        label: 'IBAN bank account',
        type: 'text',
        validator: (value) => regexValidator(value, /^[A-Z]{2}:?/),
        exampleFormat: "NL123456789B01",
        optional: false,
        help: 'Bank account on which we can transfer the monthly commission.'
      });
      company_data.push({
        key: 'bic',
        label: 'BIC bank account',
        type: 'text',
        validator: (value) => regexValidator(value, /^[A-Za-z]{6}[0-9A-Za-z]{2}?/),
        exampleFormat: "BUNQNL12",
        optional: false,
        help: 'Add the BIC code corresponding to your IBAN bankaccount.'
      })
    }
    return (
      <div>
        <InputCard
          title='Company details'
          subtitle='Basic information for billing purposes'
          onSave={(data) => updateCreditor({uuid: uuid, creditor: data})}
          initialData={creditor}
          objects={company_data}
        />

        <InputCard
          title='Billing address'
          subtitle='What address can we put on the invoice?'
          onSave={(data) => updateCreditor({uuid: uuid, creditor: data})}
          initialData={creditor}
          objects={[
            {
              key: 'email',
              label: 'Email',
              type: 'text',
              validator: (value) => emailValidatorList(value),
              exampleFormat: "einstein@company.com",
              optional: false,
              help: 'Should be one or more valid email addresses, separated by a comma.'
            },
            {
              key: 'address1',
              label: 'Address line 1',
              type: 'text',
              validator: (value) => value && value.length > 0,
              optional: false,
              help: null
            },
            {
              key: 'address2',
              label: 'Address line 2',
              type: 'text',
              validator: (value) => value && value.length > 0,
              optional: true,
              help: null
            },
            {
              key: 'zipcode',
              label: 'Zip code',
              type: 'text',
              validator: (value) => value && value.length > 0, // also for other countries suited
              exampleFormat: "1234AB or 1234",
              optional: false,
              help: null
            },
            {
              key: 'city',
              label: 'City',
              type: 'text',
              validator: (value) => value && value.length > 0,
              optional: false,
              help: null
            },
            {
              key: 'country',
              label: 'Country',
              type: 'text',
              validator: (value) => regexValidator(value, /^[A-Z]{2}/),
              exampleFormat: "Netherlands",
              optional: false,
              restrict: false,
              choices: countries,
              help: null
            }
          ]}
        />

      </div>
    );
  }
}
