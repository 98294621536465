import React, {useState, useCallback} from 'react'
import {Button} from '../button/button';
import {PropTypes} from 'prop-types';
import {useChildrenContentMemo} from './useChildrenContentMemo';

const Form = ({onSave, validators, initialValues, buttonText, children, isLoading, footerContent}) => {
  const [values, setValues] = useState(initialValues);
  const [wasValidated, setWasValidated] = useState(false);
  const [validation, setValidation] = useState({});

  // when any field value is changed, this callback should be called
  const onChange = useCallback(
    (name, value) => {
      setValues(state => {
        return {
          ...state,
          [name]: value
        }
      });

      if (wasValidated) {
        const validator = validators[name];
        let validatorOutput;
        if (validator && validator.validatorFunction(value) === false) {
          validatorOutput = validator.errorMessage;
        }

        setValidation(state => {
          return {
            ...state,
            [name]: validatorOutput
          }
        })
      }
    },
    [validators, wasValidated]
  );

  // only for testing purposes
  // useEffect(
  //     () => console.log('Form values changed', values),
  //     [values]
  // )

  // it is called on Button save click
  const onSaveCallback = useCallback(
    () => {
      const validatorOutput = {};

      for (const name of Object.keys(validators)) {
        const validator = validators[name];
        if (validator.validatorFunction(values[name]) === false) {
          validatorOutput[name] = validator.errorMessage;
        }
      }

      setWasValidated(true);
      setValidation(validatorOutput);

      if (Object.keys(validatorOutput).length === 0) {
        onSave(values)
      }

    },
    [values, onSave, validators]
  );

  // create childrens with theirs and some of parent props
  const childrenWithProps = useChildrenContentMemo({children, values, wasValidated, validation, onChange, onSaveCallback});

  return (
    <div>
      {childrenWithProps}
      {footerContent}
      <Button
        isLoading={isLoading}
        title={buttonText}
        onClick={onSaveCallback}
        style={{marginTop: '25px'}}
      />
    </div>
  )
};

Form.propTypes = {
  pending: PropTypes.bool,
  onSave: PropTypes.func,
  validators: PropTypes.object,
  initialValues: PropTypes.object,
  buttonText: PropTypes.string,
  children: PropTypes.node,
  footerContent: PropTypes.node,
};

Form.defaultProps = {
  isLoading: false,
  validators: {},
  initialValues: {},
  buttonText: 'Save',
  footerContent: null,
};

export default Form;
