export const getCookie = (name) => {
  // gets a cookie name from stored cookie
  let match = document.cookie.match(new RegExp('(^| )' + name + '=([^;]+)'));
  if (match) if (match[2] !== 'null') return match[2]
};

export const setCookie = (name, value) => {
  // function to set cookie value
  document.cookie = `${name}=${value};`;
};

export var optionsIfSupported = false;

try {
  window.addEventListener("test", null,
    Object.defineProperty({}, "passive", {
      get: function () {
        optionsIfSupported = {capture: false, once: false, passive: false};
        return false
      }
    }));
} catch (err) {
}


export const slugify = (text) => {
  return text.toString().toLowerCase()
    .replace(/ - +/g, '_')          // Replace " - " with underscore
    .replace(/ - +/g, ' ')          // Replace - within words with space
    .replace(/\s+/g, '_')           // Replace spaces with -
    .replace(/[^\w-]+/g, '')        // Remove all non-word chars
    .trim()                                                // finally trim
};


export const toLocale = (number, _default) => {
  // convenient function as if number is undefined it will give an error
  if (number) return number.toLocaleString();
  // otherwise return default
  return _default
};


export const updateIfNeeded = (dateLastRun, onUpdate, onSuccess = null, timeDelta = 60000) => {
  // Convenience function used if you want to update only if last_run is bit time ago. Run onSuccess if you return data
  // timeDelta in milliseconds
  if (!dateLastRun || ((new Date()) - dateLastRun > timeDelta)) {
    if (onUpdate) {
      onUpdate().then((data) => {
        // only run if success and data
        if (onSuccess && data) {
          onSuccess(data);
        }
      })
    }
  }
};


export const deleteNested = (array, keyCheck = 'action', valueCheck = 'delete', childElement = 'children') => {
  // nested filter, runs through all elements in array to check key/value for deletion
  // [{id: 1, action: delete, children: []}, {id: 2, children: [{id: 3, action: delete, children: []}]}]
  // becomes: [{id: 2, children: []}]

  let newArray = [];

  array && array.forEach((row) => {

    // iterate over all records to see if you can push the value into the row
    if (!row[keyCheck] || row[keyCheck] !== valueCheck) {

      //  check if you need to adjust the children array
      if (row[childElement] && row[childElement].length > 0) {
        row[childElement] = deleteNested(row[childElement], keyCheck, valueCheck, childElement);
      }

      // add the rows
      newArray.push(row)
    }
  });

  return newArray
};

// // Testing
// let inPut = [{id: 1, action: 'delete'}, {id: 2, children: [{id: 3, action: 'delete', children: []}, {id: 4}]}];


export const setDeepVal = (data, path, idx, newObject) => {
  // Sets a value deep in an object, nested with objects and arrays
  // Note: first copy main object, e.g. via {...data} for dic or [...data] for arrays

  let i = 0;

  if (path) {

    // we walk through the path to select it
    path = path.split('.');
    for (i = 0; i < path.length - 1; i++) {
      data = data[path[i]] || {};
    }
    // and we also select the last iterator
    data = data[path[i]]
  }

  // if not idx, then stop at the path end, e.g. data is now referring to the nested data object, so assign newObject
  if (idx === null) {
    data = newObject;

  } else {

    // if we have an index, the last element is an index,
    // can either be -1 for adding a new on the end, or the place
    if (idx === -1) {
      data[data.length] = newObject
    } else {
      data[idx] = newObject
    }
  }
};


// TEST
// var obj = {hello: 'world', foo: {bar: 1, baz: 42, bar2: [1, 2, {test: 1}]}};
// setDeepVal(obj, 'foo.bar', null, {a: 1, b: 2});
// setDeepVal(obj, 'foo.bar2.2.test', null, 42);
// setDeepVal(obj, 'foo.bar2', -1, {'new': true});
// console.log('Testing deep change: ', obj);


export const convertFromJson = (data) => {
  try {
    return data ? JSON.parse(data) : {}
  } catch (error) {
    console.log('... json error: ', error, data);
    return {tmp: data};
  }
};


export const chooseRandom = (array, number) => {
  // extract random number of array without doubling

  let randomIndex = null;

  // make copy of array
  let getArray = [...array];
  let outputArray = [];

  // While there remains elements
  while (getArray.length > 0 && outputArray.length < number) {

    // Pick a remaining element...
    randomIndex = Math.floor(Math.random() * getArray.length);

    // And remove this element from array, splice removes it from the original array
    outputArray.push(getArray.splice(randomIndex, 1)[0]);
  }

  return outputArray;
};

// test:
// let base = [1, 2, 3, 4, 5, 6];
// console.log(' extracting 3: ', chooseRandom(base, 3));
// console.log(' extracting 9: ', chooseRandom(base, 9));

