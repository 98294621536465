import React, {useCallback, useState} from 'react';
import styles from './sectionDialog.module.scss';
import contactIcon from '../../../static/images/contact.png';
import {Button} from "../../../components/button/button";
import {colors} from "../../../theme/colors";

export const SectionDialog = ({children, goBack}) => {

  const goBackCallback = useCallback(
    () => {
      setPending(true);
      goBack()
    },
    [goBack]
    );
  const [pending, setPending] = useState(false);

  return (
    <div className={styles.sectionDialog_cover}>
      <div className={styles.sectionDialog}>
        <img src={contactIcon} alt='' width='100%'/>
        {children}

        <div className='row' style={{marginTop: '25px'}}>
          <Button
            isLoading={pending}
            title='Go back'
            onClick={goBackCallback}
            color={colors.brand.darkgray}
            cancel={true}
            backgroundColor={colors.brand.gray_light}
          />
          <Button
            style={{marginLeft: 6}}
            title='OK'
            onClick={() => window.open('https://tracedock.com', '_self')}
          />
        </div>
      </div>
    </div>
  )
};
