import React from "react";
import { connect } from "react-redux";

import { accountActions, persistActions, rulesActions } from "../../redux/actions";
import { Loader } from "../../components/loader/loader";
import { toLocale, updateIfNeeded } from "../../lib/helpers";

import { DomainSection } from "./sections/domainSection";
import { BillingSection } from "./sections/billingSection";
import { SideModal } from "../../components/sideModal/sideModal";
import { BlankCard } from "../../components/cards/blankCard";

class AdminPage extends React.Component {

  constructor(props, context) {
    super(props, context);

    this.state = {
      currentCreditor: props.account.admin,

      showPaymentModal: false,
      pendingPayment: false,
    };

    updateIfNeeded(this.props.account.profile_updated, this.props.getProfile);

    // for migration in case you don't have admin
    if (!this.props.account.admin) {
      // this.props.logout()
    }
  }

  async _gotoImplementation(data) {
    await this.props.SetDomain(data);
    await this.props.getDev({uuid: data.domain.id});
    this.props.onClose('/installation');
  }

  _gotoLive(data) {
    this.props.SetDomain(data).then(
      () => this.props.onClose('/live')
    )
  }


  render({history, persist, publishBuild, account, show, onClose, confirmPaymentMethod} = this.props) {

    return (

      <SideModal title={account.admin.company_name ? `Organisation: ${account.admin.company_name}` : ''}
                 hasSave={false}
                 show={show} onClose={() => onClose()}>

        {account.isPending && (<Loader style={{marginTop: '20vh', marginLeft: '48%'}}/>)}

        {!account.isPending && (
          <DomainSection
            user_email={this.props.account.profile.email}
            admin={account.admin}
            inviteAdmin={(data) => this.props.inviteAdmin(data).then(() => this.props.getProfile())}
            gotoImplementation={(data) => this._gotoImplementation(data)}
            gotoLive={(data) => this._gotoLive(data)}
            onAddDomain={(domains, callback) => {
              this.props.createDomain({
                domains: domains,
                company_uuid: account.admin.uuid,
                as_agent: false,
              }).then((domains) => {
                this.props.getProfile();
                if (callback) {
                  callback()
                }
              });
            }}

          />
        )}

        {!account.isPending && (
          <BlankCard title='Current price plan' subtitle='For monthly license fee'>

            <p>Current monthly fee <span className='bold'>€{account.admin.flat_fee - account.admin.discount}</span>.</p>
            {account.admin.page_views > 0 && <p>Monthly page views: <span className='bold'>{toLocale( account.admin.page_views)}</span>.</p>}
            {account.admin.state !== 'live' && account.admin.trial_days > 0 && <p>The first billing period will start after the trial period has ended.</p>}
            {account.admin.state !== 'live' && account.admin.trial_days === 0 && <p>The first billing period will start after the implementation is successful.</p>}
            {account.admin.state === 'live' && account.admin.next_invoice_date && <p>Your next payment is due on <span className='bold'>{account.admin.next_invoice_date}</span>.</p>}

          </BlankCard>
        )}

        {!account.isPending && (

          <BillingSection
            creditor={account.admin}
            uuid={account.admin.uuid}
            updateCreditor={this.props.updateCreditor}
          />
        )}

        <div style={{height: 100}}/>


      </SideModal>

    );
  }
}


const mapStateToProps = (state) => ({
  persist: state.persist,
  account: state.account,
});

const mapDispatchToProps = (dispatch) => {
  return {
    getProfile: () => dispatch(accountActions.getProfile()),
    // logout: () => dispatch(accountActions.logout()),
    SetDomain: (d) => dispatch(persistActions.SetDomain(d)),
    // checkStatus: (data) => dispatch(accountActions.checkStatus(data)),
    createDomain: (data) => dispatch(accountActions.createDomain(data)),
    updateCreditor: (data) => dispatch(accountActions.updateCreditor(data)),
    inviteAdmin: (data) => dispatch(accountActions.inviteAdmin(data)),
    confirmPaymentMethod: (d) => dispatch(accountActions.confirmPaymentMethod(d)),
    getDev: (d) => dispatch(rulesActions.getDev(d)),
  }
};

export default AdminPage = connect(mapStateToProps, mapDispatchToProps)(AdminPage);