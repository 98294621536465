import React from 'react';
import PropTypes from 'prop-types';

import './modalLayout.scss';
import {Icon, x} from "../icon/icon";
import {colors} from "../../theme/colors";

export class ModalLayout extends React.Component {

  static propTypes = {
    onClose: PropTypes.func, // number, string, any, func
    title: PropTypes.string,
    subTitle: PropTypes.any,
    withLogo: PropTypes.bool,
    width: PropTypes.string
  };

  static defaultProps = {
    onClose: null,
    title: '',
    subTitle: '',
    withLogo: false,
    width: '350px'
  };

  constructor(props, context) {
    super(props, context);

    this.state = {};
  }

  render({children, title, subTitle, withLogo, onClose, width} = this.props) {
    return (
      <div className='modalLayout'>

        <div className='modalContent slideInFromTop'>

          <div className='modal' style={{minWidth: width}}>

            {onClose && (<Icon size={14} icon={x} color={colors.brand.darkgray_light} className='close clickable' onClick={onClose}/>)}

            {withLogo && (<img style={{marginBottom: 32}} width='100' src={require('../../static/images/logo_grey.png')} alt='tracedock'/>)}

            {title && (
              <div className='title spacer'>
                <h2>{title}.</h2>
                {subTitle && (<h6>{subTitle}</h6>)}
              </div>
            )}

            {children}

          </div>
        </div>

      </div>
    );
  }
}