import {store} from "../index.js"

// export const BASE_DOMAINS_ENDPOINT = `v1/accounts/${getLogicalAccount()}/tenants/${getTechnicalLink()}/domains`;




export const getSsoAccount = () => {
    if(store){
        var cmAcc = store.getState().cmaccount;
        return cmAcc.cmSSOAccountGuid;
    }

  }
  
export const getLogicalAccount = () => {
    return "ccf990f7-1117-4cf0-80f7-2de975fc322e"
    if(store){
        var cmAcc = store.getState().cmaccount;
        return cmAcc.cmLogicalAccountGuid;
    }

  }
  
export const getOrganisationId = () => {
    if(store){
        var cmAcc = store.getState().cmaccount;
        return cmAcc.organisationId;
    }

  }
  
export const getTechnicalLink = () => {
    var cmAcc = store.getState().cmaccount;
    return cmAcc.traceDockKey;
    // return "ccf990f7-1117-4cf0-80f7-2de975fc322e";
  }

  export const getCurrentTenantId = () => {
    var tenantId = store.getState().cmaccount.currentTenantId;
    return tenantId;
  }