import {resetNonPersistedStates} from "../reducer";
import {api} from "../../lib/api";
import { getTechnicalLink, getOrganisationId, getLogicalAccount, getSsoAccount, getCurrentTenantId } from "../../redux/apiConstants"

export const SetDomain = ({domain}) => {
  return async (dispatch) => {
    //force a new getDev, we also need to update the "dev" state which is domain info+developer settings for domain
    dispatch({
      type: 'SET_DOMAIN',
      domain: domain
    });
  }
};


export const resetPersistState = () => {
  return async (dispatch) => {
    dispatch({
      type: 'RESET_PERSIST_STATE'
    })
  }
};


// export const getAllAgencies = () => {
//   return async (dispatch) => {

//     let res = await api.get('agency/get_agencies.json');

//     if (res) {
//       dispatch({
//         type: 'SET_AGENCIES',
//         all_agencies: res,
//       });
//     }
//   }
// };

// export const setAgency = ({agency}) => {
//   return async (dispatch) => {
//     dispatch({
//       type: 'SET_AGENCY',
//       agency: agency,
//     });
//   }
// };


export const setDemo = (items) => {
  return async (dispatch) => {
    dispatch({
      type: 'SET_DEMO',
      items: items
    })
  }
};