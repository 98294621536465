export const identify = (id) => {
  // for now turned off
  // try {
  //   window.td.identify(id);
  // } catch (error) {
  //   console.error('identify tracedock error: ', error)
  // }
};

export const trackPageView = () => {

};

export const trackEvent = (category, action, label, value, ga = true, td = true) => {
  // optionally track event via TraceDock, directly to GA, or both

  // if (td) {
  //   try {
  //     window.td.trackEvent(category, action, label, value, {});
  //   } catch (error) {
  //     console.error('trackEvent TraceDock error: ', error)
  //   }
  // }

  if (ga) {
    try {
      window.ga('send', {
        hitType: 'event',
        eventCategory: category,
        eventAction: action,
        eventLabel: label,
        eventValue: value
      });

    } catch (error) {
      console.error('trackEvent GA error: ', error)
    }
  }

};
