import {rulesState} from './rules.state';
import {normalizeRuleEvents} from "./rules.normalizers";

export function rulesReducer(state = rulesState, action) {
  switch (action.type) {

    case 'RULES_PENDING':
      return {
        ...state,
        isPending: true,
      };

    case 'RULES_DONE':
      return {
        ...state,
        isPending: false,
      };

    case 'SET_DATE_LAST_BUILD':
      return {
        ...state,
        isPending: false,
        date_last_build: action.date_last_build,
        requires_publish: action.requires_publish,
      };

    case 'DEV_DONE':
      return {
        ...state,
        isPending: false,
        dev_updated: new Date(),
        dev: action.dev ? action.dev : state.dev,
        date_last_build: action.dev ? action.dev.date_last_build : state.date_last_build,
        requires_publish: action.dev ? action.dev.requires_publish : state.requires_publish,
      };

    case 'PENDINGCHECKSTATUS':
      return {
        ...state,
        pendingCheckStatus: action.pendingCheckStatus,
      };

    case 'RESET_RULES_STATE':
      return {
        ...rulesState
      };

    /*
    ##########################################################################################
    # EVENTS
    ##########################################################################################
   */

    case 'SET_EVENTS':
      // check all events if date updated is after date last published, if so publish is required
      return {
        ...state,
        date_last_build: action.date_last_build,
        requires_publish: action.requires_publish,
        events_updated: new Date(),
        events: {
          pageload: normalizeRuleEvents(action.events.pageload, 'pageload'),
          click: normalizeRuleEvents(action.events.click, 'click'),
          datalayer: normalizeRuleEvents(action.events.datalayer, 'datalayer'),
        },
        isPending: false
      };

    case 'SET_EVENTS_FOR_COPY':
      let copy_events = [];
      action.copy_events.pageload.map((event) => copy_events.push({key: event.id, value: event.name, data: {...event, type: 'pageload'}}));
      action.copy_events.click.map((event) => copy_events.push({key: event.id, value: event.name, data: {...event, type: 'click'}}));
      action.copy_events.datalayer.map((event) => copy_events.push({key: event.id, value: event.name, data: {...event, type: 'datalayer'}}));
      return {
        ...state,
        copy_events: copy_events,
      };

    case 'SET_EVENT_INDEX':
      return {
        ...state,
        event_index: action.event_index,
        event_type: action.event_type,
      };

    case 'EVENT_UPDATED':

      // copy state click_events and update with last data
      let new_events = {...state.events};
      new_events[action.event.type][action.event_index] = action.event;

      return {
        ...state,
        events: new_events,
        requires_publish: true
      };

    case 'EVENT_ADDED':
      let added_events = {...state.events};
      added_events[action.event.type] = [action.event].concat(added_events[action.event.type]);
      return {
        ...state,
        events: added_events,
        requires_publish: true,
        copy_events: []
      };


    case 'EVENT_REMOVE':

      // filter out click events to remove the one that is removed
      let filtered_events = {...state.events};
      filtered_events[action.event.type] = filtered_events[action.event.type].filter((_e) => _e.uuid !== action.event.id);
      return {
        ...state,
        event_index: -1,
        events: filtered_events,
        requires_publish: true,
      };

    /*
    ##########################################################################################
    # EXTERNAL EVENTS
    ##########################################################################################
   */

    case 'SET_IDENTIFY_EVENTS':
      return {
        ...state,
        identify_events: action.identify_events,
        requires_publish: action.requires_publish || state.requires_publish,
      };

    case 'SET_EXTERNAL_EVENTS':
      return {
        ...state,
        external_events_updated: new Date(),
        external_events: action.external_events,
        isPending: false
      };

    case 'SET_EXTERNAL_EVENT_INDEX':
      return {
        ...state,
        external_event_index: action.external_event_index
      };

    case 'EXTERNAL_EVENT_UPDATED':

      // copy state external_events and update with last data
      let new_external_events = state.external_events.concat();
      new_external_events[action.external_event_index] = action.external_event;

      return {
        ...state,
        external_events: new_external_events
      };

    case 'EXTERNAL_EVENT_ADDED':

      return {
        ...state,
        external_events: [action.external_event].concat(state.external_events)
      };


    case 'EXTERNAL_EVENT_REMOVE':

      // filter out click events to remove the one that is removed
      let filtered_external_events = state.external_events.filter((_dl) => _dl.uuid !== action.external_event.id);
      return {
        ...state,
        external_event_index: -1,
        external_events: filtered_external_events
      };



    default:
      return state;
  }
}
