import React from "react";
import {connect} from "react-redux";

import {colors} from "../../../theme/colors";

import {PageLayout} from "../../../components/layout/pageLayout";
import {rulesActions} from "../../../redux/actions";
import {Loader} from "../../../components/loader/loader";
import {BlankCard} from "../../../components/cards/blankCard";
import {Table} from "../../../components/table/table";
import {Icon, info, live_active, pencil} from "../../../components/icon/icon";
import {formatDateTime} from "../../../lib/normalize_values";
import {ExternalEventModal} from "./sectionExternalEvent.modal";
import {updateIfNeeded} from "../../../lib/helpers";
import {baseFieldsData} from "../../../components/dataFieldObject/dataFieldObject";
import {classifyJsonAvailableFields} from "../../../components/jsonRaw/json.classify.availableFields";
import {IdentifyEventModal} from "./sectionIdentify.modal";
import {Modal} from "../../../components/modal/modal";
import {Button} from "../../../components/button/button";
import {ExternalEventsAutocomplete} from "../../../lib/autocomplete_ecommerce";
import {classifyJsonSelectedFields} from "../../../components/jsonRaw/json.classify.selectedFields";
import {GoogleAnalyticsSettingsModule} from "../settings/GoogleAnalyticsSettings.module";
import {FacebookConversionSettingsModule} from "../settings/FacebookConversionSettings.module";
import {CDPSettingsModule} from "../settings/CDPSettings";


export const baseEventFieldsData = {

  title: 'Add new serverside event',

  name: '',
  endpoint: '',

  fields: [{...baseFieldsData}],
  selectedFieldPaths: [],
  template: '',
  unique_field: null,

  fb_template: '',
  fb_fields: [],

  cdp_template: '',
  cdp_fields: [],

  data: null,
  availableFieldPaths: [],

  is_active: true
};

class ExternalEvents extends React.Component {

  constructor(props, context) {
    super(props, context);

    // Prefill data if inbound data is passed along, e.g. via live view
    if (props.history.location.state && props.history.location.state.data) {
      this.state = {

        identify_event_idx: -2,

        currentExternalEvent: {
          ...baseEventFieldsData,

          // adjust raw data
          data: props.history.location.state.data,
          availableFieldPaths: classifyJsonAvailableFields(props.history.location.state.data),

          // prefill name and endpoint
          name: props.history.location.state.data.endpoint,
          endpoint: props.history.location.state.data.endpoint,
        },
        showModal: true,
        addModalVisible: false,
        pendingDev: true,
      }

    } else {
      // otherwise baseline
      this.state = {
        identify_event_idx: -2,
        showModal: false,
        addModalVisible: false,
        currentExternalEvent: baseEventFieldsData,
        pendingDev: true,
      };
    }

    props.persist.domain.id && updateIfNeeded(this.props.external_events_updated,
      () => this.props.getExternalEvents({uuid: props.persist.domain.id}));


    // always refresh dev due to input rendering with same pending flag
    props.persist.domain.id && updateIfNeeded(null,
      () => this.props.getDev({uuid: this.props.persist.domain.id}), () => this.setState({pendingDev: false}));
  }

  header = [
    {key: 'name', value: 'Name', help: null},
    {key: 'is_active', value: 'State', help: null},
    {key: 'last_edited', value: 'Last edited', help: null},
    {},
  ];

  componentWillUpdate(nextProps, nextState, nextContext) {
    if (nextProps.persist.domain.id && this.props.persist.domain.id !== nextProps.persist.domain.id) {
      this.props.getExternalEvents({uuid: nextProps.persist.domain.id})
    }
  }

  _add_new(template) {
    // add new and open modal
    this.props.setExternalEventIndex({external_event_index: -1})
      .then(() => this.setState({
        addModalVisible: false,
        showModal: true,
        currentExternalEvent: {
          ...baseEventFieldsData,
          ...template,
          availableFieldPaths: template ? classifyJsonAvailableFields(template.data) : [],
          selectedFieldPaths: template ? classifyJsonSelectedFields(template.fields) : [],
        }
      }))
  }

  _open_external_event(external_event, idx) {
    // open external event
    this.props.setExternalEventIndex({external_event_index: idx})
      .then(() => this.setState({
        showModal: true,
        currentExternalEvent: {
          ...external_event,
          title: `${this.props.persist.domain.role === 'admin' ? 'Adjust' : "View"} server side event`
        }
      }))
  }

  _save(external_event) {
    // depending on uuid we either add or update
    let data = {
      uuid: this.props.persist.domain.id,
      external_event_index: this.props.rules.external_event_index,
      external_event: external_event
    };

    if (external_event.id) {
      return this.props.updateExternalEvent(data)
    } else {
      return this.props.addExternalEvent(data)
    }
  }

  _delete(external_event) {
    if (external_event.id) {
      // actual data layer event, as such we delete
      this.props.deleteExternalEvent({
        uuid: this.props.persist.domain.id,
        external_event_index: this.props.rules.external_event_index,
        external_event: external_event
      })
        .then(() =>{
          this.setState({showModal: false}),
          this.props.getExternalEvents({uuid: this.props.persist.domain.id})
        })
    } else {
      // we just close the window, no real data
      this.setState({showModal: false})
    }
  }

  _open_identify_event(identify_event_idx) {
    this.setState({identify_event_idx})
  }


  render({history, persist, publishBuild, rules} = this.props) {

    return (
      <PageLayout title='Server side events'
                  history={this.props.history}
                  domain={persist.domain}
                  date_last_build={rules.date_last_build}
                  requires_publish={rules.requires_publish}
                  hasPublish={rules.identify_events.length > 0 && persist.domain.state === 'live'}
                  onPublish={(message) => publishBuild({uuid: persist.domain.id, message: message})}>
        {rules.isPending && (<Loader style={{marginTop: '20vh', marginLeft: '48%'}}/>)}

        {!rules.isPending && (
          <Modal visible={this.state.addModalVisible} onClose={() => this.setState({addModalVisible: false})}>
            <h2>Add an event</h2>
            <h6 className='spacer' style={{marginBottom: 24}}>Select which type of event you want to add.</h6>

            {ExternalEventsAutocomplete.map((template, idx) => (
              <Button key={idx} style={{marginTop: 6}} title={template.button} onClick={() => this._add_new(template)}/>
            ))}

            <Button style={{marginTop: 6}} title='Blank event (Select a template)' onClick={() => this._add_new()}/>

          </Modal>
        )}


        {!rules.isPending && (
          <BlankCard
            title='My identify events'
            subtitle="Client side events to connect the server side event to the user's browser data."
            padding={false}
            onAddNewTitle='New event'
            onAddNew={() => this._open_identify_event(-1)}>
            <Table header={this.header}>

              {rules.identify_events.length > 0 && rules.identify_events.map((identify_event, idx) => (

                <tr key={idx}>
                  {/* NOTE: rows are build up custom */}

                  <td className='clickable' onClick={() => this._open_identify_event(idx)}>
                    <p className='verticalpadding'>{identify_event.name}</p>
                  </td>

                  <td>
                    <p className='verticalpadding'>{identify_event.is_active ? 'active' : 'inactive'}</p>
                  </td>

                  <td className='row'>
                    <p className='verticalpadding'>{formatDateTime(identify_event.date_updated)}</p>
                  </td>

                  <td>

                    <div className='row'>
                      <Icon size={24} className='clickable' icon={live_active} color={colors.brand.primary} onClick={() => history.push('/live', {event: 'id_storage'})}/>

                      <Icon size={24} className='clickable' icon={pencil}
                            color={colors.brand.primary}
                            onClick={() => this._open_identify_event(idx)}/>
                    </div>
                  </td>

                </tr>
              ))}
            </Table>

            <div className='row' style={{alignItems: 'center', maxWidth: 700, marginLeft: 24}}>
              <Icon icon={info} color={colors.brand.primary}/>
              <p style={{marginLeft: 12}} className='small'>
                An identify event is triggered in the browser. This can be done either through a <span>dataLayer</span> event or via javascript: td.identify(). This event connects
                the customerID that is added to the server side event with the user's browser data: the (cookie)clientID, IP address and user Agent.
                With this connection the data is properly stitched to the correct user session.
              </p>
            </div>

          </BlankCard>
        )}

        {!rules.isPending && (
          <BlankCard
            title='My server side events'
            subtitle='Events forwarded by your server to connect with the user data and fowarded to GA'

            padding={false}
            onAddNewTitle='New event'
            onAddNew={() => this.setState({addModalVisible: true})}>
            <Table header={this.header}>

              {rules.external_events.length > 0 && rules.external_events.map((external_event, idx) => (

                <tr key={idx}>
                  {/* NOTE: rows are build up custom */}

                  <td className='clickable' onClick={() => this._open_external_event(external_event, idx)}>
                    <p className='verticalpadding'>{external_event.name}</p>
                  </td>

                  <td>
                    <p className='verticalpadding'>{external_event.is_active ? 'active' : 'inactive'}</p>
                  </td>

                  <td className='row'>
                    <p className='verticalpadding'>{formatDateTime(external_event.date_updated)}</p>
                  </td>

                  <td>

                    <div className='row'>
                      <Icon size={24} className='clickable' icon={live_active} color={colors.brand.primary} onClick={() => history.push('/live', {event: external_event.name})}/>

                      <Icon size={24} className='clickable' icon={pencil}
                            color={colors.brand.primary}
                            onClick={() => this._open_external_event(external_event, idx)}/>
                    </div>
                  </td>

                </tr>
              ))}
            </Table>

          </BlankCard>
        )}

        {!rules.isPending && !this.state.pendingDev &&
        <GoogleAnalyticsSettingsModule
          visible={!rules.isPending && !this.state.pendingDev && rules.dev}
          data={rules.dev}
          pageType='serverside'
          onSave={(data) => this.props.updateDev({
            ...data,
            id: persist.domain.id
          }).then(() => this.props.getDev({uuid: persist.domain.id}))}
        />}

        {!rules.isPending && !this.state.pendingDev && rules.dev ?
          <FacebookConversionSettingsModule
          visible={!rules.isPending && !this.state.pendingDev && rules.dev}
          data={rules.dev}
          onSave={(data) => this.props.updateDev({
            ...data,
            id: persist.domain.id
          }).then(() => this.props.getDev({uuid: persist.domain.id}))}
        /> : <></>}


        {!rules.isPending && !this.state.pendingDev && rules.dev && rules.dev.cdp_enabled ?
          <CDPSettingsModule
            visible={!rules.isPending && !this.state.pendingDev && rules.dev && rules.dev.cdp_product_id}
            data={rules.dev}
            onSave={(data) => this.props.updateDev({
              ...data,
              id: persist.domain.id
            }).then(() => this.props.getEvents({uuid: persist.domain.id}))}
          /> : <></>}

        {this.state.showModal && (
          <ExternalEventModal
            domain={persist.domain.name}
            api_endpoint={`https://${persist.domain.subdomain}.${persist.domain.domain}${persist.domain.api_endpoint}`}
            externalEvent={this.state.currentExternalEvent}
            show={this.state.showModal}
            readOnly={false}
            onClose={() => this.setState({showModal: false})}
            onSave={(external_event) => this._save(external_event)}
            onDelete={(external_event) => this._delete(external_event)}
            dev={rules.dev}
          />
        )}

        {this.state.identify_event_idx > -2 && (
          <IdentifyEventModal
            domain={persist.domain.name}
            identify_event_idx={this.state.identify_event_idx}
            identify_events={this.props.rules.identify_events}
            onClose={() => this.setState({identify_event_idx: -2})}
            onSave={(identify_events) => this.props.saveIdentifyEvents({uuid: persist.domain.id, identify_events: identify_events})}
          />
        )}
      </PageLayout>
    );
  }
}


const mapStateToProps = (state) => ({
  persist: state.persist,
  rules: state.rules,
});

const mapDispatchToProps = (dispatch) => {
  return {
    getDev: (data) => dispatch(rulesActions.getDev(data)),
    getExternalEvents: (d) => dispatch(rulesActions.getExternalEvents(d)),
    setExternalEventIndex: (d) => dispatch(rulesActions.setExternalEventIndex(d)),
    addExternalEvent: (d) => dispatch(rulesActions.addExternalEvent(d)),
    updateExternalEvent: (d) => dispatch(rulesActions.updateExternalEvent(d)),
    deleteExternalEvent: (d) => dispatch(rulesActions.deleteExternalEvent(d)),
    saveIdentifyEvents: (d) => dispatch(rulesActions.saveIdentifyEvents(d)),
    updateDev: (data) => dispatch(rulesActions.updateDev(data)),
    publishBuild: (d) => dispatch(rulesActions.publishBuild(d)),
  }
};

export default ExternalEvents = connect(mapStateToProps, mapDispatchToProps)(ExternalEvents);