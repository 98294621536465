
export const rulesState = {

	isPending: false,

	date_last_build: null,
	requires_publish: false,

	event_index: -1,
	event_type: null,
	events_updated: null,

	copy_events: [],

	pendingCheckStatus: '',

	events: {
		pageload: [],
		click: [],
		datalayer: []
	},

	dev_updated: null,
	dev: {
		ga_property_id: "",
		tracedock_hit: "",
		debug: false,
		is_active: true,
		subdomain: "",
		domain: "",
		api_endpoint: "",
		arecord: "",
		javascript: "",
		dns_provider: "",
		cdp_enabled: false,
		cdp_product_id: '',
		fb_enabled: false,
		fb_id: '',
		fb_token: '',
	},

	// External
	identify_events: [],
	external_event_index: -1,
	external_events_updated: null,
	external_events: [],
};
