export const formatDateTime = (dateString) => {
  // helper function to format datestrings
  if (dateString === '-1' || !dateString) return '';
  return new Date(Date.parse(dateString)).toLocaleTimeString('en-EN',
    {weekday: 'short', year: 'numeric', month: 'short', day: 'numeric'})
};


export const formatDate = (dateString) => {
  // helper function to format datestrings
  if (dateString === '-1' || !dateString) return '';
  return new Date(Date.parse(dateString)).toLocaleDateString('en-EN',
    {weekday: 'short', year: 'numeric', month: 'short', day: 'numeric'})
};

export const todayShortFormat = () => {
  // format yymmdd for files
  let date = new Date();
  let month = date.getMonth() + 1;
  return `${date.getFullYear().toString().substring(2, 4)}${month < 10 ? '0' + month : month}${date.getDate() < 10 ? '0' + date.getDate() : date.getDate()}`
};


export function normalizeCurrency(price) {
  price = price.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,').replace('.00', '');
  if (price) {
    return `€${price}`
  } else {
    return '€0,-'
  }
}


export function addDays(start, days) {
  return (new Date(start.setDate(start.getDate() + days))).toLocaleDateString('en-EN',
    {year: 'numeric', month: 'short', day: 'numeric'})
}

export function addWeeks(start, weekLater) {

  start.setDate(start.getDate() + weekLater * 7);

  let day = start.getDay();
  let diff = start.getDate() - day + (day === 0 ? -6 : 1); // adjust when day is sunday

  return (new Date(start.setDate(diff))).toLocaleDateString('en-EN',
    {year: 'numeric', month: 'short', day: 'numeric'})
}
