import React from "react";
import PropTypes from 'prop-types';

import { BlankCard } from "../../../components/cards/blankCard";
import { Input } from "../../../components/input/input";
import { DataFieldObject } from "../../../components/dataFieldObject/dataFieldObject";
import { ga4TemplateChoices, ga4Templates } from "../../../lib/google_analytics/templates";
import { ga4PossibleFields } from "../../../lib/google_analytics/possibleFields";
import { gaFieldDescriptions, gaOtherFields } from "../../../lib/google_analytics/fieldDescriptions";

import { Popup, PopupPosition } from "../../../components/popup";
import { eye, eye_active, Icon } from "../../../components/icon/icon";
import { colors } from "../../../theme/colors";
import { DataFieldPreview } from "../../../components/dataFieldObject/dataFieldPreview";
import { testFieldMapping } from "../../../redux/rules/rules.actions";
import { CustomDimensionsObject } from "../../../components/customDimensionsObject/customDimensionsObject";


export class CardExternalGa4Fields extends React.Component {

  static propTypes = {
    fields: PropTypes.array,
    data: PropTypes.any,
    invalidObjects: PropTypes.any,
    availableFieldPaths: PropTypes.array,
    readOnly: PropTypes.bool,
    collapsed: PropTypes.bool,
    onUpdate: PropTypes.func,
    onUpdateCustomDimensions: PropTypes.func,
    setTemplate: PropTypes.func,
    template: PropTypes.string,
    domain: PropTypes.string,
  };

  static defaultProps = {
    template: 'blank',
    fields: [],
    data: {},
    invalidObjects: {},
    availableFieldPaths: [],
    readOnly: false,
    domain: '',
    onUpdate: (data) => console.log('updating data: ', data),
    onUpdateCustomDimensions: (data) => console.log('updating custom dimensions: ', data),
    setTemplate: (template) => console.log('setting template: ', template),
  };

  constructor(props, context) {
    super(props, context);
    this.state = {
      tab: 'fields'
    };
  }

  _updateTemplate(template) {

    // updating template
    // todo: don't throw away fields if you make a selection. Now we just refresh them
    this.props.onUpdate([], () => {

      // add 'client_id'
      this.props.onUpdate(
        [{field_name: 'cid', field_type: 'l', stitch: true, children: []}].concat(ga4Templates[template])
        , () => this.props.setTemplate(template));
    })
  }

  render({ invalidObjects, collapsed, availableFieldPaths, fields, data, readOnly, template, domain, customDimensions } = this.props) {


    return (

      <BlankCard
        collapsable={true}
        initialCollapsed={collapsed}
        title='To Google Analytics 4'
        subtitle='Determine what data should be forwarded.'
        padding={false}>

        <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between'}}>
          <Input
            style={{padding: '0 24px', maxWidth: 250}}
            type="text"
            label="Select initial template"
            restrict={true}
            readOnly={false}
            choices={ga4TemplateChoices}
            initial={template}
            onChange={(template) => this._updateTemplate(template)}
          />

          <Popup
            content={
              <p style={{width: 200}}>Preview your forwarded data here. It is calculated based on your raw data as found on the left side.</p>
            }
            initialOpen={false}
            stayOpen={false}
            position={PopupPosition.LEFT_CENTER}
          >
            <div className='clickable' style={{padding: '10px 27px 5px 10px'}} onClick={() => this.setState({tab: this.state.tab === 'fields' ? 'preview' : 'fields'})}>
              <Icon size={25} icon={this.state.tab === 'preview' ? eye_active : eye} color={colors.brand.secondary}/>
            </div>
          </Popup>
        </div>


        {this.state.tab === 'fields' && (
          <DataFieldObject
            editReadOnlyFields={true}
            invalidObjects={invalidObjects}
            readOnly={false}

            availableFieldPaths={availableFieldPaths}
            onUpdate={(data) => this.props.onUpdate(data)}

            fields={fields}
            parent=''
            level={0}

            possibleFields={ga4PossibleFields}
            fieldDescriptions={gaFieldDescriptions}

            stitchfield='cid'
          />
        )}

        {this.state.tab === 'preview' && (
          <div style={{minHeight: 510, padding: 20}}>
            <DataFieldPreview
              testFieldMapping={testFieldMapping}
              domain={domain}
              fields={fields}
              data={data}

              type='ga4'
              FieldDescriptions={gaFieldDescriptions}
              OtherFields={gaOtherFields}
            />
          </div>
        )}

        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
          <div className='headerCustomDimensions'><h5>Custom Dimensions</h5></div>
        </div>

        <CustomDimensionsObject
          // invalidObjects={{}}
          readOnly={false}

          availableFieldPaths={availableFieldPaths}
          onUpdateCustomDimensions={(data) => this.props.onUpdateCustomDimensions(data)}

          // possibleFields={{}}
          // fieldDescriptions={{}}

          // fields={fields}
          customDimensions={customDimensions}
          parent=''
          level={0}
        />


      </BlankCard>
    )
  }
}
