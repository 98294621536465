import React from 'react';
import PropTypes from 'prop-types';

import './javascript.scss';
import {CopyIcon} from "../icon/copy";

/*
    Note: component is a pure functional React component,
    class-based and function based can co-exist, we are migrating to function based components
 */

export function Javascript(props) {

  return (
    <div className='JavascriptWrapper'>

      <div className='codeWrapper' style={{whiteSpace: props.overflow ? 'wrap' : 'nowrap'}}>
        <p className='title'>
          {props.value}
        </p>
      </div>

      <div className='copyWrapper clickable'>
        <CopyIcon text={props.value} onCopy={() => props.onCopy()} title={`Copy ${props.title}`}/>
      </div>
    </div>
  )
}

Javascript.propTypes = {
  overflow: PropTypes.bool,
  title: PropTypes.string,
  value: PropTypes.string,
  onCopy: PropTypes.func,
};

Javascript.defaultProps = {
  overflow: true,
  title: '',
  value: PropTypes.string,
  onCopy: () => {},
};