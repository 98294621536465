import {api} from "../../lib/api";
import {setAlertDialog} from "../../components/alert/alert";
import {store} from "../../index.js"
import { getTechnicalLink, getOrganisationId, getLogicalAccount, getSsoAccount, getCurrentTenantId } from "../apiConstants";
import storage from "redux-persist/lib/storage";

// var tempUuid = "iuY6OdxFfr6imQWkP6C0m8VKP6tonF";

export const testFieldMapping = async ({field_mapping, data, domain, type, test_event_code, id}) => {
  try {
    const BASE_EVENTS_ENDPOINT = `v1/agencies/${getTechnicalLink()}/tenants/${getCurrentTenantId()}`;
    let res = await api.posttodotnet(`${BASE_EVENTS_ENDPOINT}/domains/${id}/testfieldmapping`,{field_mapping, data, domain, type})
    return res;
    return api.post('rules/test_field_mapping.json', {field_mapping, data, domain, type, test_event_code});
  } catch (error) {
    return {
      'error': 'Server error, please refresh or contact support@tracedock.com'
    }
  }
};


export const publishBuild = ({uuid, message}) => {
  return async (dispatch) => {
    try {

      dispatch({
        type: 'RULES_PENDING'
      });

      // let res = await api.post('domain/publish.json', {uuid, message});
      const BASE_PLUGIN_ENDPOINT = `v1/agencies/${getTechnicalLink()}/tenants/${getCurrentTenantId()}`;
      let res = await api.puttodotnet(`${BASE_PLUGIN_ENDPOINT}/domains/${uuid}/plugin`, {"Message": message});

      dispatch({
        type: 'SET_DATE_LAST_BUILD',
        date_last_build: res.date_last_build,
        requires_publish: res.requires_publish,
      });

      return res

    } catch (error) {
      dispatch({
        type: 'RULES_DONE',
      });
    }
  }
};

export function resetRulesState() {
  return async (dispatch) => {
    dispatch({
      type: 'RESET_RULES_STATE'
    })
  }
}

/*
  ##########################################################################################
  # COOKIES AND DEV SETTINGS
  ##########################################################################################
 */

export const getDev = ({uuid}) => {
  //This func gets single domain specifics
  const BASE_DOMAINS_ENDPOINT = `v1/agencies/${getTechnicalLink()}/tenants/${getCurrentTenantId()}/domains`;
  return async (dispatch) => {
    try {
      dispatch({
        type: 'RULES_PENDING'
      });
      let res = await api.getdotnet(`${BASE_DOMAINS_ENDPOINT}/${uuid}`);

      dispatch({
        type: 'DEV_DONE',
        dev: res
      });

      dispatch({
        type: 'RULES_DONE',
      });

      return res

    } catch (error) {
      dispatch({
        type: 'RULES_DONE',
      });
    }
  }
};

//This is updateDomain
export const updateDev = (data) => {
  return async (dispatch) => {
    try {

      dispatch({
        type: 'RULES_PENDING',
      });

      // let res = await api.post('domain/update_dev.json', data);
      const BASE_DOMAINS_ENDPOINT = `v1/agencies/${getTechnicalLink()}/tenants/${getCurrentTenantId()}/domains`;
      let res = await api.puttodotnet(`${BASE_DOMAINS_ENDPOINT}`, data);

      if (res) {
        dispatch({
          type: 'DEV_DONE',
          dev: res
        });
      }

      return res

    } catch (error) {
      dispatch({
        type: 'RULES_DONE',
      });
    }
  }
};



export const checkStatus = (data) => {
  
  // data.uuid = 'QhBZyrqmWX5YDFB208wnkM9CufIeSX'
  // data.type = 'dns'
  return async (dispatch) => {
    try {

  //     dispatch({
  //       type: 'PENDINGCHECKSTATUS',
  //       pendingCheckStatus: data.type,
  //     });

      // let res = await api.post('domain/check_status.json', data);
      const BASE_DOMAINS_ENDPOINT = `v1/agencies/${getTechnicalLink()}/tenants/${getCurrentTenantId()}/domains`;
      let res = await api.posttodotnet(`${BASE_DOMAINS_ENDPOINT}/${data.id}/checkstatus`, data);

  //     if (res.message) {
  //       setAlertDialog('Status check', res.message, res.details)
  //     }

      if(res.domain){
        dispatch({
        type: 'UPDATE_DOMAIN',
        domain: res.domain,
      });
      }
  //     dispatch({
  //       type: 'UPDATE_DOMAIN',
  //       domain: res.domain,
  //     });

  //     dispatch({
  //       type: 'PENDINGCHECKSTATUS',
  //       pendingCheckStatus: '',
  //     });

  //     return res

    } catch (error) {
      console.error('Error set Pending')
    }
  }
};

/*
  ##########################################################################################
  # EVENTS
  ##########################################################################################
*/


export const getEvents = ({uuid}) => {
  return async (dispatch) => {
    try {

      dispatch({
        type: 'RULES_PENDING'
      });
      // let bla = await api.post('rules/get_events.json', {uuid});
      // uuid = tempUuid
      
      const BASE_EVENTS_ENDPOINT = `v1/agencies/${getTechnicalLink()}/tenants/${getCurrentTenantId()}/domains`;
      let res = await api.getdotnet(`${BASE_EVENTS_ENDPOINT}/${uuid}/events`);
      dispatch({
        type: 'SET_EVENTS',
        events: res,
        date_last_build: res.date_last_build,
        requires_publish: res.requires_publish,
      });

      return res

    } catch (error) {
      dispatch({
        type: 'RULES_DONE',
      });
    }
  }
};


export function setEventIndex({event_index, event_type}) {
  return async (dispatch) => {
    dispatch({
      type: 'SET_EVENT_INDEX',
      event_index: event_index,
      event_type: event_type
    })
  }
}


export const addEvent = ({uuid, event_index, event}) => {
  return async (dispatch) => {
    try {
      // let res = await api.post('rules/add_event.json', {uuid, event});
      const BASE_EVENTS_ENDPOINT = `v1/agencies/${getTechnicalLink()}/tenants/${getCurrentTenantId()}/domains`;
      let res = await api.puttodotnet(`${BASE_EVENTS_ENDPOINT}/${uuid}/events`, {event});

      // console.log("AddEventOriginal response: ", bla)
      if (res.event) {
        dispatch({
          type: 'EVENT_ADDED',
          event: res.event
        });
      }

      return res

    } catch (error) {
      dispatch({
        type: 'RULES_DONE',
      });
    }
  }
};


export const updateEvent = ({uuid, event_index, event}) => {
  return async (dispatch) => {
    try {
      // let res = await api.post('rules/update_event.json', {uuid, event});
      //uuid becomes ID and we put it in URL instead of body
      const BASE_EVENTS_ENDPOINT = `v1/agencies/${getTechnicalLink()}/tenants/${getCurrentTenantId()}/domains`;
      let res = await api.puttodotnet(`${BASE_EVENTS_ENDPOINT}/${uuid}/events`, {event});

      if (res.event) {
        dispatch({
          type: 'EVENT_UPDATED',
          event_index: event_index,
          event: res.event
        });
      }

      return res

    } catch (error) {
      dispatch({
        type: 'RULES_DONE',
      });
    }
  }
};


export const deleteEvent = ({uuid, event}) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: 'EVENT_REMOVE',
        event: event
      });

      // await api.post('rules/delete_event.json', {uuid, event});
      // await api.deletetodotnet('events/event', event);
      const BASE_EVENTS_ENDPOINT = `v1/agencies/${getTechnicalLink()}/tenants/${getCurrentTenantId()}`;
      let res = await api.deletetodotnet(`${BASE_EVENTS_ENDPOINT}/events/${event.id}`,{});

    } catch (error) {
      dispatch({
        type: 'RULES_DONE',
      });
    }
  }
};

export const getEventsForCopy = ({uuid}) => {
  return async (dispatch) => {
    try {

      const BASE_EVENTS_ENDPOINT = `v1/agencies/${getTechnicalLink()}/tenants/${getCurrentTenantId()}/domains`;
      let res = await api.getdotnet(`${BASE_EVENTS_ENDPOINT}/${uuid}/events`);
      // let res = await api.post('rules/get_events.json', {uuid});

      dispatch({
        type: 'SET_EVENTS_FOR_COPY',
        copy_events: res,
      });

    } catch (error) {}
  }
};

export const copyEvents = ({uuid, eventIds}) => {
  return async (dispatch) => {
    try {

      dispatch({
        type: 'RULES_PENDING'
      });

      const BASE_EVENTS_ENDPOINT = `v1/agencies/${getTechnicalLink()}/tenants/${getCurrentTenantId()}`;
      let res = await api.posttodotnet(`${BASE_EVENTS_ENDPOINT}/domains/${uuid}/events/copyevents`, eventIds);

      dispatch({
        type: 'SET_EVENTS',
        events: res,
        date_last_build: res.date_last_build,
        requires_publish: res.requires_publish,
      });

      return res

    } catch (error) {
      dispatch({
        type: 'RULES_DONE',
      });
    }
  }
};

/*
  ##########################################################################################
  # EXTERNAL EVENTS
  ##########################################################################################
*/

//Also gets identify_events
export const getExternalEvents = ({uuid}) => {
  return async (dispatch) => {
    try {

      dispatch({
        type: 'RULES_PENDING'
      });

      // let res = await api.post('rules/get_external_events.json', {uuid});
      // uuid = tempUuid;
      const BASE_EXTERNALEVENTS_ENDPOINT = `v1/agencies/${getTechnicalLink()}/tenants/${getCurrentTenantId()}/domains`;
      let res = await api.getdotnet(`${BASE_EXTERNALEVENTS_ENDPOINT}/${uuid}/externalevents`);

      dispatch({
        type: 'SET_EXTERNAL_EVENTS',
        external_events: res.external_events,
      });
      dispatch({
        type: 'SET_IDENTIFY_EVENTS',
        identify_events: res.identify_events || [],
      });

      return res

    } catch (error) {
      dispatch({
        type: 'RULES_DONE',
      });
    }
  }
};

export const saveIdentifyEvents = ({uuid, identify_events}) => {
  return async (dispatch) => {
    try {

      const BASE_IDENTIFYEVENTS_ENDPOINT = `v1/agencies/${getTechnicalLink()}/tenants/${getCurrentTenantId()}/domains`;
      // let res = await api.post('rules/save_identify_events.json', {uuid, identify_events});
      let res = await api.puttodotnet(`${BASE_IDENTIFYEVENTS_ENDPOINT}/${uuid}/identifyevents`, {identify_events});

      res && dispatch({
        type: 'SET_IDENTIFY_EVENTS',
        identify_events: res.identify_events,
        requires_publish: true
      });

      return res

    } catch (error) {
    }
  }
};


export function setExternalEventIndex({external_event_index}) {
  return async (dispatch) => {
    dispatch({
      type: 'SET_EXTERNAL_EVENT_INDEX',
      external_event_index: external_event_index
    })
  }
}


export const addExternalEvent = ({uuid, external_event_index, external_event}) => {
  return async (dispatch) => {
    try {
      const BASE_EXTERNALEVENTS_ENDPOINT = `v1/agencies/${getTechnicalLink()}/tenants/${getCurrentTenantId()}/domains`;
      let res = await api.puttodotnet(`${BASE_EXTERNALEVENTS_ENDPOINT}/${uuid}/externalevents`,{external_event});

      if (res.external_event) {
        dispatch({
          type: 'EXTERNAL_EVENT_ADDED',
          external_event: res.external_event
        });
      }

      return res

    } catch (error) {
      dispatch({
        type: 'RULES_DONE',
      });
    }
  }
};


export const updateExternalEvent = ({uuid, external_event_index, external_event}) => {
  return async (dispatch) => {
    try {

      const BASE_EXTERNALEVENTS_ENDPOINT = `v1/agencies/${getTechnicalLink()}/tenants/${getCurrentTenantId()}/domains`;
      // let res = await api.post('rules/update_external_event.json', {uuid, external_event});
      let res = await api.puttodotnet(`${BASE_EXTERNALEVENTS_ENDPOINT}/${uuid}/externalevents`,{external_event});

      if (res.external_event) {
        dispatch({
          type: 'EXTERNAL_EVENT_UPDATED',
          external_event_index: external_event_index,
          external_event: res.external_event
        });
      }

      return res

    } catch (error) {
      dispatch({
        type: 'RULES_DONE',
      });
    }
  }
};


export const deleteExternalEvent = ({uuid, external_event}) => {
  return async (dispatch) => {
    try {

      dispatch({
        type: 'EXTERNAL_EVENT_REMOVE',
        external_event: external_event
      });
      
      // let res = await api.post('rules/delete_external_event.json', {uuid, external_event});
      const BASE_EXTERNALEVENTS_ENDPOINT = `v1/agencies/${getTechnicalLink()}/tenants/${getCurrentTenantId()}`;
      let res = await api.deletetodotnet(`${BASE_EXTERNALEVENTS_ENDPOINT}/externalevents/${external_event.id}`,{});

    } catch (error) {
      dispatch({
        type: 'RULES_DONE',
      });
    }
  }
};