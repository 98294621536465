import React, {useState} from "react";
import {BlankCard} from "../../components/cards/blankCard";
import {colors} from "../../theme/colors";
import {Input} from "../../components/input/input";
import {Button} from "../../components/button/button";
import {api} from "../../lib/api";

export const ServerSideDemoPage = () => {

  const [CID, setCID] = useState('');
  const [TXid, setTXid] = useState('');
  const [Rev, setRev] = useState('');
  const [Env, setEnv] = useState('production');

  const [loadingIdentify, setLoadingIdentify] = useState(false);
  const [loadingEvent, setLoadingEvent] = useState(false);

  const _identify = () => {
    setLoadingIdentify(true);
    window.td.identify(CID);
    setTimeout(() => setLoadingIdentify(false), 750);
  }

  const _event = async () => {
    setLoadingEvent(true);

    let payload = {customerId: CID, tx_id: TXid, rev: Rev, env: Env};
    navigator.sendBeacon(api.config.td_serverside, JSON.stringify(payload));

    setTimeout(() => setLoadingEvent(false), 750);
  }


  return (
    <div style={{width: '100vw', height: '100vh', padding: 40, backgroundColor: colors.brand.gray_lighter}}>

      <h2 className='center' style={{marginBottom: 32}}>TraceDock Serverside Transactions</h2>

      <div className='row' style={{justifyContent: 'center'}}>

        <BlankCard
          style={{width: '40%', margin: 24}}
          title='1. Identify'
          subtitle='Connect internal user to browser session'
        >
          <Input
            label="Internal customer id"
            type="text"
            value={CID}
            focus={true}
            onChange={(value) => setCID(value)}
          />

          <Button
            isLoading={loadingIdentify}
            onClick={() => _identify()}
            title='Identify'
            style={{marginTop: 5}}
          />

          <p style={{marginTop: 24}}>Internal customer ID is the value of the customer given at server level, this value is connected in the client to the browser session
          with the clientID (_ga cookie), IP address and User Agent. The following code is required to accomplish this:</p>


          <p style={{marginTop: 12}}>Via the DataLayer:<br/>
            <span className='code' dangerouslySetInnerHTML={{__html: `window.dataLayer.push({customerID: "${CID}"}`}}/></p>

          <p style={{marginTop: 12}}>Or via Javascript:<br/>
            <span className='code' dangerouslySetInnerHTML={{__html: `window.td.identify("${CID}")`}}/></p>

        </BlankCard>

        <BlankCard
          style={{width: '40%', margin: 24}}
          title='2. Serverside call'
          subtitle='Forward data connected to internal user'
        >

          <p>Internal customer id: <span className='code'>{CID}</span></p>

          <Input
            label="Transaction ID"
            type="text"
            value={TXid}
            onChange={(value) => setTXid(value)}
          />

          <Input
            label="Revenue (€)"
            type="number"
            value={Rev}
            onChange={(value) => setRev(value)}
          />

          <Input
            label="Environment"
            type="text"
            initial='production'
            value={Rev}
            onChange={(value) => setEnv(value)}
          />

          <Button
            isLoading={loadingEvent}
            onClick={() => _event()}
            title='Send event'
            style={{marginTop: 5}}
          />

          <p style={{marginTop: 24}}>The server sends over all transaction information using the internal customer id.
            You can forward any data, including all enhanced Ecommerce fields or even margin data.
            TraceDock does the heavy lifting, and:</p>
          <ul>
            <li>Connects the customer to the browser session, regarding the clientID (_ga cookie), IP address and User Agent.</li>
            <li>Allows you to filter out unwanted data (e.g. only accept data from production servers).</li>
            <li>Optionally de-duplicates data, ensuring that transactions are only forwarded the first time around.</li>
            <li>Formats the payload to the standards of GA (and GA4) using an intuitive designed user portal.</li>
          </ul>

          <p style={{marginTop: 12, marginBottom: 24}}>This call can be accomplished via a simple POST request, in each programming language. For example:<br/>

            <span className='code' dangerouslySetInnerHTML={{__html: `curl -X POST 'https://{{your tracedock endpoint}}>/tx' \\`}}/><br/>
            <span className='code'>  -H'Content-Type: application/json' \</span><br/>
            <span className='code' dangerouslySetInnerHTML={{__html: `  -d {"customerId": "${CID}", "tx": "${TXid}", "rev": ${Rev}}`}}/>
          </p>

        </BlankCard>

      </div>

    </div>
  );
};
