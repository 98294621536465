import React from 'react';

import {Popup} from "../Popup";
import {PopupPosition} from "../constants";

export const Explainer = ({message, learnMore, position, word}) => {
  return (
    <Popup
      content={
        <div style={{minWidth: 300}}>
          <p style={{marginBottom: 0}}>{message}<br/>
            {learnMore && <a className='link clickable'
                             target="_blank" rel="noopener noreferrer"
                             title='Learn more'
                             href={learnMore}>Learn more</a>}
          </p>
        </div>
      }
      stayOpen={false}
      initialOpen={false}
      position={position || PopupPosition.BOTTOM_RIGHT}>
      <span className='clickable' style={{textDecoration: 'underline', margin: '0 2px'}}>{word}</span>
    </Popup>
  )
};

