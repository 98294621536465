/*
    GA measurement protocol
    https://developers.google.com/analytics/devguides/collection/protocol/v1/reference
*/


import {tracedockFields} from "../tracedockFields";

export const gaOtherFields = [
  "tid",
  "bot",
  "ua",
  "ds",
  "dr",
  "dh",
  "dt",
  "sr",
  "vp",
  "de",
  "sd",
  "ul",
  "je",
  "ni",
  "uip",
  "timestamp",
  "type",
  "ga_payload",
  "gA4Payload",
  "gclid",
  "dclid",
  "fbclid",
  "qtime",
  "co",
  "url_params",
  "cookieless",
  "endpoint",
  "tdc"
];


// List contains all fields, including all technical fields
export const gaFieldDescriptions = {

  ...tracedockFields,

// lookups for ids
  ids: {title: 'ids', exclude: 0, caption: 'User ID used for stitch'},
  fbp: {title: 'fbp', exclude: 0, caption: 'Facebook Cookie ID'},
  fbc: {title: 'fbc', exclude: 0, caption: 'Facebook Click ID'},

//Basics
  v: {title: 'version', exclude: 1, caption: ''},
  tid: {title: 'property id', exclude: 0, caption: ''},

//control variables
  qt: {title: 'queue time', exclude: 1, caption: 'in milliseconds, max 4 hours'},
  z: {title: 'cache buster', exclude: 1, caption: 'random figure, not in use'},
  sc: {title: 'session control', exclude: 0, caption: '"start" forces new session. "end" ends it. With beacon done.'},

//event and interaction related
  t: {title: 'hit type', exclude: 0, caption: 'event type for Google Analytics, e.g. pageview, event, timing',
    choices: [{key: 'pageview', value: 'pageview'}, {key: 'event', value: 'event'}]},
  ni: {title: 'non-interaction', exclude: 0, caption: 'specifies that a hit can be considered non-interactive, does it reflect a user click or action?'},
  linkid: {title: 'link-id', exclude: 0, caption: 'id of the dom element on which it is clicked'},

//Client data
  cid: {title: 'client id', exclude: 0, caption: 'Anonymously identifies a user/device'},
  tdc: {title: 'tdc', exclude: 0, caption: 'TraceDock ClientID: Anonymously identifies a user/device'},
  uid: {title: 'user id', exclude: 0, caption: 'Company user ID. Cant Personally identifiable info. Cant persist in cookie. Required if no CID.'},
  geoid: {title: 'geo location id', exclude: 0, caption: 'not in use'},
  uip: {title: 'user ip', exclude: 0, caption: 'user ip address', readOnlyValue: 'request.ip'},
  aip: {title: 'boolean anonymous ip', exclude: 1, caption: 'anonymous user ip, not in use as uip always overrides to an anonymous ip'},

//event data
  name: {title: 'name', exclude: 0, caption: ''},
  ec: {title: 'event category', exclude: 0, caption: 'mandatory for t=event'},
  ea: {title: 'event action', exclude: 0, caption: 'mandatory for t=event'},
  el: {title: 'event label', exclude: 0, caption: 'optional'},
  ev: {title: 'event value', exclude: 0, caption: 'optional'},

//location
  ua: {title: 'user agent', exclude: 0, caption: 'user agent string', readOnlyValue: 'request.userAgent'},
  ds: {title: 'data source', exclude: 0, caption: 'data source, defaults to "web"'},

//path url. Import, we only use dh + dp
  dh: {title: 'host name', exclude: 0, caption: 'host name, e.g. tracedock.com', readOnlyValue: 'window.location.hostname'},
  dp: {title: 'path', exclude: 0, caption: 'page path, e.g. /home', readOnlyValue: 'window.location.pathname'},
  dl: {title: 'full path location', exclude: 0, caption: 'full path location, http://login.tracedock.com/home?a=b, not in use'},
  dt: {title: 'document title', exclude: 0, caption: 'document title', readOnlyValue: 'window.document.title'},
  cd: {title: 'screen name', exclude: 0, caption: 'screen name, required for screenview hittypes for app page views, currently not in use'},

//marketing data
  dr: {title: 'document referrer', exclude: 0, caption: 'document referrer: where does the user come from?', readOnlyValue: 'window.document.referrer'},
  cn: {title: 'campaign name', exclude: 0, caption: 'campaign name: utm_campaign', readOnlyValue: 'url_params.utm_campaign'},
  cs: {title: 'campaign source', exclude: 0, caption: 'campaign source: utm_source', readOnlyValue: 'url_params.utm_source'},
  cm: {title: 'campaign medium', exclude: 0, caption: 'campaign medium: utm_medium', readOnlyValue: 'url_params.utm_medium'},
  ck: {title: 'campaign keywords', exclude: 0, caption: 'campaign keywords: utm_term', readOnlyValue: 'url_params.utm_term'},
  cc: {title: 'campaign content', exclude: 0, caption: 'campaign content: utm_content', readOnlyValue: 'url_params.utm_content'},
  gclid: {title: 'Google ads id', exclude: 0, caption: 'Google ads id', readOnlyValue: 'url_params.gclid'},
  dclid: {title: 'Display ads id', exclude: 0, caption: 'Display ads id', readOnlyValue: 'url_params.dclid'},
  fbclid: {title: 'Facebook ads id', exclude: 0, caption: 'Facebook ads id', readOnlyValue: 'url_params.fbclid'},

//Device data
  sr: {title: 'screen resolution', exclude: 0, caption: 'screen resolution', readOnlyValue: 'window.screen.width/height'},
  vp: {title: 'viewport size', exclude: 0, caption: 'viewport size', readOnlyValue: 'window.screen.availWidth/Height'},
  de: {title: 'document encoding', exclude: 0, caption: 'document encoding', readOnlyValue: 'window.document.characterSet'},
  sd: {title: 'screen colors', exclude: 0, caption: 'screen colors', readOnlyValue: 'window.screen.colorDepth'},
  ul: {title: 'language support', exclude: 0, caption: 'language support', readOnlyValue: 'window.navigator.language'},
  je: {title: 'javascript enabled', exclude: 0, caption: 'does javascript run? Per definition 1.'},
  fl: {title: 'flash version', exclude: 0, caption: 'Flash, not in use'},

// ga4 fields
  client_id: {title: 'client_id', exclude: 0, caption: 'Anonymously identifies a user/device'},
  params: {title: 'params', exclude: 0},
  item_list_id: {title: 'item_list_id', exclude: 0},
  item_list_name: {title: 'item_list_name', exclude: 0},
  currency: {title: 'currency', exclude: 0},
  value: {title: 'value', exclude: 0},
  shipping: {title: 'shipping', exclude: 0},
  tax: {title: 'tax', exclude: 0},
  items: {title: 'items', exclude: 0},
  item_id: {title: 'item_id', exclude: 0},
  item_name: {title: 'item_name', exclude: 0},
  item_brand: {title: 'item_brand', exclude: 0},
  item_category: {title: 'item_category', exclude: 0},
  item_category2: {title: 'item_category2', exclude: 0},
  item_category3: {title: 'item_category3', exclude: 0},
  item_category4: {title: 'item_category4', exclude: 0},
  item_category5: {title: 'item_category5', exclude: 0},
  item_variant: {title: 'item_variant', exclude: 0},
  price: {title: 'price', exclude: 0},
  quantity: {title: 'quantity', exclude: 0},
  discount: {title: 'discount', exclude: 0},
  affiliation: {title: 'affiliation', exclude: 0},
  coupon: {title: 'coupon', exclude: 0},
  transaction_id: {title: 'transaction_id', exclude: 0},
  transaction: {title: 'transaction', exclude: 0},
  payment_type: {title: 'payment_type', exclude: 0},
  location_id: {title: 'location_id', exclude: 0},
  index: {title: 'index', exclude: 0},
  shipping_tier: {title: 'shipping_tier', exclude: 0},
  creative_name: {title: 'creative_name', exclude: 0},
  creative_slot: {title: 'creative_slot', exclude: 0},
  promotion_id: {title: 'promotion_id', exclude: 0},
  promotion_name: {title: 'promotion_name', exclude: 0},
  search_term: {title: 'search_term', exclude: 0},

//e-commerce
  ti: {title: 'transaction id', exclude: 0, caption: 'unique transaction ID received from the client'},
  ta: {title: 'transaction affiliation', exclude: 0, caption: 'transaction affiliation, e.g. store name'},
  tr: {title: 'transaction revenue', exclude: 0, caption: 'Total revenue, in float 2 decimal points, 14.57'},
  ts: {title: 'transaction shipping costs', exclude: 0, caption: 'Total shipping costs, in float 2 decimal points, 14.57'},
  tt: {title: 'transaction tax', exclude: 0, caption: 'Total tax, in float 2 decimal points, 14.57'},
  cu: {title: 'transaction currency', exclude: 0, caption: 'Currency, 3 letter code, e.g. EUR'},
  tcc: {title: 'transaction coupon', exclude: 0, caption: 'Transaction coupon for purchases or refunds.'},

//enhanced e-commerce
  pa: {title: 'product action', exclude: 0, caption: 'Can be: click, add, remove, checkout'},
  pal: {title: 'product action list', exclude: 0, caption: 'when product action is "detail or click" you can set list here, e.g. Search Results'},
  cos: {title: 'check out step', exclude: 0, caption: 'when product action is "checkout"'},
  col: {title: 'check out step option', exclude: 0, caption: 'when product action is "checkout"'},


//Product list, nested field
  pr: {title: 'product list', exclude: 0, caption: 'Array, enhanced ecommerce, if pr is present, check keys below to match if all is well.'},
  'pr.id': {title: 'product ID', exclude: 0, caption: 'ID of the product'},
  'pr.nm': {title: 'product name', exclude: 0, caption: 'Name'},
  'pr.br': {title: 'product brand', exclude: 0, caption: 'Brand'},
  'pr.ca': {title: 'product category', exclude: 0, caption: 'Category'},
  'pr.va': {title: 'product variant', exclude: 0, caption: 'Variant, e.g. colour'},
  'pr.pr': {title: 'product price', exclude: 0, caption: 'Price, in float, 2 decimal, e.g. 29.20'},
  'pr.qt': {title: 'product quantity', exclude: 0, caption: 'Quantity'},
  'pr.cc': {title: 'product coupon code', exclude: 0, caption: 'Coupon code, SUMMERSALE12?'},
  'pr.ps': {title: 'product position', exclude: 0, caption: 'Product position in list, between 1 and 200'},

//Product impressions
  il: {title: 'impressions list', exclude: 0, caption: 'Array, product impressions list'},
  'il.nm': {title: 'impressions list name', exclude: 0, caption: 'product impressions list name, required'},
  'il.pi': {title: 'product impression list', exclude: 0, caption: 'Array, products'},

  // doubling of pr.
  // 'il.pi.id': {title: 'product ID', exclude: 0, caption: 'ID of the product'},
  // 'il.pi.nm': {title: 'product name', exclude: 0, caption: 'Name'},
  // 'il.pi.br': {title: 'product brand', exclude: 0, caption: 'Brand'},
  // 'il.pi.ca': {title: 'product category', exclude: 0, caption: 'Category'},
  // 'il.pi.va': {title: 'product variant', exclude: 0, caption: 'Variant, e.g. colour'},
  // 'il.pi.pr': {title: 'product price', exclude: 0, caption: 'Price, in float, 2 decimal, e.g. 29.20'},
  // 'il.pi.ps': {title: 'product position', exclude: 0, caption: 'Product position in list, from 1 to 200'},

// Promotions
  promo: {title: 'promotion list', exclude: 0, caption: 'Array, promotions'},
  'promo.id': {title: 'promotion ID', exclude: 0, caption: 'ID of the promotion'},
  'promo.nm': {title: 'promotion name', exclude: 0, caption: 'Name of the promotion'},
  'promo.cr': {title: 'promotion creative', exclude: 0, caption: 'Creative associated with promotion'},
  'promo.ps': {title: 'promotion position', exclude: 0, caption: 'Slot of the banner position'},
  'promo.pa': {title: 'promotion action', exclude: 0, caption: 'Action, "view" if assumed, otherwise set to "promo_click"'},


//item hit, deprecated
  in: {title: 'item name', exclude: 0, caption: 'item name, required for item hit type, deprecated'},
  ip: {title: 'item price', exclude: 0, caption: 'item price, in float 2 decimal points, deprecated'},
  iq: {title: 'item quantity', exclude: 0, caption: 'item quantity, optional defaults to 1, deprecated'},
  ic: {title: 'item code', exclude: 0, caption: 'item code, optional, can be SKU code, deprecated'},
  iv: {title: 'item category', exclude: 0, caption: 'item category, deprecated'},

//social interactions
  sn: {title: 'social network', exclude: 0, caption: 'not in use'},
  sa: {title: 'social action', exclude: 0, caption: 'e.g. like, not in use'},
  st: {title: 'social target', exclude: 0, caption: 'click, url, not in use'},

//user timing
  utv: {title: 'user timing variable', exclude: 0, caption: 'e.g. lookup, not in use'},
  utt: {title: 'user timing time in milliseconds', exclude: 0, caption: 'not in use'},
  utc: {title: 'user timing category', exclude: 0, caption: 'not in use'},
  utl: {title: 'user timing label', exclude: 0, caption: 'not in use'},
  plt: {title: 'page load time', exclude: 0, caption: 'not in use'},
  dns: {title: 'dns load time', exclude: 0, caption: 'not in use'},
  pdt: {title: 'page download time', exclude: 0, caption: 'not in use'},
  rrt: {title: 'page redirect time', exclude: 0, caption: 'not in use'},
  tcp: {title: 'tcp connection time', exclude: 0, caption: 'not in use'},
  srt: {title: 'server response time', exclude: 0, caption: 'not in use'},
  dit: {title: 'dom interactive time', exclude: 0, caption: 'not in use'},
  clt: {title: 'content load time', exclude: 0, caption: 'not in use'},

//exceptions
  exd: {title: 'exception description', exclude: 0, caption: 'not in use'},
  exf: {title: 'exception fatal', exclude: 0, caption: 'not in use'},

//experiments
  xid: {title: 'experiment id', exclude: 0, caption: 'not in use'},
  xvar: {title: 'experiment variant', exclude: 0, caption: 'e.g. 1 of 2, a/b'},

//for tracking apps
  an: {title: 'app name used to track', exclude: 0, caption: 'not in use'},
  ai: {title: 'app id used to track', exclude: 0, caption: 'not in use'},
  av: {title: 'app version used to track', exclude: 0, caption: 'not in use'},
  aiid: {title: 'app installer identifier', exclude: 0, caption: 'e.g. com.platform.vending, used to track, currently not in use'},

//content groups to cluster data, currently not in use
  cg1: {title: 'content group 1', exclude: 0, caption: ''},
  cg2: {title: 'content group 2', exclude: 0, caption: ''},
  cg3: {title: 'content group 3', exclude: 0, caption: ''},
  cg4: {title: 'content group 4', exclude: 0, caption: ''},
  cg5: {title: 'content group 5', exclude: 0, caption: ''},
  cg6: {title: 'content group 6', exclude: 0, caption: ''},
  cg7: {title: 'content group 7', exclude: 0, caption: ''},
  cg8: {title: 'content group 8', exclude: 0, caption: ''},
  cg9: {title: 'content group 9', exclude: 0, caption: ''},
  cg10: {title: 'content group 10', exclude: 0, caption: ''},

//Custom dims
  cd1: {title: 'custom dim 1', exclude: 0, caption: 'defined by customer'},
  cd2: {title: 'custom dim 2', exclude: 0, caption: 'defined by customer'},
  cd3: {title: 'custom dim 3', exclude: 0, caption: 'defined by customer'},
  cd4: {title: 'custom dim 4', exclude: 0, caption: 'defined by customer'},
  cd5: {title: 'custom dim 5', exclude: 0, caption: 'defined by customer'},
  cd6: {title: 'custom dim 6', exclude: 0, caption: 'defined by customer'},
  cd7: {title: 'custom dim 7', exclude: 0, caption: 'defined by customer'},
  cd8: {title: 'custom dim 8', exclude: 0, caption: 'defined by customer'},
  cd9: {title: 'custom dim 9', exclude: 0, caption: 'defined by customer'},
  cd10: {title: 'custom dim 10', exclude: 0, caption: 'defined by customer'},
  cd11: {title: 'custom dim 11', exclude: 0, caption: 'defined by customer'},
  cd12: {title: 'custom dim 12', exclude: 0, caption: 'defined by customer'},
  cd13: {title: 'custom dim 13', exclude: 0, caption: 'defined by customer'},
  cd14: {title: 'custom dim 14', exclude: 0, caption: 'defined by customer'},
  cd15: {title: 'custom dim 15', exclude: 0, caption: 'defined by customer'},
  cd16: {title: 'custom dim 16', exclude: 0, caption: 'defined by customer'},
  cd17: {title: 'custom dim 17', exclude: 0, caption: 'defined by customer'},
  cd18: {title: 'custom dim 18', exclude: 0, caption: 'defined by customer'},
  cd19: {title: 'custom dim 19', exclude: 0, caption: 'defined by customer'},
  cd20: {title: 'custom dim 20', exclude: 0, caption: 'defined by customer'},
  cd21: {title: 'custom dim 21', exclude: 0, caption: 'defined by customer'},
  cd22: {title: 'custom dim 22', exclude: 0, caption: 'defined by customer'},
  cd23: {title: 'custom dim 23', exclude: 0, caption: 'defined by customer'},
  cd24: {title: 'custom dim 24', exclude: 0, caption: 'defined by customer'},
  cd25: {title: 'custom dim 25', exclude: 0, caption: 'defined by customer'},
  cd26: {title: 'custom dim 26', exclude: 0, caption: 'defined by customer'},
  cd27: {title: 'custom dim 27', exclude: 0, caption: 'defined by customer'},
  cd28: {title: 'custom dim 28', exclude: 0, caption: 'defined by customer'},
  cd29: {title: 'custom dim 29', exclude: 0, caption: 'defined by customer'},
  cd30: {title: 'custom dim 30', exclude: 0, caption: 'defined by customer'},
  cd31: {title: 'custom dim 31', exclude: 0, caption: 'defined by customer'},
  cd32: {title: 'custom dim 32', exclude: 0, caption: 'defined by customer'},
  cd33: {title: 'custom dim 33', exclude: 0, caption: 'defined by customer'},
  cd34: {title: 'custom dim 34', exclude: 0, caption: 'defined by customer'},
  cd35: {title: 'custom dim 35', exclude: 0, caption: 'defined by customer'},
  cd36: {title: 'custom dim 36', exclude: 0, caption: 'defined by customer'},
  cd37: {title: 'custom dim 37', exclude: 0, caption: 'defined by customer'},
  cd38: {title: 'custom dim 38', exclude: 0, caption: 'defined by customer'},
  cd39: {title: 'custom dim 39', exclude: 0, caption: 'defined by customer'},
  cd40: {title: 'custom dim 40', exclude: 0, caption: 'defined by customer'},
  cd41: {title: 'custom dim 41', exclude: 0, caption: 'defined by customer'},
  cd42: {title: 'custom dim 42', exclude: 0, caption: 'defined by customer'},
  cd43: {title: 'custom dim 43', exclude: 0, caption: 'defined by customer'},
  cd44: {title: 'custom dim 44', exclude: 0, caption: 'defined by customer'},
  cd45: {title: 'custom dim 45', exclude: 0, caption: 'defined by customer'},
  cd46: {title: 'custom dim 46', exclude: 0, caption: 'defined by customer'},
  cd47: {title: 'custom dim 47', exclude: 0, caption: 'defined by customer'},
  cd48: {title: 'custom dim 48', exclude: 0, caption: 'defined by customer'},
  cd49: {title: 'custom dim 49', exclude: 0, caption: 'defined by customer'},
  cd50: {title: 'custom dim 50', exclude: 0, caption: 'defined by customer'},
  cd51: {title: 'custom dim 51', exclude: 0, caption: 'defined by customer'},
  cd52: {title: 'custom dim 52', exclude: 0, caption: 'defined by customer'},
  cd53: {title: 'custom dim 52', exclude: 0, caption: 'defined by customer'},
  cd54: {title: 'custom dim 54', exclude: 0, caption: 'defined by customer'},
  cd55: {title: 'custom dim 55', exclude: 0, caption: 'defined by customer'},
  cd56: {title: 'custom dim 56', exclude: 0, caption: 'defined by customer'},
  cd57: {title: 'custom dim 57', exclude: 0, caption: 'defined by customer'},
  cd58: {title: 'custom dim 58', exclude: 0, caption: 'defined by customer'},
  cd59: {title: 'custom dim 59', exclude: 0, caption: 'defined by customer'},

//custom metrics, not in use
  cm1: {title: 'custom metric 1', exclude: 0, caption: 'defined by customer'},
  cm2: {title: 'custom metric 2', exclude: 0, caption: 'defined by customer'},
  cm3: {title: 'custom metric 3', exclude: 0, caption: 'defined by customer'},
  cm4: {title: 'custom metric 4', exclude: 0, caption: 'defined by customer'},
  cm5: {title: 'custom metric 5', exclude: 0, caption: 'defined by customer'},
  cm6: {title: 'custom metric 6', exclude: 0, caption: 'defined by customer'},
  cm7: {title: 'custom metric 7', exclude: 0, caption: 'defined by customer'},
  cm8: {title: 'custom metric 8', exclude: 0, caption: 'defined by customer'},
  cm9: {title: 'custom metric 9', exclude: 0, caption: 'defined by customer'},
  cm10: {title: 'custom metric 10', exclude: 0, caption: 'defined by customer'},
  cm11: {title: 'custom metric 11', exclude: 0, caption: 'defined by customer'},
  cm12: {title: 'custom metric 12', exclude: 0, caption: 'defined by customer'},
  cm13: {title: 'custom metric 13', exclude: 0, caption: 'defined by customer'},
  cm14: {title: 'custom metric 14', exclude: 0, caption: 'defined by customer'},
  cm15: {title: 'custom metric 15', exclude: 0, caption: 'defined by customer'},
  cm16: {title: 'custom metric 16', exclude: 0, caption: 'defined by customer'},
  cm17: {title: 'custom metric 17', exclude: 0, caption: 'defined by customer'},
  cm18: {title: 'custom metric 18', exclude: 0, caption: 'defined by customer'},
  cm19: {title: 'custom metric 19', exclude: 0, caption: 'defined by customer'},
  cm20: {title: 'custom metric 20', exclude: 0, caption: 'defined by customer'},
};


export const ga4FieldDescriptions = {

  ...tracedockFields,

// lookups for ids
  ids: {title: 'ids', exclude: 0, caption: 'User ID used for stitch'},
  fbp: {title: 'fbp', exclude: 0, caption: 'Facebook Cookie ID'},
  fbc: {title: 'fbc', exclude: 0, caption: 'Facebook Click ID'},

//Basics
  v: {title: 'version', exclude: 1, caption: ''},
  tid: {title: 'property id', exclude: 0, caption: ''},

//control variables
  qt: {title: 'queue time', exclude: 1, caption: 'in milliseconds, max 4 hours'},
  z: {title: 'cache buster', exclude: 1, caption: 'random figure, not in use'},
  sc: {title: 'session control', exclude: 0, caption: '"start" forces new session. "end" ends it. With beacon done.'},

//event and interaction related
  t: {title: 'hit type', exclude: 0, caption: 'event type for Google Analytics, e.g. pageview, event, timing',
    choices: [{key: 'pageview', value: 'pageview'}, {key: 'event', value: 'event'}]},
  ni: {title: 'non-interaction', exclude: 0, caption: 'specifies that a hit can be considered non-interactive, does it reflect a user click or action?'},
  linkid: {title: 'link-id', exclude: 0, caption: 'id of the dom element on which it is clicked'},

//Client data
  cid: {title: 'client id', exclude: 0, caption: 'Anonymously identifies a user/device'},
  tdc: {title: 'tdc', exclude: 0, caption: 'TraceDock ClientID: Anonymously identifies a user/device'},
  uid: {title: 'user id', exclude: 0, caption: 'Company user ID. Cant Personally identifiable info. Cant persist in cookie. Required if no CID.'},
  geoid: {title: 'geo location id', exclude: 0, caption: 'not in use'},
  uip: {title: 'user ip', exclude: 0, caption: 'user ip address', readOnlyValue: 'request.ip'},
  aip: {title: 'boolean anonymous ip', exclude: 1, caption: 'anonymous user ip, not in use as uip always overrides to an anonymous ip'},

//event data
  name: {title: 'name', exclude: 0, caption: ''},
  ec: {title: 'event category', exclude: 0, caption: 'mandatory for t=event'},
  ea: {title: 'event action', exclude: 0, caption: 'mandatory for t=event'},
  el: {title: 'event label', exclude: 0, caption: 'optional'},
  ev: {title: 'event value', exclude: 0, caption: 'optional'},

//location
  ua: {title: 'user agent', exclude: 0, caption: 'user agent string', readOnlyValue: 'request.userAgent'},
  ds: {title: 'data source', exclude: 0, caption: 'data source, defaults to "web"'},

//path url. Import, we only use dh + dp
  dh: {title: 'host name', exclude: 0, caption: 'host name, e.g. tracedock.com', readOnlyValue: 'window.location.hostname'},
  dp: {title: 'path', exclude: 0, caption: 'page path, e.g. /home', readOnlyValue: 'window.location.pathname'},
  dl: {title: 'full path location', exclude: 0, caption: 'full path location, http://login.tracedock.com/home?a=b, not in use'},
  dt: {title: 'document title', exclude: 0, caption: 'document title', readOnlyValue: 'window.document.title'},
  cd: {title: 'screen name', exclude: 0, caption: 'screen name, required for screenview hittypes for app page views, currently not in use'},

//marketing data
  dr: {title: 'document referrer', exclude: 0, caption: 'document referrer: where does the user come from?', readOnlyValue: 'window.document.referrer'},
  cn: {title: 'campaign name', exclude: 0, caption: 'campaign name: utm_campaign', readOnlyValue: 'url_params.utm_campaign'},
  cs: {title: 'campaign source', exclude: 0, caption: 'campaign source: utm_source', readOnlyValue: 'url_params.utm_source'},
  cm: {title: 'campaign medium', exclude: 0, caption: 'campaign medium: utm_medium', readOnlyValue: 'url_params.utm_medium'},
  ck: {title: 'campaign keywords', exclude: 0, caption: 'campaign keywords: utm_term', readOnlyValue: 'url_params.utm_term'},
  cc: {title: 'campaign content', exclude: 0, caption: 'campaign content: utm_content', readOnlyValue: 'url_params.utm_content'},
  gclid: {title: 'Google ads id', exclude: 0, caption: 'Google ads id', readOnlyValue: 'url_params.gclid'},
  dclid: {title: 'Display ads id', exclude: 0, caption: 'Display ads id', readOnlyValue: 'url_params.dclid'},
  fbclid: {title: 'Facebook ads id', exclude: 0, caption: 'Facebook ads id', readOnlyValue: 'url_params.fbclid'},

//Device data
  sr: {title: 'screen resolution', exclude: 0, caption: 'screen resolution', readOnlyValue: 'window.screen.width/height'},
  vp: {title: 'viewport size', exclude: 0, caption: 'viewport size', readOnlyValue: 'window.screen.availWidth/Height'},
  de: {title: 'document encoding', exclude: 0, caption: 'document encoding', readOnlyValue: 'window.document.characterSet'},
  sd: {title: 'screen colors', exclude: 0, caption: 'screen colors', readOnlyValue: 'window.screen.colorDepth'},
  ul: {title: 'language support', exclude: 0, caption: 'language support', readOnlyValue: 'window.navigator.language'},
  je: {title: 'javascript enabled', exclude: 0, caption: 'does javascript run? Per definition 1.'},
  fl: {title: 'flash version', exclude: 0, caption: 'Flash, not in use'},

// ga4 fields
  params: {title: 'params', exclude: 0},
  item_list_id: {title: 'item_list_id', exclude: 0},
  item_list_name: {title: 'item_list_name', exclude: 0},
  currency: {title: 'currency', exclude: 0},
  value: {title: 'value', exclude: 0},
  shipping: {title: 'shipping', exclude: 0},
  tax: {title: 'tax', exclude: 0},
  items: {title: 'items', exclude: 0},
  item_id: {title: 'item_id', exclude: 0},
  item_name: {title: 'item_name', exclude: 0},
  item_brand: {title: 'item_brand', exclude: 0},
  item_category: {title: 'item_category', exclude: 0},
  item_variant: {title: 'item_variant', exclude: 0},
  price: {title: 'price', exclude: 0},
  quantity: {title: 'quantity', exclude: 0},
  discount: {title: 'discount', exclude: 0},
  affiliation: {title: 'affiliation', exclude: 0},
  coupon: {title: 'coupon', exclude: 0},
  transaction_id: {title: 'transaction_id', exclude: 0},
  transaction: {title: 'transaction', exclude: 0},

//e-commerce
  ti: {title: 'transaction id', exclude: 0, caption: 'unique transaction ID received from the client'},
  ta: {title: 'transaction affiliation', exclude: 0, caption: 'transaction affiliation, e.g. store name'},
  tr: {title: 'transaction revenue', exclude: 0, caption: 'Total revenue, in float 2 decimal points, 14.57'},
  ts: {title: 'transaction shipping costs', exclude: 0, caption: 'Total shipping costs, in float 2 decimal points, 14.57'},
  tt: {title: 'transaction tax', exclude: 0, caption: 'Total tax, in float 2 decimal points, 14.57'},
  cu: {title: 'transaction currency', exclude: 0, caption: 'Currency, 3 letter code, e.g. EUR'},
  tcc: {title: 'transaction coupon', exclude: 0, caption: 'Transaction coupon for purchases or refunds.'},

//enhanced e-commerce
  pa: {title: 'product action', exclude: 0, caption: 'Can be: click, add, remove, checkout'},
  pal: {title: 'product action list', exclude: 0, caption: 'when product action is "detail or click" you can set list here, e.g. Search Results'},
  cos: {title: 'check out step', exclude: 0, caption: 'when product action is "checkout"'},
  col: {title: 'check out step option', exclude: 0, caption: 'when product action is "checkout"'},


//Product list, nested field
  pr: {title: 'product list', exclude: 0, caption: 'Array, enhanced ecommerce, if pr is present, check keys below to match if all is well.'},
  'pr.id': {title: 'product ID', exclude: 0, caption: 'ID of the product'},
  'pr.nm': {title: 'product name', exclude: 0, caption: 'Name'},
  'pr.br': {title: 'product brand', exclude: 0, caption: 'Brand'},
  'pr.ca': {title: 'product category', exclude: 0, caption: 'Category'},
  'pr.va': {title: 'product variant', exclude: 0, caption: 'Variant, e.g. colour'},
  'pr.pr': {title: 'product price', exclude: 0, caption: 'Price, in float, 2 decimal, e.g. 29.20'},
  'pr.qt': {title: 'product quantity', exclude: 0, caption: 'Quantity'},
  'pr.cc': {title: 'product coupon code', exclude: 0, caption: 'Coupon code, SUMMERSALE12?'},
  'pr.ps': {title: 'product position', exclude: 0, caption: 'Product position in list, between 1 and 200'},

//Product impressions
  il: {title: 'impressions list', exclude: 0, caption: 'Array, product impressions list'},
  'il.nm': {title: 'impressions list name', exclude: 0, caption: 'product impressions list name, required'},
  'il.pi': {title: 'product impression list', exclude: 0, caption: 'Array, products'},

  // doubling of pr.
  // 'il.pi.id': {title: 'product ID', exclude: 0, caption: 'ID of the product'},
  // 'il.pi.nm': {title: 'product name', exclude: 0, caption: 'Name'},
  // 'il.pi.br': {title: 'product brand', exclude: 0, caption: 'Brand'},
  // 'il.pi.ca': {title: 'product category', exclude: 0, caption: 'Category'},
  // 'il.pi.va': {title: 'product variant', exclude: 0, caption: 'Variant, e.g. colour'},
  // 'il.pi.pr': {title: 'product price', exclude: 0, caption: 'Price, in float, 2 decimal, e.g. 29.20'},
  // 'il.pi.ps': {title: 'product position', exclude: 0, caption: 'Product position in list, from 1 to 200'},

// Promotions
  promo: {title: 'promotion list', exclude: 0, caption: 'Array, promotions'},
  'promo.id': {title: 'promotion ID', exclude: 0, caption: 'ID of the promotion'},
  'promo.nm': {title: 'promotion name', exclude: 0, caption: 'Name of the promotion'},
  'promo.cr': {title: 'promotion creative', exclude: 0, caption: 'Creative associated with promotion'},
  'promo.ps': {title: 'promotion position', exclude: 0, caption: 'Slot of the banner position'},
  'promo.pa': {title: 'promotion action', exclude: 0, caption: 'Action, "view" if assumed, otherwise set to "promo_click"'},


//item hit, deprecated
  in: {title: 'item name', exclude: 0, caption: 'item name, required for item hit type, deprecated'},
  ip: {title: 'item price', exclude: 0, caption: 'item price, in float 2 decimal points, deprecated'},
  iq: {title: 'item quantity', exclude: 0, caption: 'item quantity, optional defaults to 1, deprecated'},
  ic: {title: 'item code', exclude: 0, caption: 'item code, optional, can be SKU code, deprecated'},
  iv: {title: 'item category', exclude: 0, caption: 'item category, deprecated'},

//social interactions
  sn: {title: 'social network', exclude: 0, caption: 'not in use'},
  sa: {title: 'social action', exclude: 0, caption: 'e.g. like, not in use'},
  st: {title: 'social target', exclude: 0, caption: 'click, url, not in use'},

//user timing
  utv: {title: 'user timing variable', exclude: 0, caption: 'e.g. lookup, not in use'},
  utt: {title: 'user timing time in milliseconds', exclude: 0, caption: 'not in use'},
  utc: {title: 'user timing category', exclude: 0, caption: 'not in use'},
  utl: {title: 'user timing label', exclude: 0, caption: 'not in use'},
  plt: {title: 'page load time', exclude: 0, caption: 'not in use'},
  dns: {title: 'dns load time', exclude: 0, caption: 'not in use'},
  pdt: {title: 'page download time', exclude: 0, caption: 'not in use'},
  rrt: {title: 'page redirect time', exclude: 0, caption: 'not in use'},
  tcp: {title: 'tcp connection time', exclude: 0, caption: 'not in use'},
  srt: {title: 'server response time', exclude: 0, caption: 'not in use'},
  dit: {title: 'dom interactive time', exclude: 0, caption: 'not in use'},
  clt: {title: 'content load time', exclude: 0, caption: 'not in use'},

//exceptions
  exd: {title: 'exception description', exclude: 0, caption: 'not in use'},
  exf: {title: 'exception fatal', exclude: 0, caption: 'not in use'},

//experiments
  xid: {title: 'experiment id', exclude: 0, caption: 'not in use'},
  xvar: {title: 'experiment variant', exclude: 0, caption: 'e.g. 1 of 2, a/b'},

//for tracking apps
  an: {title: 'app name used to track', exclude: 0, caption: 'not in use'},
  ai: {title: 'app id used to track', exclude: 0, caption: 'not in use'},
  av: {title: 'app version used to track', exclude: 0, caption: 'not in use'},
  aiid: {title: 'app installer identifier', exclude: 0, caption: 'e.g. com.platform.vending, used to track, currently not in use'},
};