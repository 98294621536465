import React from 'react';
import {formatDateTime} from "../../../lib/normalize_values";
import {Tag} from "../../../components/tag/tag";
import {Icon, pencil, live_active} from "../../../components/icon/icon";
import {colors} from "../../../theme/colors";


const EventRow = (history, state, date_last_build, idx, event, openEvent) => {
  return <tr key={idx}>

    <td className='clickable' onClick={() => openEvent(event, idx)}>
      <p className='verticalpadding'>{event.name}</p>
    </td>

    <td>
      <p className='verticalpadding'>{{pageload: 'Pageview', click: 'Click event', datalayer: 'Data layer'}[event.type]}</p>
    </td>

    <td>
      <p className='verticalpadding'>{event.is_active ? 'active' : 'inactive'}</p>
    </td>

    <td className='row'>
      <p className='verticalpadding' style={{marginRight: 6}}>{formatDateTime(event.date_updated)}</p>
      {state !== 'implementing' && date_last_build < event.date_updated && (
        <Tag>not yet published</Tag>
      )}
    </td>

    <td>
      <div className='row'>

        {state !== 'implementing' && <Icon size={24} className='clickable' icon={live_active} color={colors.brand.primary} onClick={() => history.push('/live', {event: event.name})}/>}

        <Icon size={24} className='clickable' icon={pencil} color={colors.brand.primary} onClick={() => openEvent(event, idx)}/>

      </div>
    </td>

  </tr>
};

export default EventRow;
