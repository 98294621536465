
export const normalizeRuleEvents = (events, type) => {
  // normalizes event data for frontend
  let normalizedEvents = [];

  events.map((event) => {
    return normalizedEvents.push({
      ...event,
      type: type
    });
  });

  return normalizedEvents;
};

