import React, {useState} from 'react';
import {getPlan, sliderData} from "../../lib/priceData";
import {Slider} from "../slider/Slider";
import {toLocale} from "../../lib/helpers";

import './priceSelector.scss';
import {PriceExplainer} from "./priceExplainer";
import {Explainer, PopupPosition} from "../popup";
import {PriceTable} from "./priceTable";

export const PriceSelector = ({selectPlan, pending, uuid}) => {

  const [activePlan, setPlan] = useState(getPlan(sliderData.defaultValue));

  const setSelectedCallback = (plan, puuid) => {
    setPlan(plan);
    selectPlan(plan, puuid);
  };

  // Swipe on small plans?? Or make fonts smaller
  // const [showSwipe, setSwipe] = useState(false);
  // useLayoutEffect(() => {
  //   const updateWidth = () => setSwipe(window.innerWidth < 700);
  //   window.addEventListener('resize', updateWidth);
  //   updateWidth();
  //   return () => window.removeEventListener('resize', updateWidth);
  // }, []);

  return (
    <div className='priceSelector'>

      <div className='title'>
        My website(s) have

        <Explainer
          position={PopupPosition.BOTTOM_CENTER}
          message='Our price is based on your total pageviews that you currently report in Google Analytics, across all your domains.'
          word={<span className='bold' style={{fontSize: '1.1rem', margin: '0 6px'}}>
          {toLocale(activePlan.page_views)}{activePlan.step >= sliderData.max && '+'}
        </span>}/>
        page views per month</div>

      <Slider
        min={sliderData.min}
        max={sliderData.max}
        step={sliderData.step}
        defaultValue={sliderData.defaultValue}
        onChange={(slider) => setPlan(getPlan(slider))}
        style={{maxWidth: '520px', marginBottom: 30, marginTop: 14, padding: '0 32px 0 26px'}}
      />

      <PriceTable
        activePlan={activePlan}
        isLoading={pending}
        selectPlan={setSelectedCallback}
        uuid={uuid}
      />

      <PriceExplainer/>

    </div>
  )
};
