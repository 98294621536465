import React from "react";
import {connect} from "react-redux";

import {Loader} from "../../components/loader/loader";
import {rulesActions} from "../../redux/actions";
import {InputCard} from "../../components/cards/inputCard";
import {updateIfNeeded} from "../../lib/helpers";
import {PageLayout} from "../../components/layout/pageLayout";
import {BlankCard} from "../../components/cards/blankCard";
import {Javascript} from "../../components/javascript/javascript";
import {InstallationCode} from "../../lib/installation_code";
import {CNAMEcomponent} from "../../components/installation/cname";

class DevPage extends React.Component {

  constructor(props, context) {
    super(props, context);
    this.props.getDev({uuid: this.props.persist.domain.id})
    // props.persist.domain.id && updateIfNeeded(
    //   this.props.rules.dev_updated,
    //   () => this.props.getDev({uuid: this.props.persist.domain.id}));
  }

  render({rules, persist, dashboard, updateCreditor, publishBuild} = this.props) {
    return (
      <PageLayout title='Technical settings'
                  date_last_build={rules.date_last_build}
                  requires_publish={rules.requires_publish}
                  publishPending={rules.isPending}
                  hasPublish={!rules.isPending && persist.domain.state === 'live'}
                  onPublish={(message) => publishBuild({uuid: persist.domain.id, message: message})}
                  history={this.props.history} domain={persist.domain}>

        {rules.isPending && (<Loader style={{marginTop: '20vh', marginLeft: '48%'}}/>)}

        {!rules.isPending && (
          <InputCard
            title='Debug or (dis)able temporarily'
            subtitle='Setup your technical details'
            onSave={(data) => this.props.updateDev({
              ...data,
              id: persist.domain.id
            })}
            initialData={rules.dev}
            objects={[
              {
                key: 'debug',
                label: 'Enable debug mode',
                type: 'text',
                choices:[{key: 0, value: 'false'}, {key: 1, value: 'true'}],
                help: 'If true, TraceDock debug info is shown in the console.log of the browser.'
              },
              {
                key: 'is_active',
                label: 'TraceDock is active',
                type: 'text',
                choices:[{key: false, value: 'false'}, {key: true, value: 'true'}],
                help: 'If false, TraceDock code will not load any javascript and/or send data.'
              }

            ]}
          />
        )}


        {!rules.isPending && (
          <BlankCard
            title='TraceDock settings'
            subtitle='Review your installation settings'
          >
            <div style={{marginBottom: 12}}>
              <h5 style={{marginTop: 6}} className='spacer'>1. Adjust DNS</h5>
              <p >Add the following <span className='bold'>A-record</span> to your <span
                className='bold'>DNS</span> configuration</p>

              {CNAMEcomponent(rules.dev)}

              <h5 style={{marginTop: 18}} className='spacer'>2. Install code</h5>
              <p >Copy and paste the code snippet as high in the <span className='bold'>{`<head>`}</span> of your
                website as possible, outside of any GTM container.</p>
              <Javascript title='Code' value={InstallationCode(rules.dev)}/>
            </div>

          </BlankCard>
        )}

      </PageLayout>
    );
  }
}

const mapStateToProps = (state) => ({
  persist: state.persist,
  rules: state.rules
});

const mapDispatchToProps = (dispatch) => {
  return {
    getDev: (data) => dispatch(rulesActions.getDev(data)),
    updateDev: (data) => dispatch(rulesActions.updateDev(data)),
    publishBuild: (d) => dispatch(rulesActions.publishBuild(d)),
  }
};

export default DevPage = connect(mapStateToProps, mapDispatchToProps)(DevPage);
