import React from "react";
import {connect} from "react-redux";

import './signing.scss';
import {Loader} from "../../components/loader/loader";
import {accountActions} from "../../redux/actions";
import {formatDate, todayShortFormat} from "../../lib/normalize_values";
import {slugify} from "../../lib/helpers";
import {getWiki} from "../../components/wiki/wiki";
import ReactMarkdown from 'react-markdown';


class SigningPage extends React.Component {

  _set_and_print = (file, name, agency = null) => {
    this.setState({isPending: false, agency: agency},
      () => {
        document.title = `${todayShortFormat()}_${file}_${slugify(name)}`;
        setTimeout(() => {
          window.print();
        }, 1500)
      })

  };

  constructor(props, context) {
    super(props, context);

    // depending on the url parameters, we either update the creditor or agency
    this.state = {
      document: props.match.params.document,
      uuid: props.match.params.uuid,
      agency: {},
      isPending: true,
      content: '',
      today: formatDate(new Date()),
    };
  }

  componentDidMount() {

    this.props.getAgencySignData({uuid: this.state.uuid})
      .then((agency) => {
        if (agency) {
          getWiki(`${this.state.document}.md`, {agency: agency, today: this.state.today}, true).then(
            (content) => {
              if (content !== '') {
                this.setState({content: content, isPending: false, agency: agency});
                this._set_and_print(this.state.document, agency.company_name, agency);
              }
            }
          )
        }
      });

  }

  render({account} = this.props) {

    return (
      <div className='signingPage'>

        {(account.isPending || this.state.isPending) && <Loader/>}

        {!account.isPending && !this.state.isPending && (
          <table className="report-container">

            <thead className="report-header">
            <tr>
              <th className="report-header-cell">
                <div className="header-info" style={{display: 'flex', justifyContent: 'flex-end', paddingBottom: 18}}>
                  <img alt='tracedock' height='20px' src={require('../../static/images/logo_grey.png')}/>
                </div>
              </th>
            </tr>
            </thead>

            <tfoot className="report-footer">
            <tr>
              <td className="report-footer-cell">
                <div className="footer-info">

                  <div className='initials'>
                    <p className='initial small'>initials TraceDock</p>
                    <p className='initial small'>initials Partner</p>
                    <img className='signature' alt='signature' src={require('./res/signature.png')}/>
                  </div>

                  <p className='small center'><span className='bold'>TraceDock B.V.</span> | Ganzenmarkt 6, 3512 GD
                    Utrecht | +31 (0)85 747 04 75 | hello@tracedock.com</p>
                </div>

              </td>
            </tr>
            </tfoot>

            <tbody className="report-content">

            <tr>
              <td className="report-content-cell">
                <div className="main">

                  <ReactMarkdown source={this.state.content}/>

                  <p>In witness whereof, the parties have executed this Agreement on the date first written above:</p>

                  <div style={{
                    margin: '0 0 150px 0',
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between'
                  }}>
                    <div style={{width: '35%'}} className='signed'>
                      <p style={{marginBottom: 12}}>Signed on behalf of TraceDock:</p>
                      <p>Name: <span className='name bold'>Boris Schellekens</span></p>
                      <p>Title: <span className='name bold'>Co-founder</span></p>
                      <p>Date: <span className='name bold'>{this.state.today}</span></p>
                      <img className='signature' alt='signature' src={require('./res/signature.png')}/>
                    </div>

                    <div style={{width: '35%'}}>
                      <p style={{marginBottom: 12}}>Signed on behalf of Partner:</p>
                      <p>Name:</p>
                      <p>Title:</p>
                      <p>Date:</p>
                    </div>
                  </div>

                </div>
              </td>
            </tr>
            </tbody>
          </table>
        )}

      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  account: state.account,
});

const mapDispatchToProps = (dispatch) => {
  return {
    getAgencySignData: (data) => dispatch(accountActions.getAgencySignData(data)),
  }
};

export default SigningPage = connect(mapStateToProps, mapDispatchToProps)(SigningPage);
