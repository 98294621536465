import React from "react";

import {colors} from "../../../theme/colors";

import {BlankCard} from "../../../components/cards/blankCard";
import {Input} from "../../../components/input/input";
import {SideModal} from "../../../components/sideModal/sideModal";
import {Button} from "../../../components/button/button";
import {setAlertDialog} from "../../../components/alert/alert";
import {classifyJsonAvailableFields} from "../../../components/jsonRaw/json.classify.availableFields";
import {Resize} from "../../../components/resize/resize";
import {classifyJsonSelectedFields} from "../../../components/jsonRaw/json.classify.selectedFields";
import {Loader} from "../../../components/loader/loader";
import {deleteNested} from "../../../lib/helpers";
import {CardIdentifyRawData} from "./card.identify.rawData";
import {CardIdentifyTriggers, validate_identify_triggers} from "./card.identify.triggers";
import {CardIdentifyFields, validate_identify_fields} from "./card.identify.fields";

const baseIdentifyEvent = {

  name: 'Identify user',
  is_active: true,
  data: {'userId': '<dummy_value>'},
  triggers: [{
    key: 'userId',
    lookup_type: 'ex',
    value: '',
    action: 'add'
  }],

  // setting up fields is a bit of a hack to get the key into the proper format
  key: 'userId',
  fields: [{
    field_name: 'uid',
    field_type: 'l',
    field_value: 'userId',
    script: '',
    action: 'add',
    children: -1,
  }],

  availableFieldPaths: [],
  selectedFieldPaths: [],
}

export class IdentifyEventModal extends React.Component {

  constructor(props, context) {
    super(props, context);

    this.state = {
      currentIdentifyEvent: {},
      identify_event_idx: this.props.identify_event_idx,
      isUpdated: false,
      isLoading: true,
      invalidObjects: {},
    };
  }

  componentDidMount() {
    // setup new or existing event
    if (this.props.identify_event_idx === -1) {
      this.setState({
        currentIdentifyEvent: {
          ...baseIdentifyEvent,
          availableFieldPaths: classifyJsonAvailableFields(baseIdentifyEvent.data),
          selectedFieldPaths: classifyJsonSelectedFields(baseIdentifyEvent.fields),
        },
        identify_event_idx: -1,
        isLoading: false
      })
    } else {
      let identifyEvent = this.props.identify_events[this.props.identify_event_idx];
      this.setState({
        currentIdentifyEvent: {
          ...identifyEvent,
          availableFieldPaths: classifyJsonAvailableFields(identifyEvent.data),
          selectedFieldPaths: classifyJsonSelectedFields(identifyEvent.fields),
          fields: [{...baseIdentifyEvent.fields[0], field_value: identifyEvent.key, script: identifyEvent.script}],
        },
        isLoading: false,
        identify_event_idx: this.props.identify_event_idx,
      })
    }
  }

  _update_element(key, value) {
    let newData = {...this.state.currentIdentifyEvent};
    newData[key] = value;
    this.setState({isUpdated: true, currentIdentifyEvent: newData});
  }

  _update_rawData(data) {
    // raw data exports slightly more keys, so custom function
    // we take the data as is, but we parse the available fields that are possible.
    let newData = {
      ...this.state.currentIdentifyEvent,
      data: data,
      availableFieldPaths: classifyJsonAvailableFields(data),
    };
    this.setState({isUpdated: true, currentIdentifyEvent: newData});
  }

  _update_fields(fields, callback = null) {
    // DataFields updates two elements, both the fields as well as the selectedFields
    let newData = {
      ...this.state.currentIdentifyEvent,
      fields: fields,
      selectedFieldPaths: classifyJsonSelectedFields(fields),
    };
    this.setState({isUpdated: true, currentIdentifyEvent: newData}, () => {
      if (callback) callback()
    });
  }

  _save_events_in_api = (events) => {
    this.setState({isLoading: true}, () => {
      this.props.onSave(events)
        .then((d) => {
          if (d && d.identify_events) {
            this._reset();
          } else {
            this.setState({isLoading: false, isUpdated: true})
          }
        })
    });
  }

  _save() {

    // validates element, if all valid, saves
    let invalidObjects = {};
    let currentIdentifyEvent = this.state.currentIdentifyEvent;

    // check basic data
    if (currentIdentifyEvent.name.length === 0) invalidObjects['name'] = true;
    if (![true, false].includes(currentIdentifyEvent.is_active)) invalidObjects['is_active'] = true;

    // check validators
    invalidObjects = {
      ...invalidObjects,
      ...validate_identify_fields(currentIdentifyEvent.fields),
      ...validate_identify_triggers(currentIdentifyEvent.triggers),
    };

    // add key from field
    currentIdentifyEvent.key = currentIdentifyEvent.fields[0].field_value;
    currentIdentifyEvent.script = currentIdentifyEvent.fields[0].script;

    // either return or populate, invalidObjects
    if (Object.entries(invalidObjects).length === 0) {

      // delete all deleted keys of fields
      currentIdentifyEvent.date_updated = new Date();
      currentIdentifyEvent.fields = deleteNested(currentIdentifyEvent.fields);
      currentIdentifyEvent.triggers = deleteNested(currentIdentifyEvent.triggers);

      // update identifyEvent list with this event
      let newidentifyEvents = [];
      if (this.state.identify_event_idx === -1) {
        newidentifyEvents = [this.state.currentIdentifyEvent].concat(this.props.identify_events);
      } else {
        newidentifyEvents = [
          ...this.props.identify_events.slice(0, this.state.identify_event_idx),
          this.state.currentIdentifyEvent,
          ...this.props.identify_events.slice(this.state.identify_event_idx + 1)
        ]
      }
      this._save_events_in_api(newidentifyEvents);

    }
    this.setState({invalidObjects: invalidObjects})
  }

  _delete() {
    let newidentifyEvents = [
      ...this.props.identify_events.slice(0, this.state.identify_event_idx),
      ...this.props.identify_events.slice(this.state.identify_event_idx + 1)
    ]
    this._save_events_in_api(newidentifyEvents);
  }

  _reset() {
    this.setState({isLoading: true}, () => this.props.onClose())
  }

  render() {

    return (
      <SideModal
        title='Update identify event'
        show={true}
        onClose={() => this.props.onClose()}
        isUpdated={this.state.isUpdated}
        isLoading={this.state.isLoading}
        onSave={() => this._save()}
        onReset={() => this._reset()}
      >

        {this.state.isLoading && (<Loader style={{marginTop: '20vh', marginLeft: '48%'}}/>)}

        {!this.state.isLoading && (
          <div style={{width: '100%'}}>
            <BlankCard
              title='Basics'>

              <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>

                <Input
                  style={{maxWidth: 300}}
                  initial={this.state.currentIdentifyEvent.name}
                  label='Name'
                  type='text'
                  isInvalid={this.state.invalidObjects['name']}
                  onChange={(value) => this._update_element('name', value)}
                  readOnly={false}
                />
                <div style={{marginLeft: 12}}>
                  <Input
                    style={{maxWidth: 300}}
                    initial={this.state.currentIdentifyEvent.is_active}
                    label='State'
                    type='text'
                    restrict={true}
                    choices={[{key: true, value: 'active'}, {key: false, value: 'inactive'}]}
                    isInvalid={this.state.invalidObjects['is_active']}
                    onChange={(value) => this._update_element('is_active', value)}
                    readOnly={false}
                  />
                </div>

              </div>

            </BlankCard>

            <Resize
              minLeft={500}
              minRight={200}
              startLeft={700}
              leftChild={
                <div>
                  <h5 style={{marginLeft: 6}}>INCOMING DATA</h5>
                  <CardIdentifyRawData
                    data={this.state.currentIdentifyEvent.data}
                    selectedFieldPaths={this.state.currentIdentifyEvent.selectedFieldPaths}
                    readOnly={false}
                    onUpdate={(data) => this._update_rawData(data)}
                  />
                  <CardIdentifyTriggers
                    triggers={this.state.currentIdentifyEvent.triggers}
                    availableFieldPaths={this.state.currentIdentifyEvent.availableFieldPaths}
                    readOnly={false}
                    invalidObjects={this.state.invalidObjects}
                    onUpdate={(value) => this._update_element('triggers', value)}
                  />
                </div>
              }
              rightChild={
                <div>
                  <h5 style={{marginLeft: 6}}>IDENTIFY FIELDS</h5>
                  <CardIdentifyFields
                    domain={this.props.domain}
                    template={this.state.currentIdentifyEvent.template}
                    fields={this.state.currentIdentifyEvent.fields}
                    data={this.state.currentIdentifyEvent.data}
                    availableFieldPaths={this.state.currentIdentifyEvent.availableFieldPaths}
                    readOnly={false}
                    invalidObjects={this.state.invalidObjects}
                    setTemplate={(template) => this._update_element('template', template)}
                    onUpdate={(fields, callback) => this._update_fields(fields, callback)}
                  />
                </div>
              }
            />
          </div>)}

        {!this.state.isLoading && this.state.identify_event_idx > -1 && (
          <Button
            title='Delete identify event'
            backgroundColor={colors.brand.gray_light}
            color={colors.brand.darkgray_light}
            cancel={true}
            style={{width: 150, marginTop: 70}}
            onClick={() => {
              setAlertDialog('Delete identify event', 'Are you sure you want to delete this identify event?', '',
                [
                  {
                    title: 'yes',
                    backgroundColor: colors.brand.gray,
                    onPress: () => this._delete()
                  },
                  {title: 'cancel', backgroundColor: colors.brand.primary},
                ])
            }}
          />
        )}
      </SideModal>
    );
  }
}

