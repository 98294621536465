import React, {memo, useCallback} from 'react'
import {PropTypes} from 'prop-types';
import {Input} from '../../input/input';
import styles from './field.module.scss';
import {Tag} from "../../tag/tag";

const InputField = ({name, value, onChange, children, optional, ...rest}) => {
  const onChangeCallback = useCallback(
    (newValue) => {
      onChange(name, newValue);
    },
    [name, onChange]
  );
  return (
    <div className={styles.inputField}>
      {children}
      <Input onChange={onChangeCallback} initial={value} {...rest} />

      {optional && (
        <Tag style={{marginLeft: 12}} className='small'>optional</Tag>
      )}
    </div>
  )
};

InputField.propTypes = {
  name: PropTypes.string,
  value: PropTypes.any,
  onChange: PropTypes.func,
  optional: PropTypes.bool,
  children: PropTypes.node
};

InputField.defaultProps = {
  optional: false
};

export default memo(InputField);
